import { CardEntryView } from "../../../../feature";
import { InfiniteScroll, Loader, MasonryGrid } from "../../../../components";
import { useParams } from "react-router-dom";
import { useMediaQuery, useTheme } from "@chakra-ui/react";
import { useToast } from "../../../../hooks";
import { isEmpty } from "lodash";
import { EmptyState } from "./EmptyState";
import { useGetCardEntriesByCardIdQuery } from "../../../../graphql/generated/schema";

export function CardEntryList() {
  const { cardID } = useParams();
  const baseInput = {
    limit: 25, // TODO: Handle pagination wierdness. If you use limit of 2, and delete the top 2, pagination is returns nothing
    cardID: cardID ?? "",
    // sortAsc: false, // TODO: implement incase we want to sort by asc/desc
  };
  const theme = useTheme();
  // TODO: Use dynamic breakpoint pattern inside MasonryGrid to allow any component have logic.
  const { sm, md, lg } = theme?.breakpoints;
  const [isLargerThanSM] = useMediaQuery(`(min-width: ${sm})`);
  const [isLargerThanMD] = useMediaQuery(`(min-width: ${md})`);
  const [isLargerThanLG] = useMediaQuery(`(min-width: ${lg})`);

  let colSize = 1;
  colSize = isLargerThanSM ? 1 : colSize;
  colSize = isLargerThanMD ? 2 : colSize;
  colSize = isLargerThanLG ? 3 : colSize;

  const {
    fetchMore,
    data,
    error,
    loading: isLoading,
  } = useGetCardEntriesByCardIdQuery({
    variables: {
      input: baseInput,
    },
  });
  const isError = !!error;
  const items = data?.getCardEntriesByCardId.items;
  const allData = items;

  function onFetchNextPage() {
    fetchMore({
      variables: { input: { ...baseInput, nextToken: newNextToken } },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const oldValues = previousResult.getCardEntriesByCardId?.items
          ? previousResult.getCardEntriesByCardId.items
          : [];
        const newValues = fetchMoreResult.getCardEntriesByCardId?.items
          ? fetchMoreResult.getCardEntriesByCardId.items
          : [];

        return {
          ...previousResult,
          getCardEntriesByCardId: {
            ...fetchMoreResult.getCardEntriesByCardId,
            nextToken: fetchMoreResult.getCardEntriesByCardId.nextToken,
            items: [...oldValues, ...newValues],
          },
        };
      },
    });
  }

  const newNextToken = data?.getCardEntriesByCardId.nextToken;
  const hasMore = !!newNextToken;

  // Toasts
  useToast({
    show: isError,
    id: "get-card-entries-fail",
    description: "Could not load card entries.",
    status: "error",
  });

  if (isLoading) {
    return <Loader />;
  }

  // if (isEmpty(allData)) {
  //   return <EmptyState />;
  // }

  return allData?.length ? (
    <InfiniteScroll
      dataLength={allData.length} //This is important field to render the next data
      next={onFetchNextPage}
      hasMore={hasMore}
    >
      <MasonryGrid columns={colSize} space={20}>
        {allData?.map((entry) => (
          <CardEntryView entry={entry} key={entry?.id} />
        ))}
      </MasonryGrid>
    </InfiniteScroll>
  ) : null;
}
