export const ROUTES = {
  HOME: "/",
  LOGIN: "/login",
  DASHBOARD: "/dashboard",
  PRICING: "/pricing",
  CARD: "/card/:cardID",
  CARD_ENTRY: "/card/:cardID/card-entry/:id",
  CREATE_CARD: "/create-card",
  MY_ACCOUNT: "my-account",
  SELECT_CARD_TYPE: {
    url: "/:cardID/select-card-type",
    get({ cardID }: { cardID?: string }) {
      return `/${cardID}/select-card-type`;
    },
  },
};
