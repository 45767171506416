export * from "./products";

// Mocks
export let IS_LOCAL_MOCK_ON = false;
// IS_LOCAL_MOCK_ON = true;

type Env = "local" | "dev" | "prod";
export const CURRENT_ENV: Env = process.env.REACT_APP_ENV as Env;

// export const IS_PROD = process.env.NODE_ENV === "production";
export const IS_PROD = CURRENT_ENV === "prod";
export const IS_LOCAL = CURRENT_ENV === "local";
export const IS_DEV = CURRENT_ENV === "dev";

export const WEBSITE_HOME = IS_LOCAL
  ? "http://localhost:3000"
  : IS_DEV
  ? "https://dev.cardoly.com"
  : "https://cardoly.com";

export const MAX_FREE_CARDOLY_ENTRIES = 10;
// export const SEARCH_PARAM_ONE_TIME_PAYMENT_PAYMENT_SUCCESS = "ot-success";
export const SEARCH_PARAM_SUBSCRIPTION = "cardoly-sub";
export const SEARCH_PARAM_SUBSCRIPTION_WITH_CARD = "cardoly-sub-with-card";
export const SEARCH_PARAM_PAYMENT_FOR_KEY = "payment_for";
export const SEARCH_PARAM_PAYMENT_FOR = {
  SINGLE_CARD: "single-card",
  SUB_W_CARD: "sub-w-card",
  SUB_ONLY: "sub-only",
  ADD_CARD_TO_EXISTING_SUB: "add-card-to-existing-sub",
};
// payment_method=SINGLE_CARD | SUB_W_CARD | SUB_ONLY

export function getSLSGraphQLEndpoint(isPublic = false) {
  const path = isPublic ? "graphql-p" : "graphql";

  switch (CURRENT_ENV) {
    case "local": {
      return `http://localhost:4000/local/${path}`;
    }
    case "dev": {
      return `https://rhsjh1aa5l.execute-api.us-east-1.amazonaws.com/dev/${path}`;
    }
  }

  // prod
  return `https://61j9ry65se.execute-api.us-east-1.amazonaws.com/prod/${path}`;
}
// PICK DB - END

export const USE_FIXTURES = IS_LOCAL_MOCK_ON && !IS_PROD;
export const PRODUCT_NAME_SINGULAR = "Cardoly";
export const PRODUCT_NAME_PLURAL = "Cardolies";

export const VIRTUAL_CARD_ENTRY_ID = "VIRTUAL_CARD_ENTRY_ID";
export const PHOTOS_PER_PAGE = 30;
export const MAX_PHOTOS_TO_FETCH = 120;
