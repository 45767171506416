import {
  Button,
  Heading,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  Text,
  useClipboard,
  VStack,
  useToast,
} from "@chakra-ui/react";
import React from "react";
import { FiLink2 as CopyIcon, FiCheck } from "react-icons/fi";
import { useModal, useRoutes } from "../../hooks";

interface ShareCardolyModalProps {
  cardID: string;
  isSingleEntryCard?: boolean;
  isViewOnly?: boolean;
}

const SUCCESS_NOTIFICATION_DURATION_MILLISECONDS = 2000;

export function ShareCardolyModal({
  cardID,
  isSingleEntryCard,
  isViewOnly,
}: ShareCardolyModalProps) {
  const showViewOnlyMode = isViewOnly || isSingleEntryCard;
  const { inviteParticipantUrl, inviteParticipantUrlWithoutCardId } =
    useRoutes();
  const currentInviteParticipantUrl = cardID
    ? `${inviteParticipantUrlWithoutCardId}/${cardID}` // If cardId is passed from dashboard page, use that instead
    : inviteParticipantUrl;
  const { hideModal } = useModal();

  // const inviteParticipantUrl = `${window.location.origin}/card/${cardID}`;
  // const viewOnlyUrl = `${inviteParticipantUrl}?mode=r`;
  const clipboardConfig = {
    timeout: SUCCESS_NOTIFICATION_DURATION_MILLISECONDS,
  };
  const {
    hasCopied: hasCopiedInviteParticipantLink,
    onCopy: onCopyInviteParticipantLink,
  } = useClipboard(currentInviteParticipantUrl, clipboardConfig);
  // const { hasCopied: hasCopiedViewOnlyLink, onCopy: onCopyViewOnlyLink } =
  //   useClipboard(viewOnlyUrl, clipboardConfig);

  const toast = useToast();

  React.useEffect(() => {
    if (
      hasCopiedInviteParticipantLink
      // || hasCopiedViewOnlyLink
    ) {
      toast({
        id: "isSuccessSubmitPassword",
        status: "success",
        // title: "Success",
        position: "top",
        duration: SUCCESS_NOTIFICATION_DURATION_MILLISECONDS,
        description: "Copied link!",
        onCloseComplete() {
          hideModal();
        },
      });
    }
  }, [
    toast,
    hasCopiedInviteParticipantLink,
    // hasCopiedViewOnlyLink,
    hideModal,
  ]);

  return (
    <>
      <ModalCloseButton />
      <ModalHeader>Share Cardoly</ModalHeader>
      <ModalBody>
        <VStack spacing="lg">
          {/* View Only */}
          {/* <VStack spacing="sm" alignItems="start" w="full">
            <Heading color="gray.700" fontSize={"md"}>
              Recipient Link
            </Heading>
            <Text color="gray.500" fontSize={"sm"}>
              Share this link with the recipient.
            </Text>
            <HStack w="full">
              <Button
                w="full"
                onClick={onCopyViewOnlyLink}
                aria-label="copy"
                leftIcon={hasCopiedViewOnlyLink ? <FiCheck /> : <CopyIcon />}
                variant={hasCopiedViewOnlyLink ? "outline" : "solid"}
                colorScheme={hasCopiedViewOnlyLink ? "green" : "brand"}
              >
                {hasCopiedViewOnlyLink ? "Copied!" : "Copy recipient link"}
              </Button>
            </HStack>
          </VStack> */}

          {/* Invite participants */}
          <VStack spacing="sm" alignItems="start" w="full">
            {!showViewOnlyMode && (
              <Heading color="gray.700" fontSize={"md"}>
                Invite Contributor Link
              </Heading>
            )}
            <Text color="gray.500" fontSize={"sm"}>
              {`Share this link with others ${
                showViewOnlyMode
                  ? "to view your Cardoly"
                  : "to add their entries."
              }`}
            </Text>
            <VStack w="full">
              <Button
                w="full"
                onClick={onCopyInviteParticipantLink}
                aria-label="copy"
                leftIcon={
                  hasCopiedInviteParticipantLink ? <FiCheck /> : <CopyIcon />
                }
                variant={"outline"}
                colorScheme={hasCopiedInviteParticipantLink ? "green" : "brand"}
              >
                {hasCopiedInviteParticipantLink ? "Copied!" : "Copy link"}
              </Button>
              <Button variant="link" onClick={onCopyInviteParticipantLink}>
                <Text
                  fontSize={"xs"}
                  fontWeight="normal"
                  color={hasCopiedInviteParticipantLink ? "green" : "gray.700"}
                >
                  {currentInviteParticipantUrl}
                </Text>
              </Button>
            </VStack>
          </VStack>
        </VStack>
      </ModalBody>
      <ModalFooter />
    </>
  );
}
