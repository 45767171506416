import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | undefined;
export type InputMaybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
};

export type AddCardEntryInput = {
  cardID: Scalars['ID']['input'];
  entryMessage?: InputMaybe<Scalars['String']['input']>;
  font?: InputMaybe<FontInput>;
  photo?: InputMaybe<PhotoInput>;
  signerCognitoID?: InputMaybe<Scalars['ID']['input']>;
  signerEmail?: InputMaybe<Scalars['String']['input']>;
  signerName: Scalars['String']['input'];
};

export type AddCardToSubscriptionInput = {
  cardId: Scalars['String']['input'];
  subscriptionId: Scalars['String']['input'];
};

export type AddCardToSubscriptionResponse = {
  __typename?: 'AddCardToSubscriptionResponse';
  card: Card;
  subscription: Subscription;
};

export type Card = {
  __typename?: 'Card';
  bgImageUrl?: Maybe<Scalars['String']['output']>;
  bgType?: Maybe<CardBgType>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deliveredAt?: Maybe<Scalars['DateTime']['output']>;
  entryCount?: Maybe<Scalars['Int']['output']>;
  id: Scalars['ID']['output'];
  isLandscape?: Maybe<Scalars['Boolean']['output']>;
  isLocked?: Maybe<Scalars['Boolean']['output']>;
  lastEntryCreatedAt?: Maybe<Scalars['DateTime']['output']>;
  lastEntryCreatedId?: Maybe<Scalars['String']['output']>;
  orderStatus?: Maybe<OrderStatus>;
  orderType?: Maybe<OrderType>;
  ownerCognitoID?: Maybe<Scalars['ID']['output']>;
  ownerName?: Maybe<Scalars['String']['output']>;
  pColorBg?: Maybe<Scalars['String']['output']>;
  pColorFg?: Maybe<Scalars['String']['output']>;
  pType?: Maybe<Scalars['String']['output']>;
  paymentIntentID?: Maybe<Scalars['String']['output']>;
  price?: Maybe<Scalars['Int']['output']>;
  readAt?: Maybe<Scalars['DateTime']['output']>;
  receiverEmail?: Maybe<Scalars['ID']['output']>;
  receiverName?: Maybe<Scalars['String']['output']>;
  showConfetti?: Maybe<Scalars['Boolean']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  subscriptionId?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  titleFontFamily?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum CardBgType {
  Image = 'image',
  Pattern = 'pattern'
}

export type CardEntry = {
  __typename?: 'CardEntry';
  cardID?: Maybe<Scalars['ID']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  entryMessage?: Maybe<Scalars['String']['output']>;
  font?: Maybe<Font>;
  id: Scalars['ID']['output'];
  photo?: Maybe<Photo>;
  signerCognitoID?: Maybe<Scalars['ID']['output']>;
  signerEmail?: Maybe<Scalars['String']['output']>;
  signerName?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export type CardolyPhotoCreator = {
  __typename?: 'CardolyPhotoCreator';
  firstName?: Maybe<Scalars['String']['output']>;
  lastName?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  username?: Maybe<Scalars['String']['output']>;
};

export type CardolyPhotoCreatorInput = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
};

export type CardolyPhotoSizes = {
  __typename?: 'CardolyPhotoSizes';
  md: PhotoSize;
  sm: PhotoSize;
};

export type CardolyPhotoSizesInput = {
  md: PhotoSizeInput;
  sm: PhotoSizeInput;
};

export type CardsToSignersInput = {
  emailedAt?: InputMaybe<Scalars['DateTime']['input']>;
  signerCognitoId?: InputMaybe<Scalars['String']['input']>;
  signerEmail: Scalars['String']['input'];
  signerName?: InputMaybe<Scalars['String']['input']>;
};

export type CardsToSignersObject = {
  __typename?: 'CardsToSignersObject';
  emailedAt?: Maybe<Scalars['DateTime']['output']>;
  signerCognitoId?: Maybe<Scalars['String']['output']>;
  signerEmail: Scalars['String']['output'];
  signerName?: Maybe<Scalars['String']['output']>;
};

export type CreateCardInput = {
  bgImageUrl?: InputMaybe<Scalars['String']['input']>;
  bgType?: InputMaybe<CardBgType>;
  isLandscape?: InputMaybe<Scalars['Boolean']['input']>;
  isLocked?: InputMaybe<Scalars['Boolean']['input']>;
  orderStatus?: InputMaybe<OrderStatus>;
  orderType?: InputMaybe<OrderType>;
  ownerName: Scalars['String']['input'];
  pColorBg?: InputMaybe<Scalars['String']['input']>;
  pColorFg?: InputMaybe<Scalars['String']['input']>;
  pType?: InputMaybe<Scalars['String']['input']>;
  paymentIntentID?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Int']['input']>;
  receiverName: Scalars['String']['input'];
  showConfetti?: InputMaybe<Scalars['Boolean']['input']>;
  title: Scalars['String']['input'];
  titleFontFamily?: InputMaybe<Scalars['String']['input']>;
};

export type CreateSubscriptionAndAddCardInput = {
  cardId: Scalars['String']['input'];
  subscriptionId: Scalars['String']['input'];
};

export type CreateSubscriptionAndAddCardResponse = {
  __typename?: 'CreateSubscriptionAndAddCardResponse';
  card: Card;
  subscription: Subscription;
};

export type CreateSubscriptionInput = {
  subscriptionId: Scalars['String']['input'];
};

export type CreateSubscriptionResponse = {
  __typename?: 'CreateSubscriptionResponse';
  subscription: Subscription;
};

export type DeleteCardInput = {
  cardId: Scalars['ID']['input'];
};

export type DeliverCardToReceiverInput = {
  cardId: Scalars['String']['input'];
  receiverEmail: Scalars['String']['input'];
  receiverName: Scalars['String']['input'];
};

export type EmailThanksToCardSignersInput = {
  cardId: Scalars['String']['input'];
  emailAll?: InputMaybe<Scalars['Boolean']['input']>;
  receiverMessage: Scalars['String']['input'];
  signerEmails?: InputMaybe<Array<Scalars['String']['input']>>;
};

export type EmailThanksToCardSignersResponse = {
  __typename?: 'EmailThanksToCardSignersResponse';
  email?: Maybe<Scalars['String']['output']>;
  error?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
};

export type Font = {
  __typename?: 'Font';
  color?: Maybe<Scalars['String']['output']>;
  fontFamily?: Maybe<Scalars['String']['output']>;
};

export type FontInput = {
  color?: InputMaybe<Scalars['String']['input']>;
  fontFamily?: InputMaybe<Scalars['String']['input']>;
};

export type GetCardEntriesByCardIdInput = {
  cardID: Scalars['String']['input'];
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
};

export type GetCardEntriesByCardIdResponse = {
  __typename?: 'GetCardEntriesByCardIdResponse';
  items?: Maybe<Array<CardEntry>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type GetCardReadId = {
  __typename?: 'GetCardReadId';
  readId?: Maybe<Scalars['String']['output']>;
};

export type GetCardSignersInput = {
  cardId: Scalars['String']['input'];
};

export type GetCardsByOwnerCognitoIdInput = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  sortAsc?: InputMaybe<Scalars['Boolean']['input']>;
};

export type GetCardsByOwnerCognitoIdResponse = {
  __typename?: 'GetCardsByOwnerCognitoIdResponse';
  items?: Maybe<Array<Card>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type GetOrCreatePaymentIntentApiResponse = {
  __typename?: 'GetOrCreatePaymentIntentApiResponse';
  paymentIntentID?: Maybe<Scalars['String']['output']>;
};

export type GetOrCreatePaymentIntentForSubscriptionApiInput = {
  productId: Scalars['String']['input'];
};

export type GetOrCreatePaymentIntentForSubscriptionApiResponse = {
  __typename?: 'GetOrCreatePaymentIntentForSubscriptionApiResponse';
  paymentIntentID?: Maybe<Scalars['String']['output']>;
  subscriptionId?: Maybe<Scalars['String']['output']>;
};

export type GetOrCreatePaymentIntentInput = {
  cardId: Scalars['String']['input'];
};

export type GetSubscriptionByOwnerId = {
  limit?: InputMaybe<Scalars['Int']['input']>;
  nextToken?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
};

export type GetSubscriptionResponse = {
  __typename?: 'GetSubscriptionResponse';
  items?: Maybe<Array<Subscription>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type GetSubscriptionsByOwnerIdResponse = {
  __typename?: 'GetSubscriptionsByOwnerIdResponse';
  items?: Maybe<Array<Subscription>>;
  nextToken?: Maybe<Scalars['String']['output']>;
};

export type MarkCardAsReadInput = {
  cardId: Scalars['String']['input'];
  readId: Scalars['String']['input'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addCardEntry: CardEntry;
  addCardToSubscription: AddCardToSubscriptionResponse;
  createCard: Card;
  createSubscription: CreateSubscriptionResponse;
  createSubscriptionAndAddCard: CreateSubscriptionAndAddCardResponse;
  deleteCard: Card;
  deliverCardToReceiver: Card;
  emailThanksToCardSigners: Array<EmailThanksToCardSignersResponse>;
  markCardAsRead: Card;
  removeCardEntry: Scalars['ID']['output'];
  removeUser: UserEntity;
  toggleConfetti: Card;
  toggleLock: Card;
  toggleOrientation: Card;
  updateCard: Card;
  updateCardEntry: CardEntry;
  updateUser: UserEntity;
};


export type MutationAddCardEntryArgs = {
  input: AddCardEntryInput;
};


export type MutationAddCardToSubscriptionArgs = {
  input: AddCardToSubscriptionInput;
};


export type MutationCreateCardArgs = {
  input: CreateCardInput;
};


export type MutationCreateSubscriptionArgs = {
  input: CreateSubscriptionInput;
};


export type MutationCreateSubscriptionAndAddCardArgs = {
  input: CreateSubscriptionAndAddCardInput;
};


export type MutationDeleteCardArgs = {
  input: DeleteCardInput;
};


export type MutationDeliverCardToReceiverArgs = {
  input: DeliverCardToReceiverInput;
};


export type MutationEmailThanksToCardSignersArgs = {
  input: EmailThanksToCardSignersInput;
};


export type MutationMarkCardAsReadArgs = {
  input: MarkCardAsReadInput;
};


export type MutationRemoveCardEntryArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRemoveUserArgs = {
  id: Scalars['ID']['input'];
};


export type MutationToggleConfettiArgs = {
  input: ToggleConfettiInput;
};


export type MutationToggleLockArgs = {
  input: ToggleLockInput;
};


export type MutationToggleOrientationArgs = {
  input: ToggleOrientationInput;
};


export type MutationUpdateCardArgs = {
  input: UpdateCardInput;
};


export type MutationUpdateCardEntryArgs = {
  input: UpdateCardEntryInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export enum OrderStatus {
  DeliveredToReceiver = 'delivered_to_receiver',
  Paid = 'paid',
  Unpaid = 'unpaid'
}

export enum OrderType {
  Free = 'free',
  Premium = 'premium',
  Solo = 'solo'
}

export type Photo = {
  __typename?: 'Photo';
  alt_description?: Maybe<Scalars['String']['output']>;
  externalId: Scalars['String']['output'];
  height?: Maybe<Scalars['Int']['output']>;
  sizes?: Maybe<CardolyPhotoSizes>;
  type?: Maybe<PhotoType>;
  urls?: Maybe<PhotoUrls>;
  user?: Maybe<CardolyPhotoCreator>;
  width?: Maybe<Scalars['Int']['output']>;
};

export type PhotoInput = {
  alt_description?: InputMaybe<Scalars['String']['input']>;
  externalId: Scalars['String']['input'];
  height?: InputMaybe<Scalars['Int']['input']>;
  sizes?: InputMaybe<CardolyPhotoSizesInput>;
  type?: InputMaybe<PhotoType>;
  urls?: InputMaybe<PhotoUrlsInput>;
  user?: InputMaybe<CardolyPhotoCreatorInput>;
  width?: InputMaybe<Scalars['Int']['input']>;
};

export type PhotoSize = {
  __typename?: 'PhotoSize';
  height: Scalars['String']['output'];
  width: Scalars['String']['output'];
};

export type PhotoSizeInput = {
  height: Scalars['String']['input'];
  width: Scalars['String']['input'];
};

export enum PhotoType {
  Giphy = 'GIPHY',
  Unsplash = 'UNSPLASH'
}

export type PhotoUrls = {
  __typename?: 'PhotoUrls';
  md: Scalars['String']['output'];
  sm: Scalars['String']['output'];
};

export type PhotoUrlsInput = {
  md: Scalars['String']['input'];
  sm: Scalars['String']['input'];
};

export type Query = {
  __typename?: 'Query';
  cards: Array<Card>;
  findOneUser?: Maybe<UserEntity>;
  getCard: Card;
  getCardEntriesByCardId: GetCardEntriesByCardIdResponse;
  getCardEntry: CardEntry;
  getCardReadId: GetCardReadId;
  getCardSigners: Array<CardsToSignersObject>;
  getCardsByOwnerCognitoId: GetCardsByOwnerCognitoIdResponse;
  getOrCreatePaymentIntent: GetOrCreatePaymentIntentApiResponse;
  getOrCreatePaymentIntentForSubscription: GetOrCreatePaymentIntentForSubscriptionApiResponse;
  getOrCreateUser?: Maybe<UserEntity>;
  getSubscriptions: Array<GetSubscriptionResponse>;
  getSubscriptionsByOwnerId: GetSubscriptionsByOwnerIdResponse;
  users: Array<UserEntity>;
};


export type QueryFindOneUserArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetCardArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetCardEntriesByCardIdArgs = {
  input: GetCardEntriesByCardIdInput;
};


export type QueryGetCardEntryArgs = {
  id: Scalars['ID']['input'];
};


export type QueryGetCardReadIdArgs = {
  cardId: Scalars['ID']['input'];
};


export type QueryGetCardSignersArgs = {
  input: GetCardSignersInput;
};


export type QueryGetCardsByOwnerCognitoIdArgs = {
  input: GetCardsByOwnerCognitoIdInput;
};


export type QueryGetOrCreatePaymentIntentArgs = {
  input: GetOrCreatePaymentIntentInput;
};


export type QueryGetOrCreatePaymentIntentForSubscriptionArgs = {
  input: GetOrCreatePaymentIntentForSubscriptionApiInput;
};


export type QueryGetSubscriptionsByOwnerIdArgs = {
  input: GetSubscriptionByOwnerId;
};

export type Subscription = {
  __typename?: 'Subscription';
  cardsRemaining?: Maybe<Scalars['Float']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  ownerId?: Maybe<Scalars['String']['output']>;
  periodEnd?: Maybe<Scalars['DateTime']['output']>;
  periodStart?: Maybe<Scalars['DateTime']['output']>;
  productId?: Maybe<Scalars['String']['output']>;
  productName?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  stripeSubscriptionId?: Maybe<Scalars['String']['output']>;
};

export type ToggleConfettiInput = {
  cardId: Scalars['ID']['input'];
};

export type ToggleLockInput = {
  cardId: Scalars['String']['input'];
};

export type ToggleOrientationInput = {
  cardId: Scalars['ID']['input'];
};

export type UpdateCardEntryInput = {
  cardID?: InputMaybe<Scalars['ID']['input']>;
  entryMessage?: InputMaybe<Scalars['String']['input']>;
  font?: InputMaybe<FontInput>;
  id: Scalars['ID']['input'];
  photo?: InputMaybe<PhotoInput>;
  signerCognitoID?: InputMaybe<Scalars['ID']['input']>;
  signerEmail?: InputMaybe<Scalars['String']['input']>;
  signerName?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCardInput = {
  bgImageUrl?: InputMaybe<Scalars['String']['input']>;
  bgType?: InputMaybe<CardBgType>;
  deliveredAt?: InputMaybe<Scalars['DateTime']['input']>;
  id: Scalars['ID']['input'];
  isLandscape?: InputMaybe<Scalars['Boolean']['input']>;
  isLocked?: InputMaybe<Scalars['Boolean']['input']>;
  orderStatus?: InputMaybe<OrderStatus>;
  orderType?: InputMaybe<OrderType>;
  ownerName?: InputMaybe<Scalars['String']['input']>;
  pColorBg?: InputMaybe<Scalars['String']['input']>;
  pColorFg?: InputMaybe<Scalars['String']['input']>;
  pType?: InputMaybe<Scalars['String']['input']>;
  paymentIntentID?: InputMaybe<Scalars['String']['input']>;
  price?: InputMaybe<Scalars['Int']['input']>;
  readAt?: InputMaybe<Scalars['DateTime']['input']>;
  receiverEmail?: InputMaybe<Scalars['ID']['input']>;
  receiverName?: InputMaybe<Scalars['String']['input']>;
  showConfetti?: InputMaybe<Scalars['Boolean']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  titleFontFamily?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUserInput = {
  email?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};

export type UserEntity = {
  __typename?: 'UserEntity';
  cognitoId?: Maybe<Scalars['ID']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['ID']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type AddCardEntryMutationVariables = Exact<{
  input: AddCardEntryInput;
}>;


export type AddCardEntryMutation = { __typename?: 'Mutation', addCardEntry: { __typename?: 'CardEntry', cardID?: string | undefined, entryMessage?: string | undefined, signerName?: string | undefined, signerCognitoID?: string | undefined, id: string, updatedAt?: any | undefined, createdAt?: any | undefined, font?: { __typename?: 'Font', fontFamily?: string | undefined, color?: string | undefined } | undefined, photo?: { __typename?: 'Photo', externalId: string, width?: number | undefined, height?: number | undefined, alt_description?: string | undefined, type?: PhotoType | undefined, urls?: { __typename?: 'PhotoUrls', sm: string, md: string } | undefined, user?: { __typename?: 'CardolyPhotoCreator', name?: string | undefined, username?: string | undefined, firstName?: string | undefined, lastName?: string | undefined } | undefined, sizes?: { __typename?: 'CardolyPhotoSizes', sm: { __typename?: 'PhotoSize', height: string, width: string }, md: { __typename?: 'PhotoSize', height: string, width: string } } | undefined } | undefined } };

export type AddCardToSubscriptionMutationVariables = Exact<{
  input: AddCardToSubscriptionInput;
}>;


export type AddCardToSubscriptionMutation = { __typename?: 'Mutation', addCardToSubscription: { __typename?: 'AddCardToSubscriptionResponse', card: { __typename?: 'Card', title?: string | undefined, bgImageUrl?: string | undefined, bgType?: CardBgType | undefined, pColorBg?: string | undefined, pColorFg?: string | undefined, pType?: string | undefined, ownerName?: string | undefined, receiverName?: string | undefined, titleFontFamily?: string | undefined, orderStatus?: OrderStatus | undefined, orderType?: OrderType | undefined, price?: number | undefined, paymentIntentID?: string | undefined, id: string, receiverEmail?: string | undefined, createdAt?: any | undefined, updatedAt?: any | undefined, ownerCognitoID?: string | undefined, entryCount?: number | undefined, lastEntryCreatedAt?: any | undefined, lastEntryCreatedId?: string | undefined, subscriptionId?: string | undefined, status?: string | undefined }, subscription: { __typename?: 'Subscription', id: string, createdAt?: any | undefined, periodEnd?: any | undefined, periodStart?: any | undefined, status?: string | undefined, cardsRemaining?: number | undefined } } };

export type CreateCardMutationVariables = Exact<{
  input: CreateCardInput;
}>;


export type CreateCardMutation = { __typename?: 'Mutation', createCard: { __typename?: 'Card', id: string } };

export type CreateSubscriptionMutationVariables = Exact<{
  input: CreateSubscriptionInput;
}>;


export type CreateSubscriptionMutation = { __typename?: 'Mutation', createSubscription: { __typename?: 'CreateSubscriptionResponse', subscription: { __typename?: 'Subscription', ownerId?: string | undefined, stripeSubscriptionId?: string | undefined, id: string, createdAt?: any | undefined, periodEnd?: any | undefined, periodStart?: any | undefined, status?: string | undefined, cardsRemaining?: number | undefined, productId?: string | undefined, productName?: string | undefined } } };

export type CreateSubscriptionAndAddCardMutationVariables = Exact<{
  input: CreateSubscriptionAndAddCardInput;
}>;


export type CreateSubscriptionAndAddCardMutation = { __typename?: 'Mutation', createSubscriptionAndAddCard: { __typename?: 'CreateSubscriptionAndAddCardResponse', card: { __typename?: 'Card', title?: string | undefined, bgImageUrl?: string | undefined, bgType?: CardBgType | undefined, pColorBg?: string | undefined, pColorFg?: string | undefined, pType?: string | undefined, ownerName?: string | undefined, receiverName?: string | undefined, titleFontFamily?: string | undefined, orderStatus?: OrderStatus | undefined, orderType?: OrderType | undefined, price?: number | undefined, paymentIntentID?: string | undefined, id: string, receiverEmail?: string | undefined, createdAt?: any | undefined, updatedAt?: any | undefined, ownerCognitoID?: string | undefined, entryCount?: number | undefined, lastEntryCreatedAt?: any | undefined, lastEntryCreatedId?: string | undefined, subscriptionId?: string | undefined, status?: string | undefined }, subscription: { __typename?: 'Subscription', id: string, createdAt?: any | undefined, periodEnd?: any | undefined, periodStart?: any | undefined, status?: string | undefined, cardsRemaining?: number | undefined } } };

export type DeleteCardMutationVariables = Exact<{
  input: DeleteCardInput;
}>;


export type DeleteCardMutation = { __typename?: 'Mutation', deleteCard: { __typename?: 'Card', title?: string | undefined, bgImageUrl?: string | undefined, bgType?: CardBgType | undefined, pColorBg?: string | undefined, pColorFg?: string | undefined, pType?: string | undefined, ownerName?: string | undefined, receiverName?: string | undefined, titleFontFamily?: string | undefined, orderStatus?: OrderStatus | undefined, orderType?: OrderType | undefined, price?: number | undefined, paymentIntentID?: string | undefined, id: string, receiverEmail?: string | undefined, createdAt?: any | undefined, updatedAt?: any | undefined, ownerCognitoID?: string | undefined, entryCount?: number | undefined, lastEntryCreatedAt?: any | undefined, lastEntryCreatedId?: string | undefined, subscriptionId?: string | undefined, status?: string | undefined } };

export type DeliverCardToReceiverMutationVariables = Exact<{
  input: DeliverCardToReceiverInput;
}>;


export type DeliverCardToReceiverMutation = { __typename?: 'Mutation', deliverCardToReceiver: { __typename?: 'Card', title?: string | undefined, bgImageUrl?: string | undefined, bgType?: CardBgType | undefined, pColorBg?: string | undefined, pColorFg?: string | undefined, pType?: string | undefined, ownerName?: string | undefined, receiverName?: string | undefined, titleFontFamily?: string | undefined, orderStatus?: OrderStatus | undefined, orderType?: OrderType | undefined, price?: number | undefined, paymentIntentID?: string | undefined, id: string, receiverEmail?: string | undefined, createdAt?: any | undefined, updatedAt?: any | undefined, ownerCognitoID?: string | undefined, entryCount?: number | undefined, lastEntryCreatedAt?: any | undefined, lastEntryCreatedId?: string | undefined, subscriptionId?: string | undefined, readAt?: any | undefined, deliveredAt?: any | undefined, status?: string | undefined } };

export type EmailThanksToCardSignersMutationVariables = Exact<{
  input: EmailThanksToCardSignersInput;
}>;


export type EmailThanksToCardSignersMutation = { __typename?: 'Mutation', emailThanksToCardSigners: Array<{ __typename?: 'EmailThanksToCardSignersResponse', email?: string | undefined, status?: string | undefined, error?: string | undefined }> };

export type GetCardQueryVariables = Exact<{
  getCardId: Scalars['ID']['input'];
}>;


export type GetCardQuery = { __typename?: 'Query', getCard: { __typename?: 'Card', deliveredAt?: any | undefined, readAt?: any | undefined, title?: string | undefined, bgImageUrl?: string | undefined, bgType?: CardBgType | undefined, pColorBg?: string | undefined, pColorFg?: string | undefined, pType?: string | undefined, ownerName?: string | undefined, receiverName?: string | undefined, titleFontFamily?: string | undefined, orderStatus?: OrderStatus | undefined, orderType?: OrderType | undefined, price?: number | undefined, paymentIntentID?: string | undefined, id: string, receiverEmail?: string | undefined, createdAt?: any | undefined, updatedAt?: any | undefined, ownerCognitoID?: string | undefined, entryCount?: number | undefined, lastEntryCreatedAt?: any | undefined, lastEntryCreatedId?: string | undefined, subscriptionId?: string | undefined, status?: string | undefined, showConfetti?: boolean | undefined, isLocked?: boolean | undefined, isLandscape?: boolean | undefined } };

export type GetCardEntriesByCardIdQueryVariables = Exact<{
  input: GetCardEntriesByCardIdInput;
}>;


export type GetCardEntriesByCardIdQuery = { __typename?: 'Query', getCardEntriesByCardId: { __typename?: 'GetCardEntriesByCardIdResponse', nextToken?: string | undefined, items?: Array<{ __typename?: 'CardEntry', cardID?: string | undefined, entryMessage?: string | undefined, signerName?: string | undefined, signerCognitoID?: string | undefined, id: string, updatedAt?: any | undefined, createdAt?: any | undefined, font?: { __typename?: 'Font', fontFamily?: string | undefined, color?: string | undefined } | undefined, photo?: { __typename?: 'Photo', externalId: string, width?: number | undefined, height?: number | undefined, alt_description?: string | undefined, type?: PhotoType | undefined, urls?: { __typename?: 'PhotoUrls', sm: string, md: string } | undefined, user?: { __typename?: 'CardolyPhotoCreator', name?: string | undefined, username?: string | undefined, firstName?: string | undefined, lastName?: string | undefined } | undefined, sizes?: { __typename?: 'CardolyPhotoSizes', sm: { __typename?: 'PhotoSize', height: string, width: string }, md: { __typename?: 'PhotoSize', height: string, width: string } } | undefined } | undefined }> | undefined } };

export type GetCardEntryQueryVariables = Exact<{
  getCardEntryId: Scalars['ID']['input'];
}>;


export type GetCardEntryQuery = { __typename?: 'Query', getCardEntry: { __typename?: 'CardEntry', cardID?: string | undefined, entryMessage?: string | undefined, signerName?: string | undefined, signerCognitoID?: string | undefined, id: string, updatedAt?: any | undefined, createdAt?: any | undefined, font?: { __typename?: 'Font', fontFamily?: string | undefined, color?: string | undefined } | undefined, photo?: { __typename?: 'Photo', externalId: string, width?: number | undefined, height?: number | undefined, alt_description?: string | undefined, type?: PhotoType | undefined, urls?: { __typename?: 'PhotoUrls', sm: string, md: string } | undefined, user?: { __typename?: 'CardolyPhotoCreator', name?: string | undefined, username?: string | undefined, firstName?: string | undefined, lastName?: string | undefined } | undefined, sizes?: { __typename?: 'CardolyPhotoSizes', sm: { __typename?: 'PhotoSize', height: string, width: string }, md: { __typename?: 'PhotoSize', height: string, width: string } } | undefined } | undefined } };

export type GetCardReadIdQueryVariables = Exact<{
  cardId: Scalars['ID']['input'];
}>;


export type GetCardReadIdQuery = { __typename?: 'Query', getCardReadId: { __typename?: 'GetCardReadId', readId?: string | undefined } };

export type GetCardSignersQueryVariables = Exact<{
  input: GetCardSignersInput;
}>;


export type GetCardSignersQuery = { __typename?: 'Query', getCardSigners: Array<{ __typename?: 'CardsToSignersObject', signerEmail: string, signerCognitoId?: string | undefined, signerName?: string | undefined, emailedAt?: any | undefined }> };

export type GetCardsByOwnerCognitoIdQueryVariables = Exact<{
  input: GetCardsByOwnerCognitoIdInput;
}>;


export type GetCardsByOwnerCognitoIdQuery = { __typename?: 'Query', getCardsByOwnerCognitoId: { __typename?: 'GetCardsByOwnerCognitoIdResponse', nextToken?: string | undefined, items?: Array<{ __typename?: 'Card', deliveredAt?: any | undefined, readAt?: any | undefined, title?: string | undefined, bgImageUrl?: string | undefined, bgType?: CardBgType | undefined, pColorBg?: string | undefined, pColorFg?: string | undefined, pType?: string | undefined, ownerName?: string | undefined, receiverName?: string | undefined, titleFontFamily?: string | undefined, orderStatus?: OrderStatus | undefined, orderType?: OrderType | undefined, price?: number | undefined, paymentIntentID?: string | undefined, id: string, receiverEmail?: string | undefined, createdAt?: any | undefined, updatedAt?: any | undefined, ownerCognitoID?: string | undefined, entryCount?: number | undefined, lastEntryCreatedAt?: any | undefined, lastEntryCreatedId?: string | undefined, subscriptionId?: string | undefined, status?: string | undefined, isLocked?: boolean | undefined }> | undefined } };

export type GetOrCreatePaymentIntentForSubscriptionQueryVariables = Exact<{
  input: GetOrCreatePaymentIntentForSubscriptionApiInput;
}>;


export type GetOrCreatePaymentIntentForSubscriptionQuery = { __typename?: 'Query', getOrCreatePaymentIntentForSubscription: { __typename?: 'GetOrCreatePaymentIntentForSubscriptionApiResponse', paymentIntentID?: string | undefined, subscriptionId?: string | undefined } };

export type GetOrCreateUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetOrCreateUserQuery = { __typename?: 'Query', getOrCreateUser?: { __typename?: 'UserEntity', id?: string | undefined, cognitoId?: string | undefined, name?: string | undefined, email?: string | undefined } | undefined };

export type GetSubscriptionsByOwnerIdQueryVariables = Exact<{
  input: GetSubscriptionByOwnerId;
}>;


export type GetSubscriptionsByOwnerIdQuery = { __typename?: 'Query', getSubscriptionsByOwnerId: { __typename?: 'GetSubscriptionsByOwnerIdResponse', nextToken?: string | undefined, items?: Array<{ __typename?: 'Subscription', id: string, createdAt?: any | undefined, periodEnd?: any | undefined, periodStart?: any | undefined, status?: string | undefined, cardsRemaining?: number | undefined, productName?: string | undefined, productId?: string | undefined }> | undefined } };

export type MarkCardAsReadMutationVariables = Exact<{
  input: MarkCardAsReadInput;
}>;


export type MarkCardAsReadMutation = { __typename?: 'Mutation', markCardAsRead: { __typename?: 'Card', deliveredAt?: any | undefined, readAt?: any | undefined, title?: string | undefined, bgImageUrl?: string | undefined, bgType?: CardBgType | undefined, pColorBg?: string | undefined, pColorFg?: string | undefined, pType?: string | undefined, ownerName?: string | undefined, receiverName?: string | undefined, titleFontFamily?: string | undefined, orderStatus?: OrderStatus | undefined, orderType?: OrderType | undefined, price?: number | undefined, paymentIntentID?: string | undefined, id: string, receiverEmail?: string | undefined, createdAt?: any | undefined, updatedAt?: any | undefined, ownerCognitoID?: string | undefined, entryCount?: number | undefined, lastEntryCreatedAt?: any | undefined, lastEntryCreatedId?: string | undefined, subscriptionId?: string | undefined, status?: string | undefined } };

export type RemoveCardEntryMutationVariables = Exact<{
  removeCardEntryId: Scalars['ID']['input'];
}>;


export type RemoveCardEntryMutation = { __typename?: 'Mutation', removeCardEntry: string };

export type ToggleConfettiMutationVariables = Exact<{
  input: ToggleConfettiInput;
}>;


export type ToggleConfettiMutation = { __typename?: 'Mutation', toggleConfetti: { __typename?: 'Card', title?: string | undefined, bgImageUrl?: string | undefined, bgType?: CardBgType | undefined, pColorBg?: string | undefined, pColorFg?: string | undefined, pType?: string | undefined, ownerName?: string | undefined, receiverName?: string | undefined, titleFontFamily?: string | undefined, orderStatus?: OrderStatus | undefined, orderType?: OrderType | undefined, price?: number | undefined, paymentIntentID?: string | undefined, showConfetti?: boolean | undefined, id: string, receiverEmail?: string | undefined, createdAt?: any | undefined, updatedAt?: any | undefined, ownerCognitoID?: string | undefined, entryCount?: number | undefined, lastEntryCreatedAt?: any | undefined, lastEntryCreatedId?: string | undefined, subscriptionId?: string | undefined, readAt?: any | undefined, deliveredAt?: any | undefined, status?: string | undefined } };

export type ToggleLockMutationVariables = Exact<{
  input: ToggleLockInput;
}>;


export type ToggleLockMutation = { __typename?: 'Mutation', toggleLock: { __typename?: 'Card', title?: string | undefined, bgImageUrl?: string | undefined, bgType?: CardBgType | undefined, pColorBg?: string | undefined, pColorFg?: string | undefined, pType?: string | undefined, ownerName?: string | undefined, receiverName?: string | undefined, titleFontFamily?: string | undefined, orderStatus?: OrderStatus | undefined, orderType?: OrderType | undefined, price?: number | undefined, paymentIntentID?: string | undefined, showConfetti?: boolean | undefined, isLocked?: boolean | undefined, id: string, receiverEmail?: string | undefined, createdAt?: any | undefined, updatedAt?: any | undefined, ownerCognitoID?: string | undefined, entryCount?: number | undefined, lastEntryCreatedAt?: any | undefined, lastEntryCreatedId?: string | undefined, subscriptionId?: string | undefined, readAt?: any | undefined, deliveredAt?: any | undefined, status?: string | undefined } };

export type ToggleOrientationMutationVariables = Exact<{
  input: ToggleOrientationInput;
}>;


export type ToggleOrientationMutation = { __typename?: 'Mutation', toggleOrientation: { __typename?: 'Card', id: string, isLandscape?: boolean | undefined } };

export type UpdateCardMutationVariables = Exact<{
  input: UpdateCardInput;
}>;


export type UpdateCardMutation = { __typename?: 'Mutation', updateCard: { __typename?: 'Card', title?: string | undefined, bgImageUrl?: string | undefined, bgType?: CardBgType | undefined, pColorBg?: string | undefined, pColorFg?: string | undefined, pType?: string | undefined, ownerName?: string | undefined, receiverName?: string | undefined, titleFontFamily?: string | undefined, orderStatus?: OrderStatus | undefined, orderType?: OrderType | undefined, price?: number | undefined, paymentIntentID?: string | undefined, id: string, receiverEmail?: string | undefined, createdAt?: any | undefined, updatedAt?: any | undefined, ownerCognitoID?: string | undefined, entryCount?: number | undefined, lastEntryCreatedAt?: any | undefined, lastEntryCreatedId?: string | undefined, subscriptionId?: string | undefined, status?: string | undefined, isLandscape?: boolean | undefined, deliveredAt?: any | undefined, readAt?: any | undefined } };

export type UpdateCardEntryMutationVariables = Exact<{
  input: UpdateCardEntryInput;
}>;


export type UpdateCardEntryMutation = { __typename?: 'Mutation', updateCardEntry: { __typename?: 'CardEntry', cardID?: string | undefined, entryMessage?: string | undefined, signerName?: string | undefined, signerCognitoID?: string | undefined, id: string, updatedAt?: any | undefined, createdAt?: any | undefined, font?: { __typename?: 'Font', fontFamily?: string | undefined, color?: string | undefined } | undefined, photo?: { __typename?: 'Photo', externalId: string, width?: number | undefined, height?: number | undefined, alt_description?: string | undefined, type?: PhotoType | undefined, urls?: { __typename?: 'PhotoUrls', sm: string, md: string } | undefined, user?: { __typename?: 'CardolyPhotoCreator', name?: string | undefined, username?: string | undefined, firstName?: string | undefined, lastName?: string | undefined } | undefined, sizes?: { __typename?: 'CardolyPhotoSizes', sm: { __typename?: 'PhotoSize', height: string, width: string }, md: { __typename?: 'PhotoSize', height: string, width: string } } | undefined } | undefined } };

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'UserEntity', id?: string | undefined, cognitoId?: string | undefined, name?: string | undefined, email?: string | undefined } };


export const AddCardEntryDocument = gql`
    mutation AddCardEntry($input: AddCardEntryInput!) {
  addCardEntry(input: $input) {
    cardID
    entryMessage
    font {
      fontFamily
      color
    }
    photo {
      externalId
      width
      height
      alt_description
      type
      urls {
        sm
        md
      }
      user {
        name
        username
        firstName
        lastName
      }
      sizes {
        sm {
          height
          width
        }
        md {
          height
          width
        }
      }
    }
    signerName
    signerCognitoID
    id
    updatedAt
    createdAt
  }
}
    `;
export type AddCardEntryMutationFn = Apollo.MutationFunction<AddCardEntryMutation, AddCardEntryMutationVariables>;

/**
 * __useAddCardEntryMutation__
 *
 * To run a mutation, you first call `useAddCardEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCardEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCardEntryMutation, { data, loading, error }] = useAddCardEntryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCardEntryMutation(baseOptions?: Apollo.MutationHookOptions<AddCardEntryMutation, AddCardEntryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCardEntryMutation, AddCardEntryMutationVariables>(AddCardEntryDocument, options);
      }
export type AddCardEntryMutationHookResult = ReturnType<typeof useAddCardEntryMutation>;
export type AddCardEntryMutationResult = Apollo.MutationResult<AddCardEntryMutation>;
export type AddCardEntryMutationOptions = Apollo.BaseMutationOptions<AddCardEntryMutation, AddCardEntryMutationVariables>;
export const AddCardToSubscriptionDocument = gql`
    mutation AddCardToSubscription($input: AddCardToSubscriptionInput!) {
  addCardToSubscription(input: $input) {
    card {
      title
      bgImageUrl
      bgType
      pColorBg
      pColorFg
      pType
      ownerName
      receiverName
      titleFontFamily
      orderStatus
      orderType
      price
      paymentIntentID
      id
      receiverEmail
      createdAt
      updatedAt
      ownerCognitoID
      entryCount
      lastEntryCreatedAt
      lastEntryCreatedId
      subscriptionId
      status
    }
    subscription {
      id
      createdAt
      periodEnd
      periodStart
      status
      cardsRemaining
    }
  }
}
    `;
export type AddCardToSubscriptionMutationFn = Apollo.MutationFunction<AddCardToSubscriptionMutation, AddCardToSubscriptionMutationVariables>;

/**
 * __useAddCardToSubscriptionMutation__
 *
 * To run a mutation, you first call `useAddCardToSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCardToSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCardToSubscriptionMutation, { data, loading, error }] = useAddCardToSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCardToSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<AddCardToSubscriptionMutation, AddCardToSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCardToSubscriptionMutation, AddCardToSubscriptionMutationVariables>(AddCardToSubscriptionDocument, options);
      }
export type AddCardToSubscriptionMutationHookResult = ReturnType<typeof useAddCardToSubscriptionMutation>;
export type AddCardToSubscriptionMutationResult = Apollo.MutationResult<AddCardToSubscriptionMutation>;
export type AddCardToSubscriptionMutationOptions = Apollo.BaseMutationOptions<AddCardToSubscriptionMutation, AddCardToSubscriptionMutationVariables>;
export const CreateCardDocument = gql`
    mutation CreateCard($input: CreateCardInput!) {
  createCard(input: $input) {
    id
  }
}
    `;
export type CreateCardMutationFn = Apollo.MutationFunction<CreateCardMutation, CreateCardMutationVariables>;

/**
 * __useCreateCardMutation__
 *
 * To run a mutation, you first call `useCreateCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCardMutation, { data, loading, error }] = useCreateCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCardMutation(baseOptions?: Apollo.MutationHookOptions<CreateCardMutation, CreateCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCardMutation, CreateCardMutationVariables>(CreateCardDocument, options);
      }
export type CreateCardMutationHookResult = ReturnType<typeof useCreateCardMutation>;
export type CreateCardMutationResult = Apollo.MutationResult<CreateCardMutation>;
export type CreateCardMutationOptions = Apollo.BaseMutationOptions<CreateCardMutation, CreateCardMutationVariables>;
export const CreateSubscriptionDocument = gql`
    mutation CreateSubscription($input: CreateSubscriptionInput!) {
  createSubscription(input: $input) {
    subscription {
      ownerId
      stripeSubscriptionId
      id
      createdAt
      periodEnd
      periodStart
      status
      cardsRemaining
      productId
      productName
    }
  }
}
    `;
export type CreateSubscriptionMutationFn = Apollo.MutationFunction<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>;

/**
 * __useCreateSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubscriptionMutation, { data, loading, error }] = useCreateSubscriptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>(CreateSubscriptionDocument, options);
      }
export type CreateSubscriptionMutationHookResult = ReturnType<typeof useCreateSubscriptionMutation>;
export type CreateSubscriptionMutationResult = Apollo.MutationResult<CreateSubscriptionMutation>;
export type CreateSubscriptionMutationOptions = Apollo.BaseMutationOptions<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>;
export const CreateSubscriptionAndAddCardDocument = gql`
    mutation CreateSubscriptionAndAddCard($input: CreateSubscriptionAndAddCardInput!) {
  createSubscriptionAndAddCard(input: $input) {
    card {
      title
      bgImageUrl
      bgType
      pColorBg
      pColorFg
      pType
      ownerName
      receiverName
      titleFontFamily
      orderStatus
      orderType
      price
      paymentIntentID
      id
      receiverEmail
      createdAt
      updatedAt
      ownerCognitoID
      entryCount
      lastEntryCreatedAt
      lastEntryCreatedId
      subscriptionId
      status
    }
    subscription {
      id
      createdAt
      periodEnd
      periodStart
      status
      cardsRemaining
    }
  }
}
    `;
export type CreateSubscriptionAndAddCardMutationFn = Apollo.MutationFunction<CreateSubscriptionAndAddCardMutation, CreateSubscriptionAndAddCardMutationVariables>;

/**
 * __useCreateSubscriptionAndAddCardMutation__
 *
 * To run a mutation, you first call `useCreateSubscriptionAndAddCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubscriptionAndAddCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubscriptionAndAddCardMutation, { data, loading, error }] = useCreateSubscriptionAndAddCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSubscriptionAndAddCardMutation(baseOptions?: Apollo.MutationHookOptions<CreateSubscriptionAndAddCardMutation, CreateSubscriptionAndAddCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSubscriptionAndAddCardMutation, CreateSubscriptionAndAddCardMutationVariables>(CreateSubscriptionAndAddCardDocument, options);
      }
export type CreateSubscriptionAndAddCardMutationHookResult = ReturnType<typeof useCreateSubscriptionAndAddCardMutation>;
export type CreateSubscriptionAndAddCardMutationResult = Apollo.MutationResult<CreateSubscriptionAndAddCardMutation>;
export type CreateSubscriptionAndAddCardMutationOptions = Apollo.BaseMutationOptions<CreateSubscriptionAndAddCardMutation, CreateSubscriptionAndAddCardMutationVariables>;
export const DeleteCardDocument = gql`
    mutation DeleteCard($input: DeleteCardInput!) {
  deleteCard(input: $input) {
    title
    bgImageUrl
    bgType
    pColorBg
    pColorFg
    pType
    ownerName
    receiverName
    titleFontFamily
    orderStatus
    orderType
    price
    paymentIntentID
    id
    receiverEmail
    createdAt
    updatedAt
    ownerCognitoID
    entryCount
    lastEntryCreatedAt
    lastEntryCreatedId
    subscriptionId
    status
  }
}
    `;
export type DeleteCardMutationFn = Apollo.MutationFunction<DeleteCardMutation, DeleteCardMutationVariables>;

/**
 * __useDeleteCardMutation__
 *
 * To run a mutation, you first call `useDeleteCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCardMutation, { data, loading, error }] = useDeleteCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCardMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCardMutation, DeleteCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCardMutation, DeleteCardMutationVariables>(DeleteCardDocument, options);
      }
export type DeleteCardMutationHookResult = ReturnType<typeof useDeleteCardMutation>;
export type DeleteCardMutationResult = Apollo.MutationResult<DeleteCardMutation>;
export type DeleteCardMutationOptions = Apollo.BaseMutationOptions<DeleteCardMutation, DeleteCardMutationVariables>;
export const DeliverCardToReceiverDocument = gql`
    mutation DeliverCardToReceiver($input: DeliverCardToReceiverInput!) {
  deliverCardToReceiver(input: $input) {
    title
    bgImageUrl
    bgType
    pColorBg
    pColorFg
    pType
    ownerName
    receiverName
    titleFontFamily
    orderStatus
    orderType
    price
    paymentIntentID
    id
    receiverEmail
    createdAt
    updatedAt
    ownerCognitoID
    entryCount
    lastEntryCreatedAt
    lastEntryCreatedId
    subscriptionId
    readAt
    deliveredAt
    status
  }
}
    `;
export type DeliverCardToReceiverMutationFn = Apollo.MutationFunction<DeliverCardToReceiverMutation, DeliverCardToReceiverMutationVariables>;

/**
 * __useDeliverCardToReceiverMutation__
 *
 * To run a mutation, you first call `useDeliverCardToReceiverMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeliverCardToReceiverMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deliverCardToReceiverMutation, { data, loading, error }] = useDeliverCardToReceiverMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeliverCardToReceiverMutation(baseOptions?: Apollo.MutationHookOptions<DeliverCardToReceiverMutation, DeliverCardToReceiverMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeliverCardToReceiverMutation, DeliverCardToReceiverMutationVariables>(DeliverCardToReceiverDocument, options);
      }
export type DeliverCardToReceiverMutationHookResult = ReturnType<typeof useDeliverCardToReceiverMutation>;
export type DeliverCardToReceiverMutationResult = Apollo.MutationResult<DeliverCardToReceiverMutation>;
export type DeliverCardToReceiverMutationOptions = Apollo.BaseMutationOptions<DeliverCardToReceiverMutation, DeliverCardToReceiverMutationVariables>;
export const EmailThanksToCardSignersDocument = gql`
    mutation EmailThanksToCardSigners($input: EmailThanksToCardSignersInput!) {
  emailThanksToCardSigners(input: $input) {
    email
    status
    error
  }
}
    `;
export type EmailThanksToCardSignersMutationFn = Apollo.MutationFunction<EmailThanksToCardSignersMutation, EmailThanksToCardSignersMutationVariables>;

/**
 * __useEmailThanksToCardSignersMutation__
 *
 * To run a mutation, you first call `useEmailThanksToCardSignersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailThanksToCardSignersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailThanksToCardSignersMutation, { data, loading, error }] = useEmailThanksToCardSignersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEmailThanksToCardSignersMutation(baseOptions?: Apollo.MutationHookOptions<EmailThanksToCardSignersMutation, EmailThanksToCardSignersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EmailThanksToCardSignersMutation, EmailThanksToCardSignersMutationVariables>(EmailThanksToCardSignersDocument, options);
      }
export type EmailThanksToCardSignersMutationHookResult = ReturnType<typeof useEmailThanksToCardSignersMutation>;
export type EmailThanksToCardSignersMutationResult = Apollo.MutationResult<EmailThanksToCardSignersMutation>;
export type EmailThanksToCardSignersMutationOptions = Apollo.BaseMutationOptions<EmailThanksToCardSignersMutation, EmailThanksToCardSignersMutationVariables>;
export const GetCardDocument = gql`
    query GetCard($getCardId: ID!) {
  getCard(id: $getCardId) {
    deliveredAt
    readAt
    title
    bgImageUrl
    bgType
    pColorBg
    pColorFg
    pType
    ownerName
    receiverName
    titleFontFamily
    orderStatus
    orderType
    price
    paymentIntentID
    id
    receiverEmail
    createdAt
    updatedAt
    ownerCognitoID
    entryCount
    lastEntryCreatedAt
    lastEntryCreatedId
    subscriptionId
    status
    showConfetti
    isLocked
    isLandscape
  }
}
    `;

/**
 * __useGetCardQuery__
 *
 * To run a query within a React component, call `useGetCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardQuery({
 *   variables: {
 *      getCardId: // value for 'getCardId'
 *   },
 * });
 */
export function useGetCardQuery(baseOptions: Apollo.QueryHookOptions<GetCardQuery, GetCardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCardQuery, GetCardQueryVariables>(GetCardDocument, options);
      }
export function useGetCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCardQuery, GetCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCardQuery, GetCardQueryVariables>(GetCardDocument, options);
        }
export type GetCardQueryHookResult = ReturnType<typeof useGetCardQuery>;
export type GetCardLazyQueryHookResult = ReturnType<typeof useGetCardLazyQuery>;
export type GetCardQueryResult = Apollo.QueryResult<GetCardQuery, GetCardQueryVariables>;
export const GetCardEntriesByCardIdDocument = gql`
    query GetCardEntriesByCardId($input: GetCardEntriesByCardIdInput!) {
  getCardEntriesByCardId(input: $input) {
    items {
      cardID
      entryMessage
      font {
        fontFamily
        color
      }
      photo {
        externalId
        width
        height
        alt_description
        type
        urls {
          sm
          md
        }
        user {
          name
          username
          firstName
          lastName
        }
        sizes {
          sm {
            height
            width
          }
          md {
            height
            width
          }
        }
      }
      signerName
      signerCognitoID
      id
      updatedAt
      createdAt
    }
    nextToken
  }
}
    `;

/**
 * __useGetCardEntriesByCardIdQuery__
 *
 * To run a query within a React component, call `useGetCardEntriesByCardIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCardEntriesByCardIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardEntriesByCardIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCardEntriesByCardIdQuery(baseOptions: Apollo.QueryHookOptions<GetCardEntriesByCardIdQuery, GetCardEntriesByCardIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCardEntriesByCardIdQuery, GetCardEntriesByCardIdQueryVariables>(GetCardEntriesByCardIdDocument, options);
      }
export function useGetCardEntriesByCardIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCardEntriesByCardIdQuery, GetCardEntriesByCardIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCardEntriesByCardIdQuery, GetCardEntriesByCardIdQueryVariables>(GetCardEntriesByCardIdDocument, options);
        }
export type GetCardEntriesByCardIdQueryHookResult = ReturnType<typeof useGetCardEntriesByCardIdQuery>;
export type GetCardEntriesByCardIdLazyQueryHookResult = ReturnType<typeof useGetCardEntriesByCardIdLazyQuery>;
export type GetCardEntriesByCardIdQueryResult = Apollo.QueryResult<GetCardEntriesByCardIdQuery, GetCardEntriesByCardIdQueryVariables>;
export const GetCardEntryDocument = gql`
    query GetCardEntry($getCardEntryId: ID!) {
  getCardEntry(id: $getCardEntryId) {
    cardID
    entryMessage
    font {
      fontFamily
      color
    }
    photo {
      externalId
      width
      height
      alt_description
      type
      urls {
        sm
        md
      }
      user {
        name
        username
        firstName
        lastName
      }
      sizes {
        sm {
          height
          width
        }
        md {
          height
          width
        }
      }
    }
    signerName
    signerCognitoID
    id
    updatedAt
    createdAt
  }
}
    `;

/**
 * __useGetCardEntryQuery__
 *
 * To run a query within a React component, call `useGetCardEntryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCardEntryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardEntryQuery({
 *   variables: {
 *      getCardEntryId: // value for 'getCardEntryId'
 *   },
 * });
 */
export function useGetCardEntryQuery(baseOptions: Apollo.QueryHookOptions<GetCardEntryQuery, GetCardEntryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCardEntryQuery, GetCardEntryQueryVariables>(GetCardEntryDocument, options);
      }
export function useGetCardEntryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCardEntryQuery, GetCardEntryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCardEntryQuery, GetCardEntryQueryVariables>(GetCardEntryDocument, options);
        }
export type GetCardEntryQueryHookResult = ReturnType<typeof useGetCardEntryQuery>;
export type GetCardEntryLazyQueryHookResult = ReturnType<typeof useGetCardEntryLazyQuery>;
export type GetCardEntryQueryResult = Apollo.QueryResult<GetCardEntryQuery, GetCardEntryQueryVariables>;
export const GetCardReadIdDocument = gql`
    query GetCardReadId($cardId: ID!) {
  getCardReadId(cardId: $cardId) {
    readId
  }
}
    `;

/**
 * __useGetCardReadIdQuery__
 *
 * To run a query within a React component, call `useGetCardReadIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCardReadIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardReadIdQuery({
 *   variables: {
 *      cardId: // value for 'cardId'
 *   },
 * });
 */
export function useGetCardReadIdQuery(baseOptions: Apollo.QueryHookOptions<GetCardReadIdQuery, GetCardReadIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCardReadIdQuery, GetCardReadIdQueryVariables>(GetCardReadIdDocument, options);
      }
export function useGetCardReadIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCardReadIdQuery, GetCardReadIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCardReadIdQuery, GetCardReadIdQueryVariables>(GetCardReadIdDocument, options);
        }
export type GetCardReadIdQueryHookResult = ReturnType<typeof useGetCardReadIdQuery>;
export type GetCardReadIdLazyQueryHookResult = ReturnType<typeof useGetCardReadIdLazyQuery>;
export type GetCardReadIdQueryResult = Apollo.QueryResult<GetCardReadIdQuery, GetCardReadIdQueryVariables>;
export const GetCardSignersDocument = gql`
    query GetCardSigners($input: GetCardSignersInput!) {
  getCardSigners(input: $input) {
    signerEmail
    signerCognitoId
    signerName
    emailedAt
  }
}
    `;

/**
 * __useGetCardSignersQuery__
 *
 * To run a query within a React component, call `useGetCardSignersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCardSignersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardSignersQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCardSignersQuery(baseOptions: Apollo.QueryHookOptions<GetCardSignersQuery, GetCardSignersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCardSignersQuery, GetCardSignersQueryVariables>(GetCardSignersDocument, options);
      }
export function useGetCardSignersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCardSignersQuery, GetCardSignersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCardSignersQuery, GetCardSignersQueryVariables>(GetCardSignersDocument, options);
        }
export type GetCardSignersQueryHookResult = ReturnType<typeof useGetCardSignersQuery>;
export type GetCardSignersLazyQueryHookResult = ReturnType<typeof useGetCardSignersLazyQuery>;
export type GetCardSignersQueryResult = Apollo.QueryResult<GetCardSignersQuery, GetCardSignersQueryVariables>;
export const GetCardsByOwnerCognitoIdDocument = gql`
    query GetCardsByOwnerCognitoId($input: GetCardsByOwnerCognitoIdInput!) {
  getCardsByOwnerCognitoId(input: $input) {
    items {
      deliveredAt
      readAt
      title
      bgImageUrl
      bgType
      pColorBg
      pColorFg
      pType
      ownerName
      receiverName
      titleFontFamily
      orderStatus
      orderType
      price
      paymentIntentID
      id
      receiverEmail
      createdAt
      updatedAt
      ownerCognitoID
      entryCount
      lastEntryCreatedAt
      lastEntryCreatedId
      subscriptionId
      status
      isLocked
    }
    nextToken
  }
}
    `;

/**
 * __useGetCardsByOwnerCognitoIdQuery__
 *
 * To run a query within a React component, call `useGetCardsByOwnerCognitoIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCardsByOwnerCognitoIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCardsByOwnerCognitoIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCardsByOwnerCognitoIdQuery(baseOptions: Apollo.QueryHookOptions<GetCardsByOwnerCognitoIdQuery, GetCardsByOwnerCognitoIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCardsByOwnerCognitoIdQuery, GetCardsByOwnerCognitoIdQueryVariables>(GetCardsByOwnerCognitoIdDocument, options);
      }
export function useGetCardsByOwnerCognitoIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCardsByOwnerCognitoIdQuery, GetCardsByOwnerCognitoIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCardsByOwnerCognitoIdQuery, GetCardsByOwnerCognitoIdQueryVariables>(GetCardsByOwnerCognitoIdDocument, options);
        }
export type GetCardsByOwnerCognitoIdQueryHookResult = ReturnType<typeof useGetCardsByOwnerCognitoIdQuery>;
export type GetCardsByOwnerCognitoIdLazyQueryHookResult = ReturnType<typeof useGetCardsByOwnerCognitoIdLazyQuery>;
export type GetCardsByOwnerCognitoIdQueryResult = Apollo.QueryResult<GetCardsByOwnerCognitoIdQuery, GetCardsByOwnerCognitoIdQueryVariables>;
export const GetOrCreatePaymentIntentForSubscriptionDocument = gql`
    query GetOrCreatePaymentIntentForSubscription($input: GetOrCreatePaymentIntentForSubscriptionApiInput!) {
  getOrCreatePaymentIntentForSubscription(input: $input) {
    paymentIntentID
    subscriptionId
  }
}
    `;

/**
 * __useGetOrCreatePaymentIntentForSubscriptionQuery__
 *
 * To run a query within a React component, call `useGetOrCreatePaymentIntentForSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrCreatePaymentIntentForSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrCreatePaymentIntentForSubscriptionQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetOrCreatePaymentIntentForSubscriptionQuery(baseOptions: Apollo.QueryHookOptions<GetOrCreatePaymentIntentForSubscriptionQuery, GetOrCreatePaymentIntentForSubscriptionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrCreatePaymentIntentForSubscriptionQuery, GetOrCreatePaymentIntentForSubscriptionQueryVariables>(GetOrCreatePaymentIntentForSubscriptionDocument, options);
      }
export function useGetOrCreatePaymentIntentForSubscriptionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrCreatePaymentIntentForSubscriptionQuery, GetOrCreatePaymentIntentForSubscriptionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrCreatePaymentIntentForSubscriptionQuery, GetOrCreatePaymentIntentForSubscriptionQueryVariables>(GetOrCreatePaymentIntentForSubscriptionDocument, options);
        }
export type GetOrCreatePaymentIntentForSubscriptionQueryHookResult = ReturnType<typeof useGetOrCreatePaymentIntentForSubscriptionQuery>;
export type GetOrCreatePaymentIntentForSubscriptionLazyQueryHookResult = ReturnType<typeof useGetOrCreatePaymentIntentForSubscriptionLazyQuery>;
export type GetOrCreatePaymentIntentForSubscriptionQueryResult = Apollo.QueryResult<GetOrCreatePaymentIntentForSubscriptionQuery, GetOrCreatePaymentIntentForSubscriptionQueryVariables>;
export const GetOrCreateUserDocument = gql`
    query GetOrCreateUser {
  getOrCreateUser {
    id
    cognitoId
    name
    email
  }
}
    `;

/**
 * __useGetOrCreateUserQuery__
 *
 * To run a query within a React component, call `useGetOrCreateUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrCreateUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrCreateUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrCreateUserQuery(baseOptions?: Apollo.QueryHookOptions<GetOrCreateUserQuery, GetOrCreateUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrCreateUserQuery, GetOrCreateUserQueryVariables>(GetOrCreateUserDocument, options);
      }
export function useGetOrCreateUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrCreateUserQuery, GetOrCreateUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrCreateUserQuery, GetOrCreateUserQueryVariables>(GetOrCreateUserDocument, options);
        }
export type GetOrCreateUserQueryHookResult = ReturnType<typeof useGetOrCreateUserQuery>;
export type GetOrCreateUserLazyQueryHookResult = ReturnType<typeof useGetOrCreateUserLazyQuery>;
export type GetOrCreateUserQueryResult = Apollo.QueryResult<GetOrCreateUserQuery, GetOrCreateUserQueryVariables>;
export const GetSubscriptionsByOwnerIdDocument = gql`
    query GetSubscriptionsByOwnerId($input: GetSubscriptionByOwnerId!) {
  getSubscriptionsByOwnerId(input: $input) {
    items {
      id
      createdAt
      periodEnd
      periodStart
      status
      cardsRemaining
      productName
      productId
    }
    nextToken
  }
}
    `;

/**
 * __useGetSubscriptionsByOwnerIdQuery__
 *
 * To run a query within a React component, call `useGetSubscriptionsByOwnerIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubscriptionsByOwnerIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubscriptionsByOwnerIdQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSubscriptionsByOwnerIdQuery(baseOptions: Apollo.QueryHookOptions<GetSubscriptionsByOwnerIdQuery, GetSubscriptionsByOwnerIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSubscriptionsByOwnerIdQuery, GetSubscriptionsByOwnerIdQueryVariables>(GetSubscriptionsByOwnerIdDocument, options);
      }
export function useGetSubscriptionsByOwnerIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSubscriptionsByOwnerIdQuery, GetSubscriptionsByOwnerIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSubscriptionsByOwnerIdQuery, GetSubscriptionsByOwnerIdQueryVariables>(GetSubscriptionsByOwnerIdDocument, options);
        }
export type GetSubscriptionsByOwnerIdQueryHookResult = ReturnType<typeof useGetSubscriptionsByOwnerIdQuery>;
export type GetSubscriptionsByOwnerIdLazyQueryHookResult = ReturnType<typeof useGetSubscriptionsByOwnerIdLazyQuery>;
export type GetSubscriptionsByOwnerIdQueryResult = Apollo.QueryResult<GetSubscriptionsByOwnerIdQuery, GetSubscriptionsByOwnerIdQueryVariables>;
export const MarkCardAsReadDocument = gql`
    mutation MarkCardAsRead($input: MarkCardAsReadInput!) {
  markCardAsRead(input: $input) {
    deliveredAt
    readAt
    title
    bgImageUrl
    bgType
    pColorBg
    pColorFg
    pType
    ownerName
    receiverName
    titleFontFamily
    orderStatus
    orderType
    price
    paymentIntentID
    id
    receiverEmail
    createdAt
    updatedAt
    ownerCognitoID
    entryCount
    lastEntryCreatedAt
    lastEntryCreatedId
    subscriptionId
    status
  }
}
    `;
export type MarkCardAsReadMutationFn = Apollo.MutationFunction<MarkCardAsReadMutation, MarkCardAsReadMutationVariables>;

/**
 * __useMarkCardAsReadMutation__
 *
 * To run a mutation, you first call `useMarkCardAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkCardAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markCardAsReadMutation, { data, loading, error }] = useMarkCardAsReadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMarkCardAsReadMutation(baseOptions?: Apollo.MutationHookOptions<MarkCardAsReadMutation, MarkCardAsReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkCardAsReadMutation, MarkCardAsReadMutationVariables>(MarkCardAsReadDocument, options);
      }
export type MarkCardAsReadMutationHookResult = ReturnType<typeof useMarkCardAsReadMutation>;
export type MarkCardAsReadMutationResult = Apollo.MutationResult<MarkCardAsReadMutation>;
export type MarkCardAsReadMutationOptions = Apollo.BaseMutationOptions<MarkCardAsReadMutation, MarkCardAsReadMutationVariables>;
export const RemoveCardEntryDocument = gql`
    mutation RemoveCardEntry($removeCardEntryId: ID!) {
  removeCardEntry(id: $removeCardEntryId)
}
    `;
export type RemoveCardEntryMutationFn = Apollo.MutationFunction<RemoveCardEntryMutation, RemoveCardEntryMutationVariables>;

/**
 * __useRemoveCardEntryMutation__
 *
 * To run a mutation, you first call `useRemoveCardEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveCardEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeCardEntryMutation, { data, loading, error }] = useRemoveCardEntryMutation({
 *   variables: {
 *      removeCardEntryId: // value for 'removeCardEntryId'
 *   },
 * });
 */
export function useRemoveCardEntryMutation(baseOptions?: Apollo.MutationHookOptions<RemoveCardEntryMutation, RemoveCardEntryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveCardEntryMutation, RemoveCardEntryMutationVariables>(RemoveCardEntryDocument, options);
      }
export type RemoveCardEntryMutationHookResult = ReturnType<typeof useRemoveCardEntryMutation>;
export type RemoveCardEntryMutationResult = Apollo.MutationResult<RemoveCardEntryMutation>;
export type RemoveCardEntryMutationOptions = Apollo.BaseMutationOptions<RemoveCardEntryMutation, RemoveCardEntryMutationVariables>;
export const ToggleConfettiDocument = gql`
    mutation ToggleConfetti($input: ToggleConfettiInput!) {
  toggleConfetti(input: $input) {
    title
    bgImageUrl
    bgType
    pColorBg
    pColorFg
    pType
    ownerName
    receiverName
    titleFontFamily
    orderStatus
    orderType
    price
    paymentIntentID
    showConfetti
    id
    receiverEmail
    createdAt
    updatedAt
    ownerCognitoID
    entryCount
    lastEntryCreatedAt
    lastEntryCreatedId
    subscriptionId
    readAt
    deliveredAt
    status
  }
}
    `;
export type ToggleConfettiMutationFn = Apollo.MutationFunction<ToggleConfettiMutation, ToggleConfettiMutationVariables>;

/**
 * __useToggleConfettiMutation__
 *
 * To run a mutation, you first call `useToggleConfettiMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleConfettiMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleConfettiMutation, { data, loading, error }] = useToggleConfettiMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleConfettiMutation(baseOptions?: Apollo.MutationHookOptions<ToggleConfettiMutation, ToggleConfettiMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleConfettiMutation, ToggleConfettiMutationVariables>(ToggleConfettiDocument, options);
      }
export type ToggleConfettiMutationHookResult = ReturnType<typeof useToggleConfettiMutation>;
export type ToggleConfettiMutationResult = Apollo.MutationResult<ToggleConfettiMutation>;
export type ToggleConfettiMutationOptions = Apollo.BaseMutationOptions<ToggleConfettiMutation, ToggleConfettiMutationVariables>;
export const ToggleLockDocument = gql`
    mutation ToggleLock($input: ToggleLockInput!) {
  toggleLock(input: $input) {
    title
    bgImageUrl
    bgType
    pColorBg
    pColorFg
    pType
    ownerName
    receiverName
    titleFontFamily
    orderStatus
    orderType
    price
    paymentIntentID
    showConfetti
    isLocked
    id
    receiverEmail
    createdAt
    updatedAt
    ownerCognitoID
    entryCount
    lastEntryCreatedAt
    lastEntryCreatedId
    subscriptionId
    readAt
    deliveredAt
    status
  }
}
    `;
export type ToggleLockMutationFn = Apollo.MutationFunction<ToggleLockMutation, ToggleLockMutationVariables>;

/**
 * __useToggleLockMutation__
 *
 * To run a mutation, you first call `useToggleLockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleLockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleLockMutation, { data, loading, error }] = useToggleLockMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleLockMutation(baseOptions?: Apollo.MutationHookOptions<ToggleLockMutation, ToggleLockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleLockMutation, ToggleLockMutationVariables>(ToggleLockDocument, options);
      }
export type ToggleLockMutationHookResult = ReturnType<typeof useToggleLockMutation>;
export type ToggleLockMutationResult = Apollo.MutationResult<ToggleLockMutation>;
export type ToggleLockMutationOptions = Apollo.BaseMutationOptions<ToggleLockMutation, ToggleLockMutationVariables>;
export const ToggleOrientationDocument = gql`
    mutation ToggleOrientation($input: ToggleOrientationInput!) {
  toggleOrientation(input: $input) {
    id
    isLandscape
  }
}
    `;
export type ToggleOrientationMutationFn = Apollo.MutationFunction<ToggleOrientationMutation, ToggleOrientationMutationVariables>;

/**
 * __useToggleOrientationMutation__
 *
 * To run a mutation, you first call `useToggleOrientationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleOrientationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleOrientationMutation, { data, loading, error }] = useToggleOrientationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useToggleOrientationMutation(baseOptions?: Apollo.MutationHookOptions<ToggleOrientationMutation, ToggleOrientationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleOrientationMutation, ToggleOrientationMutationVariables>(ToggleOrientationDocument, options);
      }
export type ToggleOrientationMutationHookResult = ReturnType<typeof useToggleOrientationMutation>;
export type ToggleOrientationMutationResult = Apollo.MutationResult<ToggleOrientationMutation>;
export type ToggleOrientationMutationOptions = Apollo.BaseMutationOptions<ToggleOrientationMutation, ToggleOrientationMutationVariables>;
export const UpdateCardDocument = gql`
    mutation UpdateCard($input: UpdateCardInput!) {
  updateCard(input: $input) {
    title
    bgImageUrl
    bgType
    pColorBg
    pColorFg
    pType
    ownerName
    receiverName
    titleFontFamily
    orderStatus
    orderType
    price
    paymentIntentID
    id
    receiverEmail
    createdAt
    updatedAt
    ownerCognitoID
    entryCount
    lastEntryCreatedAt
    lastEntryCreatedId
    subscriptionId
    status
    isLandscape
    deliveredAt
    readAt
  }
}
    `;
export type UpdateCardMutationFn = Apollo.MutationFunction<UpdateCardMutation, UpdateCardMutationVariables>;

/**
 * __useUpdateCardMutation__
 *
 * To run a mutation, you first call `useUpdateCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCardMutation, { data, loading, error }] = useUpdateCardMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCardMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCardMutation, UpdateCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCardMutation, UpdateCardMutationVariables>(UpdateCardDocument, options);
      }
export type UpdateCardMutationHookResult = ReturnType<typeof useUpdateCardMutation>;
export type UpdateCardMutationResult = Apollo.MutationResult<UpdateCardMutation>;
export type UpdateCardMutationOptions = Apollo.BaseMutationOptions<UpdateCardMutation, UpdateCardMutationVariables>;
export const UpdateCardEntryDocument = gql`
    mutation UpdateCardEntry($input: UpdateCardEntryInput!) {
  updateCardEntry(input: $input) {
    cardID
    entryMessage
    font {
      fontFamily
      color
    }
    photo {
      externalId
      width
      height
      alt_description
      type
      urls {
        sm
        md
      }
      user {
        name
        username
        firstName
        lastName
      }
      sizes {
        sm {
          height
          width
        }
        md {
          height
          width
        }
      }
    }
    signerName
    signerCognitoID
    id
    updatedAt
    createdAt
  }
}
    `;
export type UpdateCardEntryMutationFn = Apollo.MutationFunction<UpdateCardEntryMutation, UpdateCardEntryMutationVariables>;

/**
 * __useUpdateCardEntryMutation__
 *
 * To run a mutation, you first call `useUpdateCardEntryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCardEntryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCardEntryMutation, { data, loading, error }] = useUpdateCardEntryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCardEntryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCardEntryMutation, UpdateCardEntryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCardEntryMutation, UpdateCardEntryMutationVariables>(UpdateCardEntryDocument, options);
      }
export type UpdateCardEntryMutationHookResult = ReturnType<typeof useUpdateCardEntryMutation>;
export type UpdateCardEntryMutationResult = Apollo.MutationResult<UpdateCardEntryMutation>;
export type UpdateCardEntryMutationOptions = Apollo.BaseMutationOptions<UpdateCardEntryMutation, UpdateCardEntryMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    id
    cognitoId
    name
    email
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;