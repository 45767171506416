import { Box, ModalBody, ModalCloseButton } from "@chakra-ui/react";
import { MediaTabs } from "../../feature";

export function PhotoSearchModal() {
  return (
    <>
      <ModalCloseButton />
      <ModalBody>
        <Box minH="3xl">
          <MediaTabs />
        </Box>
      </ModalBody>
    </>
  );
}
