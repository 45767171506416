import { useState } from "react";

const initialState = {
  data: null,
  error: null,
  isLoading: false,
  isError: false,
  isSuccess: false,
};

export function useMutation({ mutationFn }: any) {
  const [state, setState] = useState<{
    data: any;
    error: any;
    isLoading: boolean;
    isError: boolean;
    isSuccess: boolean;
  }>(initialState);

  function reset() {
    setState(initialState);
  }

  async function mutate(params: any) {
    setState((state) => ({
      ...state,
      isSuccess: false,
      isError: false,
      isLoading: true,
    }));

    try {
      const data = await mutationFn(params);
      //   await new Promise((r) => setTimeout(r, 500));
      //   const data = {};
      setState((state) => ({
        ...state,
        data,
        isSuccess: true,
        isLoading: false,
      }));
    } catch (err) {
      setState((state) => ({
        ...state,
        error: err,
        isSuccess: false,
        isError: true,
        isLoading: false,
      }));
    }
  }

  return {
    mutate,
    reset,
    ...state,
  };
}
