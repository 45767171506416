import { useGetOrCreatePaymentIntentForSubscriptionQuery } from "../../../../graphql/generated/schema";
import { useToast } from "../../../../hooks";

export function useStripeClientSecretForSubscription({ productId = "" }) {
  const {
    error: getOrCreatePaymentIntentForSubscriptionError,
    data,
    ...rest
  } = useGetOrCreatePaymentIntentForSubscriptionQuery({
    variables: { input: { productId } },
    skip: !productId, // !cardId || !fetch,
  });

  // TODO: for subscription, attach subscription payment id?
  const paymentIntentID =
    // card?.paymentIntentID ||
    data?.getOrCreatePaymentIntentForSubscription?.paymentIntentID;
  const subscriptionId =
    // card?.paymentIntentID ||
    data?.getOrCreatePaymentIntentForSubscription?.subscriptionId;

  const showPaymentIntentErrorToast =
    !!getOrCreatePaymentIntentForSubscriptionError;

  // Setup toasts
  useToast({
    id: "getOrCreatePaymentIntentForSubscription",
    title: "Something went wrong",
    show: showPaymentIntentErrorToast,
    status: "error",
  });

  return {
    ...rest,
    error: getOrCreatePaymentIntentForSubscriptionError,
    subscriptionId,
    clientSecret: paymentIntentID, // TODO: Rename "clientSecret" in server
  };
}
