import React from "react";
import {
  Button,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  ButtonProps,
} from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/react";

export function PopoverButton({
  buttonProps,
  popoverText,
}: {
  buttonProps: ButtonProps;
  popoverText: string;
}) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Popover isOpen={isOpen} onClose={onClose}>
      <PopoverTrigger>
        <Button
          {...buttonProps}
          onClick={isOpen ? onClose : onOpen}
          variant={"outline"}
          // onMouseEnter={onOpen}
          // onMouseLeave={onClose}
        />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        {/* <PopoverHeader>Popover Title</PopoverHeader> */}
        <PopoverBody>{popoverText}</PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
