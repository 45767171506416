import Amplify from "aws-amplify";
import { getSLSGraphQLEndpoint, IS_PROD } from "./constants";

// console.log("soimto", process.env.NODE_ENV); // development

const REGION = "us-east-1";
Amplify.configure({
  // aws_cloud_logic_custom: [
  //   {
  //     name: "graphql", // (required) - API Name (This name is used used in the client app to identify the API - API.get('your-api-name', '/path'))
  //     endpoint: IS_PROD ? "" : "http://localhost:4000/dev/", // "https://gfz59k9rlj.execute-api.us-east-1.amazonaws.com/dev", // (required) -API Gateway URL + environment
  //     region: REGION, // (required) - API Gateway region
  //   },
  // ],
  Auth: {
    region: REGION,
    userPoolId: IS_PROD
      ? "us-east-1_tB12v9jQC" // PROD
      : "us-east-1_07fwkeBUu", // DEV
    userPoolWebClientId: IS_PROD
      ? "10tjck1vtbha6ojscdv5d2j51d" // PROD
      : "27umisrn2n1jb4tldbm0buh4v7", // DEV

    // // OPTIONAL - customized storage object
    // storage: MyStorage,

    // // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
    // authenticationFlowType: "USER_PASSWORD_AUTH",

    // // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
    // clientMetadata: { myCustomKey: "myCustomValue" },
  },
  API: {
    endpoints: [
      {
        name: "cardoly-graphql",
        endpoint: getSLSGraphQLEndpoint(),
      },
      {
        name: "cardoly-graphql-public",
        endpoint: getSLSGraphQLEndpoint(true),
      },
    ],
  },
});
