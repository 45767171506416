import { RiWifiOffLine as NetworkDownIcon } from "react-icons/ri";
import { Box, Center, HStack, Text, Icon, AspectRatio } from "@chakra-ui/react";
import { IGif } from "@giphy/js-types";
import { Spinner } from "@chakra-ui/react";
import {
  entrySliceActions,
  modalActions,
  useAppDispatch,
} from "../../../../state-management";
import { useGiphy } from "../../../../api";
import { InfiniteScroll, MasonryGrid } from "../../../../components";
import { Photo } from "../Photo";
import { usePhotoSearch } from "../../../../hooks";
import { TryNewSearchPrompt } from "../TryNewSearchPrompt";
import { g } from "../../../../graphql";
import { PhotoSearchEmptyState } from "../PhotoSearchEmptyState";

// TODO: Ensure this is in shareable lib for backend and frontend
function transformGifsToCardolyPhotos(gifs: IGif[]): CardolyPhoto[] {
  return gifs.map((gif) => {
    // const mdPhoto = gif.images.fixed_width; // use sm?
    // const mdPhoto = gif?.images?.downsized; // Looks the same as downsized_medium
    // const mdPhoto = gif?.images?.downsized_small; // Diff data structure
    // const smPhoto = gif.images.fixed_width_small; // too blurry in preview
    const mdPhoto = gif?.images?.downsized_medium;
    const smPhoto = gif.images.fixed_width;

    return {
      type: g.PhotoType.Giphy,
      externalId: gif.id as string,
      urls: {
        md: mdPhoto.url,
        sm: smPhoto.webp, // Looks like webp is best for preview: https://developers.giphy.com/docs/optional-settings/#rendition-guide
      },
      sizes: {
        md: {
          height: mdPhoto.height.toString(),
          width: mdPhoto.width.toString(),
        },
        sm: {
          height: smPhoto.height.toString(),
          width: smPhoto.width.toString(),
        },
      },
    };
  });
}

/* 
   Infinite scroll Image gallery: https://www.javascriptstuff.com/react-image-gallery/
   Masonry Grid: https://github.com/chakra-ui/chakra-ui/discussions/2724#discussioncomment-482851 
*/
export function GiphyPhotoGrid() {
  // const cardolyPhotosFromGifs = transformGifsToCardolyPhotos(test1);
  const dispatch = useAppDispatch();
  const { debounceSearchText, hasSearched } = usePhotoSearch();
  const { data, error, isLoading, hasMore, fetchMoreGiphy } = useGiphy({
    searchText: debounceSearchText,
  });

  if (isLoading) {
    return (
      <Center>
        <Spinner color="blue" />
      </Center>
    );
  }

  if (error) {
    const e = error as { message: string };
    const isOffline = e?.message === "Failed to fetch";
    const errorMessage = isOffline
      ? "You are offline"
      : "Oops...Something went wrong";

    return (
      <HStack justifyContent="center">
        <Text align="center" color="red.600">
          {errorMessage}
        </Text>
        {isOffline && <Icon as={NetworkDownIcon} color="red.600" />}
      </HStack>
    );
  }

  if (data.length === 0) {
    if (hasSearched) {
      return (
        <Box>
          <Center>
            <Text>There are no results.</Text>
          </Center>
        </Box>
      );
    } else {
      return <PhotoSearchEmptyState />;
    }
  }

  let cardolyPhotosFromGifs: CardolyPhoto[] | undefined;
  if (data) {
    cardolyPhotosFromGifs = transformGifsToCardolyPhotos(data);
  }

  return (
    <Box maxHeight="60vh" overflow="auto" id="giphy-container">
      {cardolyPhotosFromGifs && (
        <InfiniteScroll
          dataLength={cardolyPhotosFromGifs.length} //This is important field to render the next data
          next={fetchMoreGiphy}
          hasMore={hasMore}
          scrollableTarget="giphy-container"
          /* if you want to reach bottom on screen, showing loader explicitly before loading new, set scrollThreshold to 1.
          May be useful to show placeholder blur explicitly before loading new image, also preventing jitter when user is
          scrolling down, but scroll of already rendered images stops a bit due to loading */
          // scrollThreshold={1}
        >
          <MasonryGrid space={4} columns={2}>
            {cardolyPhotosFromGifs?.map((photo, i) => {
              const imageWidth = photo.sizes?.sm?.width;
              const imageHeight = photo.sizes?.sm?.height;
              const url = photo?.urls?.sm ?? "";

              return (
                <AspectRatio ratio={Number(imageWidth) / Number(imageHeight)}>
                  <Photo
                    url={url}
                    key={url + i}
                    // height={`${photo.sizes?.sm?.height}px`}
                    // width={photo.sizes?.sm?.width}
                    onClick={() => {
                      dispatch(entrySliceActions.addPhoto(photo));
                      dispatch(modalActions.hideModal());
                    }}
                  />
                </AspectRatio>
              );
            })}
          </MasonryGrid>
          {!hasMore && hasSearched && <TryNewSearchPrompt />}
        </InfiniteScroll>
      )}
    </Box>
  );
}
