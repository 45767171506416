import { ReactElement } from "react";
import { AiOutlineFileGif as GifIcon } from "react-icons/ai";
import { HiOutlinePhotograph as PhotoIcon } from "react-icons/hi";
import {
  Box,
  BoxProps,
  HStack,
  Image,
  Link,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  VStack,
} from "@chakra-ui/react";
import {
  UnsplashPhotoGrid,
  GiphyPhotoGrid,
  PhotoSearchInput,
} from "./components";

interface MediaTabProps {
  label: string;
  icon: ReactElement;
}
function MediaTab({ label, icon }: MediaTabProps) {
  return (
    <Tab>
      <HStack>
        {/* <GifIcon /> */}
        {icon}
        <Box>
          <Text fontWeight="bold" color="brand">
            {label}
          </Text>
        </Box>
      </HStack>
      {/* <div>
    <Button variant="ghost" leftIcon={<GifIcon />}>
      Add GIF
    </Button>
  </div> */}
    </Tab>
  );
}

function InputStack(props: BoxProps) {
  return <VStack alignItems="self-end" spacing="1" py="2" {...props} />;
}

export function MediaTabs() {
  // line,enclosed, enclosed-colored, soft-rounded, solid-rounded
  return (
    <Box p="4">
      {/* TODO: Use detaultIndex to figure out which tab to open first */}
      <Tabs variant="soft-rounded" colorScheme="brand" defaultIndex={0} isLazy>
        <TabList>
          <MediaTab label="GIF" icon={<GifIcon />} />
          {/* <MediaTab label="Online Photo" icon={<PhotoIcon />} /> */}
        </TabList>

        <TabPanels>
          <TabPanel p="0">
            <InputStack>
              <PhotoSearchInput placeholder="Search for GIF..." />
              <Image src="/powered_by_giphy.png" w="100px" />
            </InputStack>
            <GiphyPhotoGrid />
          </TabPanel>
          <TabPanel p="0">
            <InputStack>
              <PhotoSearchInput placeholder="Search for photo..." />
              <Text fontSize="xx-small" color="gray.500">
                Photos by{" "}
                <Link
                  href={
                    "https://unsplash.com/?utm_source=cardoly&utm_medium=referral"
                  }
                  isExternal
                  fontWeight="bold"
                >
                  {" "}
                  Unsplash{" "}
                </Link>
              </Text>
            </InputStack>
            <UnsplashPhotoGrid />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}
