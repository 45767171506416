import type { ComponentStyleConfig } from "@chakra-ui/react";

// const bold = {
//   fontWeight: 700,
// };
// const body2 = {
//   fontSize: 16,
//   lineHeight: "24px",
// };

// const h6 = {
//   fontSize: 24,
//   lineHeight: "29.26px",
//   fontWeight: 400,
// };

const baseTextHeaderStyles = {
  // margin: "3rem 0 1.38rem",
  // font-family: 'Poppins', sans-serif;
  fontWeight: 700,
  lineHeight: 1.3,
};
export const Text: ComponentStyleConfig = {
  baseStyle: {
    color: "gray.700",
  },
  variants: {
    caption: {
      fontSize: "sm",
      color: "gray.500",
      textTransform: "uppercase",
    },
    h2: {
      ...baseTextHeaderStyles,
      fontSize: "1.602rem;",
    },
    h3: {
      ...baseTextHeaderStyles,
      fontSize: "1.424rem",
      // fontSize: "1.728rem",
    },
    h4: {
      ...baseTextHeaderStyles,
      // fontSize: "1.44rem",
      fontSize: "1.266rem;",
    },
    // muted: {
    //   ...baseTextHeaderStyles,
    //   // fontSize: "1.44rem",
    //   fontSize: "1.266rem;",
    // },
  },
  // defaultProps: {
  // variant: "body2",
  // },
};

export const Heading: ComponentStyleConfig = {
  ...Text,
};

export const Button: ComponentStyleConfig = {
  defaultProps: {
    colorScheme: "brand",
  },
};
