import { Box } from "@chakra-ui/react";
import { Navigate, useLocation } from "react-router-dom";
import { ROUTES } from "../../constants";
import { AmplifyAuthenticatorWithLogo } from "../../feature";
import { useAuth } from "../../hooks";

export function LoginPage() {
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  const {
    from: { pathname, search, hash },
  } = location.state ?? { from: {} };

  return isAuthenticated ? (
    location.state ? (
      <Navigate to={{ pathname, search, hash }} replace />
    ) : (
      <Navigate replace to={ROUTES.DASHBOARD} />
    )
  ) : (
    <Box pt="lg">
      <AmplifyAuthenticatorWithLogo />
    </Box>
  );
}
