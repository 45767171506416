import { Text, VStack, Box } from "@chakra-ui/react";
import { PRODUCTS } from "../../../constants";

import { PaymentButton } from "../PaymentSection";
import { Pricing } from "./Pricing";

export function SubscriptionPricingCard({ productId }: { productId: string }) {
  const product = PRODUCTS[productId];

  return (
    <Pricing.Card>
      <Pricing.ProductType>{product.name}</Pricing.ProductType>

      <VStack spacing="sm">
        <Pricing.Price>${product.price}</Pricing.Price>

        <Box textAlign="center">
          <Text textDecor={"line-through"} color="gray.700" as="span">
            ${product.bundle?.singleCardPrice}{" "}
          </Text>
          <Text color="red.500" as="span">
            ${product.bundle?.singleCardDiscountPrice}{" "}
          </Text>
          <Text color="gray.500" as="span">
            per Cardoly
          </Text>
        </Box>
        <Pricing.SavingsBadge>
          Save {product.bundle?.discount}%
        </Pricing.SavingsBadge>
      </VStack>

      <Pricing.CardNumber>
        {/* {product.bundle?.quantity} Premium {PRODUCT_NAME_PLURAL} */}
        Pack of {product.bundle?.quantity}
      </Pricing.CardNumber>

      <Text textAlign="center">Pack is valid for 1 year</Text>

      <Pricing.Footer>
        <PaymentButton productId={productId} />
      </Pricing.Footer>
    </Pricing.Card>
  );
}

// LEGACY

export interface PricingCardData {
  features: string[];
  name: string;
  price: string;
}
