import {
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  Button,
  Text,
} from "@chakra-ui/react";
import { useCard, useToast } from "../../hooks";

export function DeleteCardModal({
  cardId,
  onCloseModal,
}: {
  cardId: string;
  onCloseModal(): void;
}) {
  const { deleteCard, isLoadingDeleteCard, isErrorDeleteCard } = useCard();

  // Setup toasts
  useToast({
    id: "delete",
    title: "Delete failed",
    description: "Something went wrong :(",
    show: isErrorDeleteCard,
    status: "error",
  });

  function handleDeleteCard() {
    if (cardId) {
      deleteCard({
        variables: { input: { cardId } },
        update: (cache) => {
          // Invalidate the cache, so when these requests are attempted the data will be refetched from server
          cache.evict({
            fieldName: "getCardsByOwnerCognitoId", // To reflect latest card entry count. This is automatically handled by getCard, and Apollo associating the resulting data with this cache.
          });
          cache.gc();
        },
        onCompleted() {
          onCloseModal();
        },
      });
    }
  }

  return (
    <>
      <ModalHeader>Permanently Delete Cardoly</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Text display="inline">
          Are you sure you want to permanently delete this Cardoly? This will
          remove the Cardoly from all users.{" "}
        </Text>
        <Text fontWeight={"bold"} display="inline">
          You cannot undo this action.
        </Text>
      </ModalBody>

      <ModalFooter>
        <Button
          variant="ghost"
          color="gray.700"
          colorScheme="gray"
          onClick={onCloseModal}
          mr={2}
        >
          No, Take me back
        </Button>
        <Button
          colorScheme="red"
          isLoading={isLoadingDeleteCard}
          onClick={handleDeleteCard}
        >
          Yes, Delete
        </Button>
      </ModalFooter>
    </>
  );
}
