import { pick } from "lodash";
import { Pattern } from "pill-pity";
import { imageFiles } from "./imageFiles";
import { CardBgType } from "../graphql/generated/schema";

export const patternNames: Pattern[] = [
  "jigsaw",
  "i-like-food",
  "overcast",
  "formal-invitation",
  "topography",
  "skulls",
  "floating-cogs",
  "glamorous",
  "texture",
  "jupiter",
  "architect",
  "cutout",
  "hideout",
  "graph-paper",
  "yyy",
  "squares",
  "falling-triangles",
  "piano-man",
  "pie-factory",
  "dominos",
  "hexagons",
  "charlie-brown",
  "autumn",
  "temple",
  "stamp-collection",
  "death-star",
  "church-on-sunday",
  "overlapping-hexagons",
  "four-point-stars",
  "bamboo",
  "bathroom-floor",
  "cork-screw",
  "happy-intersection",
  "kiwi",
  "lips",
  "lisbon",
  "random-shapes",
  "steel-beams",
  "tiny-checkers",
  "x-equals",
  "anchors-away",
  "bevel-circle",
  "brick-wall",
  "fancy-rectangles",
  "heavy-rain",
  "overlapping-circles",
  "plus",
  "rounded-plus-connected",
  "volcano-lamp",
  "wiggle",
  "bubbles",
  "cage",
  "connections",
  "current",
  "diagonal-stripes",
  "flipped-diamonds",
  "houndstooth",
  "leaf",
  "lines-in-motion",
  "moroccan",
  "morphing-diamonds",
  "rails",
  "rain",
  "squares-in-squares",
  "stripes",
  "tic-tac-toe",
  "zig-zag",
  "aztec",
  "bank-note",
  "boxes",
  "circles-squares",
  "circuit-board",
  "curtain",
  "diagonal-lines",
  "endless-clouds",
  "eyes",
  "floor-tile",
  "groovy",
  "intersecting-circles",
  "melt",
  "overlapping-diamonds",
  "parkay-floor",
  "pixel-dots",
  "polka-dots",
  "signal",
  "slanted-stars",
  "wallpaper",
];

function createColor(bg: string, fg: string) {
  return {
    bg,
    fg,
  };
}

const initialColors = [
  // createColor("#10332B", "#1C594C"),
  // createColor("#050F0D", "#11362E"),
  createColor("#194d33", "#6DD09F"),
  createColor("#fff", "#00f"),
  createColor("#f4f700", "#363131"),
  createColor("#000", "#fff"),
];

export const patternCardBackgrounds = createInitialPatternCardBackgrounds();
export const imageCardBackgrounds = createInitialImageCardBackgrounds();

export function createInitialPatternCardBackgrounds(): PatternBackground[] {
  return patternNames.map((patternName, i) => {
    const colors = initialColors[i % initialColors.length];

    return {
      cardBackgroundID: patternName,
      type: CardBgType.Pattern,
      color: {
        bg: colors.bg,
        fg: colors.fg,
      },
    };
  });
}

export function createInitialImageCardBackgrounds(): ImageBackground[] {
  return imageFiles.map((imageFile) => {
    return transformImageFileToImageBackground(imageFile);
  });
}

// UTIL
// store initialCardBackgrounds in a Map for faster lookup
export const initialCardBackgrounds = new Map(
  [...patternCardBackgrounds, ...imageCardBackgrounds].map((cardBg) => [
    cardBg.cardBackgroundID,
    cardBg,
  ])
);

export function getCardBackgroundById(
  cardBackgroundID: CardolyBackground["cardBackgroundID"]
) {
  const cardBg = initialCardBackgrounds.get(cardBackgroundID);

  return formatCardBackgroundObject(cardBg);
}

export function getCardBackgroundFromCard(
  card: Partial<Card>
): CardBackgroundForCard {
  const cardBgType = card.bgType;

  let formattedCardBackground: CardBackgroundForCard = {};

  if (cardBgType === CardBgType.Pattern) {
    formattedCardBackground = pick(card, [
      "pColorBg",
      "pColorFg",
      "pType",
      "bgType",
    ]);
  }

  if (cardBgType === CardBgType.Image) {
    formattedCardBackground = pick(card, ["bgType", "bgImageUrl"]);
  }

  return formattedCardBackground;
}

export function formatCardBackgroundObject(
  selectedCardBackground: CardolyBackground | undefined
) {
  const selectedBgType = selectedCardBackground?.type;

  let formattedCardBackground: CardBackgroundForCard = {};

  if (selectedBgType === CardBgType.Pattern) {
    formattedCardBackground = {
      pColorBg: selectedCardBackground?.color.bg,
      pColorFg: selectedCardBackground?.color.fg,
      pType: selectedCardBackground?.cardBackgroundID,
      bgType: CardBgType.Pattern,
    };
  }

  if (
    selectedBgType === CardBgType.Image &&
    selectedCardBackground?.bgImageUrl
  ) {
    formattedCardBackground = {
      bgType: CardBgType.Image,
      bgImageUrl: selectedCardBackground.bgImageUrl,
    };
  }
  return formattedCardBackground;
}

export function transformImageFileToImageBackground(imageFile: ImageFile): {
  cardBackgroundID: string;
  type: CardBgType.Image;
  bgImageUrl: string;
} {
  return {
    cardBackgroundID: imageFile?.fileName,
    type: CardBgType.Image,
    bgImageUrl: `/bg-images/${imageFile?.fileName}`,
  };
}

export const imageFileGroupings: PhotoGrouping = imageFiles.reduce(
  (acc, item) => {
    const imageCardBackground = transformImageFileToImageBackground(item);
    if (item.category) {
      if (acc[item.category]) {
        acc[item.category].push(imageCardBackground);
      } else {
        acc[item.category] = [imageCardBackground];
      }
    }

    return acc;
  },
  {} as PhotoGrouping
);
