import { DeleteCardPrompt } from "./DeleteCardEntryPrompt";
import { DeleteCardModal } from "./DeleteCardModal";
import { PhotoSearchModal } from "./PhotoSearchModal";
import { ShareCardolyModal } from "./ShareCardolyModal";
import { UpgradeCardModal } from "./UpgradeCardModal";
import { PaymentModal } from "./PaymentModal";
import { PayWithSubscriptionModal } from "./PayWithSubscriptionModal";
import { ConfirmModal } from "./ConfirmModal";

type ModalComponents = Record<ModalTypes, (props: any) => JSX.Element>;
export const MODAL_COMPONENTS: ModalComponents = {
  DeleteCardModal,
  DeleteCardPrompt,
  PhotoSearchModal,
  UpgradeCardModal,
  ShareCardolyModal,
  PaymentModal,
  ConfirmModal,
  PayWithSubscriptionModal,
};
