import { Flex, Text, VStack } from "@chakra-ui/react";

// TODO: eventually add upgrade button for payment.
export function UpgradeCardoly() {
  return (
    <VStack mb="sm">
      <Flex justifyContent={"space-between"} alignItems="center" w="full">
        <Text fontSize={"md"} fontWeight="bold">
          Solo Cardoly
        </Text>
      </Flex>
      <Text fontSize={"sm"} color="gray.600">
        This is a Solo Cardoly used for 1-to-1 or 1-to-many greeting.
      </Text>
    </VStack>
  );
}
