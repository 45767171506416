import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@chakra-ui/react";
import CardsCreated from "./CardsCreated";

export function CardSummaryTabs() {
  return (
    <Tabs variant="soft-rounded" colorScheme="brand" mt="8">
      <TabList>
        <Tab>Created</Tab>
        {/* <Tab>Signed</Tab> */}
      </TabList>
      <TabPanels>
        <TabPanel>
          <CardsCreated />
        </TabPanel>
        {/* <TabPanel>
          <p>Your signed cards.</p>
        </TabPanel> */}
      </TabPanels>
    </Tabs>
  );
}
