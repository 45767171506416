import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { VIRTUAL_CARD_ENTRY_ID } from "../../constants";
export const ENTRYSLICE_FEATURE_KEY = "entry";

export interface EntryState {
  entry?: CardEntry;
  deletedCardEntryId?: string;
}

const initialEntryState: EntryState = {};

export const entrySlice = createSlice({
  name: ENTRYSLICE_FEATURE_KEY,
  initialState: initialEntryState,
  reducers: {
    addDeletedCardEntryId(state, action: PayloadAction<string>) {
      state.deletedCardEntryId = action.payload;
    },
    removeDeletedCardEntryId(state) {
      delete state.deletedCardEntryId;
    },
    addEntry(state, action: PayloadAction<CardEntry>) {
      state.entry = action.payload;
    },
    onFontChange(state, action: PayloadAction<string>) {
      // state.selectedFont = action.payload;
      if (state.entry?.font) {
        state.entry.font.fontFamily = action.payload;
      }
    },
    // onMessageChange(state, action: PayloadAction<string>) {
    //   // state.message = action.payload;
    //   if (state.entry) {
    //     state.entry.entryMessage = action.payload;
    //   }
    // },
    // onSignerNameChange(state, action: PayloadAction<string>) {
    //   if (state.entry) {
    //     state.entry.signerName = action.payload;
    //   }
    // },
    onTextColorChange(state, action: PayloadAction<string>) {
      // state.textColor = action.payload;
      if (state.entry?.font) {
        state.entry.font.color = action.payload;
      }
    },
    addPhoto(state, action: PayloadAction<CardolyPhoto>) {
      if (state.entry) {
        state.entry.photo = action.payload;
      } else {
        // user is adding new entry
        state.entry = { id: VIRTUAL_CARD_ENTRY_ID, photo: action.payload };
      }
    },
    removePhoto(state) {
      // delete state.photo;
      if (state.entry) {
        delete state?.entry?.photo;
      }
    },
    reset() {
      return {}; // Default state
    },
  },
});

/*
 * Export reducer for store configuration.
 */
export const entrySliceReducer = entrySlice.reducer;

export const entrySliceActions = entrySlice.actions;
