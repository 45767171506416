import { Box, BoxProps, Center, Icon } from "@chakra-ui/react";

export function PreviewImage({
  icon,
  ...rest
}: BoxProps & {
  icon: BoxProps["as"];
}) {
  return (
    <Box bgColor={"gray.200"} {...rest}>
      <Center h="full" p="lg">
        <Icon boxSize={"64px"} as={icon} fill="gray.400" />
      </Center>
    </Box>
  );
}
