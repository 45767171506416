import { Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { FiLogIn as LogInIcon } from "react-icons/fi";
import { useRoutes } from "../../hooks";

export function SignInButton() {
  // const match = useMatch(ROUTES.LOGIN);
  const { isLoginPage } = useRoutes();

  return isLoginPage ? null : (
    <Button
      as={Link}
      to="/login"
      leftIcon={<LogInIcon />}
      fontSize={"md"}
      variant="outline"
      w="full"
      // color={"white"}
      // bg={"brand.500"}
      // _hover={{
      //   bg: "brand.300",
      //   shadow: "2xl",
      // }}
      rounded="full"
    >
      Sign In
    </Button>
  );
}
