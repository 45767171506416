import { CardBgType } from "../graphql/generated/schema";
import {
  cardActions,
  formatCardBackgroundObject,
  selectors,
  useAppDispatch,
  useAppSelector,
} from "../state-management";

export function useCardBackground() {
  const dispatch = useAppDispatch();
  const selectedCardBackground = useAppSelector(
    selectors.cardSelectors.getSelectedCardBackground
  );
  const selectedCardBackgroundID = useAppSelector(
    selectors.cardSelectors.getSelectedCardBackgroundID
  );
  const selectedBackgroundType = useAppSelector(
    (state) => state.card.selectedBackgroundType
  );
  // const isEditing = !!selectedCardBackground?.cardBackgroundID; // TODO: Vary

  function handleBackgroundTypeChange(bgType: CardolyBackground["type"]) {
    dispatch(cardActions.onBackgroundTypeChange(bgType));
  }

  function handleSetCardBackgroundID(id: string) {
    dispatch(cardActions.setCardBackgroundID(id));
  }

  // let formattedCardBackground: Pick<
  //   Card,
  //   "pColorBg" | "pColorFg" | "pType" | "bgImageUrl" | "bgType"
  // > = formatCardBackgroundObject(selectedCardBackground);

  return {
    handleSetCardBackgroundID,
    handleBackgroundTypeChange,
    selectedBackgroundType,
    // formattedCardBackground,
    selectedCardBackgroundID,
    // isEditing,
    selectedCardBackgroundType: selectedCardBackground?.type,
  };
}
