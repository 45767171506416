import { useEffect, useState } from "react";
import { useCard, useToast } from "../../hooks";
import { useSearchParams } from "react-router-dom";
import { useStripePaymentIntent } from "./useStripePaymentIntent";
import { SEARCH_PARAM_SUBSCRIPTION } from "../../constants";
import {
  OrderStatus,
  OrderType,
  useCreateSubscriptionAndAddCardMutation,
  useCreateSubscriptionMutation,
} from "../../graphql/generated/schema";
import { usePayment } from "../../hooks/usePayment";

export function useHandleStripeRedirect() {
  const {
    paymentFor,
    isPaymentForSubscriptionOnly,
    isPaymentForSubscriptionWithCard,
    isPaymentForSingleCard,
  } = usePayment();
  const { updateCard, cardID, cardUpdateError, isFree } = useCard();

  const [
    createSubscriptionAndAddCard,
    { error: errorCreateSubscriptionAndAddCard },
  ] = useCreateSubscriptionAndAddCardMutation();

  const [createSubscription, { error: errorCreateSubscription }] =
    useCreateSubscriptionMutation();

  // HANDLE PAYMENT SUCCESS
  const [searchParams] = useSearchParams();
  // When Stripe redirects after successful payment, it sends a payment status
  const isPaymentSuccess = searchParams.get("redirect_status") === "succeeded";
  const clientSecret = searchParams.get("payment_intent_client_secret");
  const shouldCheckIfCustomerHasPaid = isPaymentSuccess;
  const [isLoading, setIsLoading] = useState(isPaymentSuccess);
  const subscriptionId = searchParams.get(SEARCH_PARAM_SUBSCRIPTION);

  const { paymentIntentState, isLoadingPaymentIntentFromStripe } =
    useStripePaymentIntent({
      fetch: shouldCheckIfCustomerHasPaid,
      clientSecret,
    });

  /**
   Backend is unaware of Stripe payment being successful. Possibly, the webhook from Stripe to BE failed,
   or it is mid-processing. In either case, we forcefully change the order status of the card in the BE,
   once Stripe redirects us back to the UI with a success message and the card !isFree.
   */
  useEffect(() => {
    if (!isLoadingPaymentIntentFromStripe) {
      // If we have fetched the payment intent from Stripe and there's an error, stop loading
      if (paymentIntentState.error) {
        setIsLoading(false);
      }

      const processPaymentInBackend = async () => {
        if (paymentIntentState.isSuccess) {
          try {
            if (isPaymentForSubscriptionOnly && subscriptionId) {
              // SUB ONLY
              await createSubscription({
                variables: { input: { subscriptionId } },
              });
            }

            if (
              isPaymentForSubscriptionWithCard &&
              subscriptionId &&
              cardID &&
              isFree
            ) {
              // SUB WITH CARD
              // Buying a subscription associated with a card.
              // 1. Create a subscription in backend (if it doesn't exist already from webhook)
              // 2. Add card to subscription
              await createSubscriptionAndAddCard({
                variables: {
                  input: { cardId: cardID, subscriptionId },
                },
              });
            }

            if (isPaymentForSingleCard && cardID && isFree) {
              // SINGLE CARD
              // Is regular single card purchase without subscription
              await updateCard({
                id: cardID,
                orderStatus: OrderStatus.Paid,
                orderType: OrderType.Premium,
              });
            }
          } catch (err) {
            // Handle error if needed
          } finally {
            setIsLoading(false); // Set loading state to false when mutation is done (whether it succeeded or not)
          }
        }
      };

      processPaymentInBackend();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingPaymentIntentFromStripe, isFree]);

  const processingPaymentInBackendError =
    errorCreateSubscriptionAndAddCard ||
    errorCreateSubscription ||
    cardUpdateError ||
    paymentIntentState.error;

  useToast({
    id: "processingPaymentInBackendError",
    show: !!processingPaymentInBackendError,
    status: "error",
    title: "Error",
    duration: 10000,
    description: "Something went wrong",
  });

  return {
    redirectToPaymentSuccessPage:
      !!paymentFor && !processingPaymentInBackendError,
    isProcessingPaymentInBackend: isLoading,
    processingPaymentInBackendError,
  };
}
