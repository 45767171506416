import { Button, Flex, Text, VStack } from "@chakra-ui/react";
// import { MdUpgrade as UpgradeIcon } from "react-icons/md";
import { FiLock as UpgradeIcon } from "react-icons/fi";
import { MAX_FREE_CARDOLY_ENTRIES } from "../../../../../constants";
import { useCard, useRoutes } from "../../../../../hooks";

export function UpgradeCardoly() {
  const { toSelectCardTypePage } = useRoutes();
  const { cardID, isFree, cardType } = useCard();

  return isFree ? (
    <VStack mb="sm">
      <Flex justifyContent={"space-between"} alignItems="center" w="full">
        <Text fontSize={"md"} fontWeight="bold">
          {cardType} Cardoly
        </Text>
        <Button
          colorScheme="brand"
          onClick={() => toSelectCardTypePage({ cardID })}
          rightIcon={<UpgradeIcon />}
        >
          Upgrade Cardoly
        </Button>
      </Flex>
      <Text fontSize={"sm"} color="gray.600">
        This Cardoly is limited to {MAX_FREE_CARDOLY_ENTRIES} posts. Upgrade for
        unlimited posts.
      </Text>
    </VStack>
  ) : null;
}
