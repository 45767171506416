import { Button } from "@chakra-ui/react";
import { FiShoppingCart as ShoppingCartIcon } from "react-icons/fi";
import { useCard, useRoutes } from "../../../hooks";

/* 
  We use Buy Now button because without it, we will let PaymentForm load everytime 
  which would be creating a lot of payment intents in Stripe -- and most of them aren't 
  due to people actually wanting to buy; spamming the system. 
*/
export function PaymentButton({ productId }: { productId: string }) {
  const { cardID } = useCard();
  const nav = useRoutes();

  return (
    <Button
      w="full"
      colorScheme="brand"
      leftIcon={<ShoppingCartIcon />}
      onClick={() => nav.toPaymentPageForProduct(productId, cardID)}
    >
      Buy Now
    </Button>
  );
}
