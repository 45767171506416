import {
  Button,
  Center,
  Checkbox,
  Circle,
  Flex,
  Heading,
  HStack,
  Link,
  SimpleGrid,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { Loader, PageContainer } from "../../components";
import { CardolyLogoName } from "../../feature";
import {
  CardsToSignersObject,
  useEmailThanksToCardSignersMutation,
  useGetCardSignersQuery,
} from "../../graphql/generated/schema";
import { useCard, useCustomToast, useRoutes } from "../../hooks";

interface Signer extends CardsToSignersObject {
  isChecked: boolean;
}

export function ThanksPage() {
  const { inviteParticipantUrl } = useRoutes();
  const { cardID, card } = useCard();
  const { data, loading: isLoadingGetCardSigners } = useGetCardSignersQuery({
    variables: {
      input: {
        cardId: cardID ?? "",
      },
    },
  });
  const receiverName = card?.receiverName;
  const serverSigners = data?.getCardSigners;

  const [selectAll, setSelectAll] = useState(true);
  const [checkboxes, setCheckboxes] = useState<Signer[]>([]);
  const [receiverMessage, setReceiverMessage] = useState("");

  const handleSelectAll = () => {
    const updatedCheckboxes = checkboxes.map((checkbox) => ({
      ...checkbox,
      isChecked: !selectAll,
    }));
    setSelectAll(!selectAll);
    setCheckboxes(updatedCheckboxes);
  };

  const handleCheckboxChange = (index: number) => {
    const updatedCheckboxes = [...checkboxes];
    updatedCheckboxes[index].isChecked = !updatedCheckboxes[index].isChecked;
    setCheckboxes(updatedCheckboxes);
    setSelectAll(updatedCheckboxes.every((checkbox) => checkbox.isChecked));
  };

  // Initialize signer to keep track of isChecked state for each user
  useEffect(() => {
    if (serverSigners) {
      if (checkboxes.length) {
        // If it has been initialized before, and we get here again by cache invalidation, maintain checked state
        // while showing new server data e.g. emailedAt
        setCheckboxes(
          serverSigners.map((s) => {
            return {
              ...s,
              isChecked: !!checkboxes.find(
                (c) => c.signerEmail === s.signerEmail
              )?.isChecked,
            };
          })
        );
      } else {
        // Initialize
        setCheckboxes(
          serverSigners.map((s) => {
            return {
              ...s,
              isChecked: selectAll,
            };
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serverSigners]);

  const [emailThanks, { loading }] = useEmailThanksToCardSignersMutation();
  const toast = useCustomToast();

  // TODO: If one item is unchecked, then uncheck "Select All"

  if (isLoadingGetCardSigners) {
    return <Loader />;
  }

  if (isEmpty(serverSigners)) {
    return (
      <PageContainer>
        <Text align={"center"}>There are no card signers.</Text>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <Heading variant="h2">Send Thanks</Heading>
      <SimpleGrid columns={[1, null, 2]} spacing="md" py="lg">
        <VStack alignItems="stretch" spacing="md">
          <Heading fontSize="md">Contributors to send to</Heading>
          <Text>
            Please select the contributors you'd like to send an email to (we
            can only send to contributors who provided their email address).
          </Text>
          <VStack alignItems="stretch">
            <Checkbox isChecked={selectAll} onChange={handleSelectAll}>
              Select All
            </Checkbox>
            {checkboxes?.map((s, i) => {
              const hasBeenEmailed = !!s.emailedAt;
              return (
                <Flex
                  // justifyContent={"space-between"}
                  align="center"
                >
                  <Checkbox
                    isChecked={s.isChecked}
                    onChange={() => handleCheckboxChange(i)}
                    flex="1"
                  >
                    {s.signerName}
                  </Checkbox>
                  <HStack flex="1">
                    <Circle
                      bg={hasBeenEmailed ? "green.500" : "gray.300"}
                      size="10px"
                    />
                    <Text fontSize="sm" color="gray.500">
                      {hasBeenEmailed ? "Sent" : "Not sent"}
                    </Text>
                  </HStack>
                </Flex>
              );
            })}
          </VStack>
        </VStack>

        {/* EmailPreview */}
        <VStack align="stretch">
          <Heading fontSize="md">Write your message</Heading>
          <Text fontSize={"sm"} color="gray.500">
            Live email preview
          </Text>
          <VStack
            align={"stretch"}
            border="1px solid"
            borderColor={"gray.200"}
            rounded="md"
            w="full"
            spacing="md"
            p="lg"
          >
            <Center>
              <CardolyLogoName />
            </Center>
            <Text>
              {receiverName} has just received a Cardoly featuring your
              contribution. Here's a 'thank you' message directly from{" "}
              {receiverName}:
            </Text>
            <Textarea
              onChange={(e) => {
                setReceiverMessage(e.target.value);
              }}
              value={receiverMessage}
              sx={{
                fontStyle: "Italic",
                lineHeight: "24px",
              }}
              rows={5}
              placeholder={`Write your message here e.g. \nThanks so much everyone! I really loved my Cardoly! It helped make it a special day!`}
            />
            <Text>You can view the Cardoly {receiverName} received</Text>
            <Center>
              <Button
                variant="outline"
                as={Link}
                href={inviteParticipantUrl}
                target="_blank"
              >
                View Cardoly
              </Button>
            </Center>
            <Text>Thanks,</Text>
            <div>
              <Text>The Cardoly Team</Text>
              <Link
                color="blue.500"
                target="_blank"
                href="https://www.cardoly.com/"
              >
                https://www.cardoly.com/
              </Link>
            </div>
          </VStack>
          <div>
            <Button
              onClick={() => {
                const selectedEmails = checkboxes
                  ?.filter((s) => s.isChecked)
                  .map((s) => s.signerEmail);

                if (!selectAll && isEmpty(selectedEmails)) {
                  toast({
                    id: "form-submit-error",
                    title: "Error",
                    status: "error",
                    description: "You must select at least one email",
                  });
                  return;
                }

                if (!receiverMessage) {
                  toast({
                    id: "form-submit-error-email",
                    title: "Error",
                    status: "error",
                    description: "Please enter an email message",
                  });
                  return;
                }

                if (cardID) {
                  emailThanks({
                    variables: {
                      input: {
                        cardId: cardID,
                        receiverMessage,
                        emailAll: selectAll,
                        signerEmails: selectAll ? undefined : selectedEmails,
                      },
                    },
                    update: (cache) => {
                      cache.evict({
                        fieldName: "getCardSigners", // To reflect the latest pagination info from BE
                      });
                      cache.gc();
                    },
                    onCompleted(res) {
                      const errorEmails = res.emailThanksToCardSigners.filter(
                        (r) => !!r.error
                      );
                      if (isEmpty(errorEmails)) {
                        toast({
                          id: "send-email-success",
                          title: "Success",
                          status: "success",
                          description: "Emails sent",
                        });
                      } else {
                        errorEmails.forEach((r) => {
                          toast({
                            id: `email-error-${r.email}`,
                            title: "Error",
                            status: "error",
                            description: `Failed to send to ${r.email}. ${r.error}`,
                            duration: null,
                          });
                        });
                      }
                    },
                    onError(res) {
                      toast({
                        id: "send-email-error",
                        title: "Error",
                        status: "error",
                        description: `Something went wrong.\n${res.message}`,
                        duration: null,
                      });
                    },
                  });
                }
              }}
              isLoading={loading}
              loadingText="Sending email..."
            >
              Send email now
            </Button>
          </div>
        </VStack>
      </SimpleGrid>
    </PageContainer>
  );
}
