import { Box, Center, IconButton, Image, Tooltip } from "@chakra-ui/react";
import { FiTrash2 as DeleteIcon } from "react-icons/fi";
import {
  useAppSelector,
  useAppDispatch,
  entrySliceActions,
  selectors,
} from "../../state-management";

function DeleteButton({ onAddClick }: { onAddClick(): void }) {
  return (
    <Center p="2">
      <Tooltip label="Remove image" placement="top">
        <IconButton
          onClick={onAddClick}
          aria-label="Delete image"
          icon={<DeleteIcon />}
          colorScheme="red"
          variant="outline"
          isRound
        />
      </Tooltip>
    </Center>
  );
}

export function DeletablePhoto() {
  const dispatch = useAppDispatch();
  const photo = useAppSelector(selectors.entrySelectors.getPhoto);
  const url = photo?.urls?.md;
  function handleRemovePhoto() {
    dispatch(entrySliceActions.removePhoto());
  }
  // const url =
  // 'https://images.unsplash.com/photo-1588943211346-0908a1fb0b01?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNTk5ODl8MHwxfHNlYXJjaHwxfHxkb2d8ZW58MHwxfHxncmVlbnwxNjMxNTA1MDI3&ixlib=rb-1.2.1&q=80&w=400';

  return (
    <>
      {url && (
        <Box position="relative">
          <DeleteButton onAddClick={handleRemovePhoto} />
          <Image src={url} width="full" alt="cardoly image" />
        </Box>
      )}
    </>
  );
}
