import { Box, Button, Portal, Tooltip } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { FiPlus as AddIcon } from "react-icons/fi";

import { useCard, useUpgradeCard } from "../../../../hooks";

export function AddEntryOrUpgradeCardButton() {
  const { hasReachedMaxEntries, handleAddEntry, card } = useCard();
  const { handleUpgradeCard, handleCloseUpgradeCardModal } = useUpgradeCard();

  useEffect(() => {
    return () => {
      handleCloseUpgradeCardModal();
    };
  }, []);

  const addEntryButton = (
    <Button
      size="lg"
      variant="solid"
      isDisabled={card?.isLocked}
      colorScheme="green"
      leftIcon={<AddIcon />}
      rounded="full"
      onClick={hasReachedMaxEntries ? handleUpgradeCard : handleAddEntry}
    >
      Add entry
    </Button>
  );

  return (
    <Box
    // position="fixed"
    // right={"lg"}
    // bottom={"lg"}
    >
      {card?.isLocked ? (
        <Tooltip
          label="The Cardoly has been locked. To add to it, please ask the creator to unlock it."
          placement="top"
        >
          {addEntryButton}
        </Tooltip>
      ) : (
        addEntryButton
      )}
    </Box>
    // <Portal>
    //   <Box position="fixed" right={"lg"} bottom={"lg"}>
    //     <Button
    //       size="lg"
    //       variant="solid"
    //       // colorScheme="green"
    //       colorScheme="green"
    //       leftIcon={<AddIcon />}
    //       rounded="full"
    //       onClick={hasReachedMaxEntries ? handleUpgradeCard : handleAddEntry}
    //     >
    //       Add entry
    //     </Button>
    //   </Box>
    // </Portal>
  );
}
