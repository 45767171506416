import {
  Box,
  VStack,
  FormControl,
  Input,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/react";
import { cloneDeep } from "lodash";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Loader } from "../../components";
import { SaveButton } from "../../feature";
import { GetOrCreateUserQuery } from "../../graphql/generated/schema";
import { useAuth, useToast, useUser } from "../../hooks";

type Inputs = NonNullable<GetOrCreateUserQuery["getOrCreateUser"]>;

export function UserInfo() {
  const {
    updateUserAttributes,
    userUpdateState: {
      isLoadingUpdateUser,
      isSuccessUpdateUser,
      isErrorUpdateUser,
    },
  } = useAuth();
  const { isLoadingUser, user } = useUser();
  // const email = cognitoUser.email || user?.email;
  const email = user?.email;

  // Setup toasts
  useToast({
    id: "updateUser-fail",
    title: "Update failed",
    description: "Something went wrong :(",
    show: isErrorUpdateUser,
    status: "error",
  });

  useToast({
    id: "updateUser-success",
    title: "Success",
    description: "Updated user",
    show: isSuccessUpdateUser,
    status: "success",
  });

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Inputs>();

  function onSubmit(updateUserInput: Inputs) {
    if (user?.cognitoId && updateUserInput) {
      const input = cloneDeep(updateUserInput);
      delete input.__typename;
      delete input.id;

      updateUserAttributes(input);
    }
  }

  useEffect(() => {
    /**
     Since defaultValues are async, we can't just set them in useForm(...), because after the initial render
     of undefined values, when the server responds, they defaultValues don't update since they're cached.
     So we force set them here once the API responds.
     */
    const editUser = { ...user };
    delete editUser.cognitoId;
    delete editUser.id;

    if (email) {
      reset({ ...editUser, email });
    }
  }, [reset, user, email]);

  if (isLoadingUser) {
    return <Loader />;
  }

  return (
    <Box maxW="sm" mx="auto">
      <VStack spacing="md" alignItems={"stretch"}>
        <FormControl isInvalid={!!errors?.name}>
          <FormLabel>Full name</FormLabel>
          <Input
            type="text"
            placeholder="Full name"
            {...register("name", { required: true })}
          />
          <FormErrorMessage>This field is required</FormErrorMessage>
        </FormControl>
        {/* <FormControl isInvalid={!!errors?.firstName}>
          <FormLabel>First Name</FormLabel>
          <Input
            type="text"
            placeholder="First Name"
            {...register("firstName", { required: true })}
          />
          <FormErrorMessage>This field is required</FormErrorMessage>
        </FormControl>

        <FormControl isInvalid={!!errors?.lastName}>
          <FormLabel>Last Name</FormLabel>
          <Input
            type="text"
            placeholder="Last Name"
            {...register("lastName", { required: true })}
          />
          <FormErrorMessage>This field is required</FormErrorMessage>
        </FormControl> */}

        <FormControl isInvalid={!!errors?.email}>
          <FormLabel>Email</FormLabel>
          <Input
            disabled
            type="text"
            placeholder="Email"
            {...register("email", { required: true })}
          />
          <FormErrorMessage>This field is required</FormErrorMessage>
        </FormControl>

        <SaveButton
          isLoading={isLoadingUpdateUser}
          onClick={() => {
            handleSubmit(onSubmit)();
          }}
        />
      </VStack>
    </Box>
  );
}
