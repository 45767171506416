import React, { useState } from "react";
import { GifsResult, GiphyFetch } from "@giphy/js-fetch-api";
import {
  MAX_PHOTOS_TO_FETCH,
  PHOTOS_PER_PAGE,
  USE_FIXTURES,
} from "../constants";
import { giphyFixture } from "../fixtures";

// use @giphy/js-fetch-api to fetch gifs, instantiate with your api key
const GIPHY_API_KEY = "sXpGFDGZs0Dv1mmNFvYaGUvYwKX0PWIh"; // <--- 🛑 CHANGE 🛑
// const GIPHY_API_KEY = "BydZUO8p01ZsUkLGXieQPcJT18Qv2SDP"; // BETA KEY
const gf = new GiphyFetch(GIPHY_API_KEY);

interface UseGiphyProps {
  searchText?: string;
  perPage?: number;
}
interface FetchGiphy {
  searchText?: string;
  offset?: number;
  limit?: number;
}

// const LIMIT = 25;
// const LIMIT = 2;

// TRENDING
// // configure your fetch: fetch 10 gifs at a time as the user scrolls (offset is handled by the grid)
// const fetchGifs = (offset: number) => gf.trending({ offset, limit: 10 });

// SEARCH
const fetchGifs = ({
  searchText = "dogs",
  limit = PHOTOS_PER_PAGE,
  offset,
}: FetchGiphy) =>
  gf.search(searchText, {
    sort: "relevant",
    lang: "es",
    limit,
    offset,
    type: "gifs",
    // type: 'stickers', // TODO: Do we want different media types? https://developers.giphy.com/docs/sdk/#content-types
  });
//   gf.trending({ offset, limit: 10 });
// // Search
// const { data: gifs } = await gf.search('dogs', { sort: 'relevant', lang: 'es', limit: 10, type: 'stickers' })

const DEFAULT_OFFSET = 0;
export function useGiphy({
  searchText,
  perPage = PHOTOS_PER_PAGE,
}: UseGiphyProps) {
  const [isLoading, setLoading] = useState<boolean>(false);
  const [isFetching, setFetching] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [error, setError] = useState<unknown>();
  const [data, setData] = useState<GifsResult["data"]>([]);
  const [offset, setOffset] = useState<number>(DEFAULT_OFFSET);
  const isInitialSearch = !!data.length === false;

  // Reset pagination data, so we don't mix results from different searches
  React.useEffect(() => {
    setData([]);
    setOffset(DEFAULT_OFFSET);
    setHasMore(false);
    // setLoading(true);
  }, [searchText]);

  function fetchMoreGiphy() {
    setOffset(offset + perPage);
  }

  // Paginate search
  React.useEffect(() => {
    (async function () {
      if (USE_FIXTURES) {
        return setData(giphyFixture);
      }

      if (searchText) {
        try {
          if (isInitialSearch) {
            setLoading(true);
          } else {
            setFetching(true);
          }

          const res = await fetchGifs({ searchText, offset });
          const total = res.pagination.total_count;
          const newOffset = offset + perPage;

          const newHasMore =
            newOffset < MAX_PHOTOS_TO_FETCH && newOffset < total;
          setHasMore(newHasMore);

          setData((state) => [...state, ...res?.data]);
          setLoading(false);
          setFetching(false);
          setError(null);
        } catch (e) {
          setError(e);
          setLoading(false);
        }
      }
    })();
  }, [offset, searchText]);

  return { data, isLoading, isFetching, error, fetchMoreGiphy, hasMore };
}
