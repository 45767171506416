import {
  useDisclosure,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  Accordion,
  AccordionItem,
  VStack,
  HStack,
  Tooltip,
  Text,
  Icon,
  IconProps,
  TextProps,
  DrawerProps,
  Box,
  Alert,
  AlertIcon,
  Flex,
  FlexProps,
} from "@chakra-ui/react";
import {
  FiSettings as SettingsIcon,
  FiSend,
  FiShare2 as ShareIcon,
  FiHeart as HeartIcon,
} from "react-icons/fi";
import { BiMoviePlay as MovieIcon } from "react-icons/bi";
import { MdReplay as ReplayIcon } from "react-icons/md";
import { SelectCardBackground } from "../../../../../feature";
import { AccordionItemContent, AccordionItemHeader } from "./styles";
import { DeliverySettings } from "../../DeliverySettings";
import { useCard, useModal, useRoutes } from "../../../../../hooks";
import { EditCardDetails } from "./EditCardDetails";
import { MdOutlinePalette } from "react-icons/md";
import {
  TfiPencil,
  TfiPencilAlt as ChangeBackgroundIcon,
} from "react-icons/tfi";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { UpgradeCardoly } from "./UpgradeCardoly";
import { useOpeningAnimation } from "../../../../../hooks/useOpeningAnimation";
import { PopoverButton } from "./PopoverButton";
import { ConfettiToggle } from "./ConfettiToggle";
import { LockToggle } from "./LockToggle";
import { isNumber } from "lodash";
import { IconButtonWithTooltip } from "../../../../../components";
import useEditCardSettingDrawer from "../../../../../hooks/useEditCardSettingDrawer";

const FONT_BOX_SIZE = 6;
const ACCORDION_INDEXES = {
  NONE: null,
  DELIVER: 0,
  EDIT_DETAILS: 1,
} as const;
type AccordionIndexesValueType =
  (typeof ACCORDION_INDEXES)[keyof typeof ACCORDION_INDEXES];

export function CardSettings() {
  const {
    isDemoRecipientMode,
    isRealRecipientMode,
    toCardPageEditorMode,
    toThanksPage,
    toCardPageRecipientMode,
  } = useRoutes();
  const { isUserCardOwner, cardID } = useCard();
  const { handleStartOpeningSequence } = useOpeningAnimation();
  const { showModal } = useModal();

  const {
    isOpen,
    cardSettingIndex,
    handleCloseCardSettingDrawer,
    handleOpenCardSettingsDrawer,
  } = useEditCardSettingDrawer();

  function handleCopyCardolyLink(isViewOnly: boolean = false) {
    showModal({
      modalType: "ShareCardolyModal",
      modalProps: { cardID, isViewOnly },
    });
  }

  return (
    <>
      <Box w="full">
        {isDemoRecipientMode ? (
          <>
            <Box mb="md">
              <Alert status="info" rounded={"md"} justifyContent="center">
                <AlertIcon />
                <Flex
                  flexDir={["column", null, "row"]}
                  gap={"sm"}
                  alignItems={"center"}
                >
                  <Text>
                    This is a demo view showing how the recipient of the Cardoly
                    would view it after it is delivered.
                  </Text>
                  <Box w={["full", null, "fit-content"]}>
                    <Button
                      // size="sm"
                      variant="outline"
                      leftIcon={<ChangeBackgroundIcon />}
                      onClick={toCardPageEditorMode}
                      w={["full", null, "fit-content"]}
                    >
                      View as editor
                    </Button>
                  </Box>
                </Flex>
              </Alert>
            </Box>
            <FlexContainer>
              <PopoverButton
                buttonProps={{
                  leftIcon: <ShareIcon />,
                  children: "Share",
                  w: ["full", null, "fit-content"],
                }}
                popoverText="Allows the recipient share their Cardoly with a link"
              />
              <PopoverButton
                buttonProps={{
                  leftIcon: <HeartIcon />,
                  children: "Say Thanks",
                  w: ["full", null, "fit-content"],
                }}
                popoverText="Allows the recipient to respond to everyone who left a message and included their email"
              />
              <IconButtonWithTooltip
                onClick={handleStartOpeningSequence}
                icon={ReplayIcon}
                label="Replay opening animation"
              />
            </FlexContainer>
          </>
        ) : isRealRecipientMode ? (
          <FlexContainer>
            <Button
              variant={"outline"}
              leftIcon={<ShareIcon />}
              onClick={() => handleCopyCardolyLink(true)}
            >
              Share
            </Button>
            <Tooltip label="Respond to all" placement="top">
              <Button
                onClick={toThanksPage}
                leftIcon={<HeartIcon />}
                variant="outline"
                colorScheme="brand"
              >
                Say Thanks
              </Button>
            </Tooltip>
            <IconButtonWithTooltip
              onClick={handleStartOpeningSequence}
              icon={ReplayIcon}
              label="Replay opening animation"
            />
          </FlexContainer>
        ) : (
          <FlexContainer>
            {isUserCardOwner && (
              <>
                <Button
                  // rounded="full"
                  onClick={handleOpenCardSettingsDrawer({
                    index: ACCORDION_INDEXES.DELIVER,
                  })}
                  leftIcon={<FiSend />}
                  // variant="outline"
                  variant="solid"
                  colorScheme="brand"
                >
                  Deliver
                </Button>
                <Button
                  variant={"outline"}
                  leftIcon={<AiOutlineUsergroupAdd />}
                  onClick={() => handleCopyCardolyLink()}
                >
                  Invite
                </Button>
                <IconButtonWithTooltip
                  onClick={handleOpenCardSettingsDrawer({
                    index: ACCORDION_INDEXES.NONE,
                  })}
                  icon={SettingsIcon}
                  label="Settings"
                />
                {/* <Button
                  // onClick={onToggle}
                  onClick={handleOpenCardSettingsDrawer({
                    index: ACCORDION_INDEXES.NONE,
                  })}
                  leftIcon={<SettingsIcon />}
                  variant="outline"
                  colorScheme="brand"
                >
                  Settings
                </Button> */}
                <EditCardBackgroundIconButtonAndDrawer />
              </>
            )}
            {/* <Button
              onClick={toCardPageRecipientMode}
              leftIcon={<MovieIcon />}
              variant="outline"
            >
              View as recipient (demo)
            </Button> */}
            <IconButtonWithTooltip
              onClick={toCardPageRecipientMode}
              icon={MovieIcon}
              label="View as recipient (demo)"
            />
          </FlexContainer>
        )}
      </Box>

      <Drawer
        onClose={handleCloseCardSettingDrawer}
        isOpen={isOpen}
        size={"sm"}
        placement="left"
      >
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Cardoly Settings</DrawerHeader>
          <DrawerBody>
            <Sections currentIndex={cardSettingIndex} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
}

function FlexContainer(props: FlexProps) {
  return (
    <Flex
      {...props}
      flexDir={["column", null, "row"]}
      w="full"
      gap="sm"
      justifyContent={"center"}
    />
  );
}

function Sections({
  currentIndex = ACCORDION_INDEXES.DELIVER,
}: {
  currentIndex: AccordionIndexesValueType;
}) {
  return (
    <VStack alignItems={"stretch"}>
      <UpgradeCardoly />

      {/* <DeliverySettings /> */}

      <EditCardBackgroundButtonAndDrawer />

      <Accordion
        //  allowMultiple
        allowToggle
        defaultIndex={isNumber(currentIndex) ? [currentIndex] : undefined}
      >
        <AccordionItem>
          <AccordionHeader as={FiSend}>Deliver</AccordionHeader>
          <AccordionItemContent>
            <DeliverySettings />
          </AccordionItemContent>
        </AccordionItem>

        {/* <AccordionItem>
          <AccordionHeader as={AiOutlineUsergroupAdd}>
            Invite Participants
          </AccordionHeader>
          <AccordionItemContent>
            <CopyCardToClipBoard />
          </AccordionItemContent>
        </AccordionItem> */}

        <AccordionItem>
          <AccordionHeader as={TfiPencil}>Edit Details</AccordionHeader>
          <AccordionItemContent>
            <EditCardDetails />
          </AccordionItemContent>
        </AccordionItem>
      </Accordion>

      <ConfettiToggle />
      <LockToggle />
    </VStack>
  );
}

function AccordionHeader(props: IconProps & TextProps) {
  return (
    <AccordionItemHeader>
      <HStack>
        <Icon as={props.as} boxSize={FONT_BOX_SIZE} color="brand.600" />
        <Text>{props.children}</Text>
      </HStack>
    </AccordionItemHeader>
  );
}

export function SelectCardBackgroundDrawer({
  isOpen,
  onClose,
}: Pick<DrawerProps, "isOpen" | "onClose">) {
  return (
    <Drawer onClose={onClose} isOpen={isOpen} size={"sm"} placement="left">
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Select Background</DrawerHeader>
        <DrawerBody>
          <SelectCardBackground />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}

function EditCardBackgroundButtonAndDrawer() {
  const {
    isOpen: isCardOpen,
    // onToggle: onToggleCard,
    onClose: onCloseCard,
    onOpen: onOpenCard,
  } = useDisclosure({
    defaultIsOpen: false,
  });

  return (
    <>
      {/* Drawer trigger */}
      <Button
        onClick={onOpenCard}
        variant="link"
        leftIcon={
          <Icon
            as={MdOutlinePalette}
            boxSize={FONT_BOX_SIZE}
            color="brand.600"
          />
        }
        justifyContent="left"
        pl="md"
        fontSize={"sm"}
        textDecor="none"
      >
        <Text textTransform={"uppercase"}>Edit Background</Text>
      </Button>

      {/* Drawer */}
      <SelectCardBackgroundDrawer isOpen={isCardOpen} onClose={onCloseCard} />
    </>
  );
}

function EditCardBackgroundIconButtonAndDrawer() {
  const {
    isOpen: isCardOpen,
    onClose: onCloseCard,
    onOpen: onOpenCard,
  } = useDisclosure({
    defaultIsOpen: false,
  });

  return (
    <>
      {/* Drawer trigger */}
      <IconButtonWithTooltip
        icon={MdOutlinePalette}
        label="Change background"
        onClick={onOpenCard}
      />

      {/* Drawer */}
      <SelectCardBackgroundDrawer isOpen={isCardOpen} onClose={onCloseCard} />
    </>
  );
}
