import {
  Image,
  // Text,
} from "@chakra-ui/react";
import { WEBSITE_HOME } from "../../constants";
// import { useRoutes } from "../../hooks";
// import "./CardolyLogoName.css"; // Import Action Jackson font

export function CardolyLogoName({ onClickNavigateToHome = false }) {
  // const { toHome } = useRoutes();
  const renderLogoName = (
    <Image
      w={{ base: "100px", md: "136px" }}
      _hover={{ cursor: onClickNavigateToHome ? "pointer" : "default" }}
      src="/cardoly-name-logo-sm.png"
      alt="Cardoly Logo"
      // onClick={onClickNavigateToHome ? toHome : undefined}
    />
  );

  return onClickNavigateToHome ? (
    <a href={WEBSITE_HOME} className="cardoly-logo-name">
      {renderLogoName}
    </a>
  ) : (
    renderLogoName
  );

  // return (
  //   <Text
  //     onClick={toHome}
  //     lineHeight="0"
  //     _hover={{ cursor: "pointer" }}
  //     fontFamily="Luckiest Guy"
  //     // fontFamily="ActionJ"
  //     color="purple.700" // #553C9A
  //     fontSize="2xl"
  //   >
  //     Cardoly
  //   </Text>
  // );
}
