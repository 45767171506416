import { Button } from "@chakra-ui/react";
import React from "react";
import { useRoutes } from "../../../hooks";

export function CreateCardolyButton({ productId }: { productId?: string }) {
  const nav = useRoutes();

  return (
    <Button
      colorScheme="brand"
      w="full"
      onClick={() => nav.toCreateCardPage({ productId })}
      //   variant="outline"
      variant="solid"
    >
      {/* {isPricingPage ? "Create Cardoly" : "Start for free"} */}
      {/* Start for free */}
      Create Cardoly
    </Button>
  );
}
