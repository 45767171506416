import {
  useGetOrCreateUserQuery,
  useUpdateUserMutation,
} from "../graphql/generated/schema";
import { useAuth } from "./useAuth";

export function useUser() {
  const { isAuthenticated } = useAuth();
  // TODO: see if there's a hook where after updating user cognito will auto-update our user db.

  const {
    data,
    loading: isLoadingUser,
    error,
  } = useGetOrCreateUserQuery({ skip: !isAuthenticated });
  const user = data?.getOrCreateUser;

  const [
    updateUser,
    {
      loading: isPendingUpdateUser,
      data: updateUserData,
      error: errorUpdateUser,
    },
  ] = useUpdateUserMutation();
  const isSuccessUpdateUser = !!updateUserData;
  const isErrorUpdateUser = !!errorUpdateUser;

  return {
    user,
    // cognitoUser,
    name: user?.name,
    error,
    isLoadingUser,
    // Mutations
    updateUser,
    isPendingUpdateUser,
    isSuccessUpdateUser,
    isErrorUpdateUser,
  };
}
