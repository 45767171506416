export const giphyFixture: any = [
  {
    type: 'gif',
    id: 'l0NwGpoOVLTAyUJSo',
    url: 'https://giphy.com/gifs/batmanvsuperman-batman-v-superman-bat-signal-l0NwGpoOVLTAyUJSo',
    slug: 'batmanvsuperman-batman-v-superman-bat-signal-l0NwGpoOVLTAyUJSo',
    bitly_gif_url: 'http://gph.is/1SLNCzh',
    bitly_url: 'http://gph.is/1SLNCzh',
    embed_url: 'https://giphy.com/embed/l0NwGpoOVLTAyUJSo',
    username: 'batmanvsuperman',
    source: '',
    title: 'Ben Affleck Movie GIF by Batman v Superman: Dawn of Justice',
    rating: 'g',
    content_url: '',
    source_tld: '',
    source_post_url: '',
    is_sticker: 0,
    import_datetime: '2016-02-04 20:17:47',
    trending_datetime: '2016-03-07 19:01:26',
    images: {
      original: {
        height: '281',
        width: '500',
        size: '2080954',
        url: 'https://media3.giphy.com/media/l0NwGpoOVLTAyUJSo/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
        mp4_size: '2145835',
        mp4: 'https://media3.giphy.com/media/l0NwGpoOVLTAyUJSo/giphy.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.mp4&ct=g',
        webp_size: '1824424',
        webp: 'https://media3.giphy.com/media/l0NwGpoOVLTAyUJSo/giphy.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.webp&ct=g',
        frames: '68',
        hash: '86b0f772b35ad085268663a5b12720df',
      },
      downsized: {
        height: '224',
        width: '400',
        size: '1825950',
        url: 'https://media3.giphy.com/media/l0NwGpoOVLTAyUJSo/giphy-downsized.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-downsized.gif&ct=g',
      },
      downsized_large: {
        height: '281',
        width: '500',
        size: '2080954',
        url: 'https://media3.giphy.com/media/l0NwGpoOVLTAyUJSo/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_medium: {
        height: '281',
        width: '500',
        size: '2080954',
        url: 'https://media3.giphy.com/media/l0NwGpoOVLTAyUJSo/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_small: {
        height: '112',
        width: '200',
        mp4_size: '66637',
        mp4: 'https://media3.giphy.com/media/l0NwGpoOVLTAyUJSo/giphy-downsized-small.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-downsized-small.mp4&ct=g',
      },
      downsized_still: {
        height: '224',
        width: '400',
        size: '34864',
        url: 'https://media3.giphy.com/media/l0NwGpoOVLTAyUJSo/giphy-downsized_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-downsized_s.gif&ct=g',
      },
      fixed_height: {
        height: '200',
        width: '356',
        size: '1920602',
        url: 'https://media3.giphy.com/media/l0NwGpoOVLTAyUJSo/200.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.gif&ct=g',
        mp4_size: '787217',
        mp4: 'https://media3.giphy.com/media/l0NwGpoOVLTAyUJSo/200.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.mp4&ct=g',
        webp_size: '719566',
        webp: 'https://media3.giphy.com/media/l0NwGpoOVLTAyUJSo/200.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.webp&ct=g',
      },
      fixed_height_downsampled: {
        height: '200',
        width: '356',
        size: '179880',
        url: 'https://media3.giphy.com/media/l0NwGpoOVLTAyUJSo/200_d.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_d.gif&ct=g',
        webp_size: '117036',
        webp: 'https://media3.giphy.com/media/l0NwGpoOVLTAyUJSo/200_d.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_d.webp&ct=g',
      },
      fixed_height_small: {
        height: '100',
        width: '178',
        size: '439294',
        url: 'https://media3.giphy.com/media/l0NwGpoOVLTAyUJSo/100.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.gif&ct=g',
        mp4_size: '124339',
        mp4: 'https://media3.giphy.com/media/l0NwGpoOVLTAyUJSo/100.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.mp4&ct=g',
        webp_size: '142796',
        webp: 'https://media3.giphy.com/media/l0NwGpoOVLTAyUJSo/100.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.webp&ct=g',
      },
      fixed_height_small_still: {
        height: '100',
        width: '178',
        size: '8579',
        url: 'https://media3.giphy.com/media/l0NwGpoOVLTAyUJSo/100_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100_s.gif&ct=g',
      },
      fixed_height_still: {
        height: '200',
        width: '356',
        size: '28993',
        url: 'https://media3.giphy.com/media/l0NwGpoOVLTAyUJSo/200_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_s.gif&ct=g',
      },
      fixed_width: {
        height: '112',
        width: '200',
        size: '559748',
        url: 'https://media3.giphy.com/media/l0NwGpoOVLTAyUJSo/200w.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.gif&ct=g',
        mp4_size: '157003',
        mp4: 'https://media3.giphy.com/media/l0NwGpoOVLTAyUJSo/200w.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.mp4&ct=g',
        webp_size: '177496',
        webp: 'https://media3.giphy.com/media/l0NwGpoOVLTAyUJSo/200w.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.webp&ct=g',
      },
      fixed_width_downsampled: {
        height: '112',
        width: '200',
        size: '52533',
        url: 'https://media3.giphy.com/media/l0NwGpoOVLTAyUJSo/200w_d.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_d.gif&ct=g',
        webp_size: '34662',
        webp: 'https://media3.giphy.com/media/l0NwGpoOVLTAyUJSo/200w_d.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_d.webp&ct=g',
      },
      fixed_width_small: {
        height: '56',
        width: '100',
        size: '159606',
        url: 'https://media3.giphy.com/media/l0NwGpoOVLTAyUJSo/100w.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.gif&ct=g',
        mp4_size: '35040',
        mp4: 'https://media3.giphy.com/media/l0NwGpoOVLTAyUJSo/100w.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.mp4&ct=g',
        webp_size: '52808',
        webp: 'https://media3.giphy.com/media/l0NwGpoOVLTAyUJSo/100w.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.webp&ct=g',
      },
      fixed_width_small_still: {
        height: '56',
        width: '100',
        size: '3470',
        url: 'https://media3.giphy.com/media/l0NwGpoOVLTAyUJSo/100w_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w_s.gif&ct=g',
      },
      fixed_width_still: {
        height: '112',
        width: '200',
        size: '11166',
        url: 'https://media3.giphy.com/media/l0NwGpoOVLTAyUJSo/200w_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_s.gif&ct=g',
      },
      looping: {
        mp4_size: '8506761',
        mp4: 'https://media3.giphy.com/media/l0NwGpoOVLTAyUJSo/giphy-loop.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-loop.mp4&ct=g',
      },
      original_still: {
        height: '281',
        width: '500',
        size: '27430',
        url: 'https://media3.giphy.com/media/l0NwGpoOVLTAyUJSo/giphy_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy_s.gif&ct=g',
      },
      original_mp4: {
        height: '268',
        width: '480',
        mp4_size: '2145835',
        mp4: 'https://media3.giphy.com/media/l0NwGpoOVLTAyUJSo/giphy.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.mp4&ct=g',
      },
      preview: {
        height: '84',
        width: '150',
        mp4_size: '41531',
        mp4: 'https://media3.giphy.com/media/l0NwGpoOVLTAyUJSo/giphy-preview.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.mp4&ct=g',
      },
      preview_gif: {
        height: '57',
        width: '101',
        size: '48613',
        url: 'https://media3.giphy.com/media/l0NwGpoOVLTAyUJSo/giphy-preview.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.gif&ct=g',
      },
      preview_webp: {
        height: '64',
        width: '114',
        size: '15248',
        url: 'https://media3.giphy.com/media/l0NwGpoOVLTAyUJSo/giphy-preview.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.webp&ct=g',
      },
      '480w_still': {
        height: '270',
        width: '480',
        size: '2080954',
        url: 'https://media3.giphy.com/media/l0NwGpoOVLTAyUJSo/480w_s.jpg?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=480w_s.jpg&ct=g',
      },
    },
    user: {
      avatar_url:
        'https://media1.giphy.com/avatars/batmanvsuperman/kJKdDtmS1dJc.jpg',
      banner_image:
        'https://media1.giphy.com/headers/batmanvsuperman/hESH4qwNkoMg.jpg',
      banner_url:
        'https://media1.giphy.com/headers/batmanvsuperman/hESH4qwNkoMg.jpg',
      profile_url: 'https://giphy.com/batmanvsuperman/',
      username: 'batmanvsuperman',
      display_name: 'Batman v Superman: Dawn of Justice',
      description:
        "Zack Snyder's BATMAN V SUPERMAN: DAWN OF JUSTICE is in theaters March 25, 2016.",
      instagram_url: '',
      website_url: 'http://www.batmanvsuperman.com/',
      is_verified: true,
    },
    analytics_response_payload:
      'e=Z2lmX2lkPWwwTndHcG9PVkxUQXlVSlNvJmV2ZW50X3R5cGU9R0lGX1NFQVJDSCZjaWQ9OWYwZjY0MjV5NWoxNHRjNjEwcW8xNHBybGdwNHUxaHloMHBmdzY4ZDE5eWQ5M3MzJmN0PWc',
    analytics: {
      onload: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPWwwTndHcG9PVkxUQXlVSlNvJmV2ZW50X3R5cGU9R0lGX1NFQVJDSCZjaWQ9OWYwZjY0MjV5NWoxNHRjNjEwcW8xNHBybGdwNHUxaHloMHBmdzY4ZDE5eWQ5M3MzJmN0PWc&action_type=SEEN',
      },
      onclick: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPWwwTndHcG9PVkxUQXlVSlNvJmV2ZW50X3R5cGU9R0lGX1NFQVJDSCZjaWQ9OWYwZjY0MjV5NWoxNHRjNjEwcW8xNHBybGdwNHUxaHloMHBmdzY4ZDE5eWQ5M3MzJmN0PWc&action_type=CLICK',
      },
      onsent: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPWwwTndHcG9PVkxUQXlVSlNvJmV2ZW50X3R5cGU9R0lGX1NFQVJDSCZjaWQ9OWYwZjY0MjV5NWoxNHRjNjEwcW8xNHBybGdwNHUxaHloMHBmdzY4ZDE5eWQ5M3MzJmN0PWc&action_type=SENT',
      },
    },
  },
  {
    type: 'gif',
    id: '4eGUxJc4lplh6',
    url: 'https://giphy.com/gifs/hoppip-batman-art-4eGUxJc4lplh6',
    slug: 'hoppip-batman-art-4eGUxJc4lplh6',
    bitly_gif_url: 'http://gph.is/13RXyej',
    bitly_url: 'http://gph.is/13RXyej',
    embed_url: 'https://giphy.com/embed/4eGUxJc4lplh6',
    username: 'hoppip',
    source: 'http://hoppip.tumblr.com/post/15263746824',
    title: 'Animated Series Art GIF by hoppip',
    rating: 'g',
    content_url: '',
    source_tld: 'hoppip.tumblr.com',
    source_post_url: 'http://hoppip.tumblr.com/post/15263746824',
    is_sticker: 0,
    import_datetime: '2012-01-03 23:43:13',
    trending_datetime: '1970-01-01 00:00:00',
    images: {
      original: {
        height: '328',
        width: '500',
        size: '104329',
        url: 'https://media2.giphy.com/media/4eGUxJc4lplh6/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
        mp4_size: '22474',
        mp4: 'https://media2.giphy.com/media/4eGUxJc4lplh6/giphy.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.mp4&ct=g',
        webp_size: '29506',
        webp: 'https://media2.giphy.com/media/4eGUxJc4lplh6/giphy.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.webp&ct=g',
        frames: '61',
        hash: '1aeeb642eb44f06b35e0360235df8f10',
      },
      downsized: {
        height: '328',
        width: '500',
        size: '104329',
        url: 'https://media2.giphy.com/media/4eGUxJc4lplh6/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_large: {
        height: '328',
        width: '500',
        size: '104329',
        url: 'https://media2.giphy.com/media/4eGUxJc4lplh6/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_medium: {
        height: '328',
        width: '500',
        size: '104329',
        url: 'https://media2.giphy.com/media/4eGUxJc4lplh6/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_small: {
        height: '328',
        width: '500',
        mp4_size: '23647',
        mp4: 'https://media2.giphy.com/media/4eGUxJc4lplh6/giphy-downsized-small.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-downsized-small.mp4&ct=g',
      },
      downsized_still: {
        height: '328',
        width: '500',
        size: '104329',
        url: 'https://media2.giphy.com/media/4eGUxJc4lplh6/giphy_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy_s.gif&ct=g',
      },
      fixed_height: {
        height: '200',
        width: '305',
        size: '50508',
        url: 'https://media2.giphy.com/media/4eGUxJc4lplh6/200.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.gif&ct=g',
        mp4_size: '11863',
        mp4: 'https://media2.giphy.com/media/4eGUxJc4lplh6/200.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.mp4&ct=g',
        webp_size: '18522',
        webp: 'https://media2.giphy.com/media/4eGUxJc4lplh6/200.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.webp&ct=g',
      },
      fixed_height_downsampled: {
        height: '200',
        width: '305',
        size: '16652',
        url: 'https://media2.giphy.com/media/4eGUxJc4lplh6/200_d.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_d.gif&ct=g',
        webp_size: '11752',
        webp: 'https://media2.giphy.com/media/4eGUxJc4lplh6/200_d.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_d.webp&ct=g',
      },
      fixed_height_small: {
        height: '100',
        width: '153',
        size: '20727',
        url: 'https://media2.giphy.com/media/4eGUxJc4lplh6/100.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.gif&ct=g',
        mp4_size: '6679',
        mp4: 'https://media2.giphy.com/media/4eGUxJc4lplh6/100.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.mp4&ct=g',
        webp_size: '10486',
        webp: 'https://media2.giphy.com/media/4eGUxJc4lplh6/100.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.webp&ct=g',
      },
      fixed_height_small_still: {
        height: '100',
        width: '153',
        size: '3678',
        url: 'https://media2.giphy.com/media/4eGUxJc4lplh6/100_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100_s.gif&ct=g',
      },
      fixed_height_still: {
        height: '200',
        width: '305',
        size: '7778',
        url: 'https://media2.giphy.com/media/4eGUxJc4lplh6/200_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_s.gif&ct=g',
      },
      fixed_width: {
        height: '131',
        width: '200',
        size: '28645',
        url: 'https://media2.giphy.com/media/4eGUxJc4lplh6/200w.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.gif&ct=g',
        mp4_size: '8298',
        mp4: 'https://media2.giphy.com/media/4eGUxJc4lplh6/200w.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.mp4&ct=g',
        webp_size: '13164',
        webp: 'https://media2.giphy.com/media/4eGUxJc4lplh6/200w.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.webp&ct=g',
      },
      fixed_width_downsampled: {
        height: '131',
        width: '200',
        size: '9110',
        url: 'https://media2.giphy.com/media/4eGUxJc4lplh6/200w_d.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_d.gif&ct=g',
        webp_size: '7144',
        webp: 'https://media2.giphy.com/media/4eGUxJc4lplh6/200w_d.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_d.webp&ct=g',
      },
      fixed_width_small: {
        height: '66',
        width: '100',
        size: '12074',
        url: 'https://media2.giphy.com/media/4eGUxJc4lplh6/100w.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.gif&ct=g',
        mp4_size: '5068',
        mp4: 'https://media2.giphy.com/media/4eGUxJc4lplh6/100w.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.mp4&ct=g',
        webp_size: '7000',
        webp: 'https://media2.giphy.com/media/4eGUxJc4lplh6/100w.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.webp&ct=g',
      },
      fixed_width_small_still: {
        height: '66',
        width: '100',
        size: '2459',
        url: 'https://media2.giphy.com/media/4eGUxJc4lplh6/100w_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w_s.gif&ct=g',
      },
      fixed_width_still: {
        height: '131',
        width: '200',
        size: '4775',
        url: 'https://media2.giphy.com/media/4eGUxJc4lplh6/200w_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_s.gif&ct=g',
      },
      looping: {
        mp4_size: '133728',
        mp4: 'https://media2.giphy.com/media/4eGUxJc4lplh6/giphy-loop.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-loop.mp4&ct=g',
      },
      original_still: {
        height: '328',
        width: '500',
        size: '45311',
        url: 'https://media2.giphy.com/media/4eGUxJc4lplh6/giphy_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy_s.gif&ct=g',
      },
      original_mp4: {
        height: '314',
        width: '480',
        mp4_size: '22474',
        mp4: 'https://media2.giphy.com/media/4eGUxJc4lplh6/giphy.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.mp4&ct=g',
      },
      preview: {
        height: '328',
        width: '500',
        mp4_size: '23647',
        mp4: 'https://media2.giphy.com/media/4eGUxJc4lplh6/giphy-preview.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.mp4&ct=g',
      },
      preview_gif: {
        height: '328',
        width: '500',
        size: '36282',
        url: 'https://media2.giphy.com/media/4eGUxJc4lplh6/giphy-preview.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.gif&ct=g',
      },
      preview_webp: {
        height: '328',
        width: '500',
        size: '29506',
        url: 'https://media2.giphy.com/media/4eGUxJc4lplh6/giphy-preview.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.webp&ct=g',
      },
      '480w_still': {
        height: '315',
        width: '480',
        size: '104329',
        url: 'https://media2.giphy.com/media/4eGUxJc4lplh6/480w_s.jpg?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=480w_s.jpg&ct=g',
      },
    },
    user: {
      avatar_url: 'https://media0.giphy.com/avatars/hoppip.png',
      banner_image: '',
      banner_url: '',
      profile_url: 'https://giphy.com/hoppip/',
      username: 'hoppip',
      display_name: 'hoppip',
      description:
        'I think the magic of the GIF medium is actually the limitations it has: you have to try to express and idea through a small piece of graphic media that compresses pixels. One thing that is really unique about this medium is its capacity of looping infinitely which really opens a lot of possibilities on storytelling.',
      instagram_url: 'https://instagram.com/kittenrains',
      website_url: 'http://hoppip.tumblr.com',
      is_verified: true,
    },
    analytics_response_payload:
      'e=Z2lmX2lkPTRlR1V4SmM0bHBsaDYmZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw',
    analytics: {
      onload: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPTRlR1V4SmM0bHBsaDYmZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw&action_type=SEEN',
      },
      onclick: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPTRlR1V4SmM0bHBsaDYmZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw&action_type=CLICK',
      },
      onsent: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPTRlR1V4SmM0bHBsaDYmZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw&action_type=SENT',
      },
    },
  },
  {
    type: 'gif',
    id: '4UJyRK2TXNhgk',
    url: 'https://giphy.com/gifs/spoilers-august-4UJyRK2TXNhgk',
    slug: 'spoilers-august-4UJyRK2TXNhgk',
    bitly_gif_url: 'http://gph.is/1l3lJFZ',
    bitly_url: 'http://gph.is/1l3lJFZ',
    embed_url: 'https://giphy.com/embed/4UJyRK2TXNhgk',
    username: '',
    source: 'http://uglycouchcast.blogspot.com/2015_08_01_archive.html',
    title: 'Batman Dancing GIF',
    rating: 'pg',
    content_url: '',
    source_tld: 'uglycouchcast.blogspot.com',
    source_post_url:
      'http://uglycouchcast.blogspot.com/2015_08_01_archive.html',
    is_sticker: 0,
    import_datetime: '2015-11-17 18:54:24',
    trending_datetime: '2016-04-03 18:30:01',
    images: {
      original: {
        height: '240',
        width: '320',
        size: '2183945',
        url: 'https://media3.giphy.com/media/4UJyRK2TXNhgk/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
        mp4_size: '733379',
        mp4: 'https://media3.giphy.com/media/4UJyRK2TXNhgk/giphy.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.mp4&ct=g',
        webp_size: '527204',
        webp: 'https://media3.giphy.com/media/4UJyRK2TXNhgk/giphy.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.webp&ct=g',
        frames: '62',
        hash: 'e0f7ddc0a2ca7668156f869133e8e50e',
      },
      downsized: {
        height: '240',
        width: '320',
        size: '1341180',
        url: 'https://media3.giphy.com/media/4UJyRK2TXNhgk/giphy-downsized.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-downsized.gif&ct=g',
      },
      downsized_large: {
        height: '240',
        width: '320',
        size: '2183945',
        url: 'https://media3.giphy.com/media/4UJyRK2TXNhgk/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_medium: {
        height: '240',
        width: '320',
        size: '2183945',
        url: 'https://media3.giphy.com/media/4UJyRK2TXNhgk/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_small: {
        height: '162',
        width: '216',
        mp4_size: '70647',
        mp4: 'https://media3.giphy.com/media/4UJyRK2TXNhgk/giphy-downsized-small.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-downsized-small.mp4&ct=g',
      },
      downsized_still: {
        height: '240',
        width: '320',
        size: '26097',
        url: 'https://media3.giphy.com/media/4UJyRK2TXNhgk/giphy-downsized_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-downsized_s.gif&ct=g',
      },
      fixed_height: {
        height: '200',
        width: '267',
        size: '1314208',
        url: 'https://media3.giphy.com/media/4UJyRK2TXNhgk/200.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.gif&ct=g',
        mp4_size: '189506',
        mp4: 'https://media3.giphy.com/media/4UJyRK2TXNhgk/200.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.mp4&ct=g',
        webp_size: '353288',
        webp: 'https://media3.giphy.com/media/4UJyRK2TXNhgk/200.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.webp&ct=g',
      },
      fixed_height_downsampled: {
        height: '200',
        width: '267',
        size: '153556',
        url: 'https://media3.giphy.com/media/4UJyRK2TXNhgk/200_d.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_d.gif&ct=g',
        webp_size: '81464',
        webp: 'https://media3.giphy.com/media/4UJyRK2TXNhgk/200_d.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_d.webp&ct=g',
      },
      fixed_height_small: {
        height: '100',
        width: '134',
        size: '392375',
        url: 'https://media3.giphy.com/media/4UJyRK2TXNhgk/100.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.gif&ct=g',
        mp4_size: '64405',
        mp4: 'https://media3.giphy.com/media/4UJyRK2TXNhgk/100.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.mp4&ct=g',
        webp_size: '127664',
        webp: 'https://media3.giphy.com/media/4UJyRK2TXNhgk/100.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.webp&ct=g',
      },
      fixed_height_small_still: {
        height: '100',
        width: '134',
        size: '8718',
        url: 'https://media3.giphy.com/media/4UJyRK2TXNhgk/100_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100_s.gif&ct=g',
      },
      fixed_height_still: {
        height: '200',
        width: '267',
        size: '25358',
        url: 'https://media3.giphy.com/media/4UJyRK2TXNhgk/200_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_s.gif&ct=g',
      },
      fixed_width: {
        height: '150',
        width: '200',
        size: '767138',
        url: 'https://media3.giphy.com/media/4UJyRK2TXNhgk/200w.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.gif&ct=g',
        mp4_size: '121505',
        mp4: 'https://media3.giphy.com/media/4UJyRK2TXNhgk/200w.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.mp4&ct=g',
        webp_size: '229478',
        webp: 'https://media3.giphy.com/media/4UJyRK2TXNhgk/200w.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.webp&ct=g',
      },
      fixed_width_downsampled: {
        height: '150',
        width: '200',
        size: '87767',
        url: 'https://media3.giphy.com/media/4UJyRK2TXNhgk/200w_d.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_d.gif&ct=g',
        webp_size: '50572',
        webp: 'https://media3.giphy.com/media/4UJyRK2TXNhgk/200w_d.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_d.webp&ct=g',
      },
      fixed_width_small: {
        height: '75',
        width: '100',
        size: '243120',
        url: 'https://media3.giphy.com/media/4UJyRK2TXNhgk/100w.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.gif&ct=g',
        mp4_size: '41650',
        mp4: 'https://media3.giphy.com/media/4UJyRK2TXNhgk/100w.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.mp4&ct=g',
        webp_size: '80792',
        webp: 'https://media3.giphy.com/media/4UJyRK2TXNhgk/100w.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.webp&ct=g',
      },
      fixed_width_small_still: {
        height: '75',
        width: '100',
        size: '5039',
        url: 'https://media3.giphy.com/media/4UJyRK2TXNhgk/100w_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w_s.gif&ct=g',
      },
      fixed_width_still: {
        height: '150',
        width: '200',
        size: '14558',
        url: 'https://media3.giphy.com/media/4UJyRK2TXNhgk/200w_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_s.gif&ct=g',
      },
      looping: {
        mp4_size: '3218637',
        mp4: 'https://media3.giphy.com/media/4UJyRK2TXNhgk/giphy-loop.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-loop.mp4&ct=g',
      },
      original_still: {
        height: '240',
        width: '320',
        size: '57284',
        url: 'https://media3.giphy.com/media/4UJyRK2TXNhgk/giphy_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy_s.gif&ct=g',
      },
      original_mp4: {
        height: '360',
        width: '480',
        mp4_size: '733379',
        mp4: 'https://media3.giphy.com/media/4UJyRK2TXNhgk/giphy.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.mp4&ct=g',
      },
      preview: {
        height: '162',
        width: '216',
        mp4_size: '37108',
        mp4: 'https://media3.giphy.com/media/4UJyRK2TXNhgk/giphy-preview.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.mp4&ct=g',
      },
      preview_gif: {
        height: '58',
        width: '77',
        size: '46873',
        url: 'https://media3.giphy.com/media/4UJyRK2TXNhgk/giphy-preview.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.gif&ct=g',
      },
      preview_webp: {
        height: '100',
        width: '134',
        size: '35844',
        url: 'https://media3.giphy.com/media/4UJyRK2TXNhgk/giphy-preview.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.webp&ct=g',
      },
      '480w_still': {
        height: '360',
        width: '480',
        size: '2183945',
        url: 'https://media3.giphy.com/media/4UJyRK2TXNhgk/480w_s.jpg?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=480w_s.jpg&ct=g',
      },
    },
    analytics_response_payload:
      'e=Z2lmX2lkPTRVSnlSSzJUWE5oZ2smZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw',
    analytics: {
      onload: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPTRVSnlSSzJUWE5oZ2smZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw&action_type=SEEN',
      },
      onclick: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPTRVSnlSSzJUWE5oZ2smZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw&action_type=CLICK',
      },
      onsent: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPTRVSnlSSzJUWE5oZ2smZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw&action_type=SENT',
      },
    },
  },
  {
    type: 'gif',
    id: 'jIzXYqaQ0nLkA',
    url: 'https://giphy.com/gifs/fox-batman-gotham-jIzXYqaQ0nLkA',
    slug: 'fox-batman-gotham-jIzXYqaQ0nLkA',
    bitly_gif_url: 'http://gph.is/1v6FYQX',
    bitly_url: 'http://gph.is/1v6FYQX',
    embed_url: 'https://giphy.com/embed/jIzXYqaQ0nLkA',
    username: '',
    source:
      'http://tumblr.ifc.com/post/98200034784/rrrick-bat-foreplay-the-only-gotham-that-we',
    title: 'Adam West Dancing GIF',
    rating: 'g',
    content_url: '',
    source_tld: 'tumblr.ifc.com',
    source_post_url:
      'http://tumblr.ifc.com/post/98200034784/rrrick-bat-foreplay-the-only-gotham-that-we',
    is_sticker: 0,
    import_datetime: '2014-09-23 03:21:18',
    trending_datetime: '2021-02-22 03:15:07',
    images: {
      original: {
        height: '360',
        width: '480',
        size: '928298',
        url: 'https://media3.giphy.com/media/jIzXYqaQ0nLkA/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
        mp4_size: '249818',
        mp4: 'https://media3.giphy.com/media/jIzXYqaQ0nLkA/giphy.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.mp4&ct=g',
        webp_size: '348574',
        webp: 'https://media3.giphy.com/media/jIzXYqaQ0nLkA/giphy.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.webp&ct=g',
        frames: '16',
        hash: 'afaba177f45db4b65bec5bd48c029aa8',
      },
      downsized: {
        height: '360',
        width: '480',
        size: '928298',
        url: 'https://media3.giphy.com/media/jIzXYqaQ0nLkA/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_large: {
        height: '360',
        width: '480',
        size: '928298',
        url: 'https://media3.giphy.com/media/jIzXYqaQ0nLkA/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_medium: {
        height: '360',
        width: '480',
        size: '928298',
        url: 'https://media3.giphy.com/media/jIzXYqaQ0nLkA/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_small: {
        height: '320',
        width: '426',
        mp4_size: '99406',
        mp4: 'https://media3.giphy.com/media/jIzXYqaQ0nLkA/giphy-downsized-small.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-downsized-small.mp4&ct=g',
      },
      downsized_still: {
        height: '360',
        width: '480',
        size: '928298',
        url: 'https://media3.giphy.com/media/jIzXYqaQ0nLkA/giphy_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy_s.gif&ct=g',
      },
      fixed_height: {
        height: '200',
        width: '267',
        size: '365028',
        url: 'https://media3.giphy.com/media/jIzXYqaQ0nLkA/200.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.gif&ct=g',
        mp4_size: '92282',
        mp4: 'https://media3.giphy.com/media/jIzXYqaQ0nLkA/200.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.mp4&ct=g',
        webp_size: '172446',
        webp: 'https://media3.giphy.com/media/jIzXYqaQ0nLkA/200.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.webp&ct=g',
      },
      fixed_height_downsampled: {
        height: '200',
        width: '267',
        size: '153521',
        url: 'https://media3.giphy.com/media/jIzXYqaQ0nLkA/200_d.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_d.gif&ct=g',
        webp_size: '90254',
        webp: 'https://media3.giphy.com/media/jIzXYqaQ0nLkA/200_d.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_d.webp&ct=g',
      },
      fixed_height_small: {
        height: '100',
        width: '134',
        size: '119559',
        url: 'https://media3.giphy.com/media/jIzXYqaQ0nLkA/100.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.gif&ct=g',
        mp4_size: '34415',
        mp4: 'https://media3.giphy.com/media/jIzXYqaQ0nLkA/100.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.mp4&ct=g',
        webp_size: '69476',
        webp: 'https://media3.giphy.com/media/jIzXYqaQ0nLkA/100.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.webp&ct=g',
      },
      fixed_height_small_still: {
        height: '100',
        width: '134',
        size: '9817',
        url: 'https://media3.giphy.com/media/jIzXYqaQ0nLkA/100_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100_s.gif&ct=g',
      },
      fixed_height_still: {
        height: '200',
        width: '267',
        size: '24949',
        url: 'https://media3.giphy.com/media/jIzXYqaQ0nLkA/200_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_s.gif&ct=g',
      },
      fixed_width: {
        height: '150',
        width: '200',
        size: '226392',
        url: 'https://media3.giphy.com/media/jIzXYqaQ0nLkA/200w.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.gif&ct=g',
        mp4_size: '62381',
        mp4: 'https://media3.giphy.com/media/jIzXYqaQ0nLkA/200w.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.mp4&ct=g',
        webp_size: '121418',
        webp: 'https://media3.giphy.com/media/jIzXYqaQ0nLkA/200w.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.webp&ct=g',
      },
      fixed_width_downsampled: {
        height: '150',
        width: '200',
        size: '94223',
        url: 'https://media3.giphy.com/media/jIzXYqaQ0nLkA/200w_d.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_d.gif&ct=g',
        webp_size: '57774',
        webp: 'https://media3.giphy.com/media/jIzXYqaQ0nLkA/200w_d.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_d.webp&ct=g',
      },
      fixed_width_small: {
        height: '75',
        width: '100',
        size: '73141',
        url: 'https://media3.giphy.com/media/jIzXYqaQ0nLkA/100w.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.gif&ct=g',
        mp4_size: '21901',
        mp4: 'https://media3.giphy.com/media/jIzXYqaQ0nLkA/100w.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.mp4&ct=g',
        webp_size: '45870',
        webp: 'https://media3.giphy.com/media/jIzXYqaQ0nLkA/100w.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.webp&ct=g',
      },
      fixed_width_small_still: {
        height: '75',
        width: '100',
        size: '5507',
        url: 'https://media3.giphy.com/media/jIzXYqaQ0nLkA/100w_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w_s.gif&ct=g',
      },
      fixed_width_still: {
        height: '150',
        width: '200',
        size: '15818',
        url: 'https://media3.giphy.com/media/jIzXYqaQ0nLkA/200w_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_s.gif&ct=g',
      },
      looping: {
        mp4_size: '2319726',
        mp4: 'https://media3.giphy.com/media/jIzXYqaQ0nLkA/giphy-loop.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-loop.mp4&ct=g',
      },
      original_still: {
        height: '360',
        width: '480',
        size: '67656',
        url: 'https://media3.giphy.com/media/jIzXYqaQ0nLkA/giphy_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy_s.gif&ct=g',
      },
      original_mp4: {
        height: '360',
        width: '480',
        mp4_size: '249818',
        mp4: 'https://media3.giphy.com/media/jIzXYqaQ0nLkA/giphy.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.mp4&ct=g',
      },
      preview: {
        height: '160',
        width: '213',
        mp4_size: '38004',
        mp4: 'https://media3.giphy.com/media/jIzXYqaQ0nLkA/giphy-preview.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.mp4&ct=g',
      },
      preview_gif: {
        height: '59',
        width: '79',
        size: '48501',
        url: 'https://media3.giphy.com/media/jIzXYqaQ0nLkA/giphy-preview.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.gif&ct=g',
      },
      preview_webp: {
        height: '108',
        width: '144',
        size: '48170',
        url: 'https://media3.giphy.com/media/jIzXYqaQ0nLkA/giphy-preview.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.webp&ct=g',
      },
      '480w_still': {
        height: '360',
        width: '480',
        size: '928298',
        url: 'https://media3.giphy.com/media/jIzXYqaQ0nLkA/480w_s.jpg?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=480w_s.jpg&ct=g',
      },
    },
    analytics_response_payload:
      'e=Z2lmX2lkPWpJelhZcWFRMG5Ma0EmZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw',
    analytics: {
      onload: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPWpJelhZcWFRMG5Ma0EmZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw&action_type=SEEN',
      },
      onclick: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPWpJelhZcWFRMG5Ma0EmZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw&action_type=CLICK',
      },
      onsent: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPWpJelhZcWFRMG5Ma0EmZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw&action_type=SENT',
      },
    },
  },
  {
    type: 'gif',
    id: 'kg4e4Wksv20eY',
    url: 'https://giphy.com/gifs/batman-arkham-knight-kg4e4Wksv20eY',
    slug: 'batman-arkham-knight-kg4e4Wksv20eY',
    bitly_gif_url: 'http://gph.is/1K9IKBk',
    bitly_url: 'http://gph.is/1K9IKBk',
    embed_url: 'https://giphy.com/embed/kg4e4Wksv20eY',
    username: '',
    source: 'http://coronaking.tumblr.com/post/122201389173',
    title: 'Intensifies Bruce Wayne GIF',
    rating: 'pg',
    content_url: '',
    source_tld: 'coronaking.tumblr.com',
    source_post_url: 'http://coronaking.tumblr.com/post/122201389173',
    is_sticker: 0,
    import_datetime: '2015-06-23 14:23:58',
    trending_datetime: '2015-06-24 08:31:25',
    images: {
      original: {
        height: '293',
        width: '400',
        size: '553697',
        url: 'https://media1.giphy.com/media/kg4e4Wksv20eY/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
        mp4_size: '101609',
        mp4: 'https://media1.giphy.com/media/kg4e4Wksv20eY/giphy.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.mp4&ct=g',
        webp_size: '167488',
        webp: 'https://media1.giphy.com/media/kg4e4Wksv20eY/giphy.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.webp&ct=g',
        frames: '10',
        hash: '672d32d4e584d53c84e76b0083bf615c',
      },
      downsized: {
        height: '293',
        width: '400',
        size: '553697',
        url: 'https://media1.giphy.com/media/kg4e4Wksv20eY/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_large: {
        height: '293',
        width: '400',
        size: '553697',
        url: 'https://media1.giphy.com/media/kg4e4Wksv20eY/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_medium: {
        height: '293',
        width: '400',
        size: '553697',
        url: 'https://media1.giphy.com/media/kg4e4Wksv20eY/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_small: {
        height: '292',
        width: '400',
        mp4_size: '86875',
        mp4: 'https://media1.giphy.com/media/kg4e4Wksv20eY/giphy-downsized-small.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-downsized-small.mp4&ct=g',
      },
      downsized_still: {
        height: '293',
        width: '400',
        size: '553697',
        url: 'https://media1.giphy.com/media/kg4e4Wksv20eY/giphy_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy_s.gif&ct=g',
      },
      fixed_height: {
        height: '200',
        width: '273',
        size: '199146',
        url: 'https://media1.giphy.com/media/kg4e4Wksv20eY/200.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.gif&ct=g',
        mp4_size: '25118',
        mp4: 'https://media1.giphy.com/media/kg4e4Wksv20eY/200.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.mp4&ct=g',
        webp_size: '69940',
        webp: 'https://media1.giphy.com/media/kg4e4Wksv20eY/200.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.webp&ct=g',
      },
      fixed_height_downsampled: {
        height: '200',
        width: '273',
        size: '131046',
        url: 'https://media1.giphy.com/media/kg4e4Wksv20eY/200_d.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_d.gif&ct=g',
        webp_size: '80670',
        webp: 'https://media1.giphy.com/media/kg4e4Wksv20eY/200_d.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_d.webp&ct=g',
      },
      fixed_height_small: {
        height: '100',
        width: '137',
        size: '63530',
        url: 'https://media1.giphy.com/media/kg4e4Wksv20eY/100.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.gif&ct=g',
        mp4_size: '10346',
        mp4: 'https://media1.giphy.com/media/kg4e4Wksv20eY/100.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.mp4&ct=g',
        webp_size: '26704',
        webp: 'https://media1.giphy.com/media/kg4e4Wksv20eY/100.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.webp&ct=g',
      },
      fixed_height_small_still: {
        height: '100',
        width: '137',
        size: '7463',
        url: 'https://media1.giphy.com/media/kg4e4Wksv20eY/100_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100_s.gif&ct=g',
      },
      fixed_height_still: {
        height: '200',
        width: '273',
        size: '20755',
        url: 'https://media1.giphy.com/media/kg4e4Wksv20eY/200_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_s.gif&ct=g',
      },
      fixed_width: {
        height: '147',
        width: '200',
        size: '134547',
        url: 'https://media1.giphy.com/media/kg4e4Wksv20eY/200w.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.gif&ct=g',
        mp4_size: '15614',
        mp4: 'https://media1.giphy.com/media/kg4e4Wksv20eY/200w.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.mp4&ct=g',
        webp_size: '44792',
        webp: 'https://media1.giphy.com/media/kg4e4Wksv20eY/200w.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.webp&ct=g',
      },
      fixed_width_downsampled: {
        height: '147',
        width: '200',
        size: '80472',
        url: 'https://media1.giphy.com/media/kg4e4Wksv20eY/200w_d.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_d.gif&ct=g',
        webp_size: '47828',
        webp: 'https://media1.giphy.com/media/kg4e4Wksv20eY/200w_d.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_d.webp&ct=g',
      },
      fixed_width_small: {
        height: '74',
        width: '100',
        size: '39085',
        url: 'https://media1.giphy.com/media/kg4e4Wksv20eY/100w.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.gif&ct=g',
        mp4_size: '6310',
        mp4: 'https://media1.giphy.com/media/kg4e4Wksv20eY/100w.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.mp4&ct=g',
        webp_size: '17164',
        webp: 'https://media1.giphy.com/media/kg4e4Wksv20eY/100w.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.webp&ct=g',
      },
      fixed_width_small_still: {
        height: '74',
        width: '100',
        size: '4732',
        url: 'https://media1.giphy.com/media/kg4e4Wksv20eY/100w_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w_s.gif&ct=g',
      },
      fixed_width_still: {
        height: '147',
        width: '200',
        size: '16444',
        url: 'https://media1.giphy.com/media/kg4e4Wksv20eY/200w_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_s.gif&ct=g',
      },
      looping: {
        mp4_size: '1892106',
        mp4: 'https://media1.giphy.com/media/kg4e4Wksv20eY/giphy-loop.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-loop.mp4&ct=g',
      },
      original_still: {
        height: '293',
        width: '400',
        size: '66017',
        url: 'https://media1.giphy.com/media/kg4e4Wksv20eY/giphy_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy_s.gif&ct=g',
      },
      original_mp4: {
        height: '350',
        width: '480',
        mp4_size: '101609',
        mp4: 'https://media1.giphy.com/media/kg4e4Wksv20eY/giphy.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.mp4&ct=g',
      },
      preview: {
        height: '220',
        width: '301',
        mp4_size: '15684',
        mp4: 'https://media1.giphy.com/media/kg4e4Wksv20eY/giphy-preview.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.mp4&ct=g',
      },
      preview_gif: {
        height: '59',
        width: '81',
        size: '47772',
        url: 'https://media1.giphy.com/media/kg4e4Wksv20eY/giphy-preview.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.gif&ct=g',
      },
      preview_webp: {
        height: '170',
        width: '232',
        size: '34158',
        url: 'https://media1.giphy.com/media/kg4e4Wksv20eY/giphy-preview.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.webp&ct=g',
      },
      '480w_still': {
        height: '352',
        width: '480',
        size: '553697',
        url: 'https://media1.giphy.com/media/kg4e4Wksv20eY/480w_s.jpg?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=480w_s.jpg&ct=g',
      },
    },
    analytics_response_payload:
      'e=Z2lmX2lkPWtnNGU0V2tzdjIwZVkmZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw',
    analytics: {
      onload: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPWtnNGU0V2tzdjIwZVkmZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw&action_type=SEEN',
      },
      onclick: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPWtnNGU0V2tzdjIwZVkmZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw&action_type=CLICK',
      },
      onsent: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPWtnNGU0V2tzdjIwZVkmZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw&action_type=SENT',
      },
    },
  },
  {
    type: 'gif',
    id: 'C7RCCFdaixA3u',
    url: 'https://giphy.com/gifs/batman-black-and-white-C7RCCFdaixA3u',
    slug: 'batman-black-and-white-C7RCCFdaixA3u',
    bitly_gif_url: 'http://gph.is/XIlqTs',
    bitly_url: 'http://gph.is/XIlqTs',
    embed_url: 'https://giphy.com/embed/C7RCCFdaixA3u',
    username: '',
    source:
      'http://haematocryal.tumblr.com/post/46154956850/you-must-be-more-than-just-a-man-in-the-mind-of',
    title: 'black and white batman GIF',
    rating: 'g',
    content_url: '',
    source_tld: 'haematocryal.tumblr.com',
    source_post_url:
      'http://haematocryal.tumblr.com/post/46154956850/you-must-be-more-than-just-a-man-in-the-mind-of',
    is_sticker: 0,
    import_datetime: '2013-03-28 16:25:15',
    trending_datetime: '1970-01-01 00:00:00',
    images: {
      original: {
        height: '200',
        width: '500',
        size: '304854',
        url: 'https://media0.giphy.com/media/C7RCCFdaixA3u/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
        mp4_size: '125153',
        mp4: 'https://media0.giphy.com/media/C7RCCFdaixA3u/giphy.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.mp4&ct=g',
        webp_size: '124764',
        webp: 'https://media0.giphy.com/media/C7RCCFdaixA3u/giphy.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.webp&ct=g',
        frames: '19',
        hash: '67795435ec5aa03f21ffe7311fd7664d',
      },
      downsized: {
        height: '200',
        width: '500',
        size: '304854',
        url: 'https://media0.giphy.com/media/C7RCCFdaixA3u/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_large: {
        height: '200',
        width: '500',
        size: '304854',
        url: 'https://media0.giphy.com/media/C7RCCFdaixA3u/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_medium: {
        height: '200',
        width: '500',
        size: '304854',
        url: 'https://media0.giphy.com/media/C7RCCFdaixA3u/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_small: {
        height: '200',
        width: '500',
        mp4_size: '163069',
        mp4: 'https://media0.giphy.com/media/C7RCCFdaixA3u/giphy-downsized-small.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-downsized-small.mp4&ct=g',
      },
      downsized_still: {
        height: '200',
        width: '500',
        size: '304854',
        url: 'https://media0.giphy.com/media/C7RCCFdaixA3u/giphy_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy_s.gif&ct=g',
      },
      fixed_height: {
        height: '200',
        width: '500',
        size: '230447',
        url: 'https://media0.giphy.com/media/C7RCCFdaixA3u/200.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.gif&ct=g',
        mp4_size: '115416',
        mp4: 'https://media0.giphy.com/media/C7RCCFdaixA3u/200.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.mp4&ct=g',
        webp_size: '124748',
        webp: 'https://media0.giphy.com/media/C7RCCFdaixA3u/200.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.webp&ct=g',
      },
      fixed_height_downsampled: {
        height: '200',
        width: '500',
        size: '81287',
        url: 'https://media0.giphy.com/media/C7RCCFdaixA3u/200_d.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_d.gif&ct=g',
        webp_size: '72090',
        webp: 'https://media0.giphy.com/media/C7RCCFdaixA3u/200_d.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_d.webp&ct=g',
      },
      fixed_height_small: {
        height: '100',
        width: '250',
        size: '110548',
        url: 'https://media0.giphy.com/media/C7RCCFdaixA3u/100.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.gif&ct=g',
        mp4_size: '35661',
        mp4: 'https://media0.giphy.com/media/C7RCCFdaixA3u/100.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.mp4&ct=g',
        webp_size: '40836',
        webp: 'https://media0.giphy.com/media/C7RCCFdaixA3u/100.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.webp&ct=g',
      },
      fixed_height_small_still: {
        height: '100',
        width: '250',
        size: '7969',
        url: 'https://media0.giphy.com/media/C7RCCFdaixA3u/100_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100_s.gif&ct=g',
      },
      fixed_height_still: {
        height: '200',
        width: '500',
        size: '13081',
        url: 'https://media0.giphy.com/media/C7RCCFdaixA3u/200_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_s.gif&ct=g',
      },
      fixed_width: {
        height: '80',
        width: '200',
        size: '80544',
        url: 'https://media0.giphy.com/media/C7RCCFdaixA3u/200w.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.gif&ct=g',
        mp4_size: '25919',
        mp4: 'https://media0.giphy.com/media/C7RCCFdaixA3u/200w.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.mp4&ct=g',
        webp_size: '31170',
        webp: 'https://media0.giphy.com/media/C7RCCFdaixA3u/200w.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.webp&ct=g',
      },
      fixed_width_downsampled: {
        height: '80',
        width: '200',
        size: '26671',
        url: 'https://media0.giphy.com/media/C7RCCFdaixA3u/200w_d.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_d.gif&ct=g',
        webp_size: '15694',
        webp: 'https://media0.giphy.com/media/C7RCCFdaixA3u/200w_d.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_d.webp&ct=g',
      },
      fixed_width_small: {
        height: '40',
        width: '100',
        size: '32350',
        url: 'https://media0.giphy.com/media/C7RCCFdaixA3u/100w.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.gif&ct=g',
        mp4_size: '11559',
        mp4: 'https://media0.giphy.com/media/C7RCCFdaixA3u/100w.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.mp4&ct=g',
        webp_size: '15026',
        webp: 'https://media0.giphy.com/media/C7RCCFdaixA3u/100w.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.webp&ct=g',
      },
      fixed_width_small_still: {
        height: '40',
        width: '100',
        size: '2562',
        url: 'https://media0.giphy.com/media/C7RCCFdaixA3u/100w_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w_s.gif&ct=g',
      },
      fixed_width_still: {
        height: '80',
        width: '200',
        size: '5162',
        url: 'https://media0.giphy.com/media/C7RCCFdaixA3u/200w_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_s.gif&ct=g',
      },
      looping: {
        mp4_size: '814295',
        mp4: 'https://media0.giphy.com/media/C7RCCFdaixA3u/giphy-loop.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-loop.mp4&ct=g',
      },
      original_still: {
        height: '200',
        width: '500',
        size: '33192',
        url: 'https://media0.giphy.com/media/C7RCCFdaixA3u/giphy_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy_s.gif&ct=g',
      },
      original_mp4: {
        height: '192',
        width: '480',
        mp4_size: '125153',
        mp4: 'https://media0.giphy.com/media/C7RCCFdaixA3u/giphy.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.mp4&ct=g',
      },
      preview: {
        height: '110',
        width: '275',
        mp4_size: '26924',
        mp4: 'https://media0.giphy.com/media/C7RCCFdaixA3u/giphy-preview.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.mp4&ct=g',
      },
      preview_gif: {
        height: '47',
        width: '118',
        size: '48362',
        url: 'https://media0.giphy.com/media/C7RCCFdaixA3u/giphy-preview.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.gif&ct=g',
      },
      preview_webp: {
        height: '142',
        width: '356',
        size: '38502',
        url: 'https://media0.giphy.com/media/C7RCCFdaixA3u/giphy-preview.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.webp&ct=g',
      },
      '480w_still': {
        height: '192',
        width: '480',
        size: '304854',
        url: 'https://media0.giphy.com/media/C7RCCFdaixA3u/480w_s.jpg?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=480w_s.jpg&ct=g',
      },
    },
    analytics_response_payload:
      'e=Z2lmX2lkPUM3UkNDRmRhaXhBM3UmZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw',
    analytics: {
      onload: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPUM3UkNDRmRhaXhBM3UmZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw&action_type=SEEN',
      },
      onclick: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPUM3UkNDRmRhaXhBM3UmZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw&action_type=CLICK',
      },
      onsent: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPUM3UkNDRmRhaXhBM3UmZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw&action_type=SENT',
      },
    },
  },
  {
    type: 'gif',
    id: 'oOK9AZGnf9b0c',
    url: 'https://giphy.com/gifs/batman-comics-oOK9AZGnf9b0c',
    slug: 'batman-comics-oOK9AZGnf9b0c',
    bitly_gif_url: 'http://gph.is/17LzpvM',
    bitly_url: 'http://gph.is/17LzpvM',
    embed_url: 'https://giphy.com/embed/oOK9AZGnf9b0c',
    username: '',
    source:
      'http://comicsforever.tumblr.com/post/56022678132/batman-returns-gif-bykane52630-2013',
    title: 'Dc Comics Batman GIF',
    rating: 'g',
    content_url: '',
    source_tld: 'comicsforever.tumblr.com',
    source_post_url:
      'http://comicsforever.tumblr.com/post/56022678132/batman-returns-gif-bykane52630-2013',
    is_sticker: 0,
    import_datetime: '2013-10-16 19:16:56',
    trending_datetime: '2014-11-19 18:13:00',
    images: {
      original: {
        height: '175',
        width: '245',
        size: '1579912',
        url: 'https://media3.giphy.com/media/oOK9AZGnf9b0c/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
        mp4_size: '1172985',
        mp4: 'https://media3.giphy.com/media/oOK9AZGnf9b0c/giphy.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.mp4&ct=g',
        webp_size: '667982',
        webp: 'https://media3.giphy.com/media/oOK9AZGnf9b0c/giphy.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.webp&ct=g',
        frames: '88',
        hash: '0b70cd208cccd4f401b6f740876750ce',
      },
      downsized: {
        height: '175',
        width: '245',
        size: '1579912',
        url: 'https://media3.giphy.com/media/oOK9AZGnf9b0c/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_large: {
        height: '175',
        width: '245',
        size: '1579912',
        url: 'https://media3.giphy.com/media/oOK9AZGnf9b0c/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_medium: {
        height: '175',
        width: '245',
        size: '1579912',
        url: 'https://media3.giphy.com/media/oOK9AZGnf9b0c/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_small: {
        height: '136',
        width: '190',
        mp4_size: '90858',
        mp4: 'https://media3.giphy.com/media/oOK9AZGnf9b0c/giphy-downsized-small.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-downsized-small.mp4&ct=g',
      },
      downsized_still: {
        height: '175',
        width: '245',
        size: '1579912',
        url: 'https://media3.giphy.com/media/oOK9AZGnf9b0c/giphy_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy_s.gif&ct=g',
      },
      fixed_height: {
        height: '200',
        width: '280',
        size: '1761086',
        url: 'https://media3.giphy.com/media/oOK9AZGnf9b0c/200.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.gif&ct=g',
        mp4_size: '342049',
        mp4: 'https://media3.giphy.com/media/oOK9AZGnf9b0c/200.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.mp4&ct=g',
        webp_size: '747046',
        webp: 'https://media3.giphy.com/media/oOK9AZGnf9b0c/200.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.webp&ct=g',
      },
      fixed_height_downsampled: {
        height: '200',
        width: '280',
        size: '121959',
        url: 'https://media3.giphy.com/media/oOK9AZGnf9b0c/200_d.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_d.gif&ct=g',
        webp_size: '58418',
        webp: 'https://media3.giphy.com/media/oOK9AZGnf9b0c/200_d.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_d.webp&ct=g',
      },
      fixed_height_small: {
        height: '100',
        width: '140',
        size: '533898',
        url: 'https://media3.giphy.com/media/oOK9AZGnf9b0c/100.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.gif&ct=g',
        mp4_size: '114105',
        mp4: 'https://media3.giphy.com/media/oOK9AZGnf9b0c/100.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.mp4&ct=g',
        webp_size: '301956',
        webp: 'https://media3.giphy.com/media/oOK9AZGnf9b0c/100.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.webp&ct=g',
      },
      fixed_height_small_still: {
        height: '100',
        width: '140',
        size: '3444',
        url: 'https://media3.giphy.com/media/oOK9AZGnf9b0c/100_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100_s.gif&ct=g',
      },
      fixed_height_still: {
        height: '200',
        width: '280',
        size: '9375',
        url: 'https://media3.giphy.com/media/oOK9AZGnf9b0c/200_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_s.gif&ct=g',
      },
      fixed_width: {
        height: '143',
        width: '200',
        size: '1015989',
        url: 'https://media3.giphy.com/media/oOK9AZGnf9b0c/200w.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.gif&ct=g',
        mp4_size: '189038',
        mp4: 'https://media3.giphy.com/media/oOK9AZGnf9b0c/200w.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.mp4&ct=g',
        webp_size: '466964',
        webp: 'https://media3.giphy.com/media/oOK9AZGnf9b0c/200w.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.webp&ct=g',
      },
      fixed_width_downsampled: {
        height: '143',
        width: '200',
        size: '69855',
        url: 'https://media3.giphy.com/media/oOK9AZGnf9b0c/200w_d.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_d.gif&ct=g',
        webp_size: '36034',
        webp: 'https://media3.giphy.com/media/oOK9AZGnf9b0c/200w_d.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_d.webp&ct=g',
      },
      fixed_width_small: {
        height: '72',
        width: '100',
        size: '309304',
        url: 'https://media3.giphy.com/media/oOK9AZGnf9b0c/100w.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.gif&ct=g',
        mp4_size: '46472',
        mp4: 'https://media3.giphy.com/media/oOK9AZGnf9b0c/100w.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.mp4&ct=g',
        webp_size: '201058',
        webp: 'https://media3.giphy.com/media/oOK9AZGnf9b0c/100w.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.webp&ct=g',
      },
      fixed_width_small_still: {
        height: '72',
        width: '100',
        size: '2352',
        url: 'https://media3.giphy.com/media/oOK9AZGnf9b0c/100w_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w_s.gif&ct=g',
      },
      fixed_width_still: {
        height: '143',
        width: '200',
        size: '5632',
        url: 'https://media3.giphy.com/media/oOK9AZGnf9b0c/200w_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_s.gif&ct=g',
      },
      looping: {
        mp4_size: '2182844',
        mp4: 'https://media3.giphy.com/media/oOK9AZGnf9b0c/giphy-loop.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-loop.mp4&ct=g',
      },
      original_still: {
        height: '175',
        width: '245',
        size: '14679',
        url: 'https://media3.giphy.com/media/oOK9AZGnf9b0c/giphy_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy_s.gif&ct=g',
      },
      original_mp4: {
        height: '342',
        width: '480',
        mp4_size: '1172985',
        mp4: 'https://media3.giphy.com/media/oOK9AZGnf9b0c/giphy.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.mp4&ct=g',
      },
      preview: {
        height: '138',
        width: '193',
        mp4_size: '38073',
        mp4: 'https://media3.giphy.com/media/oOK9AZGnf9b0c/giphy-preview.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.mp4&ct=g',
      },
      preview_gif: {
        height: '59',
        width: '83',
        size: '47386',
        url: 'https://media3.giphy.com/media/oOK9AZGnf9b0c/giphy-preview.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.gif&ct=g',
      },
      preview_webp: {
        height: '124',
        width: '174',
        size: '44778',
        url: 'https://media3.giphy.com/media/oOK9AZGnf9b0c/giphy-preview.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.webp&ct=g',
      },
      '480w_still': {
        height: '343',
        width: '480',
        size: '1579912',
        url: 'https://media3.giphy.com/media/oOK9AZGnf9b0c/480w_s.jpg?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=480w_s.jpg&ct=g',
      },
    },
    analytics_response_payload:
      'e=Z2lmX2lkPW9PSzlBWkduZjliMGMmZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw',
    analytics: {
      onload: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPW9PSzlBWkduZjliMGMmZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw&action_type=SEEN',
      },
      onclick: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPW9PSzlBWkduZjliMGMmZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw&action_type=CLICK',
      },
      onsent: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPW9PSzlBWkduZjliMGMmZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw&action_type=SENT',
      },
    },
  },
  {
    type: 'gif',
    id: '5DQdk5oZzNgGc',
    url: 'https://giphy.com/gifs/batman-thumbs-up-cool-story-bro-5DQdk5oZzNgGc',
    slug: 'batman-thumbs-up-cool-story-bro-5DQdk5oZzNgGc',
    bitly_gif_url: 'http://gph.is/XHnf2S',
    bitly_url: 'http://gph.is/XHnf2S',
    embed_url: 'https://giphy.com/embed/5DQdk5oZzNgGc',
    username: '',
    source: 'http://through-our-relay.tumblr.com/post/44082384909',
    title: 'Batman Ok GIF',
    rating: 'g',
    content_url: '',
    source_tld: 'through-our-relay.tumblr.com',
    source_post_url: 'http://through-our-relay.tumblr.com/post/44082384909',
    is_sticker: 0,
    import_datetime: '2013-03-22 02:47:42',
    trending_datetime: '2015-11-04 03:44:38',
    images: {
      original: {
        height: '420',
        width: '500',
        size: '318958',
        url: 'https://media2.giphy.com/media/5DQdk5oZzNgGc/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
        mp4_size: '126570',
        mp4: 'https://media2.giphy.com/media/5DQdk5oZzNgGc/giphy.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.mp4&ct=g',
        webp_size: '123338',
        webp: 'https://media2.giphy.com/media/5DQdk5oZzNgGc/giphy.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.webp&ct=g',
        frames: '22',
        hash: '5b7e0e7084362e4e225a4e86c65b856b',
      },
      downsized: {
        height: '420',
        width: '500',
        size: '318958',
        url: 'https://media2.giphy.com/media/5DQdk5oZzNgGc/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_large: {
        height: '420',
        width: '500',
        size: '318958',
        url: 'https://media2.giphy.com/media/5DQdk5oZzNgGc/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_medium: {
        height: '420',
        width: '500',
        size: '318958',
        url: 'https://media2.giphy.com/media/5DQdk5oZzNgGc/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_small: {
        height: '420',
        width: '500',
        mp4_size: '148110',
        mp4: 'https://media2.giphy.com/media/5DQdk5oZzNgGc/giphy-downsized-small.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-downsized-small.mp4&ct=g',
      },
      downsized_still: {
        height: '420',
        width: '500',
        size: '318958',
        url: 'https://media2.giphy.com/media/5DQdk5oZzNgGc/giphy_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy_s.gif&ct=g',
      },
      fixed_height: {
        height: '200',
        width: '238',
        size: '74161',
        url: 'https://media2.giphy.com/media/5DQdk5oZzNgGc/200.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.gif&ct=g',
        mp4_size: '36096',
        mp4: 'https://media2.giphy.com/media/5DQdk5oZzNgGc/200.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.mp4&ct=g',
        webp_size: '45538',
        webp: 'https://media2.giphy.com/media/5DQdk5oZzNgGc/200.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.webp&ct=g',
      },
      fixed_height_downsampled: {
        height: '200',
        width: '238',
        size: '34997',
        url: 'https://media2.giphy.com/media/5DQdk5oZzNgGc/200_d.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_d.gif&ct=g',
        webp_size: '23818',
        webp: 'https://media2.giphy.com/media/5DQdk5oZzNgGc/200_d.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_d.webp&ct=g',
      },
      fixed_height_small: {
        height: '100',
        width: '119',
        size: '26682',
        url: 'https://media2.giphy.com/media/5DQdk5oZzNgGc/100.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.gif&ct=g',
        mp4_size: '13423',
        mp4: 'https://media2.giphy.com/media/5DQdk5oZzNgGc/100.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.mp4&ct=g',
        webp_size: '18504',
        webp: 'https://media2.giphy.com/media/5DQdk5oZzNgGc/100.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.webp&ct=g',
      },
      fixed_height_small_still: {
        height: '100',
        width: '119',
        size: '6570',
        url: 'https://media2.giphy.com/media/5DQdk5oZzNgGc/100_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100_s.gif&ct=g',
      },
      fixed_height_still: {
        height: '200',
        width: '238',
        size: '19797',
        url: 'https://media2.giphy.com/media/5DQdk5oZzNgGc/200_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_s.gif&ct=g',
      },
      fixed_width: {
        height: '168',
        width: '200',
        size: '56309',
        url: 'https://media2.giphy.com/media/5DQdk5oZzNgGc/200w.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.gif&ct=g',
        mp4_size: '28320',
        mp4: 'https://media2.giphy.com/media/5DQdk5oZzNgGc/200w.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.mp4&ct=g',
        webp_size: '36826',
        webp: 'https://media2.giphy.com/media/5DQdk5oZzNgGc/200w.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.webp&ct=g',
      },
      fixed_width_downsampled: {
        height: '168',
        width: '200',
        size: '25204',
        url: 'https://media2.giphy.com/media/5DQdk5oZzNgGc/200w_d.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_d.gif&ct=g',
        webp_size: '19062',
        webp: 'https://media2.giphy.com/media/5DQdk5oZzNgGc/200w_d.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_d.webp&ct=g',
      },
      fixed_width_small: {
        height: '84',
        width: '100',
        size: '20633',
        url: 'https://media2.giphy.com/media/5DQdk5oZzNgGc/100w.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.gif&ct=g',
        mp4_size: '10808',
        mp4: 'https://media2.giphy.com/media/5DQdk5oZzNgGc/100w.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.mp4&ct=g',
        webp_size: '14302',
        webp: 'https://media2.giphy.com/media/5DQdk5oZzNgGc/100w.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.webp&ct=g',
      },
      fixed_width_small_still: {
        height: '84',
        width: '100',
        size: '4962',
        url: 'https://media2.giphy.com/media/5DQdk5oZzNgGc/100w_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w_s.gif&ct=g',
      },
      fixed_width_still: {
        height: '168',
        width: '200',
        size: '16001',
        url: 'https://media2.giphy.com/media/5DQdk5oZzNgGc/200w_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_s.gif&ct=g',
      },
      looping: {
        mp4_size: '393621',
        mp4: 'https://media2.giphy.com/media/5DQdk5oZzNgGc/giphy-loop.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-loop.mp4&ct=g',
      },
      original_still: {
        height: '420',
        width: '500',
        size: '107562',
        url: 'https://media2.giphy.com/media/5DQdk5oZzNgGc/giphy_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy_s.gif&ct=g',
      },
      original_mp4: {
        height: '402',
        width: '480',
        mp4_size: '126570',
        mp4: 'https://media2.giphy.com/media/5DQdk5oZzNgGc/giphy.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.mp4&ct=g',
      },
      preview: {
        height: '336',
        width: '400',
        mp4_size: '22924',
        mp4: 'https://media2.giphy.com/media/5DQdk5oZzNgGc/giphy-preview.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.mp4&ct=g',
      },
      preview_gif: {
        height: '420',
        width: '500',
        size: '48038',
        url: 'https://media2.giphy.com/media/5DQdk5oZzNgGc/giphy-preview.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.gif&ct=g',
      },
      preview_webp: {
        height: '420',
        width: '500',
        size: '27330',
        url: 'https://media2.giphy.com/media/5DQdk5oZzNgGc/giphy-preview.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.webp&ct=g',
      },
      '480w_still': {
        height: '403',
        width: '480',
        size: '318958',
        url: 'https://media2.giphy.com/media/5DQdk5oZzNgGc/480w_s.jpg?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=480w_s.jpg&ct=g',
      },
    },
    analytics_response_payload:
      'e=Z2lmX2lkPTVEUWRrNW9aek5nR2MmZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw',
    analytics: {
      onload: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPTVEUWRrNW9aek5nR2MmZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw&action_type=SEEN',
      },
      onclick: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPTVEUWRrNW9aek5nR2MmZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw&action_type=CLICK',
      },
      onsent: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPTVEUWRrNW9aek5nR2MmZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw&action_type=SENT',
      },
    },
  },
  {
    type: 'gif',
    id: 'Qumf2QovTD4QxHPjy5',
    url: 'https://giphy.com/gifs/moodman-batman-smack-smacked-Qumf2QovTD4QxHPjy5',
    slug: 'moodman-batman-smack-smacked-Qumf2QovTD4QxHPjy5',
    bitly_gif_url: 'https://gph.is/g/ZlQr87k',
    bitly_url: 'https://gph.is/g/ZlQr87k',
    embed_url: 'https://giphy.com/embed/Qumf2QovTD4QxHPjy5',
    username: '',
    source: '',
    title: 'Meme Reaction GIF by MOODMAN',
    rating: 'pg',
    content_url: '',
    source_tld: '',
    source_post_url: '',
    is_sticker: 0,
    import_datetime: '2020-02-19 00:33:13',
    trending_datetime: '2020-09-15 15:00:06',
    images: {
      original: {
        height: '360',
        width: '480',
        size: '996194',
        url: 'https://media0.giphy.com/media/Qumf2QovTD4QxHPjy5/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
        mp4_size: '381517',
        mp4: 'https://media0.giphy.com/media/Qumf2QovTD4QxHPjy5/giphy.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.mp4&ct=g',
        webp_size: '470454',
        webp: 'https://media0.giphy.com/media/Qumf2QovTD4QxHPjy5/giphy.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.webp&ct=g',
        frames: '16',
        hash: '046c779d891b889095c6afc1651b15f1',
      },
      downsized: {
        height: '360',
        width: '480',
        size: '996194',
        url: 'https://media0.giphy.com/media/Qumf2QovTD4QxHPjy5/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_large: {
        height: '360',
        width: '480',
        size: '996194',
        url: 'https://media0.giphy.com/media/Qumf2QovTD4QxHPjy5/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_medium: {
        height: '360',
        width: '480',
        size: '996194',
        url: 'https://media0.giphy.com/media/Qumf2QovTD4QxHPjy5/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_small: {
        height: '258',
        width: '344',
        mp4_size: '106285',
        mp4: 'https://media0.giphy.com/media/Qumf2QovTD4QxHPjy5/giphy-downsized-small.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-downsized-small.mp4&ct=g',
      },
      downsized_still: {
        height: '360',
        width: '480',
        size: '996194',
        url: 'https://media0.giphy.com/media/Qumf2QovTD4QxHPjy5/giphy_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy_s.gif&ct=g',
      },
      fixed_height: {
        height: '200',
        width: '267',
        size: '269638',
        url: 'https://media0.giphy.com/media/Qumf2QovTD4QxHPjy5/200.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.gif&ct=g',
        mp4_size: '126713',
        mp4: 'https://media0.giphy.com/media/Qumf2QovTD4QxHPjy5/200.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.mp4&ct=g',
        webp_size: '208312',
        webp: 'https://media0.giphy.com/media/Qumf2QovTD4QxHPjy5/200.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.webp&ct=g',
      },
      fixed_height_downsampled: {
        height: '200',
        width: '267',
        size: '127348',
        url: 'https://media0.giphy.com/media/Qumf2QovTD4QxHPjy5/200_d.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_d.gif&ct=g',
        webp_size: '91284',
        webp: 'https://media0.giphy.com/media/Qumf2QovTD4QxHPjy5/200_d.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_d.webp&ct=g',
      },
      fixed_height_small: {
        height: '100',
        width: '134',
        size: '78610',
        url: 'https://media0.giphy.com/media/Qumf2QovTD4QxHPjy5/100.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.gif&ct=g',
        mp4_size: '40804',
        mp4: 'https://media0.giphy.com/media/Qumf2QovTD4QxHPjy5/100.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.mp4&ct=g',
        webp_size: '67834',
        webp: 'https://media0.giphy.com/media/Qumf2QovTD4QxHPjy5/100.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.webp&ct=g',
      },
      fixed_height_small_still: {
        height: '100',
        width: '134',
        size: '6920',
        url: 'https://media0.giphy.com/media/Qumf2QovTD4QxHPjy5/100_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100_s.gif&ct=g',
      },
      fixed_height_still: {
        height: '200',
        width: '267',
        size: '23941',
        url: 'https://media0.giphy.com/media/Qumf2QovTD4QxHPjy5/200_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_s.gif&ct=g',
      },
      fixed_width: {
        height: '150',
        width: '200',
        size: '180235',
        url: 'https://media0.giphy.com/media/Qumf2QovTD4QxHPjy5/200w.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.gif&ct=g',
        mp4_size: '79931',
        mp4: 'https://media0.giphy.com/media/Qumf2QovTD4QxHPjy5/200w.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.mp4&ct=g',
        webp_size: '131616',
        webp: 'https://media0.giphy.com/media/Qumf2QovTD4QxHPjy5/200w.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.webp&ct=g',
      },
      fixed_width_downsampled: {
        height: '150',
        width: '200',
        size: '81399',
        url: 'https://media0.giphy.com/media/Qumf2QovTD4QxHPjy5/200w_d.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_d.gif&ct=g',
        webp_size: '55846',
        webp: 'https://media0.giphy.com/media/Qumf2QovTD4QxHPjy5/200w_d.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_d.webp&ct=g',
      },
      fixed_width_small: {
        height: '75',
        width: '100',
        size: '47938',
        url: 'https://media0.giphy.com/media/Qumf2QovTD4QxHPjy5/100w.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.gif&ct=g',
        mp4_size: '23657',
        mp4: 'https://media0.giphy.com/media/Qumf2QovTD4QxHPjy5/100w.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.mp4&ct=g',
        webp_size: '39544',
        webp: 'https://media0.giphy.com/media/Qumf2QovTD4QxHPjy5/100w.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.webp&ct=g',
      },
      fixed_width_small_still: {
        height: '75',
        width: '100',
        size: '4283',
        url: 'https://media0.giphy.com/media/Qumf2QovTD4QxHPjy5/100w_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w_s.gif&ct=g',
      },
      fixed_width_still: {
        height: '150',
        width: '200',
        size: '14080',
        url: 'https://media0.giphy.com/media/Qumf2QovTD4QxHPjy5/200w_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_s.gif&ct=g',
      },
      looping: {
        mp4_size: '5148236',
        mp4: 'https://media0.giphy.com/media/Qumf2QovTD4QxHPjy5/giphy-loop.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-loop.mp4&ct=g',
      },
      original_still: {
        height: '360',
        width: '480',
        size: '100668',
        url: 'https://media0.giphy.com/media/Qumf2QovTD4QxHPjy5/giphy_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy_s.gif&ct=g',
      },
      original_mp4: {
        height: '360',
        width: '480',
        mp4_size: '381517',
        mp4: 'https://media0.giphy.com/media/Qumf2QovTD4QxHPjy5/giphy.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.mp4&ct=g',
      },
      preview: {
        height: '144',
        width: '192',
        mp4_size: '36179',
        mp4: 'https://media0.giphy.com/media/Qumf2QovTD4QxHPjy5/giphy-preview.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.mp4&ct=g',
      },
      preview_gif: {
        height: '72',
        width: '96',
        size: '49556',
        url: 'https://media0.giphy.com/media/Qumf2QovTD4QxHPjy5/giphy-preview.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.gif&ct=g',
      },
      preview_webp: {
        height: '94',
        width: '126',
        size: '38242',
        url: 'https://media0.giphy.com/media/Qumf2QovTD4QxHPjy5/giphy-preview.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.webp&ct=g',
      },
      hd: {
        height: '600',
        width: '800',
        mp4_size: '1109775',
        mp4: 'https://media0.giphy.com/media/Qumf2QovTD4QxHPjy5/giphy-hd.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-hd.mp4&ct=g',
      },
      '480w_still': {
        height: '360',
        width: '480',
        size: '996194',
        url: 'https://media0.giphy.com/media/Qumf2QovTD4QxHPjy5/480w_s.jpg?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=480w_s.jpg&ct=g',
      },
    },
    analytics_response_payload:
      'e=Z2lmX2lkPVF1bWYyUW92VEQ0UXhIUGp5NSZldmVudF90eXBlPUdJRl9TRUFSQ0gmY2lkPTlmMGY2NDI1eTVqMTR0YzYxMHFvMTRwcmxncDR1MWh5aDBwZnc2OGQxOXlkOTNzMyZjdD1n',
    analytics: {
      onload: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPVF1bWYyUW92VEQ0UXhIUGp5NSZldmVudF90eXBlPUdJRl9TRUFSQ0gmY2lkPTlmMGY2NDI1eTVqMTR0YzYxMHFvMTRwcmxncDR1MWh5aDBwZnc2OGQxOXlkOTNzMyZjdD1n&action_type=SEEN',
      },
      onclick: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPVF1bWYyUW92VEQ0UXhIUGp5NSZldmVudF90eXBlPUdJRl9TRUFSQ0gmY2lkPTlmMGY2NDI1eTVqMTR0YzYxMHFvMTRwcmxncDR1MWh5aDBwZnc2OGQxOXlkOTNzMyZjdD1n&action_type=CLICK',
      },
      onsent: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPVF1bWYyUW92VEQ0UXhIUGp5NSZldmVudF90eXBlPUdJRl9TRUFSQ0gmY2lkPTlmMGY2NDI1eTVqMTR0YzYxMHFvMTRwcmxncDR1MWh5aDBwZnc2OGQxOXlkOTNzMyZjdD1n&action_type=SENT',
      },
    },
  },
  {
    type: 'gif',
    id: '14y3bdRzH8aT0k',
    url: 'https://giphy.com/gifs/batman-shark-1966-14y3bdRzH8aT0k',
    slug: 'batman-shark-1966-14y3bdRzH8aT0k',
    bitly_gif_url: 'http://gph.is/1sze2eu',
    bitly_url: 'http://gph.is/1sze2eu',
    embed_url: 'https://giphy.com/embed/14y3bdRzH8aT0k',
    username: '',
    source:
      'http://randar.com/post/63413160814/presenting-the-most-epic-fight-ever-batman-vs',
    title: 'Batman 1966 GIF',
    rating: 'g',
    content_url: '',
    source_tld: 'randar.com',
    source_post_url:
      'http://randar.com/post/63413160814/presenting-the-most-epic-fight-ever-batman-vs',
    is_sticker: 0,
    import_datetime: '2016-06-10 21:48:23',
    trending_datetime: '2016-06-10 21:48:26',
    images: {
      original: {
        height: '296',
        width: '375',
        size: '775422',
        url: 'https://media4.giphy.com/media/14y3bdRzH8aT0k/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
        mp4_size: '538564',
        mp4: 'https://media4.giphy.com/media/14y3bdRzH8aT0k/giphy.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.mp4&ct=g',
        webp_size: '315392',
        webp: 'https://media4.giphy.com/media/14y3bdRzH8aT0k/giphy.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.webp&ct=g',
        frames: '26',
        hash: 'd486d25d899546af901d35b90e49e10f',
      },
      downsized: {
        height: '296',
        width: '375',
        size: '775422',
        url: 'https://media4.giphy.com/media/14y3bdRzH8aT0k/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_large: {
        height: '296',
        width: '375',
        size: '775422',
        url: 'https://media4.giphy.com/media/14y3bdRzH8aT0k/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_medium: {
        height: '296',
        width: '375',
        size: '775422',
        url: 'https://media4.giphy.com/media/14y3bdRzH8aT0k/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_small: {
        height: '216',
        width: '272',
        mp4_size: '88088',
        mp4: 'https://media4.giphy.com/media/14y3bdRzH8aT0k/giphy-downsized-small.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-downsized-small.mp4&ct=g',
      },
      downsized_still: {
        height: '296',
        width: '375',
        size: '775422',
        url: 'https://media4.giphy.com/media/14y3bdRzH8aT0k/giphy_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy_s.gif&ct=g',
      },
      fixed_height: {
        height: '200',
        width: '253',
        size: '276621',
        url: 'https://media4.giphy.com/media/14y3bdRzH8aT0k/200.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.gif&ct=g',
        mp4_size: '134041',
        mp4: 'https://media4.giphy.com/media/14y3bdRzH8aT0k/200.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.mp4&ct=g',
        webp_size: '150674',
        webp: 'https://media4.giphy.com/media/14y3bdRzH8aT0k/200.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.webp&ct=g',
      },
      fixed_height_downsampled: {
        height: '200',
        width: '253',
        size: '69415',
        url: 'https://media4.giphy.com/media/14y3bdRzH8aT0k/200_d.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_d.gif&ct=g',
        webp_size: '63186',
        webp: 'https://media4.giphy.com/media/14y3bdRzH8aT0k/200_d.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_d.webp&ct=g',
      },
      fixed_height_small: {
        height: '100',
        width: '127',
        size: '93812',
        url: 'https://media4.giphy.com/media/14y3bdRzH8aT0k/100.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.gif&ct=g',
        mp4_size: '45483',
        mp4: 'https://media4.giphy.com/media/14y3bdRzH8aT0k/100.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.mp4&ct=g',
        webp_size: '60996',
        webp: 'https://media4.giphy.com/media/14y3bdRzH8aT0k/100.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.webp&ct=g',
      },
      fixed_height_small_still: {
        height: '100',
        width: '127',
        size: '3953',
        url: 'https://media4.giphy.com/media/14y3bdRzH8aT0k/100_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100_s.gif&ct=g',
      },
      fixed_height_still: {
        height: '200',
        width: '253',
        size: '11290',
        url: 'https://media4.giphy.com/media/14y3bdRzH8aT0k/200_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_s.gif&ct=g',
      },
      fixed_width: {
        height: '158',
        width: '200',
        size: '189786',
        url: 'https://media4.giphy.com/media/14y3bdRzH8aT0k/200w.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.gif&ct=g',
        mp4_size: '90638',
        mp4: 'https://media4.giphy.com/media/14y3bdRzH8aT0k/200w.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.mp4&ct=g',
        webp_size: '110520',
        webp: 'https://media4.giphy.com/media/14y3bdRzH8aT0k/200w.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.webp&ct=g',
      },
      fixed_width_downsampled: {
        height: '158',
        width: '200',
        size: '46701',
        url: 'https://media4.giphy.com/media/14y3bdRzH8aT0k/200w_d.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_d.gif&ct=g',
        webp_size: '41952',
        webp: 'https://media4.giphy.com/media/14y3bdRzH8aT0k/200w_d.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_d.webp&ct=g',
      },
      fixed_width_small: {
        height: '79',
        width: '100',
        size: '63981',
        url: 'https://media4.giphy.com/media/14y3bdRzH8aT0k/100w.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.gif&ct=g',
        mp4_size: '31490',
        mp4: 'https://media4.giphy.com/media/14y3bdRzH8aT0k/100w.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.mp4&ct=g',
        webp_size: '45154',
        webp: 'https://media4.giphy.com/media/14y3bdRzH8aT0k/100w.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.webp&ct=g',
      },
      fixed_width_small_still: {
        height: '79',
        width: '100',
        size: '2778',
        url: 'https://media4.giphy.com/media/14y3bdRzH8aT0k/100w_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w_s.gif&ct=g',
      },
      fixed_width_still: {
        height: '158',
        width: '200',
        size: '7754',
        url: 'https://media4.giphy.com/media/14y3bdRzH8aT0k/200w_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_s.gif&ct=g',
      },
      looping: {
        mp4_size: '2408334',
        mp4: 'https://media4.giphy.com/media/14y3bdRzH8aT0k/giphy-loop.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-loop.mp4&ct=g',
      },
      original_still: {
        height: '296',
        width: '375',
        size: '35190',
        url: 'https://media4.giphy.com/media/14y3bdRzH8aT0k/giphy_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy_s.gif&ct=g',
      },
      original_mp4: {
        height: '378',
        width: '480',
        mp4_size: '538564',
        mp4: 'https://media4.giphy.com/media/14y3bdRzH8aT0k/giphy.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.mp4&ct=g',
      },
      preview: {
        height: '188',
        width: '237',
        mp4_size: '41942',
        mp4: 'https://media4.giphy.com/media/14y3bdRzH8aT0k/giphy-preview.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.mp4&ct=g',
      },
      preview_gif: {
        height: '94',
        width: '119',
        size: '49792',
        url: 'https://media4.giphy.com/media/14y3bdRzH8aT0k/giphy-preview.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.gif&ct=g',
      },
      preview_webp: {
        height: '154',
        width: '196',
        size: '43334',
        url: 'https://media4.giphy.com/media/14y3bdRzH8aT0k/giphy-preview.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.webp&ct=g',
      },
      '480w_still': {
        height: '379',
        width: '480',
        size: '775422',
        url: 'https://media4.giphy.com/media/14y3bdRzH8aT0k/480w_s.jpg?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=480w_s.jpg&ct=g',
      },
    },
    analytics_response_payload:
      'e=Z2lmX2lkPTE0eTNiZFJ6SDhhVDBrJmV2ZW50X3R5cGU9R0lGX1NFQVJDSCZjaWQ9OWYwZjY0MjV5NWoxNHRjNjEwcW8xNHBybGdwNHUxaHloMHBmdzY4ZDE5eWQ5M3MzJmN0PWc',
    analytics: {
      onload: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPTE0eTNiZFJ6SDhhVDBrJmV2ZW50X3R5cGU9R0lGX1NFQVJDSCZjaWQ9OWYwZjY0MjV5NWoxNHRjNjEwcW8xNHBybGdwNHUxaHloMHBmdzY4ZDE5eWQ5M3MzJmN0PWc&action_type=SEEN',
      },
      onclick: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPTE0eTNiZFJ6SDhhVDBrJmV2ZW50X3R5cGU9R0lGX1NFQVJDSCZjaWQ9OWYwZjY0MjV5NWoxNHRjNjEwcW8xNHBybGdwNHUxaHloMHBmdzY4ZDE5eWQ5M3MzJmN0PWc&action_type=CLICK',
      },
      onsent: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPTE0eTNiZFJ6SDhhVDBrJmV2ZW50X3R5cGU9R0lGX1NFQVJDSCZjaWQ9OWYwZjY0MjV5NWoxNHRjNjEwcW8xNHBybGdwNHUxaHloMHBmdzY4ZDE5eWQ5M3MzJmN0PWc&action_type=SENT',
      },
    },
  },
  {
    type: 'gif',
    id: 'ITnn2IenMv7SE',
    url: 'https://giphy.com/gifs/catwoman-leaving-ITnn2IenMv7SE',
    slug: 'catwoman-leaving-ITnn2IenMv7SE',
    bitly_gif_url: 'http://gph.is/Qe4diS',
    bitly_url: 'http://gph.is/Qe4diS',
    embed_url: 'https://giphy.com/embed/ITnn2IenMv7SE',
    username: '',
    source:
      'http://www.reddit.com/r/gifs/comments/22shmp/batman_leaving_catwomans_place/',
    title: 'Happy Batman GIF',
    rating: 'g',
    content_url: '',
    source_tld: 'www.reddit.com',
    source_post_url:
      'http://www.reddit.com/r/gifs/comments/22shmp/batman_leaving_catwomans_place/',
    is_sticker: 0,
    import_datetime: '2014-04-11 18:51:56',
    trending_datetime: '2014-11-12 16:22:52',
    images: {
      original: {
        height: '258',
        width: '480',
        size: '3054611',
        url: 'https://media0.giphy.com/media/ITnn2IenMv7SE/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
        mp4_size: '330355',
        mp4: 'https://media0.giphy.com/media/ITnn2IenMv7SE/giphy.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.mp4&ct=g',
        webp_size: '431076',
        webp: 'https://media0.giphy.com/media/ITnn2IenMv7SE/giphy.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.webp&ct=g',
        frames: '81',
        hash: 'b39a8d0c30e346342c6f45407b9a1884',
      },
      downsized: {
        height: '258',
        width: '480',
        size: '1709120',
        url: 'https://media0.giphy.com/media/ITnn2IenMv7SE/giphy-downsized.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-downsized.gif&ct=g',
      },
      downsized_large: {
        height: '258',
        width: '480',
        size: '3054611',
        url: 'https://media0.giphy.com/media/ITnn2IenMv7SE/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_medium: {
        height: '258',
        width: '480',
        size: '1953362',
        url: 'https://media0.giphy.com/media/ITnn2IenMv7SE/giphy-downsized-medium.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-downsized-medium.gif&ct=g',
      },
      downsized_small: {
        height: '192',
        width: '357',
        mp4_size: '91388',
        mp4: 'https://media0.giphy.com/media/ITnn2IenMv7SE/giphy-downsized-small.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-downsized-small.mp4&ct=g',
      },
      downsized_still: {
        height: '258',
        width: '480',
        size: '23022',
        url: 'https://media0.giphy.com/media/ITnn2IenMv7SE/giphy-downsized_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-downsized_s.gif&ct=g',
      },
      fixed_height: {
        height: '200',
        width: '372',
        size: '1335818',
        url: 'https://media0.giphy.com/media/ITnn2IenMv7SE/200.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.gif&ct=g',
        mp4_size: '186733',
        mp4: 'https://media0.giphy.com/media/ITnn2IenMv7SE/200.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.mp4&ct=g',
        webp_size: '278464',
        webp: 'https://media0.giphy.com/media/ITnn2IenMv7SE/200.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.webp&ct=g',
      },
      fixed_height_downsampled: {
        height: '200',
        width: '372',
        size: '108993',
        url: 'https://media0.giphy.com/media/ITnn2IenMv7SE/200_d.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_d.gif&ct=g',
        webp_size: '66444',
        webp: 'https://media0.giphy.com/media/ITnn2IenMv7SE/200_d.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_d.webp&ct=g',
      },
      fixed_height_small: {
        height: '100',
        width: '186',
        size: '435813',
        url: 'https://media0.giphy.com/media/ITnn2IenMv7SE/100.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.gif&ct=g',
        mp4_size: '73057',
        mp4: 'https://media0.giphy.com/media/ITnn2IenMv7SE/100.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.mp4&ct=g',
        webp_size: '119328',
        webp: 'https://media0.giphy.com/media/ITnn2IenMv7SE/100.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.webp&ct=g',
      },
      fixed_height_small_still: {
        height: '100',
        width: '186',
        size: '6307',
        url: 'https://media0.giphy.com/media/ITnn2IenMv7SE/100_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100_s.gif&ct=g',
      },
      fixed_height_still: {
        height: '200',
        width: '372',
        size: '18233',
        url: 'https://media0.giphy.com/media/ITnn2IenMv7SE/200_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_s.gif&ct=g',
      },
      fixed_width: {
        height: '108',
        width: '200',
        size: '484956',
        url: 'https://media0.giphy.com/media/ITnn2IenMv7SE/200w.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.gif&ct=g',
        mp4_size: '78424',
        mp4: 'https://media0.giphy.com/media/ITnn2IenMv7SE/200w.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.mp4&ct=g',
        webp_size: '129510',
        webp: 'https://media0.giphy.com/media/ITnn2IenMv7SE/200w.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.webp&ct=g',
      },
      fixed_width_downsampled: {
        height: '108',
        width: '200',
        size: '38432',
        url: 'https://media0.giphy.com/media/ITnn2IenMv7SE/200w_d.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_d.gif&ct=g',
        webp_size: '25472',
        webp: 'https://media0.giphy.com/media/ITnn2IenMv7SE/200w_d.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_d.webp&ct=g',
      },
      fixed_width_small: {
        height: '54',
        width: '100',
        size: '177214',
        url: 'https://media0.giphy.com/media/ITnn2IenMv7SE/100w.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.gif&ct=g',
        mp4_size: '32960',
        mp4: 'https://media0.giphy.com/media/ITnn2IenMv7SE/100w.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.mp4&ct=g',
        webp_size: '55284',
        webp: 'https://media0.giphy.com/media/ITnn2IenMv7SE/100w.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.webp&ct=g',
      },
      fixed_width_small_still: {
        height: '54',
        width: '100',
        size: '3039',
        url: 'https://media0.giphy.com/media/ITnn2IenMv7SE/100w_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w_s.gif&ct=g',
      },
      fixed_width_still: {
        height: '108',
        width: '200',
        size: '8053',
        url: 'https://media0.giphy.com/media/ITnn2IenMv7SE/200w_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_s.gif&ct=g',
      },
      looping: {
        mp4_size: '1858747',
        mp4: 'https://media0.giphy.com/media/ITnn2IenMv7SE/giphy-loop.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-loop.mp4&ct=g',
      },
      original_still: {
        height: '258',
        width: '480',
        size: '78773',
        url: 'https://media0.giphy.com/media/ITnn2IenMv7SE/giphy_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy_s.gif&ct=g',
      },
      original_mp4: {
        height: '258',
        width: '480',
        mp4_size: '330355',
        mp4: 'https://media0.giphy.com/media/ITnn2IenMv7SE/giphy.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.mp4&ct=g',
      },
      preview: {
        height: '188',
        width: '349',
        mp4_size: '37522',
        mp4: 'https://media0.giphy.com/media/ITnn2IenMv7SE/giphy-preview.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.mp4&ct=g',
      },
      preview_gif: {
        height: '54',
        width: '100',
        size: '48730',
        url: 'https://media0.giphy.com/media/ITnn2IenMv7SE/giphy-preview.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.gif&ct=g',
      },
      preview_webp: {
        height: '122',
        width: '226',
        size: '39294',
        url: 'https://media0.giphy.com/media/ITnn2IenMv7SE/giphy-preview.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.webp&ct=g',
      },
      '480w_still': {
        height: '258',
        width: '480',
        size: '3054611',
        url: 'https://media0.giphy.com/media/ITnn2IenMv7SE/480w_s.jpg?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=480w_s.jpg&ct=g',
      },
    },
    analytics_response_payload:
      'e=Z2lmX2lkPUlUbm4ySWVuTXY3U0UmZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw',
    analytics: {
      onload: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPUlUbm4ySWVuTXY3U0UmZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw&action_type=SEEN',
      },
      onclick: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPUlUbm4ySWVuTXY3U0UmZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw&action_type=CLICK',
      },
      onsent: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPUlUbm4ySWVuTXY3U0UmZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw&action_type=SENT',
      },
    },
  },
  {
    type: 'gif',
    id: 'a5viI92PAF89q',
    url: 'https://giphy.com/gifs/reaction-a5viI92PAF89q',
    slug: 'reaction-a5viI92PAF89q',
    bitly_gif_url: 'http://gph.is/Yo8Ryn',
    bitly_url: 'http://gph.is/Yo8Ryn',
    embed_url: 'https://giphy.com/embed/a5viI92PAF89q',
    username: '',
    source:
      'http://www.reddit.com/r/reactiongifs/comments/2hgu34/mrw_ive_just_shaved_and_missed_the_warmth/',
    title: 'Meme Think GIF',
    rating: 'g',
    content_url: '',
    source_tld: 'www.reddit.com',
    source_post_url:
      'http://www.reddit.com/r/reactiongifs/comments/2hgu34/mrw_ive_just_shaved_and_missed_the_warmth/',
    is_sticker: 0,
    import_datetime: '2014-09-27 06:00:02',
    trending_datetime: '2021-04-08 20:45:12',
    images: {
      original: {
        height: '345',
        width: '500',
        size: '371164',
        url: 'https://media1.giphy.com/media/a5viI92PAF89q/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
        mp4_size: '92993',
        mp4: 'https://media1.giphy.com/media/a5viI92PAF89q/giphy.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.mp4&ct=g',
        webp_size: '128916',
        webp: 'https://media1.giphy.com/media/a5viI92PAF89q/giphy.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.webp&ct=g',
        frames: '11',
        hash: '71d0f336024f8880c6c792b38da6c561',
      },
      downsized: {
        height: '345',
        width: '500',
        size: '371164',
        url: 'https://media1.giphy.com/media/a5viI92PAF89q/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_large: {
        height: '345',
        width: '500',
        size: '371164',
        url: 'https://media1.giphy.com/media/a5viI92PAF89q/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_medium: {
        height: '345',
        width: '500',
        size: '371164',
        url: 'https://media1.giphy.com/media/a5viI92PAF89q/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_small: {
        height: '344',
        width: '500',
        mp4_size: '118796',
        mp4: 'https://media1.giphy.com/media/a5viI92PAF89q/giphy-downsized-small.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-downsized-small.mp4&ct=g',
      },
      downsized_still: {
        height: '345',
        width: '500',
        size: '371164',
        url: 'https://media1.giphy.com/media/a5viI92PAF89q/giphy_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy_s.gif&ct=g',
      },
      fixed_height: {
        height: '200',
        width: '290',
        size: '121337',
        url: 'https://media1.giphy.com/media/a5viI92PAF89q/200.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.gif&ct=g',
        mp4_size: '26723',
        mp4: 'https://media1.giphy.com/media/a5viI92PAF89q/200.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.mp4&ct=g',
        webp_size: '49730',
        webp: 'https://media1.giphy.com/media/a5viI92PAF89q/200.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.webp&ct=g',
      },
      fixed_height_downsampled: {
        height: '200',
        width: '290',
        size: '70814',
        url: 'https://media1.giphy.com/media/a5viI92PAF89q/200_d.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_d.gif&ct=g',
        webp_size: '38494',
        webp: 'https://media1.giphy.com/media/a5viI92PAF89q/200_d.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_d.webp&ct=g',
      },
      fixed_height_small: {
        height: '100',
        width: '145',
        size: '43883',
        url: 'https://media1.giphy.com/media/a5viI92PAF89q/100.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.gif&ct=g',
        mp4_size: '10654',
        mp4: 'https://media1.giphy.com/media/a5viI92PAF89q/100.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.mp4&ct=g',
        webp_size: '25542',
        webp: 'https://media1.giphy.com/media/a5viI92PAF89q/100.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.webp&ct=g',
      },
      fixed_height_small_still: {
        height: '100',
        width: '145',
        size: '5566',
        url: 'https://media1.giphy.com/media/a5viI92PAF89q/100_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100_s.gif&ct=g',
      },
      fixed_height_still: {
        height: '200',
        width: '290',
        size: '13652',
        url: 'https://media1.giphy.com/media/a5viI92PAF89q/200_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_s.gif&ct=g',
      },
      fixed_width: {
        height: '138',
        width: '200',
        size: '70067',
        url: 'https://media1.giphy.com/media/a5viI92PAF89q/200w.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.gif&ct=g',
        mp4_size: '15339',
        mp4: 'https://media1.giphy.com/media/a5viI92PAF89q/200w.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.mp4&ct=g',
        webp_size: '34732',
        webp: 'https://media1.giphy.com/media/a5viI92PAF89q/200w.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.webp&ct=g',
      },
      fixed_width_downsampled: {
        height: '138',
        width: '200',
        size: '38184',
        url: 'https://media1.giphy.com/media/a5viI92PAF89q/200w_d.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_d.gif&ct=g',
        webp_size: '24148',
        webp: 'https://media1.giphy.com/media/a5viI92PAF89q/200w_d.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_d.webp&ct=g',
      },
      fixed_width_small: {
        height: '69',
        width: '100',
        size: '25650',
        url: 'https://media1.giphy.com/media/a5viI92PAF89q/100w.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.gif&ct=g',
        mp4_size: '7064',
        mp4: 'https://media1.giphy.com/media/a5viI92PAF89q/100w.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.mp4&ct=g',
        webp_size: '18758',
        webp: 'https://media1.giphy.com/media/a5viI92PAF89q/100w.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.webp&ct=g',
      },
      fixed_width_small_still: {
        height: '69',
        width: '100',
        size: '3714',
        url: 'https://media1.giphy.com/media/a5viI92PAF89q/100w_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w_s.gif&ct=g',
      },
      fixed_width_still: {
        height: '138',
        width: '200',
        size: '10450',
        url: 'https://media1.giphy.com/media/a5viI92PAF89q/200w_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_s.gif&ct=g',
      },
      looping: {
        mp4_size: '1045147',
        mp4: 'https://media1.giphy.com/media/a5viI92PAF89q/giphy-loop.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-loop.mp4&ct=g',
      },
      original_still: {
        height: '345',
        width: '500',
        size: '56337',
        url: 'https://media1.giphy.com/media/a5viI92PAF89q/giphy_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy_s.gif&ct=g',
      },
      original_mp4: {
        height: '330',
        width: '480',
        mp4_size: '92993',
        mp4: 'https://media1.giphy.com/media/a5viI92PAF89q/giphy.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.mp4&ct=g',
      },
      preview: {
        height: '222',
        width: '322',
        mp4_size: '16692',
        mp4: 'https://media1.giphy.com/media/a5viI92PAF89q/giphy-preview.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.mp4&ct=g',
      },
      preview_gif: {
        height: '69',
        width: '100',
        size: '49806',
        url: 'https://media1.giphy.com/media/a5viI92PAF89q/giphy-preview.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.gif&ct=g',
      },
      preview_webp: {
        height: '184',
        width: '266',
        size: '41772',
        url: 'https://media1.giphy.com/media/a5viI92PAF89q/giphy-preview.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.webp&ct=g',
      },
      '480w_still': {
        height: '331',
        width: '480',
        size: '371164',
        url: 'https://media1.giphy.com/media/a5viI92PAF89q/480w_s.jpg?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=480w_s.jpg&ct=g',
      },
    },
    analytics_response_payload:
      'e=Z2lmX2lkPWE1dmlJOTJQQUY4OXEmZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw',
    analytics: {
      onload: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPWE1dmlJOTJQQUY4OXEmZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw&action_type=SEEN',
      },
      onclick: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPWE1dmlJOTJQQUY4OXEmZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw&action_type=CLICK',
      },
      onsent: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPWE1dmlJOTJQQUY4OXEmZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw&action_type=SENT',
      },
    },
  },
  {
    type: 'gif',
    id: '1485wMdlDLG7mg',
    url: 'https://giphy.com/gifs/1485wMdlDLG7mg',
    slug: '1485wMdlDLG7mg',
    bitly_gif_url: 'http://gph.is/28OkZ2s',
    bitly_url: 'http://gph.is/28OkZ2s',
    embed_url: 'https://giphy.com/embed/1485wMdlDLG7mg',
    username: '',
    source: 'http://imgur.com/gallery/v0DZFa4',
    title: 'batman robin GIF',
    rating: 'g',
    content_url: '',
    source_tld: 'imgur.com',
    source_post_url: 'http://imgur.com/gallery/v0DZFa4',
    is_sticker: 0,
    import_datetime: '2016-06-22 19:14:14',
    trending_datetime: '1970-01-01 00:00:00',
    images: {
      original: {
        height: '286',
        width: '500',
        size: '945025',
        url: 'https://media0.giphy.com/media/1485wMdlDLG7mg/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
        mp4_size: '501050',
        mp4: 'https://media0.giphy.com/media/1485wMdlDLG7mg/giphy.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.mp4&ct=g',
        webp_size: '477356',
        webp: 'https://media0.giphy.com/media/1485wMdlDLG7mg/giphy.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.webp&ct=g',
        frames: '13',
        hash: '4a312b0413ce34b761d3ab2127b09151',
      },
      downsized: {
        height: '286',
        width: '500',
        size: '945025',
        url: 'https://media0.giphy.com/media/1485wMdlDLG7mg/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_large: {
        height: '286',
        width: '500',
        size: '945025',
        url: 'https://media0.giphy.com/media/1485wMdlDLG7mg/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_medium: {
        height: '286',
        width: '500',
        size: '945025',
        url: 'https://media0.giphy.com/media/1485wMdlDLG7mg/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_small: {
        height: '156',
        width: '272',
        mp4_size: '56707',
        mp4: 'https://media0.giphy.com/media/1485wMdlDLG7mg/giphy-downsized-small.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-downsized-small.mp4&ct=g',
      },
      downsized_still: {
        height: '286',
        width: '500',
        size: '945025',
        url: 'https://media0.giphy.com/media/1485wMdlDLG7mg/giphy_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy_s.gif&ct=g',
      },
      fixed_height: {
        height: '200',
        width: '350',
        size: '382050',
        url: 'https://media0.giphy.com/media/1485wMdlDLG7mg/200.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.gif&ct=g',
        mp4_size: '163573',
        mp4: 'https://media0.giphy.com/media/1485wMdlDLG7mg/200.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.mp4&ct=g',
        webp_size: '165652',
        webp: 'https://media0.giphy.com/media/1485wMdlDLG7mg/200.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.webp&ct=g',
      },
      fixed_height_downsampled: {
        height: '200',
        width: '350',
        size: '202847',
        url: 'https://media0.giphy.com/media/1485wMdlDLG7mg/200_d.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_d.gif&ct=g',
        webp_size: '104990',
        webp: 'https://media0.giphy.com/media/1485wMdlDLG7mg/200_d.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_d.webp&ct=g',
      },
      fixed_height_small: {
        height: '100',
        width: '175',
        size: '132401',
        url: 'https://media0.giphy.com/media/1485wMdlDLG7mg/100.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.gif&ct=g',
        mp4_size: '48317',
        mp4: 'https://media0.giphy.com/media/1485wMdlDLG7mg/100.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.mp4&ct=g',
        webp_size: '59932',
        webp: 'https://media0.giphy.com/media/1485wMdlDLG7mg/100.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.webp&ct=g',
      },
      fixed_height_small_still: {
        height: '100',
        width: '175',
        size: '11251',
        url: 'https://media0.giphy.com/media/1485wMdlDLG7mg/100_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100_s.gif&ct=g',
      },
      fixed_height_still: {
        height: '200',
        width: '350',
        size: '30985',
        url: 'https://media0.giphy.com/media/1485wMdlDLG7mg/200_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_s.gif&ct=g',
      },
      fixed_width: {
        height: '114',
        width: '200',
        size: '185243',
        url: 'https://media0.giphy.com/media/1485wMdlDLG7mg/200w.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.gif&ct=g',
        mp4_size: '60650',
        mp4: 'https://media0.giphy.com/media/1485wMdlDLG7mg/200w.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.mp4&ct=g',
        webp_size: '72056',
        webp: 'https://media0.giphy.com/media/1485wMdlDLG7mg/200w.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.webp&ct=g',
      },
      fixed_width_downsampled: {
        height: '114',
        width: '200',
        size: '82971',
        url: 'https://media0.giphy.com/media/1485wMdlDLG7mg/200w_d.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_d.gif&ct=g',
        webp_size: '41348',
        webp: 'https://media0.giphy.com/media/1485wMdlDLG7mg/200w_d.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_d.webp&ct=g',
      },
      fixed_width_small: {
        height: '57',
        width: '100',
        size: '46856',
        url: 'https://media0.giphy.com/media/1485wMdlDLG7mg/100w.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.gif&ct=g',
        mp4_size: '22714',
        mp4: 'https://media0.giphy.com/media/1485wMdlDLG7mg/100w.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.mp4&ct=g',
        webp_size: '28064',
        webp: 'https://media0.giphy.com/media/1485wMdlDLG7mg/100w.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.webp&ct=g',
      },
      fixed_width_small_still: {
        height: '57',
        width: '100',
        size: '4392',
        url: 'https://media0.giphy.com/media/1485wMdlDLG7mg/100w_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w_s.gif&ct=g',
      },
      fixed_width_still: {
        height: '114',
        width: '200',
        size: '13877',
        url: 'https://media0.giphy.com/media/1485wMdlDLG7mg/200w_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_s.gif&ct=g',
      },
      looping: {
        mp4_size: '2828831',
        mp4: 'https://media0.giphy.com/media/1485wMdlDLG7mg/giphy-loop.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-loop.mp4&ct=g',
      },
      original_still: {
        height: '286',
        width: '500',
        size: '79292',
        url: 'https://media0.giphy.com/media/1485wMdlDLG7mg/giphy_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy_s.gif&ct=g',
      },
      original_mp4: {
        height: '274',
        width: '480',
        mp4_size: '501050',
        mp4: 'https://media0.giphy.com/media/1485wMdlDLG7mg/giphy.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.mp4&ct=g',
      },
      preview: {
        height: '114',
        width: '199',
        mp4_size: '36377',
        mp4: 'https://media0.giphy.com/media/1485wMdlDLG7mg/giphy-preview.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.mp4&ct=g',
      },
      preview_gif: {
        height: '68',
        width: '119',
        size: '49803',
        url: 'https://media0.giphy.com/media/1485wMdlDLG7mg/giphy-preview.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.gif&ct=g',
      },
      preview_webp: {
        height: '90',
        width: '158',
        size: '28394',
        url: 'https://media0.giphy.com/media/1485wMdlDLG7mg/giphy-preview.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.webp&ct=g',
      },
      '480w_still': {
        height: '275',
        width: '480',
        size: '945025',
        url: 'https://media0.giphy.com/media/1485wMdlDLG7mg/480w_s.jpg?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=480w_s.jpg&ct=g',
      },
    },
    analytics_response_payload:
      'e=Z2lmX2lkPTE0ODV3TWRsRExHN21nJmV2ZW50X3R5cGU9R0lGX1NFQVJDSCZjaWQ9OWYwZjY0MjV5NWoxNHRjNjEwcW8xNHBybGdwNHUxaHloMHBmdzY4ZDE5eWQ5M3MzJmN0PWc',
    analytics: {
      onload: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPTE0ODV3TWRsRExHN21nJmV2ZW50X3R5cGU9R0lGX1NFQVJDSCZjaWQ9OWYwZjY0MjV5NWoxNHRjNjEwcW8xNHBybGdwNHUxaHloMHBmdzY4ZDE5eWQ5M3MzJmN0PWc&action_type=SEEN',
      },
      onclick: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPTE0ODV3TWRsRExHN21nJmV2ZW50X3R5cGU9R0lGX1NFQVJDSCZjaWQ9OWYwZjY0MjV5NWoxNHRjNjEwcW8xNHBybGdwNHUxaHloMHBmdzY4ZDE5eWQ5M3MzJmN0PWc&action_type=CLICK',
      },
      onsent: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPTE0ODV3TWRsRExHN21nJmV2ZW50X3R5cGU9R0lGX1NFQVJDSCZjaWQ9OWYwZjY0MjV5NWoxNHRjNjEwcW8xNHBybGdwNHUxaHloMHBmdzY4ZDE5eWQ5M3MzJmN0PWc&action_type=SENT',
      },
    },
  },
  {
    type: 'gif',
    id: 'L7p9lHy7u2QGk',
    url: 'https://giphy.com/gifs/batman-scary-movie-L7p9lHy7u2QGk',
    slug: 'batman-scary-movie-L7p9lHy7u2QGk',
    bitly_gif_url: 'http://gph.is/1iUdwxO',
    bitly_url: 'http://gph.is/1iUdwxO',
    embed_url: 'https://giphy.com/embed/L7p9lHy7u2QGk',
    username: '',
    source: 'http://www.tumblr.com',
    title: 'Batman Dancing GIF',
    rating: 'pg-13',
    content_url: '',
    source_tld: 'www.tumblr.com',
    source_post_url: 'http://www.tumblr.com',
    is_sticker: 0,
    import_datetime: '2014-02-14 03:42:53',
    trending_datetime: '2015-03-18 18:59:36',
    images: {
      original: {
        height: '298',
        width: '328',
        size: '891421',
        url: 'https://media0.giphy.com/media/L7p9lHy7u2QGk/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
        mp4_size: '501002',
        mp4: 'https://media0.giphy.com/media/L7p9lHy7u2QGk/giphy.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.mp4&ct=g',
        webp_size: '246200',
        webp: 'https://media0.giphy.com/media/L7p9lHy7u2QGk/giphy.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.webp&ct=g',
        frames: '29',
        hash: '130ddce27a09f436a17d6aa46b2756e6',
      },
      downsized: {
        height: '298',
        width: '328',
        size: '891421',
        url: 'https://media0.giphy.com/media/L7p9lHy7u2QGk/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_large: {
        height: '298',
        width: '328',
        size: '891421',
        url: 'https://media0.giphy.com/media/L7p9lHy7u2QGk/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_medium: {
        height: '298',
        width: '328',
        size: '891421',
        url: 'https://media0.giphy.com/media/L7p9lHy7u2QGk/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_small: {
        height: '258',
        width: '284',
        mp4_size: '57038',
        mp4: 'https://media0.giphy.com/media/L7p9lHy7u2QGk/giphy-downsized-small.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-downsized-small.mp4&ct=g',
      },
      downsized_still: {
        height: '298',
        width: '328',
        size: '891421',
        url: 'https://media0.giphy.com/media/L7p9lHy7u2QGk/giphy_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy_s.gif&ct=g',
      },
      fixed_height: {
        height: '200',
        width: '220',
        size: '341477',
        url: 'https://media0.giphy.com/media/L7p9lHy7u2QGk/200.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.gif&ct=g',
        mp4_size: '78561',
        mp4: 'https://media0.giphy.com/media/L7p9lHy7u2QGk/200.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.mp4&ct=g',
        webp_size: '112316',
        webp: 'https://media0.giphy.com/media/L7p9lHy7u2QGk/200.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.webp&ct=g',
      },
      fixed_height_downsampled: {
        height: '200',
        width: '220',
        size: '79376',
        url: 'https://media0.giphy.com/media/L7p9lHy7u2QGk/200_d.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_d.gif&ct=g',
        webp_size: '34432',
        webp: 'https://media0.giphy.com/media/L7p9lHy7u2QGk/200_d.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_d.webp&ct=g',
      },
      fixed_height_small: {
        height: '100',
        width: '110',
        size: '111361',
        url: 'https://media0.giphy.com/media/L7p9lHy7u2QGk/100.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.gif&ct=g',
        mp4_size: '25527',
        mp4: 'https://media0.giphy.com/media/L7p9lHy7u2QGk/100.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.mp4&ct=g',
        webp_size: '46262',
        webp: 'https://media0.giphy.com/media/L7p9lHy7u2QGk/100.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.webp&ct=g',
      },
      fixed_height_small_still: {
        height: '100',
        width: '110',
        size: '4682',
        url: 'https://media0.giphy.com/media/L7p9lHy7u2QGk/100_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100_s.gif&ct=g',
      },
      fixed_height_still: {
        height: '200',
        width: '220',
        size: '12787',
        url: 'https://media0.giphy.com/media/L7p9lHy7u2QGk/200_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_s.gif&ct=g',
      },
      fixed_width: {
        height: '182',
        width: '200',
        size: '322736',
        url: 'https://media0.giphy.com/media/L7p9lHy7u2QGk/200w.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.gif&ct=g',
        mp4_size: '67120',
        mp4: 'https://media0.giphy.com/media/L7p9lHy7u2QGk/200w.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.mp4&ct=g',
        webp_size: '98268',
        webp: 'https://media0.giphy.com/media/L7p9lHy7u2QGk/200w.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.webp&ct=g',
      },
      fixed_width_downsampled: {
        height: '182',
        width: '200',
        size: '69152',
        url: 'https://media0.giphy.com/media/L7p9lHy7u2QGk/200w_d.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_d.gif&ct=g',
        webp_size: '31646',
        webp: 'https://media0.giphy.com/media/L7p9lHy7u2QGk/200w_d.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_d.webp&ct=g',
      },
      fixed_width_small: {
        height: '91',
        width: '100',
        size: '98695',
        url: 'https://media0.giphy.com/media/L7p9lHy7u2QGk/100w.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.gif&ct=g',
        mp4_size: '22355',
        mp4: 'https://media0.giphy.com/media/L7p9lHy7u2QGk/100w.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.mp4&ct=g',
        webp_size: '40836',
        webp: 'https://media0.giphy.com/media/L7p9lHy7u2QGk/100w.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.webp&ct=g',
      },
      fixed_width_small_still: {
        height: '91',
        width: '100',
        size: '4217',
        url: 'https://media0.giphy.com/media/L7p9lHy7u2QGk/100w_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w_s.gif&ct=g',
      },
      fixed_width_still: {
        height: '182',
        width: '200',
        size: '15523',
        url: 'https://media0.giphy.com/media/L7p9lHy7u2QGk/200w_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_s.gif&ct=g',
      },
      looping: {
        mp4_size: '1558041',
        mp4: 'https://media0.giphy.com/media/L7p9lHy7u2QGk/giphy-loop.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-loop.mp4&ct=g',
      },
      original_still: {
        height: '298',
        width: '328',
        size: '49448',
        url: 'https://media0.giphy.com/media/L7p9lHy7u2QGk/giphy_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy_s.gif&ct=g',
      },
      original_mp4: {
        height: '436',
        width: '480',
        mp4_size: '501002',
        mp4: 'https://media0.giphy.com/media/L7p9lHy7u2QGk/giphy.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.mp4&ct=g',
      },
      preview: {
        height: '238',
        width: '261',
        mp4_size: '33417',
        mp4: 'https://media0.giphy.com/media/L7p9lHy7u2QGk/giphy-preview.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.mp4&ct=g',
      },
      preview_gif: {
        height: '80',
        width: '88',
        size: '49525',
        url: 'https://media0.giphy.com/media/L7p9lHy7u2QGk/giphy-preview.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.gif&ct=g',
      },
      preview_webp: {
        height: '234',
        width: '258',
        size: '38370',
        url: 'https://media0.giphy.com/media/L7p9lHy7u2QGk/giphy-preview.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.webp&ct=g',
      },
      '480w_still': {
        height: '436',
        width: '480',
        size: '891421',
        url: 'https://media0.giphy.com/media/L7p9lHy7u2QGk/480w_s.jpg?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=480w_s.jpg&ct=g',
      },
    },
    analytics_response_payload:
      'e=Z2lmX2lkPUw3cDlsSHk3dTJRR2smZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw',
    analytics: {
      onload: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPUw3cDlsSHk3dTJRR2smZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw&action_type=SEEN',
      },
      onclick: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPUw3cDlsSHk3dTJRR2smZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw&action_type=CLICK',
      },
      onsent: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPUw3cDlsSHk3dTJRR2smZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw&action_type=SENT',
      },
    },
  },
  {
    type: 'gif',
    id: 'qVID3J8fLrlZK',
    url: 'https://giphy.com/gifs/batman-film-qVID3J8fLrlZK',
    slug: 'batman-film-qVID3J8fLrlZK',
    bitly_gif_url: 'http://gph.is/1cLSAH8',
    bitly_url: 'http://gph.is/1cLSAH8',
    embed_url: 'https://giphy.com/embed/qVID3J8fLrlZK',
    username: '',
    source:
      'http://narcissusskisses.tumblr.com/post/57564403315/batman-running-away-from-shit-that-design-bastard',
    title: 'batman and robin running GIF',
    rating: 'g',
    content_url: '',
    source_tld: 'narcissusskisses.tumblr.com',
    source_post_url:
      'http://narcissusskisses.tumblr.com/post/57564403315/batman-running-away-from-shit-that-design-bastard',
    is_sticker: 0,
    import_datetime: '2013-08-08 02:39:20',
    trending_datetime: '1970-01-01 00:00:00',
    images: {
      original: {
        height: '269',
        width: '500',
        size: '874128',
        url: 'https://media4.giphy.com/media/qVID3J8fLrlZK/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
        mp4_size: '268844',
        mp4: 'https://media4.giphy.com/media/qVID3J8fLrlZK/giphy.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.mp4&ct=g',
        webp_size: '306670',
        webp: 'https://media4.giphy.com/media/qVID3J8fLrlZK/giphy.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.webp&ct=g',
        frames: '13',
        hash: '5c10462d4ea5926f20d0f3dc3e5374bd',
      },
      downsized: {
        height: '269',
        width: '500',
        size: '874128',
        url: 'https://media4.giphy.com/media/qVID3J8fLrlZK/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_large: {
        height: '269',
        width: '500',
        size: '874128',
        url: 'https://media4.giphy.com/media/qVID3J8fLrlZK/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_medium: {
        height: '269',
        width: '500',
        size: '874128',
        url: 'https://media4.giphy.com/media/qVID3J8fLrlZK/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_small: {
        height: '220',
        width: '410',
        mp4_size: '101962',
        mp4: 'https://media4.giphy.com/media/qVID3J8fLrlZK/giphy-downsized-small.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-downsized-small.mp4&ct=g',
      },
      downsized_still: {
        height: '269',
        width: '500',
        size: '874128',
        url: 'https://media4.giphy.com/media/qVID3J8fLrlZK/giphy_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy_s.gif&ct=g',
      },
      fixed_height: {
        height: '200',
        width: '372',
        size: '422142',
        url: 'https://media4.giphy.com/media/qVID3J8fLrlZK/200.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.gif&ct=g',
        mp4_size: '153913',
        mp4: 'https://media4.giphy.com/media/qVID3J8fLrlZK/200.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.mp4&ct=g',
        webp_size: '192660',
        webp: 'https://media4.giphy.com/media/qVID3J8fLrlZK/200.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.webp&ct=g',
      },
      fixed_height_downsampled: {
        height: '200',
        width: '372',
        size: '204279',
        url: 'https://media4.giphy.com/media/qVID3J8fLrlZK/200_d.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_d.gif&ct=g',
        webp_size: '120114',
        webp: 'https://media4.giphy.com/media/qVID3J8fLrlZK/200_d.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_d.webp&ct=g',
      },
      fixed_height_small: {
        height: '100',
        width: '186',
        size: '145136',
        url: 'https://media4.giphy.com/media/qVID3J8fLrlZK/100.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.gif&ct=g',
        mp4_size: '51809',
        mp4: 'https://media4.giphy.com/media/qVID3J8fLrlZK/100.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.mp4&ct=g',
        webp_size: '68200',
        webp: 'https://media4.giphy.com/media/qVID3J8fLrlZK/100.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.webp&ct=g',
      },
      fixed_height_small_still: {
        height: '100',
        width: '186',
        size: '12709',
        url: 'https://media4.giphy.com/media/qVID3J8fLrlZK/100_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100_s.gif&ct=g',
      },
      fixed_height_still: {
        height: '200',
        width: '372',
        size: '32468',
        url: 'https://media4.giphy.com/media/qVID3J8fLrlZK/200_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_s.gif&ct=g',
      },
      fixed_width: {
        height: '108',
        width: '200',
        size: '174000',
        url: 'https://media4.giphy.com/media/qVID3J8fLrlZK/200w.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.gif&ct=g',
        mp4_size: '58095',
        mp4: 'https://media4.giphy.com/media/qVID3J8fLrlZK/200w.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.mp4&ct=g',
        webp_size: '76626',
        webp: 'https://media4.giphy.com/media/qVID3J8fLrlZK/200w.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.webp&ct=g',
      },
      fixed_width_downsampled: {
        height: '108',
        width: '200',
        size: '78452',
        url: 'https://media4.giphy.com/media/qVID3J8fLrlZK/200w_d.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_d.gif&ct=g',
        webp_size: '42412',
        webp: 'https://media4.giphy.com/media/qVID3J8fLrlZK/200w_d.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_d.webp&ct=g',
      },
      fixed_width_small: {
        height: '54',
        width: '100',
        size: '47478',
        url: 'https://media4.giphy.com/media/qVID3J8fLrlZK/100w.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.gif&ct=g',
        mp4_size: '20724',
        mp4: 'https://media4.giphy.com/media/qVID3J8fLrlZK/100w.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.mp4&ct=g',
        webp_size: '26730',
        webp: 'https://media4.giphy.com/media/qVID3J8fLrlZK/100w.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.webp&ct=g',
      },
      fixed_width_small_still: {
        height: '54',
        width: '100',
        size: '4322',
        url: 'https://media4.giphy.com/media/qVID3J8fLrlZK/100w_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w_s.gif&ct=g',
      },
      fixed_width_still: {
        height: '108',
        width: '200',
        size: '14818',
        url: 'https://media4.giphy.com/media/qVID3J8fLrlZK/200w_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_s.gif&ct=g',
      },
      looping: {
        mp4_size: '2576379',
        mp4: 'https://media4.giphy.com/media/qVID3J8fLrlZK/giphy-loop.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-loop.mp4&ct=g',
      },
      original_still: {
        height: '269',
        width: '500',
        size: '69347',
        url: 'https://media4.giphy.com/media/qVID3J8fLrlZK/giphy_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy_s.gif&ct=g',
      },
      original_mp4: {
        height: '258',
        width: '480',
        mp4_size: '268844',
        mp4: 'https://media4.giphy.com/media/qVID3J8fLrlZK/giphy.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.mp4&ct=g',
      },
      preview: {
        height: '110',
        width: '205',
        mp4_size: '35675',
        mp4: 'https://media4.giphy.com/media/qVID3J8fLrlZK/giphy-preview.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.mp4&ct=g',
      },
      preview_gif: {
        height: '49',
        width: '91',
        size: '49924',
        url: 'https://media4.giphy.com/media/qVID3J8fLrlZK/giphy-preview.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.gif&ct=g',
      },
      preview_webp: {
        height: '90',
        width: '168',
        size: '44572',
        url: 'https://media4.giphy.com/media/qVID3J8fLrlZK/giphy-preview.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.webp&ct=g',
      },
      '480w_still': {
        height: '258',
        width: '480',
        size: '874128',
        url: 'https://media4.giphy.com/media/qVID3J8fLrlZK/480w_s.jpg?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=480w_s.jpg&ct=g',
      },
    },
    analytics_response_payload:
      'e=Z2lmX2lkPXFWSUQzSjhmTHJsWksmZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw',
    analytics: {
      onload: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPXFWSUQzSjhmTHJsWksmZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw&action_type=SEEN',
      },
      onclick: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPXFWSUQzSjhmTHJsWksmZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw&action_type=CLICK',
      },
      onsent: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPXFWSUQzSjhmTHJsWksmZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw&action_type=SENT',
      },
    },
  },
  {
    type: 'gif',
    id: '4zEFD8uWYwVO0',
    url: 'https://giphy.com/gifs/batman-superman-christian-bale-4zEFD8uWYwVO0',
    slug: 'batman-superman-christian-bale-4zEFD8uWYwVO0',
    bitly_gif_url: 'http://gph.is/1hjpLCN',
    bitly_url: 'http://gph.is/1hjpLCN',
    embed_url: 'https://giphy.com/embed/4zEFD8uWYwVO0',
    username: '',
    source:
      'http://ajacquelineofalltrades.tumblr.com/post/52596140694/worlds-finest-cinematic-bromance-superman',
    title: 'henry cavill batman GIF',
    rating: 'g',
    content_url: '',
    source_tld: 'tumblr.com',
    source_post_url:
      'http://ajacquelineofalltrades.tumblr.com/post/52596140694/worlds-finest-cinematic-bromance-superman',
    is_sticker: 0,
    import_datetime: '2014-01-22 17:01:10',
    trending_datetime: '1970-01-01 00:00:00',
    images: {
      original: {
        height: '200',
        width: '500',
        size: '744904',
        url: 'https://media2.giphy.com/media/4zEFD8uWYwVO0/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
        mp4_size: '245900',
        mp4: 'https://media2.giphy.com/media/4zEFD8uWYwVO0/giphy.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.mp4&ct=g',
        webp_size: '350584',
        webp: 'https://media2.giphy.com/media/4zEFD8uWYwVO0/giphy.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.webp&ct=g',
        frames: '12',
        hash: '3ec939f85f5203ef9d6562e0d1179ea2',
      },
      downsized: {
        height: '200',
        width: '500',
        size: '744904',
        url: 'https://media2.giphy.com/media/4zEFD8uWYwVO0/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_large: {
        height: '200',
        width: '500',
        size: '744904',
        url: 'https://media2.giphy.com/media/4zEFD8uWYwVO0/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_medium: {
        height: '200',
        width: '500',
        size: '744904',
        url: 'https://media2.giphy.com/media/4zEFD8uWYwVO0/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_small: {
        height: '158',
        width: '395',
        mp4_size: '58375',
        mp4: 'https://media2.giphy.com/media/4zEFD8uWYwVO0/giphy-downsized-small.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-downsized-small.mp4&ct=g',
      },
      downsized_still: {
        height: '200',
        width: '500',
        size: '744904',
        url: 'https://media2.giphy.com/media/4zEFD8uWYwVO0/giphy_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy_s.gif&ct=g',
      },
      fixed_height: {
        height: '200',
        width: '500',
        size: '639993',
        url: 'https://media2.giphy.com/media/4zEFD8uWYwVO0/200.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.gif&ct=g',
        mp4_size: '208264',
        mp4: 'https://media2.giphy.com/media/4zEFD8uWYwVO0/200.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.mp4&ct=g',
        webp_size: '353408',
        webp: 'https://media2.giphy.com/media/4zEFD8uWYwVO0/200.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.webp&ct=g',
      },
      fixed_height_downsampled: {
        height: '200',
        width: '500',
        size: '342706',
        url: 'https://media2.giphy.com/media/4zEFD8uWYwVO0/200_d.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_d.gif&ct=g',
        webp_size: '191104',
        webp: 'https://media2.giphy.com/media/4zEFD8uWYwVO0/200_d.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_d.webp&ct=g',
      },
      fixed_height_small: {
        height: '100',
        width: '250',
        size: '174571',
        url: 'https://media2.giphy.com/media/4zEFD8uWYwVO0/100.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.gif&ct=g',
        mp4_size: '41898',
        mp4: 'https://media2.giphy.com/media/4zEFD8uWYwVO0/100.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.mp4&ct=g',
        webp_size: '108746',
        webp: 'https://media2.giphy.com/media/4zEFD8uWYwVO0/100.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.webp&ct=g',
      },
      fixed_height_small_still: {
        height: '100',
        width: '250',
        size: '18462',
        url: 'https://media2.giphy.com/media/4zEFD8uWYwVO0/100_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100_s.gif&ct=g',
      },
      fixed_height_still: {
        height: '200',
        width: '500',
        size: '57981',
        url: 'https://media2.giphy.com/media/4zEFD8uWYwVO0/200_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_s.gif&ct=g',
      },
      fixed_width: {
        height: '80',
        width: '200',
        size: '109491',
        url: 'https://media2.giphy.com/media/4zEFD8uWYwVO0/200w.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.gif&ct=g',
        mp4_size: '25926',
        mp4: 'https://media2.giphy.com/media/4zEFD8uWYwVO0/200w.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.mp4&ct=g',
        webp_size: '75796',
        webp: 'https://media2.giphy.com/media/4zEFD8uWYwVO0/200w.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.webp&ct=g',
      },
      fixed_width_downsampled: {
        height: '80',
        width: '200',
        size: '60230',
        url: 'https://media2.giphy.com/media/4zEFD8uWYwVO0/200w_d.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_d.gif&ct=g',
        webp_size: '40456',
        webp: 'https://media2.giphy.com/media/4zEFD8uWYwVO0/200w_d.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_d.webp&ct=g',
      },
      fixed_width_small: {
        height: '40',
        width: '100',
        size: '31854',
        url: 'https://media2.giphy.com/media/4zEFD8uWYwVO0/100w.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.gif&ct=g',
        mp4_size: '8127',
        mp4: 'https://media2.giphy.com/media/4zEFD8uWYwVO0/100w.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.mp4&ct=g',
        webp_size: '26124',
        webp: 'https://media2.giphy.com/media/4zEFD8uWYwVO0/100w.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.webp&ct=g',
      },
      fixed_width_small_still: {
        height: '40',
        width: '100',
        size: '3955',
        url: 'https://media2.giphy.com/media/4zEFD8uWYwVO0/100w_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w_s.gif&ct=g',
      },
      fixed_width_still: {
        height: '80',
        width: '200',
        size: '13047',
        url: 'https://media2.giphy.com/media/4zEFD8uWYwVO0/200w_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_s.gif&ct=g',
      },
      looping: {
        mp4_size: '1703567',
        mp4: 'https://media2.giphy.com/media/4zEFD8uWYwVO0/giphy-loop.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-loop.mp4&ct=g',
      },
      original_still: {
        height: '200',
        width: '500',
        size: '74555',
        url: 'https://media2.giphy.com/media/4zEFD8uWYwVO0/giphy_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy_s.gif&ct=g',
      },
      original_mp4: {
        height: '192',
        width: '480',
        mp4_size: '245900',
        mp4: 'https://media2.giphy.com/media/4zEFD8uWYwVO0/giphy.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.mp4&ct=g',
      },
      preview: {
        height: '80',
        width: '200',
        mp4_size: '14808',
        mp4: 'https://media2.giphy.com/media/4zEFD8uWYwVO0/giphy-preview.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.mp4&ct=g',
      },
      preview_gif: {
        height: '56',
        width: '140',
        size: '48944',
        url: 'https://media2.giphy.com/media/4zEFD8uWYwVO0/giphy-preview.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.gif&ct=g',
      },
      preview_webp: {
        height: '72',
        width: '180',
        size: '38040',
        url: 'https://media2.giphy.com/media/4zEFD8uWYwVO0/giphy-preview.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.webp&ct=g',
      },
      '480w_still': {
        height: '192',
        width: '480',
        size: '744904',
        url: 'https://media2.giphy.com/media/4zEFD8uWYwVO0/480w_s.jpg?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=480w_s.jpg&ct=g',
      },
    },
    analytics_response_payload:
      'e=Z2lmX2lkPTR6RUZEOHVXWXdWTzAmZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw',
    analytics: {
      onload: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPTR6RUZEOHVXWXdWTzAmZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw&action_type=SEEN',
      },
      onclick: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPTR6RUZEOHVXWXdWTzAmZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw&action_type=CLICK',
      },
      onsent: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPTR6RUZEOHVXWXdWTzAmZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw&action_type=SENT',
      },
    },
  },
  {
    type: 'gif',
    id: 'G9QYU7QlKFeq4',
    url: 'https://giphy.com/gifs/batman-dinner-justice-G9QYU7QlKFeq4',
    slug: 'batman-dinner-justice-G9QYU7QlKFeq4',
    bitly_gif_url: 'http://gph.is/11BgHDH',
    bitly_url: 'http://gph.is/11BgHDH',
    embed_url: 'https://giphy.com/embed/G9QYU7QlKFeq4',
    username: '',
    source: 'http://cheezburger.com/4824790528',
    title: 'Batman Dinner GIF',
    rating: 'pg',
    content_url: '',
    source_tld: 'cheezburger.com',
    source_post_url: 'http://cheezburger.com/4824790528',
    is_sticker: 0,
    import_datetime: '2013-08-02 23:38:33',
    trending_datetime: '2014-08-25 19:50:24',
    images: {
      original: {
        height: '150',
        width: '350',
        size: '534325',
        url: 'https://media3.giphy.com/media/G9QYU7QlKFeq4/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
        mp4_size: '271914',
        mp4: 'https://media3.giphy.com/media/G9QYU7QlKFeq4/giphy.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.mp4&ct=g',
        webp_size: '266768',
        webp: 'https://media3.giphy.com/media/G9QYU7QlKFeq4/giphy.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.webp&ct=g',
        frames: '32',
        hash: '115d67377b962c3ffdffd976cad8b052',
      },
      downsized: {
        height: '150',
        width: '350',
        size: '534325',
        url: 'https://media3.giphy.com/media/G9QYU7QlKFeq4/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_large: {
        height: '150',
        width: '350',
        size: '534325',
        url: 'https://media3.giphy.com/media/G9QYU7QlKFeq4/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_medium: {
        height: '150',
        width: '350',
        size: '534325',
        url: 'https://media3.giphy.com/media/G9QYU7QlKFeq4/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_small: {
        height: '150',
        width: '350',
        mp4_size: '183582',
        mp4: 'https://media3.giphy.com/media/G9QYU7QlKFeq4/giphy-downsized-small.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-downsized-small.mp4&ct=g',
      },
      downsized_still: {
        height: '150',
        width: '350',
        size: '534325',
        url: 'https://media3.giphy.com/media/G9QYU7QlKFeq4/giphy_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy_s.gif&ct=g',
      },
      fixed_height: {
        height: '200',
        width: '467',
        size: '887015',
        url: 'https://media3.giphy.com/media/G9QYU7QlKFeq4/200.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.gif&ct=g',
        mp4_size: '219042',
        mp4: 'https://media3.giphy.com/media/G9QYU7QlKFeq4/200.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.mp4&ct=g',
        webp_size: '347858',
        webp: 'https://media3.giphy.com/media/G9QYU7QlKFeq4/200.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.webp&ct=g',
      },
      fixed_height_downsampled: {
        height: '200',
        width: '467',
        size: '201153',
        url: 'https://media3.giphy.com/media/G9QYU7QlKFeq4/200_d.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_d.gif&ct=g',
        webp_size: '117716',
        webp: 'https://media3.giphy.com/media/G9QYU7QlKFeq4/200_d.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_d.webp&ct=g',
      },
      fixed_height_small: {
        height: '100',
        width: '234',
        size: '297068',
        url: 'https://media3.giphy.com/media/G9QYU7QlKFeq4/100.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.gif&ct=g',
        mp4_size: '61564',
        mp4: 'https://media3.giphy.com/media/G9QYU7QlKFeq4/100.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.mp4&ct=g',
        webp_size: '126616',
        webp: 'https://media3.giphy.com/media/G9QYU7QlKFeq4/100.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.webp&ct=g',
      },
      fixed_height_small_still: {
        height: '100',
        width: '234',
        size: '13225',
        url: 'https://media3.giphy.com/media/G9QYU7QlKFeq4/100_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100_s.gif&ct=g',
      },
      fixed_height_still: {
        height: '200',
        width: '467',
        size: '30265',
        url: 'https://media3.giphy.com/media/G9QYU7QlKFeq4/200_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_s.gif&ct=g',
      },
      fixed_width: {
        height: '86',
        width: '200',
        size: '210155',
        url: 'https://media3.giphy.com/media/G9QYU7QlKFeq4/200w.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.gif&ct=g',
        mp4_size: '50190',
        mp4: 'https://media3.giphy.com/media/G9QYU7QlKFeq4/200w.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.mp4&ct=g',
        webp_size: '106022',
        webp: 'https://media3.giphy.com/media/G9QYU7QlKFeq4/200w.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.webp&ct=g',
      },
      fixed_width_downsampled: {
        height: '86',
        width: '200',
        size: '46938',
        url: 'https://media3.giphy.com/media/G9QYU7QlKFeq4/200w_d.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_d.gif&ct=g',
        webp_size: '29164',
        webp: 'https://media3.giphy.com/media/G9QYU7QlKFeq4/200w_d.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_d.webp&ct=g',
      },
      fixed_width_small: {
        height: '43',
        width: '100',
        size: '73456',
        url: 'https://media3.giphy.com/media/G9QYU7QlKFeq4/100w.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.gif&ct=g',
        mp4_size: '18874',
        mp4: 'https://media3.giphy.com/media/G9QYU7QlKFeq4/100w.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.mp4&ct=g',
        webp_size: '44164',
        webp: 'https://media3.giphy.com/media/G9QYU7QlKFeq4/100w.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.webp&ct=g',
      },
      fixed_width_small_still: {
        height: '43',
        width: '100',
        size: '3158',
        url: 'https://media3.giphy.com/media/G9QYU7QlKFeq4/100w_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w_s.gif&ct=g',
      },
      fixed_width_still: {
        height: '86',
        width: '200',
        size: '9023',
        url: 'https://media3.giphy.com/media/G9QYU7QlKFeq4/200w_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_s.gif&ct=g',
      },
      looping: {
        mp4_size: '1398286',
        mp4: 'https://media3.giphy.com/media/G9QYU7QlKFeq4/giphy-loop.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-loop.mp4&ct=g',
      },
      original_still: {
        height: '150',
        width: '350',
        size: '20249',
        url: 'https://media3.giphy.com/media/G9QYU7QlKFeq4/giphy_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy_s.gif&ct=g',
      },
      original_mp4: {
        height: '204',
        width: '480',
        mp4_size: '271914',
        mp4: 'https://media3.giphy.com/media/G9QYU7QlKFeq4/giphy.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.mp4&ct=g',
      },
      preview: {
        height: '120',
        width: '280',
        mp4_size: '42525',
        mp4: 'https://media3.giphy.com/media/G9QYU7QlKFeq4/giphy-preview.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.mp4&ct=g',
      },
      preview_gif: {
        height: '49',
        width: '114',
        size: '48814',
        url: 'https://media3.giphy.com/media/G9QYU7QlKFeq4/giphy-preview.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.gif&ct=g',
      },
      preview_webp: {
        height: '100',
        width: '234',
        size: '41910',
        url: 'https://media3.giphy.com/media/G9QYU7QlKFeq4/giphy-preview.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.webp&ct=g',
      },
      '480w_still': {
        height: '206',
        width: '480',
        size: '534325',
        url: 'https://media3.giphy.com/media/G9QYU7QlKFeq4/480w_s.jpg?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=480w_s.jpg&ct=g',
      },
    },
    analytics_response_payload:
      'e=Z2lmX2lkPUc5UVlVN1FsS0ZlcTQmZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw',
    analytics: {
      onload: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPUc5UVlVN1FsS0ZlcTQmZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw&action_type=SEEN',
      },
      onclick: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPUc5UVlVN1FsS0ZlcTQmZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw&action_type=CLICK',
      },
      onsent: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPUc5UVlVN1FsS0ZlcTQmZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw&action_type=SENT',
      },
    },
  },
  {
    type: 'gif',
    id: '6gDSyjaOPwZ4A',
    url: 'https://giphy.com/gifs/black-ava-duvernay-6gDSyjaOPwZ4A',
    slug: 'black-ava-duvernay-6gDSyjaOPwZ4A',
    bitly_gif_url: 'http://gph.is/1UkUHaF',
    bitly_url: 'http://gph.is/1UkUHaF',
    embed_url: 'https://giphy.com/embed/6gDSyjaOPwZ4A',
    username: '',
    source:
      'http://jezebel.com/ava-duvernay-has-confirmed-that-she-won-t-be-directing-1715677269',
    title: 'sad batman GIF',
    rating: 'g',
    content_url: '',
    source_tld: 'jezebel.com',
    source_post_url:
      'http://jezebel.com/ava-duvernay-has-confirmed-that-she-won-t-be-directing-1715677269',
    is_sticker: 0,
    import_datetime: '2015-07-04 08:45:21',
    trending_datetime: '2019-02-21 07:30:01',
    images: {
      original: {
        height: '240',
        width: '320',
        size: '510853',
        url: 'https://media3.giphy.com/media/6gDSyjaOPwZ4A/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
        mp4_size: '229805',
        mp4: 'https://media3.giphy.com/media/6gDSyjaOPwZ4A/giphy.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.mp4&ct=g',
        webp_size: '194204',
        webp: 'https://media3.giphy.com/media/6gDSyjaOPwZ4A/giphy.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.webp&ct=g',
        frames: '15',
        hash: '5efe34f153a3692c2856e381b1c8d39f',
      },
      downsized: {
        height: '240',
        width: '320',
        size: '510853',
        url: 'https://media3.giphy.com/media/6gDSyjaOPwZ4A/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_large: {
        height: '240',
        width: '320',
        size: '510853',
        url: 'https://media3.giphy.com/media/6gDSyjaOPwZ4A/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_medium: {
        height: '240',
        width: '320',
        size: '510853',
        url: 'https://media3.giphy.com/media/6gDSyjaOPwZ4A/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_small: {
        height: '240',
        width: '320',
        mp4_size: '137916',
        mp4: 'https://media3.giphy.com/media/6gDSyjaOPwZ4A/giphy-downsized-small.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-downsized-small.mp4&ct=g',
      },
      downsized_still: {
        height: '240',
        width: '320',
        size: '510853',
        url: 'https://media3.giphy.com/media/6gDSyjaOPwZ4A/giphy_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy_s.gif&ct=g',
      },
      fixed_height: {
        height: '200',
        width: '267',
        size: '294081',
        url: 'https://media3.giphy.com/media/6gDSyjaOPwZ4A/200.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.gif&ct=g',
        mp4_size: '91701',
        mp4: 'https://media3.giphy.com/media/6gDSyjaOPwZ4A/200.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.mp4&ct=g',
        webp_size: '149144',
        webp: 'https://media3.giphy.com/media/6gDSyjaOPwZ4A/200.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.webp&ct=g',
      },
      fixed_height_downsampled: {
        height: '200',
        width: '267',
        size: '127069',
        url: 'https://media3.giphy.com/media/6gDSyjaOPwZ4A/200_d.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_d.gif&ct=g',
        webp_size: '88472',
        webp: 'https://media3.giphy.com/media/6gDSyjaOPwZ4A/200_d.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_d.webp&ct=g',
      },
      fixed_height_small: {
        height: '100',
        width: '134',
        size: '90076',
        url: 'https://media3.giphy.com/media/6gDSyjaOPwZ4A/100.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.gif&ct=g',
        mp4_size: '28699',
        mp4: 'https://media3.giphy.com/media/6gDSyjaOPwZ4A/100.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.mp4&ct=g',
        webp_size: '50258',
        webp: 'https://media3.giphy.com/media/6gDSyjaOPwZ4A/100.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.webp&ct=g',
      },
      fixed_height_small_still: {
        height: '100',
        width: '134',
        size: '6729',
        url: 'https://media3.giphy.com/media/6gDSyjaOPwZ4A/100_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100_s.gif&ct=g',
      },
      fixed_height_still: {
        height: '200',
        width: '267',
        size: '24017',
        url: 'https://media3.giphy.com/media/6gDSyjaOPwZ4A/200_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_s.gif&ct=g',
      },
      fixed_width: {
        height: '150',
        width: '200',
        size: '176724',
        url: 'https://media3.giphy.com/media/6gDSyjaOPwZ4A/200w.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.gif&ct=g',
        mp4_size: '58447',
        mp4: 'https://media3.giphy.com/media/6gDSyjaOPwZ4A/200w.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.mp4&ct=g',
        webp_size: '96354',
        webp: 'https://media3.giphy.com/media/6gDSyjaOPwZ4A/200w.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.webp&ct=g',
      },
      fixed_width_downsampled: {
        height: '150',
        width: '200',
        size: '73439',
        url: 'https://media3.giphy.com/media/6gDSyjaOPwZ4A/200w_d.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_d.gif&ct=g',
        webp_size: '51832',
        webp: 'https://media3.giphy.com/media/6gDSyjaOPwZ4A/200w_d.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_d.webp&ct=g',
      },
      fixed_width_small: {
        height: '75',
        width: '100',
        size: '56368',
        url: 'https://media3.giphy.com/media/6gDSyjaOPwZ4A/100w.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.gif&ct=g',
        mp4_size: '16857',
        mp4: 'https://media3.giphy.com/media/6gDSyjaOPwZ4A/100w.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.mp4&ct=g',
        webp_size: '30768',
        webp: 'https://media3.giphy.com/media/6gDSyjaOPwZ4A/100w.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.webp&ct=g',
      },
      fixed_width_small_still: {
        height: '75',
        width: '100',
        size: '4420',
        url: 'https://media3.giphy.com/media/6gDSyjaOPwZ4A/100w_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w_s.gif&ct=g',
      },
      fixed_width_still: {
        height: '150',
        width: '200',
        size: '12232',
        url: 'https://media3.giphy.com/media/6gDSyjaOPwZ4A/200w_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_s.gif&ct=g',
      },
      looping: {
        mp4_size: '1982986',
        mp4: 'https://media3.giphy.com/media/6gDSyjaOPwZ4A/giphy-loop.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-loop.mp4&ct=g',
      },
      original_still: {
        height: '240',
        width: '320',
        size: '60155',
        url: 'https://media3.giphy.com/media/6gDSyjaOPwZ4A/giphy_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy_s.gif&ct=g',
      },
      original_mp4: {
        height: '360',
        width: '480',
        mp4_size: '229805',
        mp4: 'https://media3.giphy.com/media/6gDSyjaOPwZ4A/giphy.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.mp4&ct=g',
      },
      preview: {
        height: '144',
        width: '192',
        mp4_size: '29755',
        mp4: 'https://media3.giphy.com/media/6gDSyjaOPwZ4A/giphy-preview.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.mp4&ct=g',
      },
      preview_gif: {
        height: '58',
        width: '77',
        size: '49017',
        url: 'https://media3.giphy.com/media/6gDSyjaOPwZ4A/giphy-preview.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.gif&ct=g',
      },
      preview_webp: {
        height: '120',
        width: '160',
        size: '44478',
        url: 'https://media3.giphy.com/media/6gDSyjaOPwZ4A/giphy-preview.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.webp&ct=g',
      },
      '480w_still': {
        height: '360',
        width: '480',
        size: '510853',
        url: 'https://media3.giphy.com/media/6gDSyjaOPwZ4A/480w_s.jpg?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=480w_s.jpg&ct=g',
      },
    },
    analytics_response_payload:
      'e=Z2lmX2lkPTZnRFN5amFPUHdaNEEmZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw',
    analytics: {
      onload: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPTZnRFN5amFPUHdaNEEmZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw&action_type=SEEN',
      },
      onclick: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPTZnRFN5amFPUHdaNEEmZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw&action_type=CLICK',
      },
      onsent: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPTZnRFN5amFPUHdaNEEmZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw&action_type=SENT',
      },
    },
  },
  {
    type: 'gif',
    id: '14bhmZtBNhVnIk',
    url: 'https://giphy.com/gifs/batman-the-dark-knight-14bhmZtBNhVnIk',
    slug: 'batman-the-dark-knight-14bhmZtBNhVnIk',
    bitly_gif_url: 'http://gph.is/1fdS7PF',
    bitly_url: 'http://gph.is/1fdS7PF',
    embed_url: 'https://giphy.com/embed/14bhmZtBNhVnIk',
    username: '',
    source:
      'http://www.polygon.com/2014/11/24/7275327/batman-arkham-knight-trailer-gameplay-video-batmobile-ace-chemicals-part-1',
    title: 'The Dark Knight Thumbs Up GIF',
    rating: 'g',
    content_url: '',
    source_tld: 'www.polygon.com',
    source_post_url:
      'http://www.polygon.com/2014/11/24/7275327/batman-arkham-knight-trailer-gameplay-video-batmobile-ace-chemicals-part-1',
    is_sticker: 0,
    import_datetime: '2014-02-18 16:44:42',
    trending_datetime: '2019-08-21 14:30:08',
    images: {
      original: {
        height: '420',
        width: '500',
        size: '195471',
        url: 'https://media0.giphy.com/media/14bhmZtBNhVnIk/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
        mp4_size: '117869',
        mp4: 'https://media0.giphy.com/media/14bhmZtBNhVnIk/giphy.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.mp4&ct=g',
        webp_size: '115750',
        webp: 'https://media0.giphy.com/media/14bhmZtBNhVnIk/giphy.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.webp&ct=g',
        frames: '22',
        hash: '890f3d8499e0da14e384b01489e46c9c',
      },
      downsized: {
        height: '420',
        width: '500',
        size: '195471',
        url: 'https://media0.giphy.com/media/14bhmZtBNhVnIk/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_large: {
        height: '420',
        width: '500',
        size: '195471',
        url: 'https://media0.giphy.com/media/14bhmZtBNhVnIk/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_medium: {
        height: '420',
        width: '500',
        size: '195471',
        url: 'https://media0.giphy.com/media/14bhmZtBNhVnIk/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_small: {
        height: '420',
        width: '500',
        mp4_size: '136319',
        mp4: 'https://media0.giphy.com/media/14bhmZtBNhVnIk/giphy-downsized-small.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-downsized-small.mp4&ct=g',
      },
      downsized_still: {
        height: '420',
        width: '500',
        size: '195471',
        url: 'https://media0.giphy.com/media/14bhmZtBNhVnIk/giphy_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy_s.gif&ct=g',
      },
      fixed_height: {
        height: '200',
        width: '238',
        size: '60405',
        url: 'https://media0.giphy.com/media/14bhmZtBNhVnIk/200.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.gif&ct=g',
        mp4_size: '34536',
        mp4: 'https://media0.giphy.com/media/14bhmZtBNhVnIk/200.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.mp4&ct=g',
        webp_size: '42546',
        webp: 'https://media0.giphy.com/media/14bhmZtBNhVnIk/200.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.webp&ct=g',
      },
      fixed_height_downsampled: {
        height: '200',
        width: '238',
        size: '32808',
        url: 'https://media0.giphy.com/media/14bhmZtBNhVnIk/200_d.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_d.gif&ct=g',
        webp_size: '23606',
        webp: 'https://media0.giphy.com/media/14bhmZtBNhVnIk/200_d.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_d.webp&ct=g',
      },
      fixed_height_small: {
        height: '100',
        width: '119',
        size: '22596',
        url: 'https://media0.giphy.com/media/14bhmZtBNhVnIk/100.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.gif&ct=g',
        mp4_size: '13212',
        mp4: 'https://media0.giphy.com/media/14bhmZtBNhVnIk/100.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.mp4&ct=g',
        webp_size: '16784',
        webp: 'https://media0.giphy.com/media/14bhmZtBNhVnIk/100.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.webp&ct=g',
      },
      fixed_height_small_still: {
        height: '100',
        width: '119',
        size: '6485',
        url: 'https://media0.giphy.com/media/14bhmZtBNhVnIk/100_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100_s.gif&ct=g',
      },
      fixed_height_still: {
        height: '200',
        width: '238',
        size: '20050',
        url: 'https://media0.giphy.com/media/14bhmZtBNhVnIk/200_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_s.gif&ct=g',
      },
      fixed_width: {
        height: '168',
        width: '200',
        size: '47178',
        url: 'https://media0.giphy.com/media/14bhmZtBNhVnIk/200w.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.gif&ct=g',
        mp4_size: '27216',
        mp4: 'https://media0.giphy.com/media/14bhmZtBNhVnIk/200w.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.mp4&ct=g',
        webp_size: '34448',
        webp: 'https://media0.giphy.com/media/14bhmZtBNhVnIk/200w.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.webp&ct=g',
      },
      fixed_width_downsampled: {
        height: '168',
        width: '200',
        size: '23982',
        url: 'https://media0.giphy.com/media/14bhmZtBNhVnIk/200w_d.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_d.gif&ct=g',
        webp_size: '17772',
        webp: 'https://media0.giphy.com/media/14bhmZtBNhVnIk/200w_d.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_d.webp&ct=g',
      },
      fixed_width_small: {
        height: '84',
        width: '100',
        size: '17781',
        url: 'https://media0.giphy.com/media/14bhmZtBNhVnIk/100w.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.gif&ct=g',
        mp4_size: '10617',
        mp4: 'https://media0.giphy.com/media/14bhmZtBNhVnIk/100w.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.mp4&ct=g',
        webp_size: '12634',
        webp: 'https://media0.giphy.com/media/14bhmZtBNhVnIk/100w.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.webp&ct=g',
      },
      fixed_width_small_still: {
        height: '84',
        width: '100',
        size: '4982',
        url: 'https://media0.giphy.com/media/14bhmZtBNhVnIk/100w_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w_s.gif&ct=g',
      },
      fixed_width_still: {
        height: '168',
        width: '200',
        size: '16152',
        url: 'https://media0.giphy.com/media/14bhmZtBNhVnIk/200w_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_s.gif&ct=g',
      },
      looping: {
        mp4_size: '366803',
        mp4: 'https://media0.giphy.com/media/14bhmZtBNhVnIk/giphy-loop.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-loop.mp4&ct=g',
      },
      original_still: {
        height: '420',
        width: '500',
        size: '107549',
        url: 'https://media0.giphy.com/media/14bhmZtBNhVnIk/giphy_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy_s.gif&ct=g',
      },
      original_mp4: {
        height: '402',
        width: '480',
        mp4_size: '117869',
        mp4: 'https://media0.giphy.com/media/14bhmZtBNhVnIk/giphy.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.mp4&ct=g',
      },
      preview: {
        height: '336',
        width: '400',
        mp4_size: '22856',
        mp4: 'https://media0.giphy.com/media/14bhmZtBNhVnIk/giphy-preview.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.mp4&ct=g',
      },
      preview_gif: {
        height: '420',
        width: '500',
        size: '47343',
        url: 'https://media0.giphy.com/media/14bhmZtBNhVnIk/giphy-preview.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.gif&ct=g',
      },
      preview_webp: {
        height: '420',
        width: '500',
        size: '27340',
        url: 'https://media0.giphy.com/media/14bhmZtBNhVnIk/giphy-preview.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.webp&ct=g',
      },
      '480w_still': {
        height: '403',
        width: '480',
        size: '195471',
        url: 'https://media0.giphy.com/media/14bhmZtBNhVnIk/480w_s.jpg?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=480w_s.jpg&ct=g',
      },
    },
    analytics_response_payload:
      'e=Z2lmX2lkPTE0YmhtWnRCTmhWbklrJmV2ZW50X3R5cGU9R0lGX1NFQVJDSCZjaWQ9OWYwZjY0MjV5NWoxNHRjNjEwcW8xNHBybGdwNHUxaHloMHBmdzY4ZDE5eWQ5M3MzJmN0PWc',
    analytics: {
      onload: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPTE0YmhtWnRCTmhWbklrJmV2ZW50X3R5cGU9R0lGX1NFQVJDSCZjaWQ9OWYwZjY0MjV5NWoxNHRjNjEwcW8xNHBybGdwNHUxaHloMHBmdzY4ZDE5eWQ5M3MzJmN0PWc&action_type=SEEN',
      },
      onclick: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPTE0YmhtWnRCTmhWbklrJmV2ZW50X3R5cGU9R0lGX1NFQVJDSCZjaWQ9OWYwZjY0MjV5NWoxNHRjNjEwcW8xNHBybGdwNHUxaHloMHBmdzY4ZDE5eWQ5M3MzJmN0PWc&action_type=CLICK',
      },
      onsent: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPTE0YmhtWnRCTmhWbklrJmV2ZW50X3R5cGU9R0lGX1NFQVJDSCZjaWQ9OWYwZjY0MjV5NWoxNHRjNjEwcW8xNHBybGdwNHUxaHloMHBmdzY4ZDE5eWQ5M3MzJmN0PWc&action_type=SENT',
      },
    },
  },
  {
    type: 'gif',
    id: 'l396BoOTIFem9xqQU',
    url: 'https://giphy.com/gifs/film-cinemagraph-cinemagraphs-l396BoOTIFem9xqQU',
    slug: 'film-cinemagraph-cinemagraphs-l396BoOTIFem9xqQU',
    bitly_gif_url: 'http://gph.is/1q3aOyd',
    bitly_url: 'http://gph.is/1q3aOyd',
    embed_url: 'https://giphy.com/embed/l396BoOTIFem9xqQU',
    username: 'technoir',
    source: 'http://technoir.nl',
    title: 'bat signal batman GIF by Tech Noir',
    rating: 'g',
    content_url: '',
    source_tld: 'technoir.nl',
    source_post_url: 'http://technoir.nl',
    is_sticker: 0,
    import_datetime: '2016-05-06 09:12:45',
    trending_datetime: '0000-00-00 00:00:00',
    images: {
      original: {
        height: '270',
        width: '480',
        size: '430101',
        url: 'https://media1.giphy.com/media/l396BoOTIFem9xqQU/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
        mp4_size: '33778',
        mp4: 'https://media1.giphy.com/media/l396BoOTIFem9xqQU/giphy.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.mp4&ct=g',
        webp_size: '90140',
        webp: 'https://media1.giphy.com/media/l396BoOTIFem9xqQU/giphy.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.webp&ct=g',
        frames: '48',
        hash: '9270c1fcd0738e850dae26c7f56dd487',
      },
      downsized: {
        height: '270',
        width: '480',
        size: '430101',
        url: 'https://media1.giphy.com/media/l396BoOTIFem9xqQU/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_large: {
        height: '270',
        width: '480',
        size: '430101',
        url: 'https://media1.giphy.com/media/l396BoOTIFem9xqQU/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_medium: {
        height: '270',
        width: '480',
        size: '430101',
        url: 'https://media1.giphy.com/media/l396BoOTIFem9xqQU/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_small: {
        height: '270',
        width: '480',
        mp4_size: '33862',
        mp4: 'https://media1.giphy.com/media/l396BoOTIFem9xqQU/giphy-downsized-small.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-downsized-small.mp4&ct=g',
      },
      downsized_still: {
        height: '270',
        width: '480',
        size: '430101',
        url: 'https://media1.giphy.com/media/l396BoOTIFem9xqQU/giphy_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy_s.gif&ct=g',
      },
      fixed_height: {
        height: '200',
        width: '356',
        size: '237405',
        url: 'https://media1.giphy.com/media/l396BoOTIFem9xqQU/200.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.gif&ct=g',
        mp4_size: '21621',
        mp4: 'https://media1.giphy.com/media/l396BoOTIFem9xqQU/200.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.mp4&ct=g',
        webp_size: '65046',
        webp: 'https://media1.giphy.com/media/l396BoOTIFem9xqQU/200.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.webp&ct=g',
      },
      fixed_height_downsampled: {
        height: '200',
        width: '356',
        size: '37193',
        url: 'https://media1.giphy.com/media/l396BoOTIFem9xqQU/200_d.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_d.gif&ct=g',
        webp_size: '18982',
        webp: 'https://media1.giphy.com/media/l396BoOTIFem9xqQU/200_d.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_d.webp&ct=g',
      },
      fixed_height_small: {
        height: '100',
        width: '178',
        size: '94207',
        url: 'https://media1.giphy.com/media/l396BoOTIFem9xqQU/100.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.gif&ct=g',
        mp4_size: '8262',
        mp4: 'https://media1.giphy.com/media/l396BoOTIFem9xqQU/100.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.mp4&ct=g',
        webp_size: '26594',
        webp: 'https://media1.giphy.com/media/l396BoOTIFem9xqQU/100.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.webp&ct=g',
      },
      fixed_height_small_still: {
        height: '100',
        width: '178',
        size: '4810',
        url: 'https://media1.giphy.com/media/l396BoOTIFem9xqQU/100_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100_s.gif&ct=g',
      },
      fixed_height_still: {
        height: '200',
        width: '356',
        size: '10967',
        url: 'https://media1.giphy.com/media/l396BoOTIFem9xqQU/200_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_s.gif&ct=g',
      },
      fixed_width: {
        height: '113',
        width: '200',
        size: '99754',
        url: 'https://media1.giphy.com/media/l396BoOTIFem9xqQU/200w.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.gif&ct=g',
        mp4_size: '8823',
        mp4: 'https://media1.giphy.com/media/l396BoOTIFem9xqQU/200w.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.mp4&ct=g',
        webp_size: '29752',
        webp: 'https://media1.giphy.com/media/l396BoOTIFem9xqQU/200w.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.webp&ct=g',
      },
      fixed_width_downsampled: {
        height: '113',
        width: '200',
        size: '16723',
        url: 'https://media1.giphy.com/media/l396BoOTIFem9xqQU/200w_d.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_d.gif&ct=g',
        webp_size: '8798',
        webp: 'https://media1.giphy.com/media/l396BoOTIFem9xqQU/200w_d.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_d.webp&ct=g',
      },
      fixed_width_small: {
        height: '57',
        width: '100',
        size: '43130',
        url: 'https://media1.giphy.com/media/l396BoOTIFem9xqQU/100w.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.gif&ct=g',
        mp4_size: '4266',
        mp4: 'https://media1.giphy.com/media/l396BoOTIFem9xqQU/100w.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.mp4&ct=g',
        webp_size: '11244',
        webp: 'https://media1.giphy.com/media/l396BoOTIFem9xqQU/100w.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.webp&ct=g',
      },
      fixed_width_small_still: {
        height: '57',
        width: '100',
        size: '2752',
        url: 'https://media1.giphy.com/media/l396BoOTIFem9xqQU/100w_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w_s.gif&ct=g',
      },
      fixed_width_still: {
        height: '113',
        width: '200',
        size: '5298',
        url: 'https://media1.giphy.com/media/l396BoOTIFem9xqQU/200w_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_s.gif&ct=g',
      },
      looping: {
        mp4_size: '180439',
        mp4: 'https://media1.giphy.com/media/l396BoOTIFem9xqQU/giphy-loop.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-loop.mp4&ct=g',
      },
      original_still: {
        height: '270',
        width: '480',
        size: '64520',
        url: 'https://media1.giphy.com/media/l396BoOTIFem9xqQU/giphy_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy_s.gif&ct=g',
      },
      original_mp4: {
        height: '270',
        width: '480',
        mp4_size: '33778',
        mp4: 'https://media1.giphy.com/media/l396BoOTIFem9xqQU/giphy.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.mp4&ct=g',
      },
      preview: {
        height: '270',
        width: '480',
        mp4_size: '33862',
        mp4: 'https://media1.giphy.com/media/l396BoOTIFem9xqQU/giphy-preview.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.mp4&ct=g',
      },
      preview_gif: {
        height: '82',
        width: '146',
        size: '49622',
        url: 'https://media1.giphy.com/media/l396BoOTIFem9xqQU/giphy-preview.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.gif&ct=g',
      },
      preview_webp: {
        height: '270',
        width: '480',
        size: '21374',
        url: 'https://media1.giphy.com/media/l396BoOTIFem9xqQU/giphy-preview.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.webp&ct=g',
      },
      '480w_still': {
        height: '270',
        width: '480',
        size: '430101',
        url: 'https://media1.giphy.com/media/l396BoOTIFem9xqQU/480w_s.jpg?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=480w_s.jpg&ct=g',
      },
    },
    user: {
      avatar_url: 'https://media2.giphy.com/avatars/technoir.gif',
      banner_image:
        'https://media2.giphy.com/headers/technoir/cEuZVViXdUs8.jpg',
      banner_url: 'https://media2.giphy.com/headers/technoir/cEuZVViXdUs8.jpg',
      profile_url: 'https://giphy.com/technoir/',
      username: 'technoir',
      display_name: 'Tech Noir',
      description:
        'A gallery of cinemagraphs made from movie scenes. Creator of giphy.com/livingstills',
      instagram_url: '',
      website_url: 'http://technoir.nl/',
      is_verified: true,
    },
    analytics_response_payload:
      'e=Z2lmX2lkPWwzOTZCb09USUZlbTl4cVFVJmV2ZW50X3R5cGU9R0lGX1NFQVJDSCZjaWQ9OWYwZjY0MjV5NWoxNHRjNjEwcW8xNHBybGdwNHUxaHloMHBmdzY4ZDE5eWQ5M3MzJmN0PWc',
    analytics: {
      onload: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPWwzOTZCb09USUZlbTl4cVFVJmV2ZW50X3R5cGU9R0lGX1NFQVJDSCZjaWQ9OWYwZjY0MjV5NWoxNHRjNjEwcW8xNHBybGdwNHUxaHloMHBmdzY4ZDE5eWQ5M3MzJmN0PWc&action_type=SEEN',
      },
      onclick: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPWwzOTZCb09USUZlbTl4cVFVJmV2ZW50X3R5cGU9R0lGX1NFQVJDSCZjaWQ9OWYwZjY0MjV5NWoxNHRjNjEwcW8xNHBybGdwNHUxaHloMHBmdzY4ZDE5eWQ5M3MzJmN0PWc&action_type=CLICK',
      },
      onsent: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPWwzOTZCb09USUZlbTl4cVFVJmV2ZW50X3R5cGU9R0lGX1NFQVJDSCZjaWQ9OWYwZjY0MjV5NWoxNHRjNjEwcW8xNHBybGdwNHUxaHloMHBmdzY4ZDE5eWQ5M3MzJmN0PWc&action_type=SENT',
      },
    },
  },
  {
    type: 'gif',
    id: '124n6MRMPIFAMU',
    url: 'https://giphy.com/gifs/maudit-lol-maudit-batman-124n6MRMPIFAMU',
    slug: 'maudit-lol-maudit-batman-124n6MRMPIFAMU',
    bitly_gif_url: 'http://gph.is/1c6k7i8',
    bitly_url: 'http://gph.is/1c6k7i8',
    embed_url: 'https://giphy.com/embed/124n6MRMPIFAMU',
    username: 'maudit',
    source: 'http://maudit.tumblr.com/post/31958784168',
    title: 'The Brave And The Bold Batman GIF by Maudit',
    rating: 'g',
    content_url: '',
    source_tld: 'maudit.tumblr.com',
    source_post_url: 'http://maudit.tumblr.com/post/31958784168',
    is_sticker: 0,
    import_datetime: '2013-12-25 09:39:31',
    trending_datetime: '2015-12-24 03:41:59',
    images: {
      original: {
        height: '250',
        width: '500',
        size: '708183',
        url: 'https://media1.giphy.com/media/124n6MRMPIFAMU/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
        mp4_size: '166382',
        mp4: 'https://media1.giphy.com/media/124n6MRMPIFAMU/giphy.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.mp4&ct=g',
        webp_size: '410980',
        webp: 'https://media1.giphy.com/media/124n6MRMPIFAMU/giphy.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.webp&ct=g',
        frames: '29',
        hash: '48cffcceca53a5832c4b1a6b1361c69f',
      },
      downsized: {
        height: '250',
        width: '500',
        size: '708183',
        url: 'https://media1.giphy.com/media/124n6MRMPIFAMU/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_large: {
        height: '250',
        width: '500',
        size: '708183',
        url: 'https://media1.giphy.com/media/124n6MRMPIFAMU/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_medium: {
        height: '250',
        width: '500',
        size: '708183',
        url: 'https://media1.giphy.com/media/124n6MRMPIFAMU/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_small: {
        height: '224',
        width: '448',
        mp4_size: '60523',
        mp4: 'https://media1.giphy.com/media/124n6MRMPIFAMU/giphy-downsized-small.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-downsized-small.mp4&ct=g',
      },
      downsized_still: {
        height: '250',
        width: '500',
        size: '708183',
        url: 'https://media1.giphy.com/media/124n6MRMPIFAMU/giphy_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy_s.gif&ct=g',
      },
      fixed_height: {
        height: '200',
        width: '400',
        size: '438183',
        url: 'https://media1.giphy.com/media/124n6MRMPIFAMU/200.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.gif&ct=g',
        mp4_size: '89236',
        mp4: 'https://media1.giphy.com/media/124n6MRMPIFAMU/200.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.mp4&ct=g',
        webp_size: '267178',
        webp: 'https://media1.giphy.com/media/124n6MRMPIFAMU/200.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.webp&ct=g',
      },
      fixed_height_downsampled: {
        height: '200',
        width: '400',
        size: '120315',
        url: 'https://media1.giphy.com/media/124n6MRMPIFAMU/200_d.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_d.gif&ct=g',
        webp_size: '81394',
        webp: 'https://media1.giphy.com/media/124n6MRMPIFAMU/200_d.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_d.webp&ct=g',
      },
      fixed_height_small: {
        height: '100',
        width: '200',
        size: '145765',
        url: 'https://media1.giphy.com/media/124n6MRMPIFAMU/100.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.gif&ct=g',
        mp4_size: '30399',
        mp4: 'https://media1.giphy.com/media/124n6MRMPIFAMU/100.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.mp4&ct=g',
        webp_size: '96218',
        webp: 'https://media1.giphy.com/media/124n6MRMPIFAMU/100.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.webp&ct=g',
      },
      fixed_height_small_still: {
        height: '100',
        width: '200',
        size: '11597',
        url: 'https://media1.giphy.com/media/124n6MRMPIFAMU/100_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100_s.gif&ct=g',
      },
      fixed_height_still: {
        height: '200',
        width: '400',
        size: '28873',
        url: 'https://media1.giphy.com/media/124n6MRMPIFAMU/200_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_s.gif&ct=g',
      },
      fixed_width: {
        height: '100',
        width: '200',
        size: '120253',
        url: 'https://media1.giphy.com/media/124n6MRMPIFAMU/200w.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.gif&ct=g',
        mp4_size: '30722',
        mp4: 'https://media1.giphy.com/media/124n6MRMPIFAMU/200w.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.mp4&ct=g',
        webp_size: '96218',
        webp: 'https://media1.giphy.com/media/124n6MRMPIFAMU/200w.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.webp&ct=g',
      },
      fixed_width_downsampled: {
        height: '100',
        width: '200',
        size: '35688',
        url: 'https://media1.giphy.com/media/124n6MRMPIFAMU/200w_d.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_d.gif&ct=g',
        webp_size: '31750',
        webp: 'https://media1.giphy.com/media/124n6MRMPIFAMU/200w_d.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_d.webp&ct=g',
      },
      fixed_width_small: {
        height: '50',
        width: '100',
        size: '42602',
        url: 'https://media1.giphy.com/media/124n6MRMPIFAMU/100w.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.gif&ct=g',
        mp4_size: '12489',
        mp4: 'https://media1.giphy.com/media/124n6MRMPIFAMU/100w.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.mp4&ct=g',
        webp_size: '28532',
        webp: 'https://media1.giphy.com/media/124n6MRMPIFAMU/100w.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.webp&ct=g',
      },
      fixed_width_small_still: {
        height: '50',
        width: '100',
        size: '3889',
        url: 'https://media1.giphy.com/media/124n6MRMPIFAMU/100w_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w_s.gif&ct=g',
      },
      fixed_width_still: {
        height: '100',
        width: '200',
        size: '11378',
        url: 'https://media1.giphy.com/media/124n6MRMPIFAMU/200w_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_s.gif&ct=g',
      },
      looping: {
        mp4_size: '623236',
        mp4: 'https://media1.giphy.com/media/124n6MRMPIFAMU/giphy-loop.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-loop.mp4&ct=g',
      },
      original_still: {
        height: '250',
        width: '500',
        size: '66309',
        url: 'https://media1.giphy.com/media/124n6MRMPIFAMU/giphy_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy_s.gif&ct=g',
      },
      original_mp4: {
        height: '240',
        width: '480',
        mp4_size: '166382',
        mp4: 'https://media1.giphy.com/media/124n6MRMPIFAMU/giphy.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.mp4&ct=g',
      },
      preview: {
        height: '200',
        width: '400',
        mp4_size: '31574',
        mp4: 'https://media1.giphy.com/media/124n6MRMPIFAMU/giphy-preview.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.mp4&ct=g',
      },
      preview_gif: {
        height: '74',
        width: '148',
        size: '49783',
        url: 'https://media1.giphy.com/media/124n6MRMPIFAMU/giphy-preview.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.gif&ct=g',
      },
      preview_webp: {
        height: '112',
        width: '224',
        size: '43986',
        url: 'https://media1.giphy.com/media/124n6MRMPIFAMU/giphy-preview.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.webp&ct=g',
      },
      '480w_still': {
        height: '240',
        width: '480',
        size: '708183',
        url: 'https://media1.giphy.com/media/124n6MRMPIFAMU/480w_s.jpg?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=480w_s.jpg&ct=g',
      },
    },
    user: {
      avatar_url: 'https://media3.giphy.com/avatars/maudit.gif',
      banner_image: '',
      banner_url: '',
      profile_url: 'https://giphy.com/maudit/',
      username: 'maudit',
      display_name: 'Maudit',
      description: '',
      instagram_url: '',
      website_url: 'http://maudit.tumblr.com',
      is_verified: true,
    },
    analytics_response_payload:
      'e=Z2lmX2lkPTEyNG42TVJNUElGQU1VJmV2ZW50X3R5cGU9R0lGX1NFQVJDSCZjaWQ9OWYwZjY0MjV5NWoxNHRjNjEwcW8xNHBybGdwNHUxaHloMHBmdzY4ZDE5eWQ5M3MzJmN0PWc',
    analytics: {
      onload: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPTEyNG42TVJNUElGQU1VJmV2ZW50X3R5cGU9R0lGX1NFQVJDSCZjaWQ9OWYwZjY0MjV5NWoxNHRjNjEwcW8xNHBybGdwNHUxaHloMHBmdzY4ZDE5eWQ5M3MzJmN0PWc&action_type=SEEN',
      },
      onclick: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPTEyNG42TVJNUElGQU1VJmV2ZW50X3R5cGU9R0lGX1NFQVJDSCZjaWQ9OWYwZjY0MjV5NWoxNHRjNjEwcW8xNHBybGdwNHUxaHloMHBmdzY4ZDE5eWQ5M3MzJmN0PWc&action_type=CLICK',
      },
      onsent: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPTEyNG42TVJNUElGQU1VJmV2ZW50X3R5cGU9R0lGX1NFQVJDSCZjaWQ9OWYwZjY0MjV5NWoxNHRjNjEwcW8xNHBybGdwNHUxaHloMHBmdzY4ZDE5eWQ5M3MzJmN0PWc&action_type=SENT',
      },
    },
  },
  {
    type: 'gif',
    id: 'myhb94p7RwksU',
    url: 'https://giphy.com/gifs/batman-film-myhb94p7RwksU',
    slug: 'batman-film-myhb94p7RwksU',
    bitly_gif_url: 'http://gph.is/18LUA2X',
    bitly_url: 'http://gph.is/18LUA2X',
    embed_url: 'https://giphy.com/embed/myhb94p7RwksU',
    username: '',
    source:
      'http://totalfilm.tumblr.com/post/52788966118/worst-to-best-superhero-movie-costumes',
    title: 'tim burton batman GIF',
    rating: 'pg',
    content_url: '',
    source_tld: 'totalfilm.tumblr.com',
    source_post_url:
      'http://totalfilm.tumblr.com/post/52788966118/worst-to-best-superhero-movie-costumes',
    is_sticker: 0,
    import_datetime: '2013-07-28 22:31:57',
    trending_datetime: '1970-01-01 00:00:00',
    images: {
      original: {
        height: '230',
        width: '500',
        size: '401707',
        url: 'https://media4.giphy.com/media/myhb94p7RwksU/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
        mp4_size: '141605',
        mp4: 'https://media4.giphy.com/media/myhb94p7RwksU/giphy.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.mp4&ct=g',
        webp_size: '114842',
        webp: 'https://media4.giphy.com/media/myhb94p7RwksU/giphy.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.webp&ct=g',
        frames: '8',
        hash: '9431875fe83fc2e4593550cbfda67929',
      },
      downsized: {
        height: '230',
        width: '500',
        size: '401707',
        url: 'https://media4.giphy.com/media/myhb94p7RwksU/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_large: {
        height: '230',
        width: '500',
        size: '401707',
        url: 'https://media4.giphy.com/media/myhb94p7RwksU/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_medium: {
        height: '230',
        width: '500',
        size: '401707',
        url: 'https://media4.giphy.com/media/myhb94p7RwksU/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_small: {
        height: '230',
        width: '500',
        mp4_size: '173545',
        mp4: 'https://media4.giphy.com/media/myhb94p7RwksU/giphy-downsized-small.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-downsized-small.mp4&ct=g',
      },
      downsized_still: {
        height: '230',
        width: '500',
        size: '401707',
        url: 'https://media4.giphy.com/media/myhb94p7RwksU/giphy_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy_s.gif&ct=g',
      },
      fixed_height: {
        height: '200',
        width: '435',
        size: '201380',
        url: 'https://media4.giphy.com/media/myhb94p7RwksU/200.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.gif&ct=g',
        mp4_size: '98326',
        mp4: 'https://media4.giphy.com/media/myhb94p7RwksU/200.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.mp4&ct=g',
        webp_size: '83288',
        webp: 'https://media4.giphy.com/media/myhb94p7RwksU/200.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.webp&ct=g',
      },
      fixed_height_downsampled: {
        height: '200',
        width: '435',
        size: '142306',
        url: 'https://media4.giphy.com/media/myhb94p7RwksU/200_d.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_d.gif&ct=g',
        webp_size: '88976',
        webp: 'https://media4.giphy.com/media/myhb94p7RwksU/200_d.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_d.webp&ct=g',
      },
      fixed_height_small: {
        height: '100',
        width: '218',
        size: '61378',
        url: 'https://media4.giphy.com/media/myhb94p7RwksU/100.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.gif&ct=g',
        mp4_size: '29524',
        mp4: 'https://media4.giphy.com/media/myhb94p7RwksU/100.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.mp4&ct=g',
        webp_size: '28934',
        webp: 'https://media4.giphy.com/media/myhb94p7RwksU/100.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.webp&ct=g',
      },
      fixed_height_small_still: {
        height: '100',
        width: '218',
        size: '5331',
        url: 'https://media4.giphy.com/media/myhb94p7RwksU/100_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100_s.gif&ct=g',
      },
      fixed_height_still: {
        height: '200',
        width: '435',
        size: '14962',
        url: 'https://media4.giphy.com/media/myhb94p7RwksU/200_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_s.gif&ct=g',
      },
      fixed_width: {
        height: '92',
        width: '200',
        size: '52802',
        url: 'https://media4.giphy.com/media/myhb94p7RwksU/200w.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.gif&ct=g',
        mp4_size: '26897',
        mp4: 'https://media4.giphy.com/media/myhb94p7RwksU/200w.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.mp4&ct=g',
        webp_size: '25554',
        webp: 'https://media4.giphy.com/media/myhb94p7RwksU/200w.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.webp&ct=g',
      },
      fixed_width_downsampled: {
        height: '92',
        width: '200',
        size: '37229',
        url: 'https://media4.giphy.com/media/myhb94p7RwksU/200w_d.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_d.gif&ct=g',
        webp_size: '25690',
        webp: 'https://media4.giphy.com/media/myhb94p7RwksU/200w_d.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_d.webp&ct=g',
      },
      fixed_width_small: {
        height: '46',
        width: '100',
        size: '18522',
        url: 'https://media4.giphy.com/media/myhb94p7RwksU/100w.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.gif&ct=g',
        mp4_size: '9550',
        mp4: 'https://media4.giphy.com/media/myhb94p7RwksU/100w.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.mp4&ct=g',
        webp_size: '9610',
        webp: 'https://media4.giphy.com/media/myhb94p7RwksU/100w.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.webp&ct=g',
      },
      fixed_width_small_still: {
        height: '46',
        width: '100',
        size: '2209',
        url: 'https://media4.giphy.com/media/myhb94p7RwksU/100w_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w_s.gif&ct=g',
      },
      fixed_width_still: {
        height: '92',
        width: '200',
        size: '4742',
        url: 'https://media4.giphy.com/media/myhb94p7RwksU/200w_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_s.gif&ct=g',
      },
      looping: {
        mp4_size: '1008542',
        mp4: 'https://media4.giphy.com/media/myhb94p7RwksU/giphy-loop.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-loop.mp4&ct=g',
      },
      original_still: {
        height: '230',
        width: '500',
        size: '45875',
        url: 'https://media4.giphy.com/media/myhb94p7RwksU/giphy_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy_s.gif&ct=g',
      },
      original_mp4: {
        height: '220',
        width: '480',
        mp4_size: '141605',
        mp4: 'https://media4.giphy.com/media/myhb94p7RwksU/giphy.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.mp4&ct=g',
      },
      preview: {
        height: '122',
        width: '265',
        mp4_size: '26075',
        mp4: 'https://media4.giphy.com/media/myhb94p7RwksU/giphy-preview.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.mp4&ct=g',
      },
      preview_gif: {
        height: '54',
        width: '117',
        size: '48212',
        url: 'https://media4.giphy.com/media/myhb94p7RwksU/giphy-preview.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.gif&ct=g',
      },
      preview_webp: {
        height: '200',
        width: '434',
        size: '44054',
        url: 'https://media4.giphy.com/media/myhb94p7RwksU/giphy-preview.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.webp&ct=g',
      },
      '480w_still': {
        height: '221',
        width: '480',
        size: '401707',
        url: 'https://media4.giphy.com/media/myhb94p7RwksU/480w_s.jpg?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=480w_s.jpg&ct=g',
      },
    },
    analytics_response_payload:
      'e=Z2lmX2lkPW15aGI5NHA3Undrc1UmZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw',
    analytics: {
      onload: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPW15aGI5NHA3Undrc1UmZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw&action_type=SEEN',
      },
      onclick: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPW15aGI5NHA3Undrc1UmZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw&action_type=CLICK',
      },
      onsent: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPW15aGI5NHA3Undrc1UmZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw&action_type=SENT',
      },
    },
  },
  {
    type: 'gif',
    id: 'Sid4QgwDxJ8l2',
    url: 'https://giphy.com/gifs/batman-reaction-Sid4QgwDxJ8l2',
    slug: 'batman-reaction-Sid4QgwDxJ8l2',
    bitly_gif_url: 'http://gph.is/15vx4QK',
    bitly_url: 'http://gph.is/15vx4QK',
    embed_url: 'https://giphy.com/embed/Sid4QgwDxJ8l2',
    username: '',
    source:
      'http://awesomegifs.com/2013/08/when-we-found-out-ben-affleck-was-playing-batman/',
    title: 'sad batman GIF',
    rating: 'g',
    content_url: '',
    source_tld: 'awesomegifs.com',
    source_post_url:
      'http://awesomegifs.com/2013/08/when-we-found-out-ben-affleck-was-playing-batman/',
    is_sticker: 0,
    import_datetime: '2013-09-04 17:30:44',
    trending_datetime: '1970-01-01 00:00:00',
    images: {
      original: {
        height: '300',
        width: '400',
        size: '576826',
        url: 'https://media3.giphy.com/media/Sid4QgwDxJ8l2/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
        mp4_size: '473982',
        mp4: 'https://media3.giphy.com/media/Sid4QgwDxJ8l2/giphy.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.mp4&ct=g',
        webp_size: '364340',
        webp: 'https://media3.giphy.com/media/Sid4QgwDxJ8l2/giphy.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.webp&ct=g',
        frames: '42',
        hash: '1039c6f0d2aa47035b58b64a47a52b7a',
      },
      downsized: {
        height: '300',
        width: '400',
        size: '576826',
        url: 'https://media3.giphy.com/media/Sid4QgwDxJ8l2/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_large: {
        height: '300',
        width: '400',
        size: '576826',
        url: 'https://media3.giphy.com/media/Sid4QgwDxJ8l2/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_medium: {
        height: '300',
        width: '400',
        size: '576826',
        url: 'https://media3.giphy.com/media/Sid4QgwDxJ8l2/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_small: {
        height: '210',
        width: '280',
        mp4_size: '78690',
        mp4: 'https://media3.giphy.com/media/Sid4QgwDxJ8l2/giphy-downsized-small.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-downsized-small.mp4&ct=g',
      },
      downsized_still: {
        height: '300',
        width: '400',
        size: '576826',
        url: 'https://media3.giphy.com/media/Sid4QgwDxJ8l2/giphy_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy_s.gif&ct=g',
      },
      fixed_height: {
        height: '200',
        width: '267',
        size: '358256',
        url: 'https://media3.giphy.com/media/Sid4QgwDxJ8l2/200.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.gif&ct=g',
        mp4_size: '151967',
        mp4: 'https://media3.giphy.com/media/Sid4QgwDxJ8l2/200.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.mp4&ct=g',
        webp_size: '197888',
        webp: 'https://media3.giphy.com/media/Sid4QgwDxJ8l2/200.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.webp&ct=g',
      },
      fixed_height_downsampled: {
        height: '200',
        width: '267',
        size: '56698',
        url: 'https://media3.giphy.com/media/Sid4QgwDxJ8l2/200_d.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_d.gif&ct=g',
        webp_size: '37380',
        webp: 'https://media3.giphy.com/media/Sid4QgwDxJ8l2/200_d.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_d.webp&ct=g',
      },
      fixed_height_small: {
        height: '100',
        width: '134',
        size: '141533',
        url: 'https://media3.giphy.com/media/Sid4QgwDxJ8l2/100.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.gif&ct=g',
        mp4_size: '48185',
        mp4: 'https://media3.giphy.com/media/Sid4QgwDxJ8l2/100.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.mp4&ct=g',
        webp_size: '82564',
        webp: 'https://media3.giphy.com/media/Sid4QgwDxJ8l2/100.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.webp&ct=g',
      },
      fixed_height_small_still: {
        height: '100',
        width: '134',
        size: '4385',
        url: 'https://media3.giphy.com/media/Sid4QgwDxJ8l2/100_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100_s.gif&ct=g',
      },
      fixed_height_still: {
        height: '200',
        width: '267',
        size: '10131',
        url: 'https://media3.giphy.com/media/Sid4QgwDxJ8l2/200_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_s.gif&ct=g',
      },
      fixed_width: {
        height: '150',
        width: '200',
        size: '226664',
        url: 'https://media3.giphy.com/media/Sid4QgwDxJ8l2/200w.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.gif&ct=g',
        mp4_size: '94728',
        mp4: 'https://media3.giphy.com/media/Sid4QgwDxJ8l2/200w.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.mp4&ct=g',
        webp_size: '137268',
        webp: 'https://media3.giphy.com/media/Sid4QgwDxJ8l2/200w.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.webp&ct=g',
      },
      fixed_width_downsampled: {
        height: '150',
        width: '200',
        size: '36403',
        url: 'https://media3.giphy.com/media/Sid4QgwDxJ8l2/200w_d.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_d.gif&ct=g',
        webp_size: '26618',
        webp: 'https://media3.giphy.com/media/Sid4QgwDxJ8l2/200w_d.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_d.webp&ct=g',
      },
      fixed_width_small: {
        height: '75',
        width: '100',
        size: '95042',
        url: 'https://media3.giphy.com/media/Sid4QgwDxJ8l2/100w.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.gif&ct=g',
        mp4_size: '30851',
        mp4: 'https://media3.giphy.com/media/Sid4QgwDxJ8l2/100w.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.mp4&ct=g',
        webp_size: '55590',
        webp: 'https://media3.giphy.com/media/Sid4QgwDxJ8l2/100w.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.webp&ct=g',
      },
      fixed_width_small_still: {
        height: '75',
        width: '100',
        size: '3277',
        url: 'https://media3.giphy.com/media/Sid4QgwDxJ8l2/100w_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w_s.gif&ct=g',
      },
      fixed_width_still: {
        height: '150',
        width: '200',
        size: '6982',
        url: 'https://media3.giphy.com/media/Sid4QgwDxJ8l2/200w_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_s.gif&ct=g',
      },
      looping: {
        mp4_size: '1331798',
        mp4: 'https://media3.giphy.com/media/Sid4QgwDxJ8l2/giphy-loop.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-loop.mp4&ct=g',
      },
      original_still: {
        height: '300',
        width: '400',
        size: '17804',
        url: 'https://media3.giphy.com/media/Sid4QgwDxJ8l2/giphy_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy_s.gif&ct=g',
      },
      original_mp4: {
        height: '360',
        width: '480',
        mp4_size: '473982',
        mp4: 'https://media3.giphy.com/media/Sid4QgwDxJ8l2/giphy.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.mp4&ct=g',
      },
      preview: {
        height: '240',
        width: '320',
        mp4_size: '32410',
        mp4: 'https://media3.giphy.com/media/Sid4QgwDxJ8l2/giphy-preview.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.mp4&ct=g',
      },
      preview_gif: {
        height: '67',
        width: '89',
        size: '47301',
        url: 'https://media3.giphy.com/media/Sid4QgwDxJ8l2/giphy-preview.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.gif&ct=g',
      },
      preview_webp: {
        height: '186',
        width: '248',
        size: '48042',
        url: 'https://media3.giphy.com/media/Sid4QgwDxJ8l2/giphy-preview.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.webp&ct=g',
      },
      '480w_still': {
        height: '360',
        width: '480',
        size: '576826',
        url: 'https://media3.giphy.com/media/Sid4QgwDxJ8l2/480w_s.jpg?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=480w_s.jpg&ct=g',
      },
    },
    analytics_response_payload:
      'e=Z2lmX2lkPVNpZDRRZ3dEeEo4bDImZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw',
    analytics: {
      onload: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPVNpZDRRZ3dEeEo4bDImZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw&action_type=SEEN',
      },
      onclick: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPVNpZDRRZ3dEeEo4bDImZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw&action_type=CLICK',
      },
      onsent: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPVNpZDRRZ3dEeEo4bDImZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw&action_type=SENT',
      },
    },
  },
  {
    type: 'gif',
    id: 'NkhH0Jjdo79wQ',
    url: 'https://giphy.com/gifs/chappelles-show-NkhH0Jjdo79wQ',
    slug: 'chappelles-show-NkhH0Jjdo79wQ',
    bitly_gif_url: 'http://gph.is/29lNvJ1',
    bitly_url: 'http://gph.is/29lNvJ1',
    embed_url: 'https://giphy.com/embed/NkhH0Jjdo79wQ',
    username: '',
    source:
      'http://thecelebrityauction.co/wp2/cwitter-article/the-best-prince-pop-culture-moments-you-probably-forgot-about/',
    title: 'Michael Keaton Smile GIF',
    rating: 'g',
    content_url: '',
    source_tld: 'thecelebrityauction.co',
    source_post_url:
      'http://thecelebrityauction.co/wp2/cwitter-article/the-best-prince-pop-culture-moments-you-probably-forgot-about/',
    is_sticker: 0,
    import_datetime: '2016-07-07 19:38:14',
    trending_datetime: '2017-09-05 18:00:00',
    images: {
      original: {
        height: '274',
        width: '500',
        size: '281301',
        url: 'https://media2.giphy.com/media/NkhH0Jjdo79wQ/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
        mp4_size: '45042',
        mp4: 'https://media2.giphy.com/media/NkhH0Jjdo79wQ/giphy.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.mp4&ct=g',
        webp_size: '77244',
        webp: 'https://media2.giphy.com/media/NkhH0Jjdo79wQ/giphy.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.webp&ct=g',
        frames: '11',
        hash: '6cd0f2d2b816a2a7af644d29881a5de4',
      },
      downsized: {
        height: '274',
        width: '500',
        size: '281301',
        url: 'https://media2.giphy.com/media/NkhH0Jjdo79wQ/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_large: {
        height: '274',
        width: '500',
        size: '281301',
        url: 'https://media2.giphy.com/media/NkhH0Jjdo79wQ/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_medium: {
        height: '274',
        width: '500',
        size: '281301',
        url: 'https://media2.giphy.com/media/NkhH0Jjdo79wQ/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_small: {
        height: '274',
        width: '500',
        mp4_size: '67228',
        mp4: 'https://media2.giphy.com/media/NkhH0Jjdo79wQ/giphy-downsized-small.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-downsized-small.mp4&ct=g',
      },
      downsized_still: {
        height: '274',
        width: '500',
        size: '281301',
        url: 'https://media2.giphy.com/media/NkhH0Jjdo79wQ/giphy_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy_s.gif&ct=g',
      },
      fixed_height: {
        height: '200',
        width: '365',
        size: '159509',
        url: 'https://media2.giphy.com/media/NkhH0Jjdo79wQ/200.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.gif&ct=g',
        mp4_size: '22013',
        mp4: 'https://media2.giphy.com/media/NkhH0Jjdo79wQ/200.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.mp4&ct=g',
        webp_size: '42040',
        webp: 'https://media2.giphy.com/media/NkhH0Jjdo79wQ/200.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.webp&ct=g',
      },
      fixed_height_downsampled: {
        height: '200',
        width: '365',
        size: '93254',
        url: 'https://media2.giphy.com/media/NkhH0Jjdo79wQ/200_d.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_d.gif&ct=g',
        webp_size: '52132',
        webp: 'https://media2.giphy.com/media/NkhH0Jjdo79wQ/200_d.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_d.webp&ct=g',
      },
      fixed_height_small: {
        height: '100',
        width: '183',
        size: '55996',
        url: 'https://media2.giphy.com/media/NkhH0Jjdo79wQ/100.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.gif&ct=g',
        mp4_size: '8425',
        mp4: 'https://media2.giphy.com/media/NkhH0Jjdo79wQ/100.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.mp4&ct=g',
        webp_size: '19194',
        webp: 'https://media2.giphy.com/media/NkhH0Jjdo79wQ/100.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.webp&ct=g',
      },
      fixed_height_small_still: {
        height: '100',
        width: '183',
        size: '5831',
        url: 'https://media2.giphy.com/media/NkhH0Jjdo79wQ/100_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100_s.gif&ct=g',
      },
      fixed_height_still: {
        height: '200',
        width: '365',
        size: '15025',
        url: 'https://media2.giphy.com/media/NkhH0Jjdo79wQ/200_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_s.gif&ct=g',
      },
      fixed_width: {
        height: '110',
        width: '200',
        size: '61128',
        url: 'https://media2.giphy.com/media/NkhH0Jjdo79wQ/200w.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.gif&ct=g',
        mp4_size: '8993',
        mp4: 'https://media2.giphy.com/media/NkhH0Jjdo79wQ/200w.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.mp4&ct=g',
        webp_size: '19594',
        webp: 'https://media2.giphy.com/media/NkhH0Jjdo79wQ/200w.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.webp&ct=g',
      },
      fixed_width_downsampled: {
        height: '110',
        width: '200',
        size: '34155',
        url: 'https://media2.giphy.com/media/NkhH0Jjdo79wQ/200w_d.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_d.gif&ct=g',
        webp_size: '20360',
        webp: 'https://media2.giphy.com/media/NkhH0Jjdo79wQ/200w_d.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_d.webp&ct=g',
      },
      fixed_width_small: {
        height: '55',
        width: '100',
        size: '24541',
        url: 'https://media2.giphy.com/media/NkhH0Jjdo79wQ/100w.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.gif&ct=g',
        mp4_size: '4361',
        mp4: 'https://media2.giphy.com/media/NkhH0Jjdo79wQ/100w.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.mp4&ct=g',
        webp_size: '9804',
        webp: 'https://media2.giphy.com/media/NkhH0Jjdo79wQ/100w.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.webp&ct=g',
      },
      fixed_width_small_still: {
        height: '55',
        width: '100',
        size: '2934',
        url: 'https://media2.giphy.com/media/NkhH0Jjdo79wQ/100w_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w_s.gif&ct=g',
      },
      fixed_width_still: {
        height: '110',
        width: '200',
        size: '6564',
        url: 'https://media2.giphy.com/media/NkhH0Jjdo79wQ/200w_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_s.gif&ct=g',
      },
      looping: {
        mp4_size: '483710',
        mp4: 'https://media2.giphy.com/media/NkhH0Jjdo79wQ/giphy-loop.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-loop.mp4&ct=g',
      },
      original_still: {
        height: '274',
        width: '500',
        size: '44812',
        url: 'https://media2.giphy.com/media/NkhH0Jjdo79wQ/giphy_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy_s.gif&ct=g',
      },
      original_mp4: {
        height: '262',
        width: '480',
        mp4_size: '45042',
        mp4: 'https://media2.giphy.com/media/NkhH0Jjdo79wQ/giphy.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.mp4&ct=g',
      },
      preview: {
        height: '234',
        width: '427',
        mp4_size: '14418',
        mp4: 'https://media2.giphy.com/media/NkhH0Jjdo79wQ/giphy-preview.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.mp4&ct=g',
      },
      preview_gif: {
        height: '57',
        width: '104',
        size: '48741',
        url: 'https://media2.giphy.com/media/NkhH0Jjdo79wQ/giphy-preview.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.gif&ct=g',
      },
      preview_webp: {
        height: '212',
        width: '386',
        size: '42090',
        url: 'https://media2.giphy.com/media/NkhH0Jjdo79wQ/giphy-preview.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.webp&ct=g',
      },
      '480w_still': {
        height: '263',
        width: '480',
        size: '281301',
        url: 'https://media2.giphy.com/media/NkhH0Jjdo79wQ/480w_s.jpg?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=480w_s.jpg&ct=g',
      },
    },
    analytics_response_payload:
      'e=Z2lmX2lkPU5raEgwSmpkbzc5d1EmZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw',
    analytics: {
      onload: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPU5raEgwSmpkbzc5d1EmZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw&action_type=SEEN',
      },
      onclick: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPU5raEgwSmpkbzc5d1EmZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw&action_type=CLICK',
      },
      onsent: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPU5raEgwSmpkbzc5d1EmZXZlbnRfdHlwZT1HSUZfU0VBUkNIJmNpZD05ZjBmNjQyNXk1ajE0dGM2MTBxbzE0cHJsZ3A0dTFoeWgwcGZ3NjhkMTl5ZDkzczMmY3Q9Zw&action_type=SENT',
      },
    },
  },
  {
    type: 'gif',
    id: '26CalmkpfFU3io3yU',
    url: 'https://giphy.com/gifs/theyetee-yetee-26CalmkpfFU3io3yU',
    slug: 'theyetee-yetee-26CalmkpfFU3io3yU',
    bitly_gif_url: 'http://gph.is/1TYgC5I',
    bitly_url: 'http://gph.is/1TYgC5I',
    embed_url: 'https://giphy.com/embed/26CalmkpfFU3io3yU',
    username: 'theyetee',
    source: '',
    title: 'Best Friends Yes GIF by The Yetee',
    rating: 'pg',
    content_url: '',
    source_tld: '',
    source_post_url: '',
    is_sticker: 0,
    import_datetime: '2016-01-14 20:04:43',
    trending_datetime: '2016-03-25 17:00:02',
    images: {
      original: {
        height: '270',
        width: '480',
        size: '429430',
        url: 'https://media1.giphy.com/media/26CalmkpfFU3io3yU/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
        mp4_size: '85817',
        mp4: 'https://media1.giphy.com/media/26CalmkpfFU3io3yU/giphy.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.mp4&ct=g',
        webp_size: '147378',
        webp: 'https://media1.giphy.com/media/26CalmkpfFU3io3yU/giphy.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.webp&ct=g',
        frames: '7',
        hash: 'b16e310854eedf42b77dd3feea043b7a',
      },
      downsized: {
        height: '270',
        width: '480',
        size: '429430',
        url: 'https://media1.giphy.com/media/26CalmkpfFU3io3yU/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_large: {
        height: '270',
        width: '480',
        size: '429430',
        url: 'https://media1.giphy.com/media/26CalmkpfFU3io3yU/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_medium: {
        height: '270',
        width: '480',
        size: '429430',
        url: 'https://media1.giphy.com/media/26CalmkpfFU3io3yU/giphy.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.gif&ct=g',
      },
      downsized_small: {
        height: '270',
        width: '480',
        mp4_size: '108412',
        mp4: 'https://media1.giphy.com/media/26CalmkpfFU3io3yU/giphy-downsized-small.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-downsized-small.mp4&ct=g',
      },
      downsized_still: {
        height: '270',
        width: '480',
        size: '429430',
        url: 'https://media1.giphy.com/media/26CalmkpfFU3io3yU/giphy_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy_s.gif&ct=g',
      },
      fixed_height: {
        height: '200',
        width: '356',
        size: '164534',
        url: 'https://media1.giphy.com/media/26CalmkpfFU3io3yU/200.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.gif&ct=g',
        mp4_size: '36558',
        mp4: 'https://media1.giphy.com/media/26CalmkpfFU3io3yU/200.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.mp4&ct=g',
        webp_size: '85302',
        webp: 'https://media1.giphy.com/media/26CalmkpfFU3io3yU/200.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200.webp&ct=g',
      },
      fixed_height_downsampled: {
        height: '200',
        width: '356',
        size: '148121',
        url: 'https://media1.giphy.com/media/26CalmkpfFU3io3yU/200_d.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_d.gif&ct=g',
        webp_size: '96206',
        webp: 'https://media1.giphy.com/media/26CalmkpfFU3io3yU/200_d.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_d.webp&ct=g',
      },
      fixed_height_small: {
        height: '100',
        width: '178',
        size: '65100',
        url: 'https://media1.giphy.com/media/26CalmkpfFU3io3yU/100.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.gif&ct=g',
        mp4_size: '12906',
        mp4: 'https://media1.giphy.com/media/26CalmkpfFU3io3yU/100.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.mp4&ct=g',
        webp_size: '34564',
        webp: 'https://media1.giphy.com/media/26CalmkpfFU3io3yU/100.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100.webp&ct=g',
      },
      fixed_height_small_still: {
        height: '100',
        width: '178',
        size: '9962',
        url: 'https://media1.giphy.com/media/26CalmkpfFU3io3yU/100_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100_s.gif&ct=g',
      },
      fixed_height_still: {
        height: '200',
        width: '356',
        size: '26238',
        url: 'https://media1.giphy.com/media/26CalmkpfFU3io3yU/200_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200_s.gif&ct=g',
      },
      fixed_width: {
        height: '113',
        width: '200',
        size: '92597',
        url: 'https://media1.giphy.com/media/26CalmkpfFU3io3yU/200w.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.gif&ct=g',
        mp4_size: '14569',
        mp4: 'https://media1.giphy.com/media/26CalmkpfFU3io3yU/200w.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.mp4&ct=g',
        webp_size: '39470',
        webp: 'https://media1.giphy.com/media/26CalmkpfFU3io3yU/200w.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w.webp&ct=g',
      },
      fixed_width_downsampled: {
        height: '113',
        width: '200',
        size: '72646',
        url: 'https://media1.giphy.com/media/26CalmkpfFU3io3yU/200w_d.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_d.gif&ct=g',
        webp_size: '40826',
        webp: 'https://media1.giphy.com/media/26CalmkpfFU3io3yU/200w_d.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_d.webp&ct=g',
      },
      fixed_width_small: {
        height: '57',
        width: '100',
        size: '22627',
        url: 'https://media1.giphy.com/media/26CalmkpfFU3io3yU/100w.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.gif&ct=g',
        mp4_size: '6056',
        mp4: 'https://media1.giphy.com/media/26CalmkpfFU3io3yU/100w.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.mp4&ct=g',
        webp_size: '15794',
        webp: 'https://media1.giphy.com/media/26CalmkpfFU3io3yU/100w.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w.webp&ct=g',
      },
      fixed_width_small_still: {
        height: '57',
        width: '100',
        size: '4037',
        url: 'https://media1.giphy.com/media/26CalmkpfFU3io3yU/100w_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=100w_s.gif&ct=g',
      },
      fixed_width_still: {
        height: '113',
        width: '200',
        size: '12639',
        url: 'https://media1.giphy.com/media/26CalmkpfFU3io3yU/200w_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=200w_s.gif&ct=g',
      },
      looping: {
        mp4_size: '2843147',
        mp4: 'https://media1.giphy.com/media/26CalmkpfFU3io3yU/giphy-loop.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-loop.mp4&ct=g',
      },
      original_still: {
        height: '270',
        width: '480',
        size: '89226',
        url: 'https://media1.giphy.com/media/26CalmkpfFU3io3yU/giphy_s.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy_s.gif&ct=g',
      },
      original_mp4: {
        height: '270',
        width: '480',
        mp4_size: '85817',
        mp4: 'https://media1.giphy.com/media/26CalmkpfFU3io3yU/giphy.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy.mp4&ct=g',
      },
      preview: {
        height: '182',
        width: '323',
        mp4_size: '17024',
        mp4: 'https://media1.giphy.com/media/26CalmkpfFU3io3yU/giphy-preview.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.mp4&ct=g',
      },
      preview_gif: {
        height: '71',
        width: '126',
        size: '49729',
        url: 'https://media1.giphy.com/media/26CalmkpfFU3io3yU/giphy-preview.gif?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.gif&ct=g',
      },
      preview_webp: {
        height: '136',
        width: '242',
        size: '43638',
        url: 'https://media1.giphy.com/media/26CalmkpfFU3io3yU/giphy-preview.webp?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-preview.webp&ct=g',
      },
      hd: {
        height: '540',
        width: '960',
        mp4_size: '842905',
        mp4: 'https://media1.giphy.com/media/26CalmkpfFU3io3yU/giphy-hd.mp4?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=giphy-hd.mp4&ct=g',
      },
      '480w_still': {
        height: '270',
        width: '480',
        size: '429430',
        url: 'https://media1.giphy.com/media/26CalmkpfFU3io3yU/480w_s.jpg?cid=9f0f6425y5j14tc610qo14prlgp4u1hyh0pfw68d19yd93s3&rid=480w_s.jpg&ct=g',
      },
    },
    user: {
      avatar_url: 'https://media1.giphy.com/avatars/theyetee/SuruGffjTbXe.jpg',
      banner_image:
        'https://media1.giphy.com/headers/theyetee/qiYFkyXA7Mjv.jpg',
      banner_url: 'https://media1.giphy.com/headers/theyetee/qiYFkyXA7Mjv.jpg',
      profile_url: 'https://giphy.com/theyetee/',
      username: 'theyetee',
      display_name: 'The Yetee',
      description:
        'The Yetee, a game loving, beef jerky eating, mythical beast— working with artists to make you hi-quality & affordable clothes and art.',
      instagram_url: 'https://instagram.com/theyetee',
      website_url: 'http://www.theyetee.com',
      is_verified: true,
    },
    analytics_response_payload:
      'e=Z2lmX2lkPTI2Q2FsbWtwZkZVM2lvM3lVJmV2ZW50X3R5cGU9R0lGX1NFQVJDSCZjaWQ9OWYwZjY0MjV5NWoxNHRjNjEwcW8xNHBybGdwNHUxaHloMHBmdzY4ZDE5eWQ5M3MzJmN0PWc',
    analytics: {
      onload: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPTI2Q2FsbWtwZkZVM2lvM3lVJmV2ZW50X3R5cGU9R0lGX1NFQVJDSCZjaWQ9OWYwZjY0MjV5NWoxNHRjNjEwcW8xNHBybGdwNHUxaHloMHBmdzY4ZDE5eWQ5M3MzJmN0PWc&action_type=SEEN',
      },
      onclick: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPTI2Q2FsbWtwZkZVM2lvM3lVJmV2ZW50X3R5cGU9R0lGX1NFQVJDSCZjaWQ9OWYwZjY0MjV5NWoxNHRjNjEwcW8xNHBybGdwNHUxaHloMHBmdzY4ZDE5eWQ5M3MzJmN0PWc&action_type=CLICK',
      },
      onsent: {
        url: 'https://giphy-analytics.giphy.com/v2/pingback_simple?analytics_response_payload=e%3DZ2lmX2lkPTI2Q2FsbWtwZkZVM2lvM3lVJmV2ZW50X3R5cGU9R0lGX1NFQVJDSCZjaWQ9OWYwZjY0MjV5NWoxNHRjNjEwcW8xNHBybGdwNHUxaHloMHBmdzY4ZDE5eWQ5M3MzJmN0PWc&action_type=SENT',
      },
    },
  },
];
