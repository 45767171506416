import { useGetSubscriptionsByOwnerIdQuery } from "../graphql/generated/schema";
import { useToast } from "./useToast";

// TODO: Paginate properly
export function useSubscriptions({ skip } = { skip: false }) {
  const {
    data,
    error: errorActiveSubscriptions,
    ...rest
  } = useGetSubscriptionsByOwnerIdQuery({
    variables: { input: { limit: 100 } },
    skip,
  });

  useToast({
    id: "errorActiveSubscriptions",
    show: !!errorActiveSubscriptions,
    title: "Something went wrong",
    // description: errorActiveSubscriptions?.message,
    description: "Could not fetch subscriptions",
    status: "error",
  });

  const activeSubscriptions = data?.getSubscriptionsByOwnerId.items;
  return { activeSubscriptions, ...rest };
}
