import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Container,
  Flex,
  HStack,
  Input,
  Stack,
  Text,
  Textarea,
  VStack,
  Spinner,
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/react";
import { DeletablePhoto, FontPicker } from "../../feature";
import { FaSave as SaveIcon } from "react-icons/fa";
import { ColorPicker, EmojiPicker, PhotoModalButtons } from "./components";
import {
  entrySliceActions,
  selectors,
  useAppDispatch,
  useAppSelector,
} from "../../state-management";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth, useCard, useToast, useUser } from "../../hooks";
import { useForm } from "react-hook-form";
import { UpgradeCardAlert } from "../CardolyCardPage/components";
import {
  useAddCardEntryMutation,
  useGetCardEntryQuery,
  useUpdateCardEntryMutation,
} from "../../graphql/generated/schema";
import { invalidateCacheForCardUpdate } from "../../util/invalidateCacheForCardUpdate";
import { WhyEmailPrompt } from "./components/WhyEmailPrompt";
import { cloneDeep } from "lodash";
import { sendToSlack } from "../../util/sendToSlack";
import { useDocumentTitle } from "usehooks-ts";

interface EditCardEntryPageProps {
  isAddingNewCardEntry?: boolean;
}

// interface FormInputs extends CardEntry {
interface FormInputs extends CardEntry {
  signerEmail?: string;
  signerName: string;
  entryMessage: string;
}

export function EditCardEntryPage({
  isAddingNewCardEntry = false,
}: EditCardEntryPageProps) {
  const { isAuthenticated, userId } = useAuth();
  // const charsLeft = null; // TODO: Implement fully.
  const { cardID, card, hasReachedMaxEntries } = useCard();

  const receiverName = card?.receiverName;
  useDocumentTitle(
    `${
      isAddingNewCardEntry ? "Add entry to" : "Edit entry for"
    } ${receiverName}'s Cardoly | Cardoly`
  );

  const actionButtonText = isAddingNewCardEntry
    ? "Add message"
    : "Update message";
  const headerText = isAddingNewCardEntry
    ? "Add your message"
    : "Update your message";
  const { id } = useParams<"id">();
  const navigate = useNavigate();
  const cachedEditedEntry = useAppSelector(
    selectors.entrySelectors.getEditedCardEntry
  );
  const [skip, setSkip] = useState<boolean>(
    !!cachedEditedEntry || isAddingNewCardEntry
  );
  const { loading: isFetchingCardEntries, data } = useGetCardEntryQuery({
    variables: { getCardEntryId: id ?? "" },
    skip,
  });

  const defaultCardEntryStateForNewEntry = isAddingNewCardEntry
    ? { font: { fontFamily: "Coming Soon" } } // Add default font color to keep consistent
    : {};

  const dispatch = useAppDispatch();
  const {
    reset,
    setValue,
    getValues,
    register,
    handleSubmit,
    watch,
    formState: { errors }, // errors are subscribed and reactive to state update
  } = useForm<FormInputs>({
    defaultValues: {
      ...(isAddingNewCardEntry
        ? defaultCardEntryStateForNewEntry
        : cachedEditedEntry // cachedEditedEntry is only valid in edit mode. It is added in Redux when user clicks edit from the card list page.
        ? cachedEditedEntry
        : {}),
    },
  });

  // TODO: Place everything in their own container! Each interacton with
  const serverEntry = data?.getCardEntry;
  const entry = cachedEditedEntry ? cachedEditedEntry : serverEntry;
  const selectedFont = watch("font.fontFamily");
  const textColor = watch("font.color");
  const { name } = useUser();

  useEffect(() => {
    if (serverEntry) {
      reset(cloneDeep(serverEntry));
    }
  }, [reset, serverEntry]);

  useEffect(() => {
    // isAddingNewCardEntry is to Preserve whatever full name used when card was created previously.
    if (name && isAddingNewCardEntry) {
      setValue("signerName", name);
    }
  }, [name, isAddingNewCardEntry, setValue]);

  useEffect(() => {
    // isAddingNewCardEntry is to Preserve whatever full name used when card was created previously.
    if (userId && isAddingNewCardEntry) {
      setValue("signerCognitoID", userId);
    }
  }, [userId, isAddingNewCardEntry, setValue]);

  const [
    handleUpdateCardEntry,
    { data: updateData, loading: isUpdating, error: updateError },
  ] = useUpdateCardEntryMutation();
  const isUpdatingSuccess = !!updateData;
  const isUpdatingError = !!updateError;

  const [
    handleAddCardEntry,
    { data: addData, loading: isAdding, error: addError },
  ] = useAddCardEntryMutation();
  const isAddingSuccess = !!addData;
  const isAddingError = !!addError;

  const isLoading = isUpdating || isAdding;
  const isSuccess = isUpdatingSuccess || isAddingSuccess;
  const isError = isUpdatingError || isAddingError;
  const errorMessage = addError?.message || updateError?.message;

  // Hydrate store? Or Hydrate RTK Query?
  useEffect(() => {
    if (entry?.id) {
      setSkip(true);
      // TODO: Dispatch this whenever the card is clicked from the entry list page, to avoid fetch in this component
      // We manually add userId here because some bug in Amplify doesn't do so automatically when @auth directive is used with 'groups'
      dispatch(entrySliceActions.addEntry(entry));
    }
  }, [entry?.id]);

  // Cleanup on unmount to prevent bugs
  useEffect(() => {
    // When component unmounts, remove from store
    return () => {
      dispatch(entrySliceActions.reset());
    };
  }, []);

  // Head home after successful add or edit of card entry
  useEffect(() => {
    if (isSuccess) {
      navigate(`/card/${cardID}`);
    }
  }, [isSuccess]);

  // Setup toasts
  useToast({
    id: "update",
    title: "Something went wrong",
    description: errorMessage,
    show: isError,
    status: "error",
  });

  function handleEmojiChange(emoji: string) {
    const entryMessage = getValues("entryMessage");
    const newEntryMessage = `${
      entryMessage ? `${entryMessage}${emoji}` : `${emoji}`
    }`;

    setValue("entryMessage", newEntryMessage);
  }
  function handleTextColorChange(fontColor: string) {
    setValue("font.color", fontColor);
  }

  function handleSubmitEntry() {
    // const photo: CardEntry["photo"] = entry?.photo
    //   ? entry.photo
    //   : // Deletes photo. Tried to pass 'undefined' but it doesn't go via Apollo. If we allow graphQL accept nulls (which should be the solution since the server allows nulls), many other items in Chakra UI will break, sicne they don't accept nulls (e.g. Text.fontFamily)
    //     { urls: { sm: "", md: "" }, externalId: "" };
    const photo: any = !!entry?.photo?.type ? entry.photo : null; // Deletes photo. Using "any" to simplify TS issues (should be CardEntry["photo"]). Tried to pass 'undefined' but it doesn't go via Apollo. If we allow graphQL accept nulls (which should be the solution since the server allows nulls), many other items in Chakra UI will break, sicne they don't accept nulls (e.g. Text.fontFamily)

    if (cardID) {
      if (isAddingNewCardEntry) {
        const onSubmit = (updateInput: FormInputs) => {
          const rawInput = {
            photo,
            ...updateInput,
            cardID,
          };

          const { id, ...input } = rawInput; // Deleting id
          handleAddCardEntry({
            variables: { input },
            update: invalidateCacheForCardUpdate,
            onCompleted: () => {
              const baseSlackMessage = getBaseSlackMessage(cardID, input);
              sendToSlack({
                channel: "entries",
                text: `[Adding]\n${baseSlackMessage}`,
                // blocks: [
                //   {
                //     type: "section",
                //     text: {
                //       type: "mrkdwn",
                //       text: `New card entry added by ${updateInput.signerName}`,
                //     },
                //   },
                // ],
              });
            },
          });
        };
        handleSubmit(onSubmit)();
      } else if (entry && cardID) {
        const onSubmit = (updateInput: FormInputs) => {
          const input = {
            ...updateInput,
            photo,
            id: entry.id,
          };
          delete input.createdAt;
          delete input.updatedAt;

          handleUpdateCardEntry({
            variables: { input },
            onCompleted: () => {
              const baseSlackMessage = getBaseSlackMessage(cardID, input);
              sendToSlack({
                channel: "entries",
                text: `[Updating]\n${baseSlackMessage}`,
              });
            },
          });
        };
        handleSubmit(onSubmit)();
      }
    }
  }

  function onFontSelect(selectedItem: string) {
    setValue("font.fontFamily", selectedItem);
  }

  if (isFetchingCardEntries) {
    return (
      <Box h="100vh">
        <Center h="full">
          <Spinner color="blue.500" size="xl" />
        </Center>
      </Box>
    );
  }

  // Inspiration:
  // https://unsplash.com/s/photos/envelope-pencil
  // https://unsplash.com/s/photos/card-envelope
  // const bgImageUrl = "/edit-entry-bg-min.jpeg";
  const bgImageUrl = "/edit-entry-bg.jpeg";
  return (
    <Box
      sx={{
        minH: "100vh",
        bgImage: `url('${bgImageUrl}')`,
        bgAttachment: "fixed", // Parallax.
        bgSize: "cover",
        bgPosition: "center",
        bgRepeat: "no-repeat",
        py: "6",
      }}
    >
      {hasReachedMaxEntries && isAddingNewCardEntry ? (
        <Container maxW="container.sm" bg="white" rounded="3xl" p="6">
          <UpgradeCardAlert />
        </Container>
      ) : (
        <Container maxW="container.sm" bg="white" rounded="3xl" p="6">
          <Box mb="md">
            <Text fontSize="2xl" fontWeight="bold" color={"gray.700"}>
              {headerText}
            </Text>
          </Box>
          <VStack
            spacing="2"
            bg="white"
            borderRadius="md"
            w="full"
            align="stretch"
          >
            <PhotoModalButtons />
            {/* <DeletablePhoto /> */}
            <Box>
              <FormControl isInvalid={!!errors?.entryMessage}>
                {/* <FormLabel>Your message</FormLabel> */}
                <Textarea
                  variant="filled"
                  placeholder={`Enter your message\n\n💡 Tip: Add a GIF to make your message much better!`}
                  color={textColor}
                  fontFamily={selectedFont}
                  rows={8}
                  {...register("entryMessage", {
                    maxLength: 5000,
                    validate: (value) => {
                      return !!value || !!entry?.photo?.urls?.md;
                    },
                  })}
                />
                {errors?.entryMessage?.type === "maxLength" && (
                  <FormErrorMessage>
                    You can't use more than 5,000 characters
                  </FormErrorMessage>
                )}
                {errors?.entryMessage?.type === "validate" && (
                  <FormErrorMessage>
                    Please enter a message or add a GIF
                  </FormErrorMessage>
                )}
              </FormControl>
            </Box>

            <VStack>
              <FormControl isInvalid={!!errors?.signerName}>
                {/* <FormLabel>Your name</FormLabel> */}
                <Input
                  type="text"
                  placeholder="Enter your name"
                  variant="filled"
                  fontFamily={selectedFont}
                  color={textColor}
                  {...register("signerName", {
                    required: true,
                    maxLength: 150,
                  })}
                />
                {errors?.signerName?.type === "required" && (
                  <FormErrorMessage>Please enter your name</FormErrorMessage>
                )}
                {errors?.signerName?.type === "maxLength" && (
                  <FormErrorMessage>
                    You can't use more than 150 characters
                  </FormErrorMessage>
                )}
              </FormControl>
            </VStack>

            <FormControl>
              {/* <FormLabel>Modify text color, text fonts, and emojis </FormLabel> */}
              <Flex justifyContent="flex-start">
                <HStack spacing="1">
                  <ColorPicker
                    onColorChange={(c) => {
                      handleTextColorChange(c);
                    }}
                  />
                  <EmojiPicker onEmojiChange={handleEmojiChange} />
                  <FontPicker onFontSelect={onFontSelect} />
                </HStack>
              </Flex>
            </FormControl>

            {!isAuthenticated && (
              <FormControl isInvalid={!!errors?.signerEmail}>
                <FormLabel>
                  Please enter your email. <WhyEmailPrompt />
                </FormLabel>
                <Input
                  type="text"
                  placeholder="your_email@example.com"
                  variant="filled"
                  fontFamily={selectedFont}
                  color={textColor}
                  {...register("signerEmail", {
                    maxLength: 150,
                  })}
                />
                {errors?.signerName?.type === "maxLength" && (
                  <FormErrorMessage>
                    You can't use more than 150 characters
                  </FormErrorMessage>
                )}
              </FormControl>
            )}

            {/* TODO: Eventually allow user use email to edit Cardoly with sign in flow */}
            {/* <AmplifyAuthenticator /> */}

            <Flex justifyContent={"flex-end"}>
              <Stack direction={["column", "row"]} flex={["1", "0"]}>
                <Button
                  variant="ghost"
                  onClick={() => {
                    navigate(-1); // go back
                  }}
                  colorScheme="gray"
                  // leftIcon={<StopIcon />}
                  //   isDisabled={isSavingEntry}
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleSubmitEntry}
                  isLoading={isLoading}
                  leftIcon={<SaveIcon />}
                  // colorScheme="green"
                >
                  {actionButtonText}
                </Button>
              </Stack>
            </Flex>
          </VStack>
          <DeletablePhoto />
        </Container>
      )}
    </Box>
  );
}
function getBaseSlackMessage(cardID: string, input: FormInputs | any) {
  return `Card: ${cardID}\nEntry: ${input?.entryMessage}\nby: ${input?.signerName}`;
}
