import {
  BoxProps,
  Card,
  CardBody,
  HStack,
  Stack,
  Text,
} from "@chakra-ui/react";
import { PreviewImage } from "./PreviewImage";
import { ReactComponent as PreviewSVG1 } from "./svgs/undraw_coffee.svg";
import { ReactComponent as PreviewSVG2 } from "./svgs/undraw_fun-star.svg";
import { ReactComponent as PreviewSVG3 } from "./svgs/undraw_heart.svg";

export function MultiPostPreviewEntries() {
  return (
    <HStack spacing="md" p="md" alignItems={"flex-start"}>
      <MultiPostPreviewEntryCard icon={PreviewSVG1} />
      <MultiPostPreviewEntryCard icon={PreviewSVG2} />
      <MultiPostPreviewEntryCard icon={PreviewSVG3} />
    </HStack>
  );
}

function MultiPostPreviewEntryCard({ icon }: { icon: BoxProps["as"] }) {
  return (
    <Card overflow="hidden" variant="outline" shadow={"lg"}>
      <PreviewImage icon={icon} />
      <Stack>
        <CardBody>
          <Text fontFamily="Redacted Script" fontSize="xl">
            This is an example entry post. Yes :)
          </Text>
        </CardBody>
      </Stack>
    </Card>
  );
}
