import { Center, Text, Icon, VStack } from "@chakra-ui/react";
import { MdImageSearch } from "react-icons/md";

export function PhotoSearchEmptyState() {
  return (
    <Center mt="24">
      <VStack>
        <Icon as={MdImageSearch} fontSize="9xl" color="gray.300" />
        <Text color="GrayText">Find a GIF</Text>
      </VStack>
    </Center>
  );
}
