import { Box, Heading, SimpleGrid, Text } from "@chakra-ui/react";
import { MasonryGrid, PageContainer } from "../../components";
import { OrderType } from "../../graphql/generated/schema";
import { useEditedCard } from "../../hooks/useEditedCard";
import {
  CardBackground,
  CardHeader,
} from "../../pages/CardolyCardPage/components";
import { MultiPostPreviewEntries } from "./MultiPostPreviewEntries";
import { SingleEntryPreview } from "./SingleEntryPreview";

export function CardPreview() {
  const { editedCard } = useEditedCard();
  const isSolo = editedCard?.orderType === OrderType.Solo;

  return (
    <div>
      <Heading fontSize="xl" textAlign="center" mb="md" color="gray.700">
        Your Cardoly Preview
      </Heading>
      <Box pos="relative" h="70vh">
        <CardBackground isCardPreview={true} />
        <Box>{isSolo ? null : <CardHeader />}</Box>
        {isSolo ? <SingleEntryPreview /> : <MultiPostPreviewEntries />}
        {/* <PageContainer>
        </PageContainer> */}
      </Box>
    </div>
  );
}
