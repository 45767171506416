import { configureStore } from "@reduxjs/toolkit";
import { ENTRYSLICE_FEATURE_KEY, entrySliceReducer } from "./slices";
import { cardolyApi } from "../api";
import { modalSlice } from "./slices";
import { cardSlice, photoSearchSlice } from "./slices";

export const store = configureStore({
  reducer: {
    [ENTRYSLICE_FEATURE_KEY]: entrySliceReducer,
    modal: modalSlice.reducer,
    card: cardSlice.reducer,
    photoSearch: photoSearchSlice.reducer,
    [cardolyApi.reducerPath]: cardolyApi.reducer,
  },

  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(cardolyApi.middleware),
});
