import { gql, useQuery } from "@apollo/client";
import { useCard } from "../../../../hooks/useCard";
import { useToast } from "../../../../hooks/useToast";

const GetOrCreatePaymentIntentQuery = gql`
  query GetOrCreatePaymentIntent($input: GetOrCreatePaymentIntentInput!) {
    getOrCreatePaymentIntent(input: $input) {
      paymentIntentID
    }
  }
`;

export function useStripeClientSecretForOneTimePurchase() {
  const { card } = useCard();
  const cardId = card?.id;
  const {
    loading: isGetOrCreatePaymentIntentLoading,
    error: getOrCreatePaymentIntentError,
    error,
    data,
  } = useQuery(GetOrCreatePaymentIntentQuery, {
    variables: { input: { cardId } },
    skip: !cardId, // || !fetch,
    // pollInterval: 1000,
    // onCompleted: (res) => {
    // },
  });

  const paymentIntentID =
    card?.paymentIntentID || data?.getOrCreatePaymentIntent?.paymentIntentID;
  const showPaymentIntentErrorToast = !!getOrCreatePaymentIntentError; //|| isNullPaymentIntent;

  // Setup toasts
  useToast({
    id: "getOrCreatePaymentIntent",
    title: "Something went wrong",
    // description: errorMessage,
    show: showPaymentIntentErrorToast,
    status: "error",
  });

  // // Logging
  // if (showPaymentIntentErrorToast) {
  //   if (isNullPaymentIntent) {
  //     console.error(
  //       `isNullPaymentIntent: No data returned for payment intent.`
  //     );
  //   }

  //   if (error) {
  //     console.error(error.message);
  //   }
  // }

  return {
    clientSecret: paymentIntentID, // TODO: Rename "clientSecret" in server
  };
}
