import { FiSearch as SearchIcon } from "react-icons/fi";

import {
  // Button,
  HStack,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
} from "@chakra-ui/react";
import { usePhotoSearch } from "../../../../hooks";

export function PhotoSearchInput(props: InputProps) {
  const { searchText, handleChangeSearchText } = usePhotoSearch();

  return (
    <HStack spacing={1} w="full">
      <InputGroup>
        <InputLeftElement
          pointerEvents="none"
          children={<SearchIcon color="gray.300" />}
        />
        {/* <Input placeholder="Search for GIFs!" value={searchText} /> */}
        <Input
          onChange={handleChangeSearchText}
          placeholder={props.placeholder}
          value={searchText}
        />
      </InputGroup>
      {/* <Button colorScheme="blue">Search</Button> */}
    </HStack>
  );
}
