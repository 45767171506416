import React, { useState } from "react";
import { Box, HStack, Icon, RadioProps, Text } from "@chakra-ui/react";
import { IoPersonOutline, IoPeopleOutline } from "react-icons/io5";

export function SelectCardolyType({
  onChange,
}: {
  onChange: (isSolo: boolean) => void;
}) {
  const [isSolo, setIsSolo] = useState<boolean>(false);

  function handleChange(value: boolean) {
    setIsSolo(value);
    onChange(value);
  }

  return (
    <div>
      <HStack alignItems={"stretch"}>
        <RadioCard
          icon={IoPeopleOutline}
          isChecked={!isSolo}
          headingText="Multi-Post"
          bodyText={"Invite multiple people to add content"}
          onClick={() => handleChange(false)}
        />
        <RadioCard
          isChecked={isSolo}
          icon={IoPersonOutline}
          headingText="Single Post"
          bodyText={"Perfect if you are the only contributor"}
          onClick={() => handleChange(true)}
        />
      </HStack>
    </div>
  );
}

const RadioCard = ({
  bodyText,
  headingText,
  isChecked,
  icon,
  onClick,
}: RadioProps & {
  icon: React.FunctionComponent;
  headingText: string;
  bodyText: string;
}) => (
  <Box
    bgColor={isChecked ? "blue.50" : "white"}
    borderWidth={isChecked ? "3px" : "1px"}
    borderColor={isChecked ? "blue.500" : "gray.200"}
    borderRadius="lg"
    p={3}
    textAlign="center"
    _hover={{
      cursor: "pointer",
    }}
    onClick={onClick}
  >
    <Icon as={icon} boxSize="8" color="purple.400" />
    <Text
      fontWeight={"bold"}
      // color={isChecked ? "blue.900" : ""}
    >
      {headingText}
    </Text>
    <Text
      fontSize={"xs"}
      // color={isChecked ? "blue.900" : ""}
    >
      {bodyText}
    </Text>
  </Box>
);
