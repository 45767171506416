import { Heading, HeadingProps } from "@chakra-ui/react";

export const ThemeHeading = (props: HeadingProps) => {
  return (
    <Heading
      fontSize="sm"
      textTransform="uppercase"
      color="gray.500"
      textAlign="center"
      {...props}
    />
  );
};
