import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Product } from "../../constants";
import { CardBgType } from "../../graphql/generated/schema";
import { getCardBackgroundById } from "../../util/imageUtil";

export const ACCORDION_INDEXES = {
  NONE: null,
  DELIVER: 0,
  EDIT_DETAILS: 1,
} as const;
export type AccordionIndexesValueType =
  (typeof ACCORDION_INDEXES)[keyof typeof ACCORDION_INDEXES];
interface CardState {
  selectedCardBackgroundID?: CardolyBackground["cardBackgroundID"];
  selectedBackgroundType: CardolyBackground["type"];
  editedCard?: Partial<Card>;
  deletedCardId?: Card["id"];
  selectedProduct?: Product;
  openingAnimationIsOpen?: boolean;
  cardSettingDrawer: {
    cardSettingIndex: AccordionIndexesValueType;
    isOpen: boolean;
  };
}

const initialState: CardState = {
  selectedBackgroundType: CardBgType.Pattern,
  cardSettingDrawer: {
    cardSettingIndex: ACCORDION_INDEXES.NONE,
    isOpen: false,
  },
};

export const cardSlice = createSlice({
  name: "card",
  initialState: initialState,
  reducers: {
    openCardSettingsDrawer(
      state,
      action: PayloadAction<AccordionIndexesValueType>
    ) {
      state.cardSettingDrawer.cardSettingIndex = action.payload;
      state.cardSettingDrawer.isOpen = true;
    },

    closeCardSettingsDrawer(state) {
      state.cardSettingDrawer.isOpen = false;
    },

    startOpeningSequence(state) {
      state.openingAnimationIsOpen = true;
    },
    stopOpeningSequence(state) {
      state.openingAnimationIsOpen = false;
    },
    setSelectedProduct(
      state,
      action: PayloadAction<CardState["selectedProduct"]>
    ) {
      state.selectedProduct = action.payload;
    },
    removeDeletedCardId(state) {
      delete state.deletedCardId;
    },
    setCardBackgroundID(state, action: PayloadAction<CardBackgroundId>) {
      const cardBg = getCardBackgroundById(action.payload);
      state.editedCard = { ...state.editedCard, ...cardBg };
    },
    setEditedCard(state, action: PayloadAction<Partial<Card>>) {
      state.editedCard = action.payload;
    },
    updateEditedCard(state, action: PayloadAction<Partial<Card>>) {
      const cardId = state.editedCard?.id;
      if (cardId) {
        state.editedCard = {
          ...state.editedCard,
          ...action.payload,
          id: cardId,
        };
      }
    },
    onBackgroundTypeChange(
      state,
      action: PayloadAction<CardolyBackground["type"]>
    ) {
      state.selectedBackgroundType = action.payload;
    },
    reset(state) {
      delete state.selectedCardBackgroundID;
      delete state.editedCard;
    },
  },
});

export const cardReducer = cardSlice.reducer;

export const cardActions = cardSlice.actions;
