import { useEffect } from "react";
import {
  cardActions,
  useAppDispatch,
  useAppSelector,
} from "../state-management";
import { useRoutes } from "./useRoutes";

export function useOpeningAnimation() {
  const dispatch = useAppDispatch();
  const { isRecipientMode } = useRoutes();
  const handleStopOpeningSequence = () =>
    dispatch(cardActions.stopOpeningSequence());
  const handleStartOpeningSequence = () => {
    dispatch(cardActions.startOpeningSequence());
  };
  const openingAnimationIsOpen = useAppSelector(
    (state) => state.card.openingAnimationIsOpen
  );

  // If url is in view-only mode, start opening sequence
  useEffect(() => {
    if (isRecipientMode) {
      handleStartOpeningSequence();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRecipientMode]);

  return {
    handleStopOpeningSequence,
    handleStartOpeningSequence,
    openingAnimationIsOpen,
  };
}
