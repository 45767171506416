import { Appearance } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { CheckoutForm } from "./CheckoutForm";
import { Box } from "@chakra-ui/react";
import { useStripeObject } from "../../useStripeObject";
import { useStripeClientSecretForOneTimePurchase } from "./useStripeClientSecretForOneTimePurchase";

const appearance = {
  theme: "stripe" as Appearance["theme"],
};

export function PaymentForm() {
  const { stripe } = useStripeObject();
  const { clientSecret } = useStripeClientSecretForOneTimePurchase();

  const options = {
    clientSecret,
    appearance,
  };
  console.log("PaymentForm clientSecret", clientSecret);
  return (
    <Box w="full">
      {clientSecret && (
        <Elements options={options} stripe={stripe}>
          <CheckoutForm clientSecret={clientSecret} />
        </Elements>
      )}
    </Box>
  );
}
