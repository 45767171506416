import { Box, Button, Grid, GridItem, Text, VStack } from "@chakra-ui/react";
import { useRoutes, useSubscriptions } from "../../hooks";
import { formatDate } from "../../util";

export function SubscriptionsPanel() {
  const { activeSubscriptions } = useSubscriptions();
  const { toCreateCardPage } = useRoutes();

  const activeSubscriptionsLen = activeSubscriptions?.length;
  return (
    <VStack>
      <Text fontSize="lg" fontWeight="bold" mb="md" textAlign={"center"}>
        Your active subscriptions
      </Text>

      {activeSubscriptionsLen ? (
        <Grid
          templateColumns={[
            "repeat(1, 1fr)",
            `repeat(${
              activeSubscriptionsLen < 2 ? activeSubscriptionsLen : 2
            }, 1fr)`,
            `repeat(${
              activeSubscriptionsLen < 3 ? activeSubscriptionsLen : 3
            }, 1fr)`,
            `repeat(${
              activeSubscriptionsLen < 4 ? activeSubscriptionsLen : 4 // This pattern "centers" the items when there are less column items to fill up the full width
            }, 1fr)`,
          ]}
          gap="md"
        >
          {activeSubscriptions.map((sub) => {
            return (
              <GridItem w="100%">
                <Box key={sub.id} borderWidth={"1px"} rounded="md" p="lg">
                  <Box mb="sm">
                    <Text fontWeight="bold">{sub.productName}</Text>
                  </Box>
                  <Text>Remaining Cards: {sub.cardsRemaining}</Text>
                  <Text>Ends: {formatDate(sub.periodEnd)}</Text>

                  <Box mt="md">
                    <Button
                      colorScheme="brand"
                      w="full"
                      onClick={() =>
                        toCreateCardPage({ subscriptionId: sub.id })
                      }
                    >
                      Create New Cardoly
                    </Button>
                  </Box>
                </Box>
              </GridItem>
            );
          })}
        </Grid>
      ) : (
        <Box>
          <Text>You have no active subscriptions.</Text>
        </Box>
      )}
    </VStack>
  );
}
