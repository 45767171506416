import { Box, Center } from "@chakra-ui/react";
import ConfettiExplosion from "react-confetti-explosion";

const CONFETTI_DURATION_MILLIS = 2000;
// const CONFETTI_DURATION_MILLIS = 5000;

export function ConfettiWithDuration({ onComplete = () => {} }) {
  // const width = window.innerWidth * 0.8;
  const width = window.innerWidth;

  return (
    <Box
      sx={{
        pos: "absolute",
        top: 50,
        right: 0,
        left: 0,
      }}
    >
      <Center>
        <ConfettiExplosion
          width={width}
          particleCount={150}
          duration={CONFETTI_DURATION_MILLIS}
          onComplete={onComplete}
        />
      </Center>
    </Box>
  );
}
