import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface PhotoSearchState {
  searchText: string;
}

const initialState: PhotoSearchState = {
  searchText: "",
};

export const photoSearchSlice = createSlice({
  name: "photoSearch",
  initialState,
  reducers: {
    onSearchTextChange(state, action: PayloadAction<string>) {
      state.searchText = action.payload;
    },
  },
});

export const photoSearchActions = photoSearchSlice.actions;

export default photoSearchSlice.reducer;
