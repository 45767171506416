import { useGetCardEntriesByCardIdQuery } from "../graphql/generated/schema";
import { useCard } from "./useCard";

export function useSingleCardEntry() {
  const { cardId } = useCard();
  const {
    data,
    error,
    loading: isLoading,
  } = useGetCardEntriesByCardIdQuery({
    variables: {
      input: {
        limit: 1, // TODO: Handle pagination wierdness. If you use limit of 2, and delete the top 2, pagination is returns nothing
        cardID: cardId ?? "",
      },
    },
  });

  const isError = !!error;
  const entry = data?.getCardEntriesByCardId.items?.[0];

  const PHOTO_SIZE = "md";
  const entryImg = entry?.photo?.urls?.[PHOTO_SIZE];
  const imageWidth = entry?.photo?.sizes?.[PHOTO_SIZE]?.width;
  const imageHeight = entry?.photo?.sizes?.[PHOTO_SIZE]?.height;

  return {
    entry,
    entryImg,
    hasImage: !!entryImg,
    imageWidth,
    imageHeight,
    isLoading,
    isError,
  };
}
