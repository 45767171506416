import { LazyLoadImage, Effect } from "react-lazy-load-image-component";
import { Image as ChakraImage, ImageProps } from "@chakra-ui/react";
import "react-lazy-load-image-component/src/effects/blur.css";

export function LazyImage(props: ImageProps & { effect?: Effect }) {
  return (
    <ChakraImage
      as={LazyLoadImage}
      // effect="blur"
      {...props}
    />
  );
}
