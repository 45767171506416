export const getOrCreateUser = /* GraphQL */ `
  mutation GetOrCreateUser {
    getOrCreateUser {
      id
      email
      cognitoId
      name
    }
  }
`;

export const getCard = /* GraphQL */ `
  query GetCard($id: ID!) {
    getCard(id: $id) {
      id
      entryCount
      lastEntryCreatedAt
      bgType
      bgImageUrl
      orderType
      orderStatus
      ownerCognitoID
      pColorBg
      pColorFg
      pType
      title
      titleFontFamily
      subscriptionId
      receiverEmail
      receiverName
      ownerName
    }
  }
`;

export const getUnsplashPhotos = /* GraphQL */ `
  query GetUnsplashPhotos($page: Int!, $perPage: Int!, $queryText: String!) {
    getPhotos(
      input: { queryText: $queryText, page: $page, perPage: $perPage }
    ) {
      message
      photos {
        alt_description
        externalId
        height
        width
        type
        urls {
          md
          sm
        }
        user {
          name
          username
        }
      }
      total
      totalPages
    }
  }
`;

// export const getCardEntriesByCardId = /* GraphQL */ `
//   query GetCardEntriesByCardId($input: GetCardEntriesByCardIdInput!) {
//     getCardEntriesByCardId(input: $input) {
//       items {
//         cardID
//         entryMessage
//         font {
//           fontFamily
//           color
//         }
//         photo {
//           externalId
//           width
//           height
//           alt_description
//           type
//           urls {
//             sm
//             md
//           }
//           user {
//             name
//             username
//             firstName
//             lastName
//           }
//           sizes {
//             sm {
//               height
//               width
//             }
//             md {
//               height
//               width
//             }
//           }
//         }
//         signerName
//         signerCognitoID
//         id
//         updatedAt
//         createdAt
//       }
//       nextToken
//     }
//   }
// `;

// export const getCardEntry = /* GraphQL */ `
//   query GetCardEntry($input: GetCardEntryInput) {
//     getCardEntry(input: $input) {
//       id
//       cardID
//       entryMessage
//       font {
//         fontFamily
//         color
//       }
//       photo {
//         type
//         urls {
//           sm
//           md
//         }
//       }
//       signerName
//       signerCognitoID
//     }
//   }
// `;

export const getCardsByOwnerCognitoId = /* GraphQL */ `
  query GetCardsByOwnerCognitoId($input: GetCardsByOwnerCognitoIdInput!) {
    getCardsByOwnerCognitoId(input: $input) {
      items {
        id
        entryCount
        receiverName
        lastEntryCreatedAt
        bgType
        bgImageUrl
        orderType
        orderStatus
        ownerCognitoID
        pColorBg
        pColorFg
        pType
        title
        titleFontFamily
        createdAt
        entryCount
        subscriptionId
      }
      nextToken
    }
  }
`;

// export const getSubscriptionsByOwnerId = /* GraphQL */ `
//   query GetSubscriptionByOwnerId($input: GetSubscriptionByOwnerId!) {
//     getSubscriptionsByOwnerId(input: $input) {
//       items {
//         id
//         createdAt
//         periodEnd
//         periodStart
//         status
//         cardsRemaining
//       }
//     }
//   }
// `;
