import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  ApolloLink,
} from "@apollo/client";
import { getSLSGraphQLEndpoint, IS_PROD } from "../constants";
import { Auth } from "aws-amplify";

import { setContext } from "@apollo/client/link/context";
import { isEmpty } from "lodash";
import { omitDeep } from "../util";
import { sendTextToErrorsChannelInSlack } from "../util/sendTextToErrorsChannelInSlack";
import { onError } from "@apollo/client/link/error";

async function getCognitoAuthHeader() {
  try {
    return {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    };
  } catch (e) {
    return {};
  }
}

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    for (const err of graphQLErrors) {
      const errorString = JSON.stringify(err);
      sendTextToErrorsChannelInSlack(errorString);
    }
  }

  if (networkError) {
    const errorString = `[Network error]: ${networkError}`;
    sendTextToErrorsChannelInSlack(errorString);
  }
});

const httpLink = createHttpLink({
  // https://github.com/mswjs/examples/blob/master/examples/graphql-react-apollo/src/ApolloClient.js
  // Use explicit `window.fetch` so that outgoing requests
  // are captured and deferred until the Service Worker is ready.
  // Related: https://mswjs.io/docs/recipes/deferred-mounting
  fetch: (...args) => fetch(...args),
});

// Extend HTTPLink
const authLink = setContext(async (_, { headers }) => {
  const cognitoAuthHeaders = await getCognitoAuthHeader();
  const isPublic = isEmpty(cognitoAuthHeaders);

  return {
    uri: getSLSGraphQLEndpoint(isPublic),
    headers: {
      ...headers,
      //   authorization: await getAuthToken(),
      ...cognitoAuthHeaders,
    },
  };
});

// Create a custom ApolloLink to strip __typename from the variables.
const stripTypenameLink = new ApolloLink((operation, forward) => {
  const isMutation = operation.query.definitions.some(
    (def) => def.kind === "OperationDefinition" && def.operation === "mutation"
  );
  if (operation.variables && isMutation) {
    // Remove the __typename fields from the variables object.
    operation.variables = omitDeep(operation.variables, "__typename");
  }
  return forward(operation);
});

export const apolloClient = new ApolloClient({
  // uri: BASE_URL,
  // link: authLink.concat(httpLink),
  // link: errorLink.concat(authLink).concat(httpLink), // NOTE: Ensure httpLink is last to prevent "Error: You are calling concat on a terminating link, which will have no effect"
  link: ApolloLink.from([stripTypenameLink, errorLink, authLink, httpLink]),
  // addTypename helps with cache. E.g. after updateCard,  __typename: "Card" with an id on the card is returned
  // That returned card will update the cache holding the Card object getCard(id), keeping it up to date
  cache: new InMemoryCache({ addTypename: true }),
  connectToDevTools: !IS_PROD,
});
