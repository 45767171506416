import { Button } from "@chakra-ui/react";
import { useCallback } from "react";
import { PRODUCT_NAME_SINGULAR } from "../../../constants";
import { useCard, useRoutes } from "../../../hooks";
import { CreateCardolyButton } from "./CreateCardolyButton";
import { Pricing } from "./Pricing";

export function MiniPricingCard() {
  const nav = useRoutes();

  const { cardID } = useCard();

  const handleNavToCardPage = useCallback(
    () =>
      nav.toCardPage({
        cardID,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cardID]
  );

  return (
    <Pricing.Card>
      <Pricing.ProductType>Mini</Pricing.ProductType>
      <Pricing.Price>Free</Pricing.Price>
      <Pricing.CardNumber>1 {PRODUCT_NAME_SINGULAR}</Pricing.CardNumber>
      <Pricing.FeatureList
        features={[
          "Everything in a Premium Cardoly, except you are limited to 10 posts only",

          // "Up to 10 card posts",
          // "Use multiple fonts",
          // "Custom font colors",
          // // Change using Chat GPT
          // "Add messages and GIFS",
          // "Many backgrounds",
          // "Desktop & mobile friendly",
          // "Accessible forever",
          // "No advertisements",
          // "Deliver online",
        ]}
      />
      {/* <Button w="full">Start for free</Button> */}

      <Pricing.Footer>
        {cardID ? (
          <Button
            colorScheme="brand"
            w="full"
            onClick={handleNavToCardPage}
            variant="outline"
          >
            Start for free
          </Button>
        ) : (
          <CreateCardolyButton />
        )}
      </Pricing.Footer>
    </Pricing.Card>
  );
}
