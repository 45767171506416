import { Button, HStack } from "@chakra-ui/react";
import { AiOutlineFileGif as GifIcon } from "react-icons/ai";
import { HiOutlinePhotograph as PhotoIcon } from "react-icons/hi";
import { modalActions, useAppDispatch } from "../../../state-management";

interface MediaTabProps {
  label: string;
  icon: React.ReactElement;
  onClick(): void;
}

function AddPhotoButton({ label, icon, onClick }: MediaTabProps) {
  return (
    <Button
      onClick={onClick}
      leftIcon={icon}
      variant="outline"
      colorScheme="brand"
      rounded="full"
    >
      {label}
    </Button>
  );
}

export function PhotoModalButtons() {
  const dispatch = useAppDispatch();
  function onOpen() {
    dispatch(
      modalActions.showModal({
        modalType: "PhotoSearchModal",
        chakraModalProps: {
          size: "2xl",
        },
      })
    );
  }
  return (
    <HStack spacing={2}>
      <AddPhotoButton onClick={onOpen} label="Add GIF" icon={<GifIcon />} />
      {/* <AddPhotoButton
        onClick={onOpen}
        label="Add Online Photo"
        icon={<PhotoIcon />}
      /> */}
    </HStack>
  );
}
