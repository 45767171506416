import { Box, Center, Stack, Text } from "@chakra-ui/react";
import { useEditedCard } from "../../hooks/useEditedCard";
import { PreviewImage } from "./PreviewImage";
import { ReactComponent as PreviewSVG } from "./svgs/undraw_heart.svg";

export function SingleEntryPreview() {
  const { editedCard: card } = useEditedCard();

  const flexItemStyles = {
    width: "50%",
  };

  return (
    <Box pt="2xl">
      <Center>
        <Box
          display="flex"
          overflow="hidden"
          bg="white"
          rounded="xl"
          shadow={"dark-lg"}
          w="80%"
          flexDirection="row"
          height="40vh"
        >
          <PreviewImage {...flexItemStyles} icon={PreviewSVG} />
          <Box
            {...flexItemStyles}
            p={4}
            overflowY="auto"
            height="100%"
            position="relative"
          >
            <Stack spacing="lg" w="full" p="lg">
              <Text
                textAlign={"center"}
                fontSize="3xl"
                fontFamily={card?.titleFontFamily}
              >
                {card?.title ? card?.title : "Title"}
              </Text>

              {/* <CardEntry /> */}
              <Text
                textAlign={"left"}
                fontSize="xl"
                fontFamily={"Redacted Script"}
              >
                This is an example entry post. This is an example entry post.
                Indeed... :)
              </Text>
            </Stack>
          </Box>
        </Box>
      </Center>
    </Box>
  );
}
