import { Button, ButtonProps } from "@chakra-ui/react";
import { FaSave as SaveIcon } from "react-icons/fa";

export function SaveButton(props: ButtonProps) {
  return (
    <Button
      leftIcon={<SaveIcon />}
      // colorScheme="green"
      colorScheme="brand"
      {...props}
    >
      Save
    </Button>
  );
}
