import { Select } from "@chakra-ui/react";
import { imageFileGroupings } from "../../state-management";

type SelectThemeProps = {
  value: PhotoCategory;
  onChange: (value: PhotoCategory) => void;
};

export const SelectTheme = ({ value, onChange }: SelectThemeProps) => {
  return (
    <Select
      value={value}
      onChange={(e) => onChange(e.target.value as PhotoCategory)}
    >
      <option value="all">All</option>
      {Object.keys(imageFileGroupings).map((groupingKey) => {
        return (
          <option key={groupingKey} value={groupingKey}>
            {groupingKey}
          </option>
        );
      })}
    </Select>
  );
};
