import { Box, Center, Spinner } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { TryNewSearchPrompt } from "../TryNewSearchPrompt";
import { cardolyApi } from "../../../../api";
import { InfiniteScroll, MasonryGrid } from "../../../../components";
import { usePhotoSearch } from "../../../../hooks";
import { entrySliceActions, modalActions } from "../../../../state-management";
import { Photo } from "../Photo";
import React from "react";
import { createEntityAdapter, EntityState } from "@reduxjs/toolkit";
import { MAX_PHOTOS_TO_FETCH } from "../../../../constants";

function getAttribution(photo: CardolyPhoto) {
  const isUnsplash = photo.type === "UNSPLASH";
  if (isUnsplash) {
    const creatorUrl = `https://unsplash.com/@${photo.user?.username}?utm_source=cardoly&utm_medium=referral`;

    return {
      creatorUrl,
      creatorName: photo.user?.name,
    };
  }
}

const INITIAL_PAGE = 1;

const dataAdapter = createEntityAdapter<CardolyPhoto>({
  selectId: (item) => item.externalId, // Assume IDs are stored in a field other than `item.id`
});
const dataSelectors = dataAdapter.getSelectors();

export function UnsplashPhotoGrid() {
  const dispatch = useDispatch();
  const { debounceSearchText, perPage, hasSearched } = usePhotoSearch();
  const [currentPage, setCurrentPage] = React.useState<number>(INITIAL_PAGE);
  const { data, isLoading, isFetching, requestId } =
    cardolyApi.useGetUnsplashPhotosQuery(
      {
        queryText: debounceSearchText,
        page: currentPage,
        perPage,
      },
      { skip: !hasSearched }
    );

  const fetchedData = data?.photos;
  const [infiniteScrollData, setInfiniteScrollData] = React.useState<
    EntityState<CardolyPhoto>
  >(dataAdapter.getInitialState());
  const allData = dataSelectors.selectAll(infiniteScrollData);
  // const hasMore = false;
  // const hasMore = newOffset < MAX_PHOTOS_TO_FETCH && offset < totalCount;
  const newOffset = currentPage * perPage;
  const totalCount = data?.total ?? 0;
  const hasMore =
    hasSearched && newOffset < MAX_PHOTOS_TO_FETCH && newOffset < totalCount;

  function fetchMore() {
    setCurrentPage(currentPage + 1);
  }

  // Reset pagination data, so we don't mix results from different searches
  React.useEffect(() => {
    setCurrentPage(INITIAL_PAGE);
    setInfiniteScrollData((state) => dataAdapter.removeAll(state));
  }, [debounceSearchText]);

  React.useEffect(() => {
    if (fetchedData?.length) {
      setInfiniteScrollData((state) => dataAdapter.addMany(state, fetchedData));
    }
  }, [requestId, fetchedData]);

  if (isLoading) {
    return (
      <Center>
        <Spinner color="blue" />
      </Center>
    );
  }

  return (
    <Box maxHeight="60vh" overflow="auto" id="giphy-container">
      <InfiniteScroll
        dataLength={allData.length}
        next={fetchMore}
        hasMore={hasMore}
        scrollableTarget="giphy-container"
      >
        <MasonryGrid space={4} columns={2}>
          {allData?.map((photo) => {
            const attribution = getAttribution(photo);
            const url = photo?.urls?.sm ?? "";

            // if (!url) return null;

            return (
              <Photo
                url={url}
                key={url}
                creatorUrl={attribution?.creatorUrl}
                creatorName={attribution?.creatorName}
                onClick={() => {
                  // TODO: Group actions together. When addPhoto is done, close photo. Both here and Giphy
                  dispatch(entrySliceActions.addPhoto(photo));
                  dispatch(modalActions.hideModal());
                }}
              />
            );
          })}
        </MasonryGrid>
        {!hasMore && hasSearched && <TryNewSearchPrompt />}
      </InfiniteScroll>

      {/* <MasonryGrid space={4} columns={2}>
        {data?.map((photo) => {
          const attribution = getAttribution(photo);

          return (
            <Photo
              url={photo.urls.sm}
              key={photo.urls.md}
              creatorUrl={attribution?.creatorUrl}
              creatorName={attribution?.creatorName}
              onClick={() => {
                // TODO: Group actions together. When addPhoto is done, close photo. Both here and Giphy
                dispatch(entrySliceActions.addPhoto(photo));
                dispatch(modalActions.hideModal());
              }}
            />
          );
        })}
      </MasonryGrid>
      {!hasMore && hasSearched && <TryNewSearchPrompt />} */}

      {/* {isFetching && (
        <Center mt="1">
          <Spinner color="blue" />
        </Center>
      )} */}

      {/* <button onClick={fetchMoreGiphy}>More</button> */}
    </Box>
  );
}
