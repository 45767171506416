import { AmplifyAuthenticator } from "./AmplifyAuthenticator";
import { Center, Flex, Text } from "@chakra-ui/react";
import { CardolyLogoName } from "../CardolyLogoName";

export function AmplifyAuthenticatorWithLogo() {
  return (
    <Center my="lg">
      <Flex flexDir={"column"} alignItems="center" gap="lg">
        <CardolyLogoName />
        <Text
          fontSize={"lg"}
          // fontWeight="bold"
        >
          Welcome 😊
        </Text>
        <AmplifyAuthenticator />
      </Flex>
    </Center>
  );
}
