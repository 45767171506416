import { FiPlusCircle as PlusIcon } from "react-icons/fi";
import {
  Box,
  Center,
  // Image,
  Link,
} from "@chakra-ui/react";
import { Button } from "@chakra-ui/react";
import { LazyImage } from "../../../../components";
// import { LazyLoadImage } from "react-lazy-load-image-component";

const BORDER_RADIUS_SIZE = "lg";
function PhotoOverlay({
  onAddClick,
  creatorUrl,
  creatorName,
}: {
  onAddClick(): void;
  creatorUrl?: string;
  creatorName?: string;
}) {
  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        background: "#bee3f859",
        opacity: 0,
        // opacity: 1, // Testing only.
        /**
         * Handling the hover/border this way vs the select card bg way is is the borderWidth is 16 thick,
         * the border is still "inward" -- that way, it doesn't shrink the photo in the grid.
         */
        // BORDER STYLES
        borderWidth: 3,
        borderColor: "white",
        rounded: BORDER_RADIUS_SIZE,
        overflow: "hidden",
      }}
      _hover={{
        opacity: 1,
        cursor: "pointer",
        // BORDER STYLES
        borderColor: "brand.600",
      }}
    >
      {/* <Box h="full" display="flex" w="full"> */}
      <Box h="full" display="flex" flexDir="column" w="full">
        <Center h="full">
          <Button
            onClick={onAddClick}
            variant="solid"
            colorScheme="blue"
            leftIcon={<PlusIcon />}
          >
            Add
          </Button>
        </Center>
        {creatorUrl && (
          <Center
            mt="auto"
            py="1px"
            px="4px"
            bgColor="brand.300"
            w="full"
            bg="#00000063"
          >
            <Link
              href={creatorUrl}
              isExternal
              color="white"
              fontSize="xs"
              fontWeight="bold"
            >
              {creatorName}
            </Link>
          </Center>
        )}
      </Box>
    </Box>
  );
}

export function Photo({
  // height,
  // width,
  url,
  onClick,
  creatorUrl,
  creatorName,
}: {
  url: string;
  onClick(): void;
  creatorUrl?: string;
  creatorName?: string;
  // height?: string;
  // width?: string | number;
}) {
  return (
    <Box position="relative">
      <PhotoOverlay
        onAddClick={onClick}
        creatorUrl={creatorUrl}
        creatorName={creatorName}
      />
      <LazyImage
        // h={height}
        // // @ts-ignore
        // placeholder={
        //   <Box h={height} bgColor={"yellow.300"}>
        //     NAS
        //   </Box>
        // }
        src={url}
        rounded={BORDER_RADIUS_SIZE}
        // srcSet={`${}`}
        width="full"
        alt="cardoly photo"
      />
    </Box>
  );
}
