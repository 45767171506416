import { modalActions, useAppDispatch } from "../state-management";

export function useUpgradeCard() {
  const dispatch = useAppDispatch();

  function handleUpgradeCard() {
    dispatch(modalActions.showModal({ modalType: "UpgradeCardModal" }));
  }

  function handleCloseUpgradeCardModal() {
    dispatch(modalActions.hideModal());
  }

  return {
    handleUpgradeCard,
    handleCloseUpgradeCardModal,
  };
}
