import InfiniteScrollComponent, {
  Props,
} from "react-infinite-scroll-component";
import { Loader } from "../Loader";

type InfiniteScrollProps = Omit<Props, "loader">;

export function InfiniteScroll(props: InfiniteScrollProps) {
  return (
    <>
      <InfiniteScrollComponent
        loader={""} // Add dumb loader to prevent TS from complaining.
        // scrollThreshold={0.8}
        {...props}
      />
      {props.hasMore && <Loader />}
    </>
  );
}
