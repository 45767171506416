import {
  Alert,
  AlertIcon,
  AlertDescription,
  CloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { UpgradeCardMessage } from "../../../feature";
import { useCard, useRoutes } from "../../../hooks";

export function UpgradeCardAlert() {
  const { onClose, onOpen, isOpen } = useDisclosure({ defaultIsOpen: false });
  const { isRecipientMode } = useRoutes();
  const { isFree, card } = useCard();
  const entryCount = card?.entryCount;

  useEffect(() => {
    if (isFree && entryCount && !isRecipientMode) {
      onOpen();
    } else {
      onClose();
    }
  }, [isFree, entryCount, isRecipientMode, onOpen, onClose]);

  return isOpen ? (
    <Alert
      status="info"
      variant="subtle"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
    >
      <AlertIcon />
      <AlertDescription>
        <UpgradeCardMessage />
      </AlertDescription>
      <CloseButton ml="sm" right={-1} top={-1} onClick={onClose} />
    </Alert>
  ) : null;
}
