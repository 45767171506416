import { useEffect } from "react";
import { Control, useWatch } from "react-hook-form";
import { imageFileGroupings } from "../util/imageUtil";
import { CardBgType } from "../graphql/generated/schema";
import {
  cardActions,
  getCardBackgroundById,
  useAppDispatch,
} from "../state-management";
import { useCard } from "./useCard";
import { useCardBackground } from "./useCardBackground";

interface UseEditedCardFormProps {
  isCreateCardolyFlow?: boolean;
  control?: Control<CreateOrEditCardFormInputs>;
}

/**
 * Hook is responsible for tracking when a card is being edited, and updating the redux store accordingly.
 * It initializes the redux store with the card to be edited, and updates the store as the form is updated.
 */
export function useEditedCardFormListener({
  isCreateCardolyFlow = false,
  control,
}: UseEditedCardFormProps) {
  const dispatch = useAppDispatch();
  const { card } = useCard();

  const formValues = useWatch({
    control,
    // name: "firstName", // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    // defaultValue: "default", // default value before the render
  });

  // const editedCard = useAppSelector(selectors.cardSelectors.getEditedCard);
  useEffect(() => {
    // Setup store.
    // Add server card to store. The added card is presumably one to be edited.
    if (card && card.id) {
      dispatch(cardActions.setEditedCard(card));
    }

    if (isCreateCardolyFlow) {
      dispatch(
        cardActions.setEditedCard({
          id: "TEMP_EDITED_CARD_FOR_CREATE_CARDOLY",
          ...getCardBackgroundById("jigsaw"),
        })
      );
    }

    // When card edit is complete, empty store.
    return () => {
      dispatch(cardActions.reset());
    };
  }, [card?.id]);

  // Listen for changes to form, and change redux accordingly
  useEffect(() => {
    if (formValues) {
      dispatch(cardActions.updateEditedCard(formValues));
    }
  }, [dispatch, formValues]);

  const { handleSetCardBackgroundID } = useCardBackground();
  useEffect(() => {
    if (isCreateCardolyFlow) {
      if (formValues.occasion) {
        const images = imageFileGroupings[formValues.occasion];
        const randomPhoto = getRandomItemFromArray(images);
        if (randomPhoto?.type === CardBgType.Image) {
          handleSetCardBackgroundID(randomPhoto.cardBackgroundID);
        }
      } else {
        // handleSetCardBackgroundID("tic-tac-toe");
        handleSetCardBackgroundID("jigsaw");
      }
    }
  }, [formValues.occasion, isCreateCardolyFlow]);
}

function getRandomItemFromArray(arr: ImageBackground[] | null) {
  if (arr) {
    // Check if the array is empty
    if (arr.length === 0) {
      return null; // or undefined, depending on your preference
    }

    // Generate a random index between 0 and the length of the array (exclusive)
    const randomIndex = Math.floor(Math.random() * arr.length);

    // Return the item at the random index
    return arr[randomIndex];
  }
}
