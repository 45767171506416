import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "../../hooks";

/**
 * Like "ProtectedRoute". This is a parent for any component that requires auth.
 */
export function RequireAuth({ children }: { children: JSX.Element }) {
  let { isAuthenticated } = useAuth();
  let location = useLocation();

  // if (!isAuthenticated && !isAuthLoading) {
  if (!isAuthenticated) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}
