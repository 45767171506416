import { Container, ContainerProps } from "@chakra-ui/react";

export function PageContainer(props: ContainerProps) {
  return (
    // <Container maxW="1280px" pb="lg">
    /**
     TODO: Look into fluid body fitting any footer/header https://stackoverflow.com/questions/17054158/css-100-height-with-header-and-footer
     This can help with things like dynamically centering items from vertical and horizontal angles
     */
    <Container maxW="container.xl" py="md" {...props} />
  );
}
