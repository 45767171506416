import { Navigate } from "react-router-dom";

export function RedirectTo404() {
  return (
    <Navigate
      // replace // TODO: Good practice to replace? Some example had it.
      to="/404" // This hits catch-all url, since we don't have 404. It preserves original wrong URL in browser (e.g. Github.com preserves it too)
    />
  );
}
