import { Button, Center, Icon, Text, VStack } from "@chakra-ui/react";
import { useRoutes } from "../../hooks";
import { CardSummary } from "./CardSummary";
import { FiPlus as PlusIcon } from "react-icons/fi";
import { InfiniteScroll, Loader } from "../../components";
import { useGetCardsByOwnerCognitoIdQuery } from "../../graphql/generated/schema";
// import { NetworkStatus } from "@apollo/client";

const baseInput = {
  // limit: 3, // TODO: Handle pagination wierdness. If you use limit of 2, and delete the top 2, pagination is returns nothing
  limit: 25, // TODO: Handle pagination wierdness. If you use limit of 2, and delete the top 2, pagination is returns nothing
  sortAsc: false,
};

export default function CardsCreated() {
  const { toCreateCardPage } = useRoutes();

  const {
    fetchMore,
    data,
    loading: isLoading, // Shows loading whether or not NetworkStatus is 'loading' or 'setVariables' state
    error,
  } = useGetCardsByOwnerCognitoIdQuery({
    variables: {
      input: baseInput,
    },
  });

  // const isFetching = NetworkStatus.fetchMore === networkStatus;

  const isError = !!error;
  const items = data?.getCardsByOwnerCognitoId.items;
  const allData = items;
  const newNextToken = data?.getCardsByOwnerCognitoId.nextToken;
  const hasMore = !!newNextToken;
  const showCardList = allData?.length;

  function onFetchNextPage() {
    fetchMore({
      variables: { input: { ...baseInput, nextToken: newNextToken } },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const oldValues = previousResult.getCardsByOwnerCognitoId?.items
          ? previousResult.getCardsByOwnerCognitoId.items
          : [];
        const newValues = fetchMoreResult.getCardsByOwnerCognitoId?.items
          ? fetchMoreResult.getCardsByOwnerCognitoId.items
          : [];

        return {
          ...previousResult,
          getCardsByOwnerCognitoId: {
            ...fetchMoreResult.getCardsByOwnerCognitoId,
            nextToken: fetchMoreResult.getCardsByOwnerCognitoId.nextToken,
            items: [...oldValues, ...newValues],
          },
        };
      },
    });
  }

  if (isLoading) {
    return <Loader />;
  }

  if (isError) {
    return (
      <Text align="center" color="red.600">
        Could not fetch data.
      </Text>
    );
  }

  return (
    <div>
      {showCardList ? (
        <InfiniteScroll
          dataLength={allData.length} //This is important field to render the next data
          next={onFetchNextPage}
          hasMore={hasMore}
        >
          <VStack spacing="md">
            {allData?.map((card) => (
              <CardSummary card={card} key={card?.id} />
            ))}
          </VStack>
        </InfiniteScroll>
      ) : (
        <Center>
          <VStack>
            <Text>You have not created any Cardoly.</Text>
            <Button
              colorScheme="brand"
              variant="outline"
              leftIcon={<Icon as={PlusIcon} />}
              onClick={() => toCreateCardPage()}
            >
              Add Cardoly
            </Button>
          </VStack>
        </Center>
      )}
    </div>
  );
}
