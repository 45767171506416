import {
  Box,
  Flex,
  IconButton,
  Text,
  VStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { FiEdit as EditIcon, FiTrash2 as DeleteIcon } from "react-icons/fi";
import { IoIosOptions as MenuIcon } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import { useAuth, useCard, useRoutes } from "../hooks";
import {
  entrySliceActions,
  modalActions,
  useAppDispatch,
} from "../state-management";

export function EntryBody({
  entry,
}: // canUserViewMenu,
// canUserEditEntry,
// handleEdit,
// canUserDeleteEntry,
// handleDelete,
{
  entry: CardEntry;
  // canUserViewMenu: string | boolean | undefined;
  // canUserEditEntry: string | boolean | undefined;
  // handleEdit: () => void;
  // canUserDeleteEntry: string | boolean | undefined;
  // handleDelete: () => void;
}) {
  const { user } = useAuth();
  const { cardID, card } = useCard();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const entryID = entry.id;
  const userId = user.userId;
  const isUserSigner =
    userId && // ensure we don't compare "null === null"
    userId === entry?.signerCognitoID;
  const isUserCardOwner =
    userId && // ensure we don't compare "null === null"
    userId === card?.ownerCognitoID;
  const { isRecipientMode } = useRoutes();
  const canUserEditEntry = isUserSigner && !isRecipientMode;
  const canUserDeleteEntry =
    (isUserSigner || isUserCardOwner) && !isRecipientMode;
  const canUserViewMenu =
    !isRecipientMode && (canUserEditEntry || canUserDeleteEntry);

  function handleDelete() {
    // TODO: Dispatch to trigger modal
    dispatch(
      modalActions.showModal({
        modalType: "DeleteCardPrompt",
        modalProps: { entryID },
      })
    );
  }

  function handleEdit() {
    dispatch(entrySliceActions.addEntry(entry));
    const url = `/card/${cardID}/card-entry/${entryID}`;
    navigate(url);
  }

  return (
    <VStack spacing="4">
      <Box
        w="full"
        //  overflowY="auto" height="100%"
      >
        <pre style={{ whiteSpace: "pre-wrap" }}>
          <Text fontFamily={entry?.font?.fontFamily} color={entry?.font?.color}>
            {entry?.entryMessage}
          </Text>
        </pre>
      </Box>

      <Flex justifyContent="space-between" w="full">
        {canUserViewMenu && (
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<MenuIcon />}
              variant="outline"
            />
            <MenuList>
              {canUserEditEntry && (
                <MenuItem icon={<EditIcon />} onClick={handleEdit}>
                  Edit
                </MenuItem>
              )}
              {/* <MenuItem icon={<LinkIcon />} onClick={handleGetLink}>
                      Get Link
                    </MenuItem> */}
              {canUserDeleteEntry && (
                <MenuItem
                  color="red.600"
                  icon={<DeleteIcon />}
                  onClick={handleDelete}
                >
                  Delete
                </MenuItem>
              )}
            </MenuList>
          </Menu>
        )}
        <Box ml="auto">
          <Signature signerName={entry?.signerName} font={entry.font} />
        </Box>
      </Flex>
    </VStack>
  );
}

interface SignatureProps {
  signerName?: string;
  font?: CardEntry["font"];
}
export function Signature({ signerName, font }: SignatureProps) {
  return (
    <Text fontSize="sm" fontFamily={font?.fontFamily} color={font?.color}>
      From {signerName}
    </Text>
  );
}
