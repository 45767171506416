import { Auth } from "aws-amplify";

/**
 * Docs
 * https://docs.amplify.aws/lib/auth/emailpassword/q/platform/js/
 */
export async function onLogout() {
  await Auth.signOut();
}

// Send confirmation code to user's email
export async function forgotPassword({ username }: { username: string }) {
  try {
    await Auth.forgotPassword(username);
  } catch (error) {
    console.log("error forgotPassword", error);
    throw error;
  }
}

// Collect confirmation code and new password, then
export async function forgotPasswordSubmit({
  username,
  confirmationCode,
  newPassword,
}: {
  username: string;
  confirmationCode: string;
  newPassword: string;
}) {
  try {
    const user = await Auth.forgotPasswordSubmit(
      username,
      confirmationCode,
      newPassword
    );
  } catch (error) {
    console.log("error forgotPasswordSubmit", error);
    throw error;
  }
}

export async function signIn({
  username,
  password,
}: {
  username: string;
  password: string;
}) {
  try {
    const user = await Auth.signIn(username, password);
  } catch (error) {
    console.log("error signing in", error);
    // throw error;
  }
}

export async function signUp({
  username,
  password,
  email,
}: {
  username: string;
  password: string;
  email: string;
}) {
  try {
    const { user } = await Auth.signUp({
      username,
      password,
      attributes: {
        email, // optional
        // phone_number, // optional - E.164 number convention
        // other custom attributes
      },
    });
    console.log(user);
  } catch (error) {
    console.log("error signing up:", error);
    throw error;
  }
}

// After sign up flow, the user gets email with code, we use this enter the code to finish sign up
export async function confirmSignUp({
  username,
  confirmationCode,
}: {
  username: string;
  confirmationCode: string;
}) {
  try {
    await Auth.confirmSignUp(username, confirmationCode);
  } catch (error) {
    console.log("error confirming sign up", error);
  }
}

export async function resendConfirmationCode({
  username,
}: {
  username: string;
}) {
  try {
    await Auth.resendSignUp(username);
    console.log("code resent successfully");
  } catch (err) {
    console.log("error resending code: ", err);
    throw err;
  }
}
