import React from "react";
import { Button, Center, Icon, Text, VStack } from "@chakra-ui/react";
import { PageContainer } from "../../components";
import { FiCheckCircle } from "react-icons/fi";
import { usePayment } from "../../hooks/usePayment";
import { useRoutes } from "../../hooks";
import { PRODUCT_NAME_PLURAL, PRODUCT_NAME_SINGULAR } from "../../constants";

export default function PaymentSuccessPage() {
  const {
    cardId,
    isPaymentForSingleCard,
    isPaymentForSubscriptionOnly,
    isPaymentForSubscriptionWithCard,
    isPaymentForAddCardToExistingSubscription,
  } = usePayment();
  const nav = useRoutes();

  function handleViewPacks() {
    nav.toMyPacks();
  }
  function handleViewCardoly() {
    nav.toCardPage({ cardID: cardId });
  }

  return (
    <PageContainer>
      <Center h="80vh">
        <VStack spacing="md">
          <Icon as={FiCheckCircle} boxSize="120px" color="green.500" />
          <Text fontSize="3xl" fontWeight="bold">
            Success
          </Text>
          {/* <Text>You have successfully updated your trusted contact!</Text> */}
          {/* <Text>Your payment was successful</Text> */}

          {isPaymentForAddCardToExistingSubscription && (
            <VStack spacing={"md"}>
              <Text>
                You successfully got a {PRODUCT_NAME_SINGULAR} using your
                subscription.
              </Text>
              <Button onClick={handleViewCardoly}>View Cardoly</Button>
            </VStack>
          )}
          {isPaymentForSingleCard && (
            <VStack spacing={"md"}>
              <Text>You successfully purchased a {PRODUCT_NAME_SINGULAR}</Text>
              <Button onClick={handleViewCardoly}>View Cardoly</Button>
            </VStack>
          )}
          {isPaymentForSubscriptionOnly && (
            <VStack spacing={"md"}>
              <Text>
                You successfully purchased a pack of {PRODUCT_NAME_PLURAL}.
              </Text>
              <Button onClick={handleViewPacks}>
                View my {PRODUCT_NAME_SINGULAR} packs
              </Button>
            </VStack>
          )}
          {isPaymentForSubscriptionWithCard && (
            <VStack spacing={"md"}>
              <Text>
                You successfully purchased a pack of {PRODUCT_NAME_PLURAL},
                which includes your newly created {PRODUCT_NAME_SINGULAR}
              </Text>

              <VStack spacing="md">
                <Button w="full" onClick={handleViewCardoly}>
                  View created Cardoly
                </Button>
                <Button w="full" variant={"outline"} onClick={handleViewPacks}>
                  View my {PRODUCT_NAME_SINGULAR} packs
                </Button>
              </VStack>
            </VStack>
          )}
        </VStack>
      </Center>
    </PageContainer>
  );
}
