import { Box, Text } from "@chakra-ui/react";

export function TryNewSearchPrompt() {
  return (
    <Box my="lg">
      <Text textAlign="center" fontWeight="bold" color="gray.600">
        Your perfect item might be elsewhere! Try a new search 😃
      </Text>
    </Box>
  );
}
