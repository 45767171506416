import { Modal, ModalOverlay, ModalContent } from "@chakra-ui/react";
import {
  modalActions,
  useAppDispatch,
  useAppSelector,
} from "../state-management";

import { MODAL_COMPONENTS } from "./modalRegistry";

export const ModalRoot = () => {
  const { modalType, chakraModalProps, modalProps } = useAppSelector(
    (state) => state.modal
  );
  const dispatch = useAppDispatch();

  if (!modalType) {
    return null;
  }

  function onCloseModal() {
    dispatch(modalActions.hideModal());
  }

  const SpecificModal = MODAL_COMPONENTS[modalType];

  return (
    <Modal
      isOpen={true}
      onClose={onCloseModal}
      isCentered
      scrollBehavior="inside"
      {...chakraModalProps}
    >
      <ModalOverlay />
      <ModalContent>
        <SpecificModal {...modalProps} {...{ onCloseModal }} />
      </ModalContent>
    </Modal>
  );
};

// import {
//   Modal,
//   ModalOverlay,
//   ModalContent,
//   BoxProps,
//   Box,
// } from "@chakra-ui/react";
// import {
//   modalActions,
//   useAppDispatch,
//   useAppSelector,
// } from "../state-management";

// import { MODAL_COMPONENTS } from "./modalRegistry";

// const BlankComponent = (props: BoxProps) => <Box {...props} />;

// export const ModalRoot = () => {
//   const { modalType, chakraModalProps, modalProps } = useAppSelector(
//     (state) => state.modal
//   );
//   const dispatch = useAppDispatch();
//   const isOpen = !!modalType;

//   function onCloseModal() {
//     dispatch(modalActions.hideModal());
//   }

//   const SpecificModal = modalType
//     ? MODAL_COMPONENTS[modalType]
//     : BlankComponent;

//   return (
//     <Modal
//       isOpen={isOpen}
//       onClose={onCloseModal}
//       isCentered
//       scrollBehavior="inside"
//       {...chakraModalProps}
//     >
//       <ModalOverlay />
//       <ModalContent>
//         <SpecificModal {...modalProps} {...{ onCloseModal }} />
//       </ModalContent>
//     </Modal>
//   );
// };
