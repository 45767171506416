import { createPortal } from "react-dom";

import { BouncingCardWithConfettiAnimation } from "./BouncingCardWithConfettiAnimation";

export function OpeningSequence(props: OpeningSequenceProps) {
  return createPortal(
    <BouncingCardWithConfettiAnimation {...props} />,
    document.body
  );
}
