import {
  Text,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
} from "@chakra-ui/react";

export function WhyEmailPrompt() {
  return (
    <Popover placement="top">
      <PopoverTrigger>
        <Text
          fontWeight={"bold"}
          display="inline"
          color="blue.500"
          cursor="pointer"
        >
          Why?
        </Text>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>Why do we need your email?</PopoverHeader>
        <PopoverBody>
          {/* TODO: Text when we allow user to edit entry */}
          {/* This will allow you to edit your entry later and enable the recipient
          to thank you. We respect your privacy and will not use your email for
          any other purpose. */}
          This will enable the recipient to thank you. We respect your privacy
          and will not use your email for any other purpose.
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
