import {
  Text,
  HStack,
  Icon,
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
  useDisclosure,
  IconButton,
  Flex,
  Box,
  Stack,
  Heading,
  Center,
  LinkProps,
  Link,
  VStack,
} from "@chakra-ui/react";

import { CgMenuRight as HamburgerIcon } from "react-icons/cg";
import {
  // FiMenu as HamburgerIcon,
  FiX as CloseIcon,
  // FiUser as UserIcon,
  // FiLogOut as LogOutIcon,
  // FiPlus as PlusIcon,
} from "react-icons/fi";

import { useMatch } from "react-router-dom";
import { DesktopOnly, MobileOnly, PageContainer } from "../../components";
import { ROUTES } from "../../constants";
import { ForgotPassword } from "../../feature";
import { useAuth, useRoutes } from "../../hooks";
import { SubscriptionsPanel } from "./SubscriptionsPanel";
import { UserInfo } from "./UserInfo";

function getPath(path: string) {
  return `${ROUTES.MY_ACCOUNT}/${path}`;
}

const USER_DETAIL_PATH = "user";
const SECURITY_PATH = "security";
const SUBSCRIPTION_PATH = "subscription";

const contentConfig = {
  [USER_DETAIL_PATH]: {
    content: <UserInfo />,
    index: 0,
  },
  [SECURITY_PATH]: {
    content: <ForgotPassword />,
    index: 1,
  },
  [SUBSCRIPTION_PATH]: {
    content: <SubscriptionsPanel />,
    index: 2,
  },
};

// Re-use?
const NavLink = ({
  children,
  isActive,
  ...rest
}: LinkProps & { isActive?: boolean }) => (
  <Link
    px={4}
    py={2}
    rounded={"md"}
    w="full"
    text="blue"
    bg={isActive ? "blue.50" : ""}
    color={isActive ? "blue.500" : ""}
    _hover={{
      textDecoration: "none",
      bg: "blue.50",
      color: "blue.500",
    }}
    {...rest}
  >
    {children}
  </Link>
);

export function MyAccountPage() {
  const matchUserDetail = useMatch(getPath(USER_DETAIL_PATH));
  const matchSecurity = useMatch(getPath(SECURITY_PATH));
  const matchSubscription = useMatch(getPath(SUBSCRIPTION_PATH));

  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isAuthenticated, handleLogout } = useAuth();
  const { toMyAccount, toCreateCardPage } = useRoutes();

  const config = matchUserDetail
    ? contentConfig[USER_DETAIL_PATH]
    : matchSecurity
    ? contentConfig[SECURITY_PATH]
    : matchSubscription
    ? contentConfig[SUBSCRIPTION_PATH]
    : contentConfig[USER_DETAIL_PATH];

  const renderContent = config.content;
  function handleMobileNav(path: string) {
    return () => {
      toMyAccount(path);
      onClose();
    };
  }

  const renderNavContent = (
    <VStack w="full">
      <NavLink
        isActive={!!matchUserDetail}
        onClick={handleMobileNav(USER_DETAIL_PATH)}
      >
        User Detail
      </NavLink>
      <NavLink
        isActive={!!matchSecurity}
        onClick={handleMobileNav(SECURITY_PATH)}
      >
        Security
      </NavLink>
      <NavLink
        isActive={!!matchSubscription}
        onClick={handleMobileNav(SUBSCRIPTION_PATH)}
      >
        Subscription
      </NavLink>
    </VStack>
  );

  return (
    <>
      <MobileOnly>
        <Flex justifyContent="space-between" w="full" mt="sm" mb="md" px="md">
          <Center>
            <Heading variant={"h4"}>Account Settings</Heading>
          </Center>
          <IconButton
            variant={"ghost"}
            icon={
              isOpen ? <Icon as={CloseIcon} /> : <Icon as={HamburgerIcon} />
            }
            aria-label={"Open Menu"}
            display={{ md: "none" }}
            onClick={isOpen ? onClose : onOpen}
          />
        </Flex>

        {isOpen ? (
          <Box
            p={4}
            left="0"
            display={{ md: "none" }}
            pos="absolute"
            bg="white"
            zIndex={"dropdown"}
            w="full"
            border="1"
            boxShadow={"md"}
          >
            <Stack as={"nav"} spacing={4}>
              {renderNavContent}
            </Stack>
          </Box>
        ) : null}

        <Box px="md">{renderContent}</Box>
      </MobileOnly>

      <DesktopOnly>
        <PageContainer>
          <HStack spacing="sm" mb="md">
            {/* <Icon as={ToolIcon} fontSize="3xl"></Icon> */}
            <Text variant={"h3"} color={"gray.700"}>
              My Account
            </Text>
          </HStack>

          <Tabs
            variant="soft-rounded"
            colorScheme={"brand"}
            isLazy
            // orientation="vertical"
            // defaultIndex={2}
            index={config.index}
            onChange={(index) => {
              const item = Object.entries(contentConfig).find((entry) => {
                return entry[1].index === index;
              });
              const path = item?.[0];
              if (path) {
                toMyAccount(path);
              }
            }}
          >
            <TabList>
              <Tab>User Details</Tab>
              <Tab>Security</Tab>
              <Tab>Subscriptions</Tab>
            </TabList>

            <TabPanels>
              <TabPanel>
                <UserInfo />
              </TabPanel>
              <TabPanel>
                <ForgotPassword />
              </TabPanel>
              <TabPanel>
                <SubscriptionsPanel />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </PageContainer>
      </DesktopOnly>
    </>
  );
}

// import React, { ReactNode } from "react";
// import {
//   IconButton,
//   Avatar,
//   Box,
//   CloseButton,
//   Flex,
//   HStack,
//   VStack,
//   Icon,
//   useColorModeValue,
//   Link,
//   Drawer,
//   DrawerContent,
//   Text,
//   useDisclosure,
//   BoxProps,
//   FlexProps,
//   Menu,
//   MenuButton,
//   MenuDivider,
//   MenuItem,
//   MenuList,
//   Center,
// } from "@chakra-ui/react";
// import {
//   FiHome,
//   FiTrendingUp,
//   FiCompass,
//   FiStar,
//   FiSettings,
//   FiMenu,
//   FiBell,
//   FiChevronDown,
// } from "react-icons/fi";
// import { IconType } from "react-icons";
// import { ReactText } from "react";
// import { UserInfo } from "./UserInfo";
// import { MobileOnly, PageContainer } from "../../components";

// interface LinkItemProps {
//   name: string;
//   icon: IconType;
// }
// const LinkItems: Array<LinkItemProps> = [
//   { name: "Home", icon: FiHome },
//   { name: "Trending", icon: FiTrendingUp },
//   { name: "Explore", icon: FiCompass },
//   { name: "Favourites", icon: FiStar },
//   { name: "Settings", icon: FiSettings },
// ];

// export function MyAccountPage({ children }: { children?: ReactNode }) {
//   const { isOpen, onOpen, onClose } = useDisclosure();
//   return (
//     <Box
//       // mt="1"
//       minH="100vh"
//       // bg={useColorModeValue("gray.100", "gray.900")}
//     >
//       <PageContainer>
//         <SidebarContent
//           onClose={() => onClose}
//           display={{ base: "none", md: "block" }}
//         />
//       </PageContainer>

//       <Drawer
//         autoFocus={false}
//         isOpen={isOpen}
//         placement="left"
//         onClose={onClose}
//         returnFocusOnClose={false}
//         onOverlayClick={onClose}
//         size="full"
//       >
//         <MobileOnly>
//           <DrawerContent>
//             <SidebarContent onClose={onClose} />
//           </DrawerContent>
//         </MobileOnly>
//       </Drawer>

//       <MobileNav onOpen={onOpen} />
//       <Box ml={{ base: 0, md: 60 }} p="4">
//         {children}
//         <UserInfo />
//       </Box>
//     </Box>
//   );
// }

// interface SidebarProps extends BoxProps {
//   onClose: () => void;
// }

// const SidebarContent = ({ onClose, ...rest }: SidebarProps) => {
//   return (
//     <Box
//       // transition="3s ease"
//       // bg={useColorModeValue("white", "gray.900")}
//       bg="white"
//       borderRight="1px"
//       borderRightColor={useColorModeValue("gray.200", "gray.700")}
//       w={{ base: "full", md: 60 }}
//       pos="absolute"
//       h="full"
//       {...rest}
//     >
//       <MobileOnly>
//         <Flex h="20" alignItems="center" mx="8" justifyContent="space-between">
//           <Text fontSize="2xl" fontFamily="monospace" fontWeight="bold">
//             Cardoly
//           </Text>
//           <CloseButton
//             display={{ base: "flex", md: "none" }}
//             onClick={onClose}
//           />
//         </Flex>
//       </MobileOnly>

//       {LinkItems.map((link) => (
//         <NavItem key={link.name} icon={link.icon}>
//           {link.name}
//         </NavItem>
//       ))}
//     </Box>
//   );
// };

// interface NavItemProps extends FlexProps {
//   icon: IconType;
//   children: ReactText;
// }
// const NavItem = ({ icon, children, ...rest }: NavItemProps) => {
//   return (
//     <Link
//       href="#"
//       style={{ textDecoration: "none" }}
//       _focus={{ boxShadow: "none" }}
//     >
//       <Flex
//         align="center"
//         p="4"
//         mx="4"
//         borderRadius="lg"
//         role="group"
//         cursor="pointer"
//         _hover={{
//           bg: "cyan.400",
//           color: "white",
//         }}
//         {...rest}
//       >
//         {icon && (
//           <Icon
//             mr="4"
//             fontSize="16"
//             _groupHover={{
//               color: "white",
//             }}
//             as={icon}
//           />
//         )}
//         {children}
//       </Flex>
//     </Link>
//   );
// };

// interface MobileProps extends FlexProps {
//   onOpen: () => void;
// }
// const MobileNav = ({ onOpen, ...rest }: MobileProps) => {
//   return (
//     <IconButton
//       display={{ base: "flex", md: "none" }}
//       onClick={onOpen}
//       variant="outline"
//       aria-label="open menu"
//       icon={<FiMenu />}
//     />
//   );
//   // return (
//   //   <Flex
//   //     ml={{ base: 0, md: 60 }}
//   //     px={{ base: 4, md: 4 }}
//   //     height="20"
//   //     alignItems="center"
//   //     bg={useColorModeValue("white", "gray.900")}
//   //     borderBottomWidth="1px"
//   //     borderBottomColor={useColorModeValue("gray.200", "gray.700")}
//   //     justifyContent={{ base: "space-between", md: "flex-end" }}
//   //     {...rest}
//   //   >
//   //     <IconButton
//   //       display={{ base: "flex", md: "none" }}
//   //       onClick={onOpen}
//   //       variant="outline"
//   //       aria-label="open menu"
//   //       icon={<FiMenu />}
//   //     />

//   //     <Text
//   //       display={{ base: "flex", md: "none" }}
//   //       fontSize="2xl"
//   //       fontFamily="monospace"
//   //       fontWeight="bold"
//   //     >
//   //       Logo
//   //     </Text>

//   //     <HStack spacing={{ base: "0", md: "6" }}>
//   //       <IconButton
//   //         size="lg"
//   //         variant="ghost"
//   //         aria-label="open menu"
//   //         icon={<FiBell />}
//   //       />
//   //       <Flex alignItems={"center"}>
//   //         <Menu>
//   //           <MenuButton
//   //             py={2}
//   //             transition="all 0.3s"
//   //             _focus={{ boxShadow: "none" }}
//   //           >
//   //             <HStack>
//   //               <Avatar
//   //                 size={"sm"}
//   //                 src={
//   //                   "https://images.unsplash.com/photo-1619946794135-5bc917a27793?ixlib=rb-0.3.5&q=80&fm=jpg&crop=faces&fit=crop&h=200&w=200&s=b616b2c5b373a80ffc9636ba24f7a4a9"
//   //                 }
//   //               />
//   //               <VStack
//   //                 display={{ base: "none", md: "flex" }}
//   //                 alignItems="flex-start"
//   //                 spacing="1px"
//   //                 ml="2"
//   //               >
//   //                 <Text fontSize="sm">Justina Clark</Text>
//   //                 <Text fontSize="xs" color="gray.600">
//   //                   Admin
//   //                 </Text>
//   //               </VStack>
//   //               <Box display={{ base: "none", md: "flex" }}>
//   //                 <FiChevronDown />
//   //               </Box>
//   //             </HStack>
//   //           </MenuButton>
//   //           <MenuList
//   //             bg={useColorModeValue("white", "gray.900")}
//   //             borderColor={useColorModeValue("gray.200", "gray.700")}
//   //           >
//   //             <MenuItem>Profile</MenuItem>
//   //             <MenuItem>Settings</MenuItem>
//   //             <MenuItem>Billing</MenuItem>
//   //             <MenuDivider />
//   //             <MenuItem>Sign out</MenuItem>
//   //           </MenuList>
//   //         </Menu>
//   //       </Flex>
//   //     </HStack>
//   //   </Flex>
//   // );
// };
