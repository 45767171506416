import { IconButton, Tooltip, Icon, Button } from "@chakra-ui/react";
import { useScreen } from "../hooks";

export function IconButtonWithTooltip(props: {
  icon: any;
  label: string;
  onClick: () => void;
}) {
  const { isDesktop } = useScreen();
  const SpecificIcon = props.icon;

  return isDesktop ? (
    <Tooltip label={props.label} placement="top">
      <IconButton
        size={"md"}
        isRound
        variant={"outline"}
        icon={
          <Icon
            as={props.icon}
            // boxSize={FONT_BOX_SIZE}
            boxSize={5}
          />
        }
        aria-label={props.label}
        bgColor={"white"}
        onClick={props.onClick}
      />
    </Tooltip>
  ) : (
    <Button
      onClick={props.onClick}
      leftIcon={<SpecificIcon />}
      variant="outline"
      colorScheme="brand"
      bgColor="white"
    >
      {props.label}
    </Button>
  );
}
