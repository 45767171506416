import {
  Box,
  Flex,
  Avatar,
  HStack,
  Link,
  IconButton,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  Stack,
  Icon,
  Text,
  Divider,
  LinkProps,
  Container,
  Spacer,
} from "@chakra-ui/react";
import {
  FiMenu as HamburgerIcon,
  FiX as CloseIcon,
  FiUser as UserIcon,
  FiLogOut as LogOutIcon,
  FiPlus as PlusIcon,
} from "react-icons/fi";
import { UserEmail } from "./UserEmail";
import { SignInButton } from "./SignUpButton";
import { useAuth, useRoutes } from "../../hooks";
import { CardolyLogoName } from "../CardolyLogoName";
import { MobileOnly, DesktopOnly } from "../../components";

const NavLink = ({ onClick, children, ...rest }: LinkProps) => (
  <Link
    onClick={onClick}
    px={4}
    py={2}
    rounded={"md"}
    _hover={{
      textDecoration: "none",
      bg: "blue.50",
      color: "blue.500",
    }}
    {...rest}
  >
    {children}
  </Link>
);

function Links({ onLinkClick }: { onLinkClick(): void }) {
  const { toCreateCardPage, toDashboard, toMyAccount } = useRoutes();
  const { isAuthenticated } = useAuth();
  const nav = useRoutes();
  // const isCreateCardolyPage = useIsCreateCardPage();
  const isCreateCardolyPage = nav.isCreateCardolyPage;

  function handleLinkClick(fn: () => void) {
    return () => {
      fn();
      onLinkClick();
    };
  }

  return (
    <>
      {isAuthenticated && (
        <NavLink onClick={handleLinkClick(toDashboard)}>Dashboard</NavLink>
      )}
      <NavLink onClick={handleLinkClick(nav.toPricingPage)}>Pricing</NavLink>
      <MobileOnly>
        <NavLink
          onClick={handleLinkClick(() => toMyAccount("user"))}
          display="inline-block"
          w="full"
        >
          My Account
        </NavLink>
      </MobileOnly>

      {isCreateCardolyPage ? null : (
        <DesktopOnly>
          <Button
            rounded="full"
            colorScheme="brand"
            leftIcon={<Icon as={PlusIcon} />}
            onClick={handleLinkClick(toCreateCardPage)}
          >
            Create a Cardoly
          </Button>
        </DesktopOnly>
      )}
    </>
  );
}

export function NavBar() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isAuthenticated, handleLogout } = useAuth();
  const { toMyAccount, toCreateCardPage, isCreateCardolyPage, isLoginPage } =
    useRoutes();
  const renderNavContent = <Links onLinkClick={onClose} />;

  return (
    <Box
      bg={useColorModeValue("white", "gray.900")}
      shadow="sm"
      // position="fixed"
      // w="full"
    >
      {/* Mobile Only */}

      <MobileOnly px="4">
        <Flex h={16} alignItems={"center"}>
          <Box>
            <CardolyLogoName onClickNavigateToHome />
          </Box>

          <Spacer />
          {isCreateCardolyPage ? null : (
            <Button
              rounded="full"
              colorScheme="brand"
              leftIcon={<Icon as={PlusIcon} />}
              onClick={() => toCreateCardPage()}
            >
              New Cardoly
            </Button>
          )}
          {isLoginPage ? null : (
            <IconButton
              // size={"sm"}
              ml="sm"
              variant={"ghost"}
              icon={
                isOpen ? <Icon as={CloseIcon} /> : <Icon as={HamburgerIcon} />
              }
              aria-label={"Open Menu"}
              display={{ md: "none" }}
              onClick={isOpen ? onClose : onOpen}
            />
          )}
        </Flex>
        {isOpen ? (
          <Box
            p={4}
            left="0"
            display={{ md: "none" }}
            pos="absolute"
            bg="white"
            zIndex={"modal"}
            w="full"
            border="1"
            boxShadow={"md"}
          >
            <Stack as={"nav"} spacing={4}>
              {renderNavContent}
              <Divider />
              {isAuthenticated ? (
                <Button
                  leftIcon={<LogOutIcon />}
                  onClick={handleLogout}
                  variant="ghost"
                  colorScheme={"gray"}
                >
                  Sign Out
                </Button>
              ) : (
                <Box
                  onClick={onClose}
                  // w="full"
                >
                  <SignInButton />
                </Box>
              )}
            </Stack>
          </Box>
        ) : null}
      </MobileOnly>

      {/* Desktop only */}
      <DesktopOnly>
        <Container
          maxW="container.xl"
          // h="80px"
          py="md"
        >
          <Flex alignItems={"center"} justifyContent={"space-between"}>
            <Flex
              alignItems={"center"}
              justifyContent={"space-between"}
              w="full"
            >
              <Box>
                <CardolyLogoName onClickNavigateToHome />
              </Box>
              <HStack
                as={"nav"}
                spacing={4}
                display={{ base: "none", md: "flex" }}
              >
                {renderNavContent}
              </HStack>
            </Flex>

            <Box ml={["0", "6"]} display={["none", "block"]}>
              {isAuthenticated ? (
                <Flex alignItems={"center"}>
                  <Menu>
                    <MenuButton
                      as={Button}
                      rounded={"full"}
                      variant={"link"}
                      cursor={"pointer"}
                      minW={0}
                    >
                      <Avatar
                        size={"sm"}
                        icon={<Icon as={UserIcon} color={"gray.600"} />}
                        bg="gray.200"
                      />
                    </MenuButton>
                    <MenuList>
                      <UserEmail />
                      <MenuDivider />
                      <MenuItem onClick={() => toMyAccount()}>
                        <Text fontSize="sm">My Account</Text>
                      </MenuItem>
                      <MenuDivider />
                      <MenuItem icon={<LogOutIcon />} onClick={handleLogout}>
                        <Text fontSize="sm">Sign Out</Text>
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Flex>
              ) : (
                <SignInButton />
              )}
            </Box>
          </Flex>
        </Container>
      </DesktopOnly>
    </Box>
  );
}
