import {
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  Button,
  Text,
} from "@chakra-ui/react";
import { useEffect } from "react";
import { useRemoveCardEntryMutation } from "../../graphql/generated/schema";
import { useToast } from "../../hooks";
import { entrySliceActions, useAppDispatch } from "../../state-management";
import { invalidateCacheForCardUpdate } from "../../util/invalidateCacheForCardUpdate";

export function DeleteCardPrompt({
  entryID,
  onCloseModal,
}: {
  entryID: string;
  onCloseModal(): void;
}) {
  const [deleteCardEntry, { data, loading: isDeleting, error }] =
    useRemoveCardEntryMutation();
  const isDeletingSuccess = !!data;
  const isDeletingError = !!error;

  // const { cardID } = useCard();
  const dispatch = useAppDispatch();

  // Setup toasts
  useToast({
    id: "delete",
    title: "Delete failed",
    description: "Something went wrong :(",
    show: isDeletingError,
    status: "error",
  });

  function handleDeleteCardEntry() {
    deleteCardEntry({
      variables: { removeCardEntryId: entryID },
      update: invalidateCacheForCardUpdate,
    });
  }

  useEffect(() => {
    if (isDeletingSuccess) {
      onCloseModal();
      // If successsful, broadcast the id of the deleted message so EntryCardList can delete the entry from infinite scroll (if that page is visible)
      dispatch(entrySliceActions.addDeletedCardEntryId(entryID));
    }
  }, [isDeletingSuccess]);

  return (
    <>
      <ModalHeader>Delete entry</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Text>Are you sure you want to delete entry?</Text>
      </ModalBody>

      <ModalFooter>
        <Button
          variant="ghost"
          colorScheme={"gray"}
          onClick={onCloseModal}
          mr={2}
        >
          Cancel
        </Button>
        <Button
          colorScheme="red"
          isLoading={isDeleting}
          onClick={handleDeleteCardEntry}
        >
          Delete
        </Button>
      </ModalFooter>
    </>
  );
}
