import {
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  ModalFooter,
  Button,
} from "@chakra-ui/react";
import { useModal } from "../../hooks";

export function ConfirmModal({ title, message, onConfirm }: ConfirmModalProps) {
  const { hideModal } = useModal();

  return (
    <>
      <ModalHeader>{title}</ModalHeader>
      <ModalCloseButton />
      <ModalBody>{message}</ModalBody>
      <ModalFooter>
        <Button colorScheme="blue" mr={"sm"} onClick={hideModal}>
          Cancel
        </Button>
        <Button variant="ghost" onClick={onConfirm}>
          Confirm
        </Button>
      </ModalFooter>
    </>
  );
}
