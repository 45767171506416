import {
  Image,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  Text,
  VStack,
} from "@chakra-ui/react";
import { CardolyLogoName } from "../../feature";
import {
  PaymentForm,
  PaymentFormForSubscription,
} from "../../pages/PaymentPage/PaymentSection/PaymentForm";
import { useAppSelector } from "../../state-management";

export function PaymentModal() {
  const selectedProduct = useAppSelector((state) => state.card.selectedProduct);
  const isPack = !!selectedProduct?.bundle;

  return (
    <>
      <ModalHeader>Purchase Cardoly</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <VStack spacing="lg" minH="container.sm">
          <CardolyLogoName />

          <div>
            <Text as="span" fontWeight={"bold"}>
              {selectedProduct?.name}
            </Text>
            {" for "}
            <Text as="span">${selectedProduct?.price}</Text>
          </div>
          {isPack ? <PaymentFormForSubscription /> : <PaymentForm />}
          <PoweredByStripe />
        </VStack>
      </ModalBody>
    </>
  );
}

function PoweredByStripe() {
  return (
    <a href="https://stripe.com/" target="_blank" rel="noopener noreferrer">
      <Image src="/powered_by_stripe_logo.svg" w="180px" />
    </a>
  );
}
