import PillPity from "pill-pity";
import {
  SimpleGrid,
  Box,
  AspectRatio,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  useToast,
  Heading,
  VStack,
} from "@chakra-ui/react";

import {
  cardActions,
  useAppDispatch,
  patternCardBackgrounds,
  getCardBackgroundById,
} from "../../state-management";
import { useCard } from "../../hooks";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { CardBgType } from "../../graphql/generated/schema";
import { imageFileGroupings } from "../../util/imageUtil";
import { useState } from "react";
import { SelectTheme } from "./SelectTheme";
import { ThemeHeading } from "./ThemeHeading";
import { useEditedCard } from "../../hooks/useEditedCard";

export function SelectCardBackground() {
  const [selectedTheme, setSelectedTheme] = useState<PhotoCategory>("All");

  return (
    <Tabs variant={"soft-rounded"} isFitted>
      <TabList>
        <Tab>Illustrations</Tab>
        <Tab>Pattern</Tab>
      </TabList>

      <TabPanels>
        <TabPanel px={0}>
          <VStack spacing="lg" alignItems="stretch">
            <VStack spacing="md" alignItems="stretch">
              <Heading
                fontSize="sm"
                textTransform={"uppercase"}
                color="gray.500"
                textAlign={"center"}
              >
                Find Theme
              </Heading>
              <SelectTheme
                value={selectedTheme}
                onChange={(theme) => setSelectedTheme(theme)}
              />
            </VStack>
            {imageFileGroupings[selectedTheme] ? (
              //  If a theme has been picked, show only that one
              <VStack key={selectedTheme} alignItems="stretch">
                <ThemeHeading>{selectedTheme}</ThemeHeading>
                <Box>
                  <BackgroundList
                    backgrounds={imageFileGroupings[selectedTheme]}
                  />
                </Box>
              </VStack>
            ) : (
              // Otherwise, show all themes
              Object.entries(imageFileGroupings).map(
                ([groupingKey, groupingValues]) => {
                  return (
                    <VStack key={groupingKey} alignItems="stretch">
                      <ThemeHeading>{groupingKey}</ThemeHeading>
                      <Box>
                        <BackgroundList backgrounds={groupingValues} />
                      </Box>
                    </VStack>
                  );
                }
              )
            )}
          </VStack>
        </TabPanel>
        <TabPanel px={0}>
          <BackgroundList backgrounds={patternCardBackgrounds} />
        </TabPanel>
      </TabPanels>
    </Tabs>
  );
}

export function BackgroundList({
  backgrounds,
}: {
  backgrounds: CardolyBackground[] | undefined;
}) {
  const toast = useToast();
  const { editedCard } = useEditedCard();
  const { updateCard, card, cardID } = useCard();
  const { selectedCardBackgroundID } = useEditedCard();
  const dispatch = useAppDispatch();

  function handleUpdateCard(updateCardInput: UpdateCardInput) {
    updateCard(updateCardInput, {
      onError() {
        toast({
          id: "error-edit-bg",
          title: "Error",
          description: "Background save failed",
          duration: 3000,
          status: "error",
          position: "bottom",
        });
      },
      onCompleted() {
        toast({
          id: "success-edit-bg",
          title: "Success",
          description: "Background saved",
          duration: 700,
          status: "success",
          position: "bottom",
          isClosable: true,
        });
      },
    });
  }

  function handleSelectBackground(
    backgroundID: CardolyBackground["cardBackgroundID"]
  ) {
    return function handle(_e: any) {
      if (!editedCard?.id && card && card?.id) {
        // Add server card to store, so bg change doesn't override card header text in UI
        dispatch(cardActions.setEditedCard(card));
      }

      dispatch(cardActions.setCardBackgroundID(backgroundID));
      const cardBackground = getCardBackgroundById(backgroundID);
      if (cardID) {
        const updateCardInput: UpdateCardInput = {
          id: cardID,
          ...cardBackground,
        };
        handleUpdateCard(updateCardInput);
      }
    };
  }

  const highlightStyle = {
    cursor: "pointer",
    borderColor: "blue.500",
  };

  return (
    <Box pos="relative">
      <SimpleGrid columns={[2, 3]} spacing="sm">
        {backgrounds?.map((cardBg) => {
          const condStyle =
            selectedCardBackgroundID === cardBg.cardBackgroundID
              ? highlightStyle
              : {};

          return (
            <Box
              key={cardBg.cardBackgroundID}
              rounded="2xl"
              overflow="hidden"
              shadow="md"
              border="4px solid #fff"
              _hover={highlightStyle}
              sx={condStyle}
              onClick={handleSelectBackground(cardBg.cardBackgroundID)}
            >
              <AspectRatio ratio={1}>
                {cardBg?.type === CardBgType.Pattern ? (
                  <LazyLoadComponent>
                    <PillPity
                      pattern={cardBg.cardBackgroundID}
                      w="full"
                      bgColor={cardBg.color.bg}
                      patternFill={cardBg.color.fg}
                      patternOpacity={1}
                    />
                  </LazyLoadComponent>
                ) : (
                  <LazyLoadComponent>
                    <Box
                      sx={{
                        w: "full",
                        // bgImage: cardBg.bgImageUrl, // TODO: Retire bgImageUrl. Dynamically compute with cardBg.cardBackgroundID aka fileName
                        bgImage: `/bg-images/thumbnails/${cardBg?.cardBackgroundID}`,
                        bgSize: "cover",
                        bgPosition: "center",
                        bgRepeat: "no-repeat",
                      }}
                    />
                  </LazyLoadComponent>
                )}
              </AspectRatio>
            </Box>
          );
        })}
      </SimpleGrid>
    </Box>
  );
}
