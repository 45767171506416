import { loadStripe } from "@stripe/stripe-js";
import { format } from "date-fns";
import { Card } from "../graphql/generated/schema";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const STRIPE_PK = process.env.REACT_APP_STRIPE_PK;
export const stripePromise = STRIPE_PK ? loadStripe(STRIPE_PK) : null;

// export function isCardFree(card?: Partial<Card>) {
export function isCardFree(card?: Card) {
  const orderType = card?.orderType;
  const orderStatus = card?.orderStatus;
  const hasSubscription = !!card?.subscriptionId;

  if (hasSubscription) {
    return false;
  }

  if (orderType) {
    return orderType === "free";
  }

  if (orderStatus) {
    return orderStatus === "unpaid";
  }

  return true;
}

export function getCardType(card?: Partial<Card>) {
  const orderType = card?.orderType;

  if (orderType === "free") {
    return "Mini";
  }

  return orderType;
}

export function formatDate(date: string) {
  return format(new Date(date), "MMM dd, yyyy");
}

// https://github.com/apollographql/apollo-client/issues/1564#issuecomment-342421733
export function omitDeep(value: any, key: any): any {
  if (Array.isArray(value)) {
    return value.map((i: any) => omitDeep(i, key));
  } else if (typeof value === "object" && value !== null) {
    return Object.keys(value).reduce((newObject: any, k: any): any => {
      if (k === key) return newObject;
      return Object.assign({ [k]: omitDeep(value[k], key) }, newObject);
    }, {});
  }
  return value;
}
