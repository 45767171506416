import {
  FormControl,
  FormLabel,
  HStack,
  Switch,
  Icon,
  Text,
  Flex,
  Popover,
  PopoverTrigger,
  useDisclosure,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  IconButton,
} from "@chakra-ui/react";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import { useToggleConfettiMutation } from "../../../../../graphql/generated/schema";
import { useCard } from "../../../../../hooks";

export function ConfettiToggle() {
  const { cardId, card } = useCard();
  const [toggleConfetti] = useToggleConfettiMutation();

  const id = "confetti-toggle";
  return (
    <FormControl display="flex" alignItems="center" justifyContent={"flex-end"}>
      <FormLabel htmlFor={id} mb="0">
        <HStack spacing="xs">
          <Text fontSize="sm">Show Confetti</Text>
          <ConfettiIcon />
        </HStack>
      </FormLabel>
      <Switch
        // disabled={true}
        isChecked={card?.showConfetti}
        id={id}
        onChange={() => {
          if (cardId) {
            toggleConfetti({ variables: { input: { cardId } } });
          }
        }}
      />
    </FormControl>
  );
}

function ConfettiIcon() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    // <Tooltip
    //   label="Shows a confetti animation when the recipient views the board"
    //   hasArrow
    //   placement="top"
    // >
    //   <Flex>
    //     <Icon
    //       as={BsFillQuestionCircleFill}
    //       fontSize="md"
    //       sx={{ color: "blue.500" }}
    //     />
    //   </Flex>
    // </Tooltip>
    <Flex>
      <Popover isOpen={isOpen} onClose={onClose} placement="top">
        <PopoverTrigger>
          <IconButton
            aria-label="confetti-toggle"
            variant="link"
            size="xs"
            icon={
              <Icon
                onClick={onOpen}
                as={BsFillQuestionCircleFill}
                fontSize="md"
                sx={{ color: "blue.500" }}
              />
            }
          />
          {/* <Box width="fit-content">
            <Icon
              onClick={onOpen}
              as={BsFillQuestionCircleFill}
              fontSize="md"
              sx={{ color: "blue.500" }}
            />
          </Box> */}
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>
            Shows a confetti animation when the recipient views the board
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Flex>
  );
}
