import {
  Box,
  Button,
  HStack,
  Input,
  Text,
  VStack,
  Icon,
  Badge,
  FormLabel,
  FormControl,
} from "@chakra-ui/react";
import { useState } from "react";
import { useCard, useCustomToast, useModal } from "../../../hooks";
import { FiSend as DeliverIcon } from "react-icons/fi";
import { FiLink2 as CopyIcon } from "react-icons/fi";
import { BiEnvelopeOpen as EmailOpenedIcon } from "react-icons/bi";
import { CopyRecipientLink } from "./CopyRecipientLink";
import { useDeliverCardToReceiverMutation } from "../../../graphql/generated/schema";

export function DeliverySettings() {
  const { card, cardID } = useCard();
  const toast = useCustomToast();
  const [onDeliverCard, { loading, error }] =
    useDeliverCardToReceiverMutation();
  const deliveredAt = card?.deliveredAt;
  const isDelivered = !!deliveredAt;
  const receiverName = card?.receiverName;
  const receiverEmail = card?.receiverEmail;
  const readAt = card?.readAt;
  const isRead = !!readAt;
  const [newReceiverName, setReceiverName] = useState(receiverName);
  const [newReceiverEmail, setReceiverEmail] = useState(receiverEmail);

  function handleDeliverCard() {
    if (newReceiverEmail && newReceiverName && cardID) {
      onDeliverCard({
        variables: {
          input: {
            receiverEmail: newReceiverEmail,
            receiverName: newReceiverName,
            cardId: cardID,
          },
        },
        onError() {
          toast({
            id: "delivery-error",
            status: "error",
            title: "Error",
            description: `Failed to deliver${
              error?.message ? `: ${error.message}` : ""
            }`,
          });
        },
      });
    }
  }

  return (
    <VStack align={"left"}>
      {/* Post-Delivery Status */}
      {isDelivered && (
        <VStack align={"left"} spacing="md">
          <VStack spacing={"xs"} align={"left"}>
            <Text fontSize="sm">Recipient Name</Text>
            <Text fontSize="sm" fontWeight="bold">
              {newReceiverName}
            </Text>
          </VStack>

          {newReceiverEmail && (
            <VStack spacing={"xs"} align={"left"}>
              <Text fontSize="sm">Recipient Email</Text>
              <Text fontSize="sm" fontWeight="bold">
                {newReceiverEmail}
              </Text>
            </VStack>
          )}

          <VStack align="left">
            <HStack>
              <Icon as={DeliverIcon} color={"teal"} />
              <HStack>
                <Badge
                  colorScheme={isDelivered ? "green" : "gray"}
                  // fontSize="xs"
                  fontSize="sm"
                  px="sm"
                  rounded="full"
                >
                  {isDelivered ? "Delivered" : "Not Delivered"}
                </Badge>
                {deliveredAt && (
                  <Text fontSize="xs">
                    on {new Date(deliveredAt).toDateString()}
                  </Text>
                )}
                <ResetDelivery />
              </HStack>
            </HStack>

            <HStack>
              <Icon as={EmailOpenedIcon} color={"teal"} />
              <HStack>
                <Badge
                  colorScheme={isRead ? "green" : "gray"}
                  // fontSize="xs"
                  fontSize="sm"
                  px="sm"
                  rounded="full"
                >
                  {isRead ? "Opened" : "Not Opened"}
                </Badge>
                {readAt && (
                  <Text fontSize="xs">
                    on {new Date(readAt).toDateString()}
                  </Text>
                )}
              </HStack>
            </HStack>
            {/* <Button
              variant="link"
              fontSize="sm"
              // colorScheme="teal"
              onClick={handleResetDelivery}
            >
              Reset Delivery
            </Button> */}
          </VStack>
        </VStack>
      )}

      {/* Pre-Delivery */}
      {!isDelivered && (
        <VStack spacing="lg" align={"stretch"}>
          {/* Auto/Email Delivery Form */}
          <VStack spacing="md">
            <FormControl
            // isInvalid={!!errors?.email}
            >
              <FormLabel>Recipient Name</FormLabel>
              <Input
                placeholder="Jane Doe"
                value={newReceiverName}
                onChange={(e) => setReceiverName(e.target.value)}
                disabled={isDelivered}
              />
            </FormControl>

            <FormControl>
              <FormLabel>Recipient Email</FormLabel>
              <Input
                placeholder="janedoe@example.com"
                value={newReceiverEmail}
                onChange={(e) => setReceiverEmail(e.target.value)}
                disabled={isDelivered}
              />
            </FormControl>

            <Button
              isLoading={loading}
              isDisabled={isDelivered}
              leftIcon={<DeliverIcon />}
              onClick={handleDeliverCard}
              // colorScheme="gray"
              w="full"
            >
              {isDelivered ? "Delivered!" : "Deliver now"}
            </Button>
          </VStack>

          {/* Manual Delivery */}
          <HStack>
            <Icon as={CopyIcon} />
            <Box fontSize="sm">
              <Text sx={{ display: "inline" }} color="gray.600">
                {" "}
                Or provide the recipient a{" "}
              </Text>
              <CopyRecipientLink />
              <Text
                sx={{
                  display: "inline",
                  fontStyle: "italic",
                  // textDecoration: "underline",
                }}
                color="gray.600"
              >
                . Once this link is visited, the Cardoly will be marked as both
                delivered and opened.
              </Text>
              {/* <OrderedList>
            <ListItem>
              {" "}
              <Text sx={{ display: "inline" }} color="gray.600">
                {" "}
                Or share via a{" "}
              </Text>
              <CopyRecipientLink />
            </ListItem>
            <ListItem>
              {" "}
              <Text sx={{ display: "inline" }} color="gray.600">
                {" "}
                and then{" "}
              </Text>
              <Button variant="link" fontSize="sm">
                {" "}
                mark it delivered
              </Button>
            </ListItem>
          </OrderedList> */}
            </Box>
          </HStack>
        </VStack>
      )}
    </VStack>
  );
}

function ResetDelivery() {
  const { cardID, updateCard } = useCard();
  const { openConfirmModal, closeConfirmModal } = useModal();
  // const toast = useCustomToast();

  function handleResetDelivery() {
    if (!cardID) return;

    updateCard(
      {
        id: cardID,
        deliveredAt: null,
        readAt: null,
      },
      {
        onCompleted() {
          closeConfirmModal();
        },
      }
    );
  }

  return (
    <Button
      variant="link"
      fontSize="sm"
      // colorScheme="teal"
      // onClick={handleResetDelivery}
      onClick={() =>
        openConfirmModal({
          title: "Reset Delivery",
          message:
            "Have you accidentally sent your board to the wrong email address? Reset the delivery here to ensure it reaches the intended recipient.",
          onConfirm: handleResetDelivery,
        })
      }
    >
      Reset
      {/* Delivery */}
    </Button>
  );
}
