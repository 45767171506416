import { useCallback } from "react";
import {
  useMatch,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { MAX_FREE_CARDOLY_ENTRIES, ROUTES } from "../constants";
import {
  OrderType,
  useDeleteCardMutation,
  useGetCardQuery,
  useUpdateCardMutation,
} from "../graphql/generated/schema";
import { selectors, useAppSelector } from "../state-management";
import { getCardType, isCardFree } from "../util";
import { useAuth } from "./useAuth";
import { useCustomToast } from "./useToast";

export function useCard() {
  const { user } = useAuth();
  let { cardID } = useParams();
  const navigate = useNavigate();
  let selectCardTypeMatch = useMatch(ROUTES.SELECT_CARD_TYPE.url);
  let match = useMatch("/card/:cardID"); // useParams only works as a Route component. useMatch gets params outside of Route.
  // If we call from Modal, cardID from useParams would be undefined, since ModalRoot isn't a child of Route
  // so we attempt to use match to get cardID from its potential url param
  const [searchParams] = useSearchParams();
  const cid = searchParams.get("cid");
  cardID =
    cid ||
    cardID ||
    match?.params?.cardID ||
    selectCardTypeMatch?.params.cardID;

  // http://localhost:3001/payment?pid=premium&cid=clocesgwn0000kvp41xpp0pxz

  const {
    data,
    loading: isLoading,
    error,
  } = useGetCardQuery({
    variables: { getCardId: cardID ?? "" },
    skip: !cardID,
  });

  const [
    apolloUpdateCard,
    { data: cardUpdateData, loading: isCardUpdating, error: cardUpdateError },
  ] = useUpdateCardMutation({});
  const isCardUpdateError = !!cardUpdateError;
  const isCardUpdateSuccess = !!cardUpdateData;
  const toast = useCustomToast();

  const updateCard = useCallback((input: Card, apolloOptions = {}) => {
    apolloUpdateCard({
      variables: { input },
      onError() {
        toast({
          id: "update-card-error",
          status: "error",
          duration: 5000,
          title: "Error",
          description: "Failed to update card",
        });
      },
      ...apolloOptions,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedCardBackground = useAppSelector(
    selectors.cardSelectors.getSelectedCardBackground
  );
  const [
    deleteCard,
    {
      data: dataDeleteCard,
      loading: isLoadingDeleteCard,
      error: errorDeleteCard,
    },
  ] = useDeleteCardMutation();
  const isSuccessDeleteCard = !!dataDeleteCard;
  const isErrorDeleteCard = !!errorDeleteCard;

  function handleAddEntry() {
    navigate(`/card/${cardID}/create`);
  }

  const card = data?.getCard; // || cacheCardData;
  const isUserCardOwner = user?.userId === card?.ownerCognitoID;
  const entryCount = card?.entryCount;
  const isFree = isCardFree(card);
  const hasReachedMaxEntries =
    entryCount && isFree ? entryCount >= MAX_FREE_CARDOLY_ENTRIES : false;

  return {
    hasReachedMaxEntries,
    handleAddEntry,
    card,
    cardType: getCardType(card),
    isUserCardOwner,
    cardID,
    cardId: cardID, // Duplicate until the cardID is deprecated.
    isFree,
    isSolo: card?.orderType === OrderType.Solo,
    isLoading,
    // Mutation
    updateCard,
    isCardUpdating,
    isCardUpdateSuccess,
    isCardUpdateError,
    cardUpdateError,
    deleteCard,
    isLoadingDeleteCard,
    isSuccessDeleteCard,
    isErrorDeleteCard,
    // Selectors
    selectedCardBackground,
    error,
  };
}
