import { FiSmile as SmileIcon } from "react-icons/fi"; // TODO: Check treeshaking options
import {
  Box,
  IconButton,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";

import data from "@emoji-mart/data";
// @ts-ignore
import Picker from "@emoji-mart/react";

const iconButtonControlStyleProps = {
  variant: "ghost",
  isRound: true,
  fontSize: "x-large",
};

export function EmojiPicker({
  onEmojiChange,
}: {
  onEmojiChange(val: string): void;
}) {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const handleChange = (emojiObject: { native: string }) => {
    onEmojiChange(emojiObject.native);
    onClose();
  };

  return (
    <Popover
      // isLazy // When not lazy, the picker isn't jumpy because the dimensions are fully known. If lazy, it renders at bottom, collides, then jumps to top.
      placement="bottom-start"
      isOpen={isOpen}
      onClose={onClose}
      returnFocusOnClose={false}
    >
      {/* Trick to make Popover work with Tooltip: https://github.com/chakra-ui/chakra-ui/issues/2843#issuecomment-748641805 */}
      <Tooltip label="Emoji" placement="top">
        <Box display="inline-block">
          <PopoverTrigger>
            <IconButton
              aria-label="color picker icon"
              {...iconButtonControlStyleProps}
              icon={<SmileIcon />}
              onClick={onToggle}
            />
          </PopoverTrigger>
        </Box>
      </Tooltip>
      <PopoverContent width="fit-content">
        <Picker data={data} onEmojiSelect={handleChange} theme={"light"} />
      </PopoverContent>
    </Popover>
  );
}
