const isPatternBg = true;
const BG_CONFIG = isPatternBg
  ? {
      pType: "i-like-food",
      bgType: "pattern",
      pColorBg: "#fff",
      pColorFg: "#00f",
    }
  : {
      bgType: "image",
      bgImageUrl: "/bg-images/bday-1.jpeg",
    };
export const SAMPLE_API_RESPONSES = {
  data: {
    data: {
      getCardsByOwnerCognitoId: {
        items: [
          {
            id: "4",
            ownerCognitoID: "8ee8a223-55f2-4c03-b09a-ac398db76799",
            createdAt: "2022-02-26T05:33:31.257Z",
            ...BG_CONFIG,
            title: "4th Card",
            titleFontFamily: "Caveat",
            orderType: "free",
            orderStatus: "unpaid",
            receiverName: "John Doe",
            paymentIntentID:
              "pi_3LbcZxIUMCWr2xgV1OoW5YCC_secret_o5hsu9hXKUgAFOa0tLbslT8yZ",
          },
        ],
      },
      getCard: {
        id: "4",
        ownerCognitoID: "8ee8a223-55f2-4c03-b09a-ac398db76799",
        createdAt: "2022-02-26T05:33:31.257Z",
        ...BG_CONFIG,
        title:
          "This is a text of 190 chars. A long happy birthday. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia possimus dolor nobis error illo debitis molestias quae fuga deleniti omnis,Y",
        titleFontFamily: "Caveat",
        orderType: "free",
        orderStatus: "unpaid",
        receiverName: "Johnny Bravo",
        ownerName: "The Marketing team",
        paymentIntentID:
          "pi_3LbcZxIUMCWr2xgV1OoW5YCC_secret_o5hsu9hXKUgAFOa0tLbslT8yZ",
      },
      getPhotos: {
        message: "Success",
        photos: [
          {
            alt_description: null,
            externalId: "Mv9hjnEUHR4",
            height: 4032,
            type: "UNSPLASH",
            urls: {
              md: "https://images.unsplash.com/photo-1517849845537-4d257902454a?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNTk5ODl8MHwxfHNlYXJjaHwxfHxkb2d8ZW58MHwxfHx8MTY1NzQxMDU4Mg&ixlib=rb-1.2.1&q=80&w=400",
              sm: "https://images.unsplash.com/photo-1517849845537-4d257902454a?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNTk5ODl8MHwxfHNlYXJjaHwxfHxkb2d8ZW58MHwxfHx8MTY1NzQxMDU4Mg&ixlib=rb-1.2.1&q=80&w=200",
            },
            width: 3024,
            user: {
              name: "charlesdeluvio",
              username: "charlesdeluvio",
            },
          },
          {
            alt_description: null,
            externalId: "Sg3XwuEpybU",
            height: 6000,
            type: "UNSPLASH",
            urls: {
              md: "https://images.unsplash.com/photo-1552053831-71594a27632d?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNTk5ODl8MHwxfHNlYXJjaHwyfHxkb2d8ZW58MHwxfHx8MTY1NzQxMDU4Mg&ixlib=rb-1.2.1&q=80&w=400",
              sm: "https://images.unsplash.com/photo-1552053831-71594a27632d?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNTk5ODl8MHwxfHNlYXJjaHwyfHxkb2d8ZW58MHwxfHx8MTY1NzQxMDU4Mg&ixlib=rb-1.2.1&q=80&w=200",
            },
            width: 3742,
            user: {
              name: "Richard Brutyo",
              username: "richardbrutyo",
            },
          },
          {
            alt_description: null,
            externalId: "yihlaRCCvd4",
            height: 6016,
            type: "UNSPLASH",
            urls: {
              md: "https://images.unsplash.com/photo-1530281700549-e82e7bf110d6?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNTk5ODl8MHwxfHNlYXJjaHwzfHxkb2d8ZW58MHwxfHx8MTY1NzQxMDU4Mg&ixlib=rb-1.2.1&q=80&w=400",
              sm: "https://images.unsplash.com/photo-1530281700549-e82e7bf110d6?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNTk5ODl8MHwxfHNlYXJjaHwzfHxkb2d8ZW58MHwxfHx8MTY1NzQxMDU4Mg&ixlib=rb-1.2.1&q=80&w=200",
            },
            width: 4016,
            user: {
              name: "Oscar Sutton",
              username: "o5ky",
            },
          },
          {
            alt_description: null,
            externalId: "WX4i1Jq_o0Y",
            height: 5368,
            type: "UNSPLASH",
            urls: {
              md: "https://images.unsplash.com/photo-1586671267731-da2cf3ceeb80?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNTk5ODl8MHwxfHNlYXJjaHw0fHxkb2d8ZW58MHwxfHx8MTY1NzQxMDU4Mg&ixlib=rb-1.2.1&q=80&w=400",
              sm: "https://images.unsplash.com/photo-1586671267731-da2cf3ceeb80?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNTk5ODl8MHwxfHNlYXJjaHw0fHxkb2d8ZW58MHwxfHx8MTY1NzQxMDU4Mg&ixlib=rb-1.2.1&q=80&w=200",
            },
            width: 3596,
            user: {
              name: "Taylor Kopel",
              username: "taylorkopel",
            },
          },
          {
            alt_description: null,
            externalId: "brFsZ7qszSY",
            height: 3876,
            type: "UNSPLASH",
            urls: {
              md: "https://images.unsplash.com/photo-1537151625747-768eb6cf92b2?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNTk5ODl8MHwxfHNlYXJjaHw1fHxkb2d8ZW58MHwxfHx8MTY1NzQxMDU4Mg&ixlib=rb-1.2.1&q=80&w=400",
              sm: "https://images.unsplash.com/photo-1537151625747-768eb6cf92b2?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNTk5ODl8MHwxfHNlYXJjaHw1fHxkb2d8ZW58MHwxfHx8MTY1NzQxMDU4Mg&ixlib=rb-1.2.1&q=80&w=200",
            },
            width: 2568,
            user: {
              name: "Alvan Nee",
              username: "alvannee",
            },
          },
          {
            alt_description: null,
            externalId: "G8cB8hY3yvU",
            height: 4032,
            type: "UNSPLASH",
            urls: {
              md: "https://images.unsplash.com/photo-1588943211346-0908a1fb0b01?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNTk5ODl8MHwxfHNlYXJjaHw2fHxkb2d8ZW58MHwxfHx8MTY1NzQxMDU4Mg&ixlib=rb-1.2.1&q=80&w=400",
              sm: "https://images.unsplash.com/photo-1588943211346-0908a1fb0b01?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNTk5ODl8MHwxfHNlYXJjaHw2fHxkb2d8ZW58MHwxfHx8MTY1NzQxMDU4Mg&ixlib=rb-1.2.1&q=80&w=200",
            },
            width: 3024,
            user: {
              name: "Ralu Gal",
              username: "ralu_gal",
            },
          },
          {
            alt_description: null,
            externalId: "oU6KZTXhuvk",
            height: 3360,
            type: "UNSPLASH",
            urls: {
              md: "https://images.unsplash.com/photo-1583337130417-3346a1be7dee?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNTk5ODl8MHwxfHNlYXJjaHw3fHxkb2d8ZW58MHwxfHx8MTY1NzQxMDU4Mg&ixlib=rb-1.2.1&q=80&w=400",
              sm: "https://images.unsplash.com/photo-1583337130417-3346a1be7dee?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNTk5ODl8MHwxfHNlYXJjaHw3fHxkb2d8ZW58MHwxfHx8MTY1NzQxMDU4Mg&ixlib=rb-1.2.1&q=80&w=200",
            },
            width: 2688,
            user: {
              name: "Karsten Winegeart",
              username: "karsten116",
            },
          },
          {
            alt_description: null,
            externalId: "TzjMd7i5WQI",
            height: 3663,
            type: "UNSPLASH",
            urls: {
              md: "https://images.unsplash.com/photo-1560807707-8cc77767d783?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNTk5ODl8MHwxfHNlYXJjaHw4fHxkb2d8ZW58MHwxfHx8MTY1NzQxMDU4Mg&ixlib=rb-1.2.1&q=80&w=400",
              sm: "https://images.unsplash.com/photo-1560807707-8cc77767d783?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNTk5ODl8MHwxfHNlYXJjaHw4fHxkb2d8ZW58MHwxfHx8MTY1NzQxMDU4Mg&ixlib=rb-1.2.1&q=80&w=200",
            },
            width: 2745,
            user: {
              name: "T.R Photography 📸",
              username: "_redo_",
            },
          },
          {
            alt_description: null,
            externalId: "5Vr_RVPfbMI",
            height: 6000,
            type: "UNSPLASH",
            urls: {
              md: "https://images.unsplash.com/photo-1596492784531-6e6eb5ea9993?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNTk5ODl8MHwxfHNlYXJjaHw5fHxkb2d8ZW58MHwxfHx8MTY1NzQxMDU4Mg&ixlib=rb-1.2.1&q=80&w=400",
              sm: "https://images.unsplash.com/photo-1596492784531-6e6eb5ea9993?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNTk5ODl8MHwxfHNlYXJjaHw5fHxkb2d8ZW58MHwxfHx8MTY1NzQxMDU4Mg&ixlib=rb-1.2.1&q=80&w=200",
            },
            width: 4000,
            user: {
              name: "Peri Stojnic",
              username: "whoisperi",
            },
          },
          {
            alt_description: null,
            externalId: "2s6ORaJY6gI",
            height: 2421,
            type: "UNSPLASH",
            urls: {
              md: "https://images.unsplash.com/photo-1510771463146-e89e6e86560e?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNTk5ODl8MHwxfHNlYXJjaHwxMHx8ZG9nfGVufDB8MXx8fDE2NTc0MTA1ODI&ixlib=rb-1.2.1&q=80&w=400",
              sm: "https://images.unsplash.com/photo-1510771463146-e89e6e86560e?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNTk5ODl8MHwxfHNlYXJjaHwxMHx8ZG9nfGVufDB8MXx8fDE2NTc0MTA1ODI&ixlib=rb-1.2.1&q=80&w=200",
            },
            width: 1362,
            user: {
              name: "Celine Sayuri Tagami",
              username: "celine_sayuri",
            },
          },
        ],
      },
      getCardEntriesByCardId: {
        items: [
          {
            cardID: "2",
            entryMessage: "Raw",
            font: {
              fontFamily: "Coming Soon",
              color: "",
            },
            photo: {
              externalId: "IhyGL914g9ShHdHVFy",
              width: null,
              height: null,
              alt_description: null,
              type: "GIPHY",
              urls: {
                sm: "https://media0.giphy.com/media/IhyGL914g9ShHdHVFy/200w.webp?cid=9f0f6425bvjaf9udet5d8x70t04jo6td3sc447nggvyyuiiv&ep=v1_gifs_search&rid=200w.webp&ct=g",
                md: "https://media0.giphy.com/media/IhyGL914g9ShHdHVFy/giphy.gif?cid=9f0f6425bvjaf9udet5d8x70t04jo6td3sc447nggvyyuiiv&ep=v1_gifs_search&rid=giphy.gif&ct=g",
              },
              user: null,
              sizes: {
                sm: {
                  height: "205",
                  width: "200",
                },
                md: {
                  height: "480",
                  width: "469",
                },
              },
            },
            signerName: "Alexander S Okoli",
            signerCognitoID: "8ee8a223-55f2-4c03-b09a-ac398db76799",
            id: "clhlin47g0001ayp41jzp4ygu",
            updatedAt: "2023-05-13T04:57:27.053Z",
            createdAt: "2023-05-13T04:57:27.053Z",
          },
          {
            cardID: "2",
            entryMessage: "",
            font: {
              fontFamily: "Coming Soon",
              color: "",
            },
            photo: {
              externalId: "AEEIkLykCWBKU",
              width: null,
              height: null,
              alt_description: null,
              type: "GIPHY",
              urls: {
                sm: "https://media1.giphy.com/media/AEEIkLykCWBKU/200w.webp?cid=9f0f6425vupetj5liuzbz0xne32ezm4d8v1975a5gljl7xhn&ep=v1_gifs_search&rid=200w.webp&ct=g",
                md: "https://media1.giphy.com/media/AEEIkLykCWBKU/giphy.gif?cid=9f0f6425vupetj5liuzbz0xne32ezm4d8v1975a5gljl7xhn&ep=v1_gifs_search&rid=giphy.gif&ct=g",
              },
              user: null,
              sizes: {
                sm: {
                  height: "254",
                  width: "200",
                },
                md: {
                  height: "318",
                  width: "250",
                },
              },
            },
            signerName: "Alexander S Okoli",
            signerCognitoID: "8ee8a223-55f2-4c03-b09a-ac398db76799",
            id: "clhlinh1l0002ayp416872saj",
            updatedAt: "2023-05-13T04:57:43.690Z",
            createdAt: "2023-05-13T04:57:43.690Z",
          },
          {
            cardID: "2",
            entryMessage: "Straight west coasting",
            font: {
              fontFamily: "Coming Soon",
              color: "",
            },
            photo: {
              externalId: "WxJKwKzAmfLm1RazVi",
              width: null,
              height: null,
              alt_description: null,
              type: "GIPHY",
              urls: {
                sm: "https://media1.giphy.com/media/WxJKwKzAmfLm1RazVi/200w.webp?cid=9f0f6425dw1mcwbl9s831pid43ji36g6w7a4trjahgediegw&ep=v1_gifs_search&rid=200w.webp&ct=g",
                md: "https://media1.giphy.com/media/WxJKwKzAmfLm1RazVi/giphy-downsized-medium.gif?cid=9f0f6425dw1mcwbl9s831pid43ji36g6w7a4trjahgediegw&ep=v1_gifs_search&rid=giphy-downsized-medium.gif&ct=g",
              },
              user: null,
              sizes: {
                sm: {
                  height: "200",
                  width: "200",
                },
                md: {
                  height: "480",
                  width: "480",
                },
              },
            },
            signerName: "Alexander S Okoli",
            signerCognitoID: "8ee8a223-55f2-4c03-b09a-ac398db76799",
            id: "clhlio6680003ayp4d94tespu",
            updatedAt: "2023-05-13T05:11:22.520Z",
            createdAt: "2023-05-13T04:58:16.257Z",
          },
          {
            cardID: "2",
            entryMessage: "",
            font: {
              fontFamily: "Coming Soon",
              color: "",
            },
            photo: {
              externalId: "nMT8FHxdwgCnS",
              width: null,
              height: null,
              alt_description: null,
              type: "GIPHY",
              urls: {
                sm: "https://media3.giphy.com/media/nMT8FHxdwgCnS/200w.webp?cid=9f0f6425p54w01bxb4e0pb9o1dfikk11mxziloorhp6jtdia&ep=v1_gifs_search&rid=200w.webp&ct=g",
                md: "https://media3.giphy.com/media/nMT8FHxdwgCnS/giphy.gif?cid=9f0f6425p54w01bxb4e0pb9o1dfikk11mxziloorhp6jtdia&ep=v1_gifs_search&rid=giphy.gif&ct=g",
              },
              user: null,
              sizes: {
                sm: {
                  height: "112",
                  width: "200",
                },
                md: {
                  height: "270",
                  width: "480",
                },
              },
            },
            signerName: "Alexander S Okoli",
            signerCognitoID: "8ee8a223-55f2-4c03-b09a-ac398db76799",
            id: "clhlipc6c0004ayp4ebp811zh",
            updatedAt: "2023-05-13T04:59:10.693Z",
            createdAt: "2023-05-13T04:59:10.693Z",
          },
          {
            cardID: "2",
            entryMessage: "Fair",
            font: {
              fontFamily: "Coming Soon",
              color: "",
            },
            photo: {
              externalId: "R0e09o37I3RDK9qbfK",
              width: null,
              height: null,
              alt_description: null,
              type: "GIPHY",
              urls: {
                sm: "https://media4.giphy.com/media/R0e09o37I3RDK9qbfK/200w.webp?cid=9f0f6425saconnhyqehdhdtvutzhxe8xufuirqeyajoalhao&ep=v1_gifs_search&rid=200w.webp&ct=g",
                md: "https://media4.giphy.com/media/R0e09o37I3RDK9qbfK/giphy.gif?cid=9f0f6425saconnhyqehdhdtvutzhxe8xufuirqeyajoalhao&ep=v1_gifs_search&rid=giphy.gif&ct=g",
              },
              user: null,
              sizes: {
                sm: {
                  height: "200",
                  width: "200",
                },
                md: {
                  height: "480",
                  width: "480",
                },
              },
            },
            signerName: "Alexander S Okoli",
            signerCognitoID: "8ee8a223-55f2-4c03-b09a-ac398db76799",
            id: "clhlj46qr0000cgp4hpj80g8z",
            updatedAt: "2023-05-13T05:10:43.493Z",
            createdAt: "2023-05-13T05:10:43.493Z",
          },
          {
            cardID: "2",
            entryMessage: "",
            font: {
              fontFamily: "Coming Soon",
              color: "",
            },
            photo: {
              externalId: "qfz5QR8k6Hb3fpCqXg",
              width: null,
              height: null,
              alt_description: null,
              type: "GIPHY",
              urls: {
                sm: "https://media1.giphy.com/media/qfz5QR8k6Hb3fpCqXg/200w.webp?cid=9f0f6425njjg2xu306phapsuicw64hxk7tfoi1214f4h3rl1&ep=v1_gifs_search&rid=200w.webp&ct=g",
                md: "https://media1.giphy.com/media/qfz5QR8k6Hb3fpCqXg/giphy.gif?cid=9f0f6425njjg2xu306phapsuicw64hxk7tfoi1214f4h3rl1&ep=v1_gifs_search&rid=giphy.gif&ct=g",
              },
              user: null,
              sizes: {
                sm: {
                  height: "200",
                  width: "200",
                },
                md: {
                  height: "220",
                  width: "220",
                },
              },
            },
            signerName: "Alexander S Okoli",
            signerCognitoID: "8ee8a223-55f2-4c03-b09a-ac398db76799",
            id: "clhlj63tg0001cgp4fvsw702d",
            updatedAt: "2023-05-13T05:12:13.014Z",
            createdAt: "2023-05-13T05:12:13.014Z",
          },
          {
            cardID: "2",
            entryMessage: "ANSWERS",
            font: {
              fontFamily: "Coming Soon",
              color: "",
            },
            photo: {
              externalId: "xT0GqpnSvYsjt8gHlu",
              width: null,
              height: null,
              alt_description: null,
              type: "GIPHY",
              urls: {
                sm: "https://media4.giphy.com/media/xT0GqpnSvYsjt8gHlu/200w.webp?cid=9f0f6425cgqv7nfvmq62hy10xe3lg8o46fb6r25xz30xj6d3&ep=v1_gifs_search&rid=200w.webp&ct=g",
                md: "https://media4.giphy.com/media/xT0GqpnSvYsjt8gHlu/giphy.gif?cid=9f0f6425cgqv7nfvmq62hy10xe3lg8o46fb6r25xz30xj6d3&ep=v1_gifs_search&rid=giphy.gif&ct=g",
              },
              user: null,
              sizes: {
                sm: {
                  height: "200",
                  width: "200",
                },
                md: {
                  height: "480",
                  width: "480",
                },
              },
            },
            signerName: "Alexander S Okoli",
            signerCognitoID: "8ee8a223-55f2-4c03-b09a-ac398db76799",
            id: "clhlj79n70002cgp43oxt62q6",
            updatedAt: "2023-05-13T05:13:07.220Z",
            createdAt: "2023-05-13T05:13:07.220Z",
          },
          {
            cardID: "2",
            entryMessage: "",
            font: {
              fontFamily: "Coming Soon",
              color: "",
            },
            photo: {
              externalId: "STThOmSL9X8jpaEqfE",
              width: null,
              height: null,
              alt_description: null,
              type: "GIPHY",
              urls: {
                sm: "https://media1.giphy.com/media/STThOmSL9X8jpaEqfE/200w.webp?cid=9f0f64250d2479c4z8dw9emvukn1wi06v8sm056qexkoxqyg&ep=v1_gifs_search&rid=200w.webp&ct=g",
                md: "https://media1.giphy.com/media/STThOmSL9X8jpaEqfE/giphy.gif?cid=9f0f64250d2479c4z8dw9emvukn1wi06v8sm056qexkoxqyg&ep=v1_gifs_search&rid=giphy.gif&ct=g",
              },
              user: null,
              sizes: {
                sm: {
                  height: "113",
                  width: "200",
                },
                md: {
                  height: "270",
                  width: "480",
                },
              },
            },
            signerName: "Alexander S Okoli",
            signerCognitoID: "8ee8a223-55f2-4c03-b09a-ac398db76799",
            id: "clhlj8c060003cgp4fkla8lcu",
            updatedAt: "2023-05-13T05:13:56.935Z",
            createdAt: "2023-05-13T05:13:56.935Z",
          },
          {
            cardID: "2",
            entryMessage: "",
            font: {
              fontFamily: "Coming Soon",
              color: "",
            },
            photo: {
              externalId: "Yr67tieMpoNnERfqH3",
              width: null,
              height: null,
              alt_description: null,
              type: "GIPHY",
              urls: {
                sm: "https://media1.giphy.com/media/Yr67tieMpoNnERfqH3/200w.webp?cid=9f0f64250d2479c4z8dw9emvukn1wi06v8sm056qexkoxqyg&ep=v1_gifs_search&rid=200w.webp&ct=g",
                md: "https://media1.giphy.com/media/Yr67tieMpoNnERfqH3/giphy.gif?cid=9f0f64250d2479c4z8dw9emvukn1wi06v8sm056qexkoxqyg&ep=v1_gifs_search&rid=giphy.gif&ct=g",
              },
              user: null,
              sizes: {
                sm: {
                  height: "113",
                  width: "200",
                },
                md: {
                  height: "270",
                  width: "480",
                },
              },
            },
            signerName: "Alexander S Okoli",
            signerCognitoID: "8ee8a223-55f2-4c03-b09a-ac398db76799",
            id: "clhlj8j4w0004cgp40snkcuw5",
            updatedAt: "2023-05-13T05:14:06.177Z",
            createdAt: "2023-05-13T05:14:06.177Z",
          },
          {
            cardID: "2",
            entryMessage: "",
            font: {
              fontFamily: "Coming Soon",
              color: "",
            },
            photo: {
              externalId: "uitXvuWHrQym7P2nW4",
              width: null,
              height: null,
              alt_description: null,
              type: "GIPHY",
              urls: {
                sm: "https://media3.giphy.com/media/uitXvuWHrQym7P2nW4/200w.webp?cid=9f0f6425g77q5q209ispwhesfpik1mtr4jy6gbyarwhkbd7f&ep=v1_gifs_search&rid=200w.webp&ct=g",
                md: "https://media3.giphy.com/media/uitXvuWHrQym7P2nW4/giphy.gif?cid=9f0f6425g77q5q209ispwhesfpik1mtr4jy6gbyarwhkbd7f&ep=v1_gifs_search&rid=giphy.gif&ct=g",
              },
              user: null,
              sizes: {
                sm: {
                  height: "200",
                  width: "200",
                },
                md: {
                  height: "480",
                  width: "480",
                },
              },
            },
            signerName: "Alexander S Okoli",
            signerCognitoID: "8ee8a223-55f2-4c03-b09a-ac398db76799",
            id: "clhlj96vh0005cgp4gj0ih1fm",
            updatedAt: "2023-05-13T05:14:36.942Z",
            createdAt: "2023-05-13T05:14:36.942Z",
          },
        ],

        // items: [
        //   {
        //     entryMessage: "A yo!",
        //     cardID: "1",
        //     font: {
        //       color: "#f00",
        //       fontFamily: "Permanent Marker",
        //     },
        //     cardOwnerCognitoID: "ac7282cb-063a-4309-80ff-90cfe2fba1a3",
        //     createdAt: "2022-02-26T05:33:31.257Z",
        //     id: "entry-1",
        //     signerCognitoID: "ac7282cb-063a-4309-80ff-90cfe2fba1a3",
        //     signerName: "Sommy Ok",
        //     photo: {
        //       type: "GIPHY",
        //       externalId: "g64slGUlWWp3y1Ddu6",
        //       urls: {
        //         sm: "https://media1.giphy.com/media/g64slGUlWWp3y1Ddu6/200w.webp?cid=9f0f6425qacdwnpmrc4akjv1oj1fg2w9560n40uv24irtl5a&rid=200w.webp&ct=g",
        //         md: "https://media1.giphy.com/media/g64slGUlWWp3y1Ddu6/giphy.gif?cid=9f0f6425qacdwnpmrc4akjv1oj1fg2w9560n40uv24irtl5a&rid=giphy.gif&ct=g",
        //       },
        //     },
        //   },
        //   {
        //     id: "cl7cnqrss0000rmp4gm8k3rjw",
        //     cardID: "1",
        //     createdAt: "2022-02-26T05:33:31.257Z",
        //     entryMessage:
        //       "We made it! (They didn't think that we would make it)",
        //     font: {
        //       fontFamily: "Coming Soon",
        //       color: "",
        //     },
        //     photo: {
        //       type: "GIPHY",
        //       urls: {
        //         sm: "https://media3.giphy.com/media/JppxCfzyEYRuu2LBjC/200w.webp?cid=9f0f6425r1ufm5grgg2z49ro3f1op42aptwh6h2g3g7bdqtt&rid=200w.webp&ct=g",
        //         md: "https://media3.giphy.com/media/JppxCfzyEYRuu2LBjC/giphy-downsized-medium.gif?cid=9f0f6425r1ufm5grgg2z49ro3f1op42aptwh6h2g3g7bdqtt&rid=giphy-downsized-medium.gif&ct=g",
        //       },
        //       externalId: "JppxCfzyEYRuu2LBjC",
        //       width: null,
        //       height: null,
        //       alt_description: null,
        //       user: null,
        //     },
        //     signerName: "Eminem",
        //     signerCognitoID: "ac7282cb-063a-4309-80ff-90cfe2fba1a3",
        //   },
        //   {
        //     id: "cl7cq9at40000mrp4f7zr01rl",
        //     cardID: "1",
        //     createdAt: "2022-02-26T05:33:31.257Z",
        //     entryMessage: "Epic! 🌼",
        //     font: {
        //       fontFamily: "Swanky and Moo Moo",
        //       color: "#db3e00",
        //     },
        //     photo: {
        //       type: "GIPHY",
        //       urls: {
        //         sm: "https://media3.giphy.com/media/l3V0hWP5as4hPaxbi/200w.webp?cid=9f0f6425dks1im7re7ohafgj169ligyz0ddrkjyq38dn96ak&rid=200w.webp&ct=g",
        //         md: "https://media3.giphy.com/media/l3V0hWP5as4hPaxbi/giphy.gif?cid=9f0f6425dks1im7re7ohafgj169ligyz0ddrkjyq38dn96ak&rid=giphy.gif&ct=g",
        //       },
        //       externalId: "l3V0hWP5as4hPaxbi",
        //       width: null,
        //       height: null,
        //       alt_description: null,
        //       user: null,
        //     },
        //     signerName: "",
        //     signerCognitoID: "ac7282cb-063a-4309-80ff-90cfe2fba1a3",
        //   },
        //   {
        //     id: "cl7e3ye4q00009pp44xcp2dng",
        //     cardID: "1",
        //     createdAt: "2022-02-26T05:33:31.257Z",
        //     entryMessage: "First unsplash photo! - EDITED!",
        //     font: {
        //       fontFamily: "Coming Soon",
        //       color: "",
        //     },
        //     photo: {
        //       type: "UNSPLASH",
        //       urls: {
        //         sm: "https://images.unsplash.com/photo-1511857963324-9aab834f68f0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNTk5ODl8MHwxfHNlYXJjaHwxNnx8YXJjaGl0ZWN0dXJlfGVufDB8MXx8fDE2NjE3Mzc5OTI&ixlib=rb-1.2.1&q=80&w=200",
        //         md: "https://images.unsplash.com/photo-1511857963324-9aab834f68f0?crop=entropy&cs=tinysrgb&fit=max&fm=jpg&ixid=MnwyNTk5ODl8MHwxfHNlYXJjaHwxNnx8YXJjaGl0ZWN0dXJlfGVufDB8MXx8fDE2NjE3Mzc5OTI&ixlib=rb-1.2.1&q=80&w=400",
        //       },
        //       externalId: "8oRCwrBn_Fc",
        //       width: 3680,
        //       height: 5674,
        //       alt_description: "low angle photography building",
        //       user: {
        //         name: "Ricardo Gomez Angel",
        //         username: "rgaleriacom",
        //         firstName: null,
        //         lastName: null,
        //       },
        //     },
        //     signerName: "",
        //     signerCognitoID: "ac7282cb-063a-4309-80ff-90cfe2fba1a3",
        //   },
        // ],
      },
    },
  },
};
