import {
  Box,
  Button,
  Grid,
  GridItem,
  Heading,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Loader } from "../../../components";
import { PRODUCT_NAME_SINGULAR } from "../../../constants";
import { useCard, useModal, useSubscriptions } from "../../../hooks";
import { formatDate } from "../../../util";

export function SubscriptionSection() {
  const { activeSubscriptions, loading: isLoadingSubscriptions } =
    useSubscriptions();
  const { cardID } = useCard();
  const { showModal } = useModal();

  if (isLoadingSubscriptions) {
    return <Loader />;
  }

  const activeSubscriptionsLen = activeSubscriptions?.length;
  return activeSubscriptionsLen ? (
    <div>
      <VStack mb="md" mt="md">
        <Heading variant="h2" textAlign="center">
          Your Subscriptions
        </Heading>
        <Text>Get {PRODUCT_NAME_SINGULAR} with your subscriptions</Text>
      </VStack>
      <Grid
        templateColumns={[
          "repeat(1, 1fr)",
          `repeat(${
            activeSubscriptionsLen < 2 ? activeSubscriptionsLen : 2
          }, 1fr)`,
          `repeat(${
            activeSubscriptionsLen < 3 ? activeSubscriptionsLen : 3
          }, 1fr)`,
          `repeat(${
            activeSubscriptionsLen < 4 ? activeSubscriptionsLen : 4 // This pattern "centers" the items when there are less column items to fill up the full width
          }, 1fr)`,
        ]}
        gap="md"
      >
        {activeSubscriptions.map((sub) => {
          return (
            <GridItem w="100%">
              <Box key={sub.id} borderWidth="1px" rounded="md" p="lg">
                <Box mb="sm">
                  <Text fontWeight="bold">{sub.productName}</Text>
                </Box>
                <Text>Remaining Cards: {sub.cardsRemaining}</Text>
                <Text>Ends: {formatDate(sub.periodEnd)}</Text>

                <Box mt="md">
                  <Button
                    colorScheme="brand"
                    w="full"
                    isDisabled={!sub.status?.startsWith("active")}
                    onClick={() => {
                      // TODO: Report if !cardID
                      if (cardID) {
                        showModal({
                          modalType: "PayWithSubscriptionModal",
                          modalProps: {
                            cardId: cardID,
                            subscriptionId: sub.id,
                          },
                        });
                      }
                    }}
                  >
                    Use Subscription
                  </Button>
                </Box>
              </Box>
            </GridItem>
          );
        })}
      </Grid>
    </div>
  ) : null;
}
