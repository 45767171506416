import {
  Box,
  Button,
  Center,
  Container,
  Heading,
  SimpleGrid,
  Text,
  VStack,
} from "@chakra-ui/react";
import { useCallback } from "react";
import { Loader, PageContainer } from "../../components";
import { useCard, useRoutes } from "../../hooks";
import { isCardFree } from "../../util";
import { SubscriptionSection } from "./SubscriptionSection";
import { useHandleStripeRedirect } from "./useHandleStripeRedirect";
import {
  MiniPricingCard,
  PremiumPricingCard,
  SubscriptionPricingCard,
} from "./PricingCards";
import { PRODUCTS } from "../../constants";
import PaymentSuccessPage from "./PaymentSuccessPage";
import { usePayment } from "../../hooks/usePayment";

export function PaymentPage() {
  const { productId } = useRoutes();
  const { openPaymentModal } = usePayment();

  const { isProcessingPaymentInBackend, redirectToPaymentSuccessPage } =
    useHandleStripeRedirect();

  const { cardID, card, isLoading: isLoadingCard } = useCard();
  const nav = useRoutes();
  const isFree = isCardFree(card);

  const hasCustomerPaid = !isFree;

  const handleNavToCardPage = useCallback(
    () =>
      nav.toCardPage({
        cardID,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cardID, hasCustomerPaid]
  );

  // Synchronous: Processing either successful single card payment or subscription in our backend.
  if (isProcessingPaymentInBackend || isLoadingCard) {
    return <Loader />;
  }

  // post success or fail of isProcessingSuccessfulPayment, we show the user things are successful (investigate errors!)
  if (redirectToPaymentSuccessPage) {
    /**
     *  Use enum in URL to know how to redirect payment_for=SINGLE_CARD | SUB_W_CARD | SUB_ONLY
     */
    return <PaymentSuccessPage />;
  }

  if (!isFree) {
    return (
      <Center mt="lg">
        <VStack spacing="md">
          <Text textAlign="center" color="green.500">
            You have purchased the Cardoly 😊
          </Text>
          <Button onClick={handleNavToCardPage} colorScheme="brand" w="full">
            View Cardoly
          </Button>
        </VStack>
      </Center>
    );
  }

  /**
   * TODO: Prevent opening of modal if url is in incorrect state.
   * For example, opening single card purchase modal when there's no card ID
   *
   * Standalone payment page
   */
  if (productId) {
    openPaymentModal(productId);
  }

  return (
    <PageContainer>
      <Box w="full">
        <Container centerContent maxW="container.lg">
          <VStack spacing="md">
            {cardID ? <SubscriptionSection /> : null}

            <VStack spacing="lg">
              <Heading variant="h2" textAlign="center">
                Singles
              </Heading>

              <SimpleGrid gap="md" columns={[1, 1, 2]}>
                <MiniPricingCard />
                <PremiumPricingCard />
              </SimpleGrid>

              <VStack spacing="sm">
                <Heading variant="h2">Packs</Heading>
                <Text>
                  <Text
                    as="span"
                    fontWeight={"bold"}
                    // decoration="underline"
                    color="green.600"
                  >
                    Save up to 40%
                  </Text>{" "}
                  if you get a pack!
                </Text>
              </VStack>

              <SimpleGrid gap={"md"} columns={[1, 1, 2, 3]}>
                {Object.values(PRODUCTS)
                  .filter((p) => !!p.bundle)
                  .map((product) => {
                    return <SubscriptionPricingCard productId={product.id} />;
                  })}
              </SimpleGrid>
            </VStack>
          </VStack>
        </Container>
      </Box>
    </PageContainer>
  );
}
