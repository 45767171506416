import { Box, Text, AspectRatio } from "@chakra-ui/react";
import { FiLink as LinkIcon } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { LazyImage } from "../../components";
import { useAuth, useCard, useRoutes } from "../../hooks";
import {
  entrySliceActions,
  modalActions,
  useAppDispatch,
} from "../../state-management";
import { EntryBody } from "../EntryBody";

export interface CardEntryProps {
  entry: CardEntry;
  // onImageLoad(): void;
}

// export function CardEntryView({ entry, onImageLoad }: CardEntryProps) {
export function CardEntryView({ entry }: CardEntryProps) {
  const PHOTO_SIZE = "md";
  const entryImg = entry?.photo?.urls?.[PHOTO_SIZE];
  const imageWidth = entry?.photo?.sizes?.[PHOTO_SIZE]?.width;
  const imageHeight = entry?.photo?.sizes?.[PHOTO_SIZE]?.height;

  const { user } = useAuth();
  const { cardID, card } = useCard();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const entryID = entry.id;
  const userId = user.userId;
  const isUserSigner =
    userId && // ensure we don't compare "null === null"
    userId === entry?.signerCognitoID;
  const isUserCardOwner =
    userId && // ensure we don't compare "null === null"
    userId === card?.ownerCognitoID;
  const { isRecipientMode } = useRoutes();
  const canUserEditEntry = isUserSigner && !isRecipientMode;
  const canUserDeleteEntry =
    (isUserSigner || isUserCardOwner) && !isRecipientMode;
  const canUserViewMenu =
    !isRecipientMode && (canUserEditEntry || canUserDeleteEntry);

  // function handleEdit() {
  //   dispatch(entrySliceActions.addEntry(entry));
  //   const url = `/card/${cardID}/card-entry/${entryID}`;
  //   navigate(url);
  // }

  // function handleGetLink() {}

  // const handleImageLoad = (e: any) => {
  //   // console.log("loaded", e);
  //   onImageLoad();
  // };

  // function handleDelete() {
  //   // TODO: Dispatch to trigger modal
  //   dispatch(
  //     modalActions.showModal({
  //       modalType: "DeleteCardPrompt",
  //       modalProps: { entryID },
  //     })
  //   );
  // }

  return (
    <Box mx="auto" rounded="lg" shadow="lg" bg="white">
      {entryImg && (
        <AspectRatio ratio={Number(imageWidth) / Number(imageHeight)}>
          <LazyImage
            roundedTop="lg"
            w="full"
            fit="cover"
            src={entryImg}
            alt="entry"
            effect="blur"
          />
        </AspectRatio>
      )}

      <Box p={8}>
        <EntryBody entry={entry} />
      </Box>
    </Box>
  );
}
