import {
  createSearchParams,
  useMatch,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { ROUTES, SEARCH_PARAM_PAYMENT_FOR_KEY } from "../constants";
import { useCard } from "./useCard";

export enum RecipientMode {
  DEMO = "r",
  REAL = "a",
}

export function useRoutes() {
  const { cardID: currentCardID } = useCard(); // Any "cardID" in url will be used
  let [searchParams, setSearchParams] = useSearchParams();
  const currentUrlWithoutSearchParams = `${window.location.origin}${window.location.pathname}`;
  const productId = searchParams.get("pid");
  const subscriptionId = searchParams.get("sid");
  const isLoginPage = !!useMatch(ROUTES.LOGIN);
  const isCreateCardolyPage = !!useMatch(ROUTES.CREATE_CARD);

  const navigate = useNavigate();
  function toHome() {
    navigate(ROUTES.HOME);
  }
  function toCreateCardPage({
    productId = "",
    subscriptionId = "",
  }: { productId?: string; subscriptionId?: string } = {}) {
    const params = {} as Record<string, string>;

    if (productId) {
      params["pid"] = productId;
    }
    if (subscriptionId) {
      params["sid"] = subscriptionId;
    }
    navigate({
      pathname: ROUTES.CREATE_CARD,
      search: createSearchParams(params).toString(),
    });
  }
  const toCardPage = ({
    cardID,
    searchParams,
  }: {
    cardID?: string;
    searchParams?: Record<string, string>;
  }) => {
    navigate({
      pathname: `/card/${cardID}`,
      search: searchParams ? `?${createSearchParams(searchParams)}` : "",
    });
  };
  const toMyAccount = (path = "") => {
    navigate(`/my-account${path ? `/${path}` : ""}`);
  };
  const toMyPacks = () => {
    toMyAccount("subscription");
  };
  const toSelectCardTypePage = ({ cardID } = { cardID: currentCardID }) => {
    if (cardID) {
      navigate(ROUTES.SELECT_CARD_TYPE.get({ cardID }));
    }
  };
  function redirectToDashboard() {
    window.location.href = `${window.location.origin}/dashboard`;
  }
  function toDashboard() {
    navigate(ROUTES.DASHBOARD);
  }
  function toPricingPage() {
    navigate(ROUTES.PRICING);
  }

  function toCardPageRecipientMode() {
    toCardPage({
      cardID: currentCardID,
      searchParams: { mode: RecipientMode.DEMO },
    });
  }

  function toRealRecipientPage() {
    toCardPage({
      cardID: currentCardID,
      searchParams: { mode: RecipientMode.REAL },
    });
  }

  function toCardPageEditorMode() {
    // setSearchParams({ mode: "" });
    toCardPage({ cardID: currentCardID });
  }

  function toThanksPage() {
    navigate("thanks");
  }

  function toPaymentPageForProduct(
    productId = "" as string | null | undefined,
    cardId = ""
  ) {
    navigate({
      pathname: "/payment",
      search: createSearchParams({
        ...(productId ? { pid: productId } : {}),
        ...(cardId ? { cid: cardId } : {}),
      }).toString(),
    });
  }

  function toPaymentPageSuccess(paymentForValue = "") {
    navigate({
      // pathname: "/payment",
      search: createSearchParams({
        [SEARCH_PARAM_PAYMENT_FOR_KEY]: paymentForValue,
      }).toString(),
    });
  }

  const searchMode = searchParams.get("mode");
  const isDemoRecipientMode = searchMode === RecipientMode.DEMO;
  const isRealRecipientMode = searchMode === RecipientMode.REAL;
  const inviteParticipantUrlWithoutCardId = `${window.location.origin}/card`;
  const inviteParticipantUrl = `${inviteParticipantUrlWithoutCardId}/${currentCardID}`;

  // const queryParams = new URLSearchParams({
  //   mode: RecipientMode.REAL,
  //   rid: "yourRidValue",
  // });

  return {
    toThanksPage,
    toHome,
    toPricingPage,
    redirectToDashboard,
    toDashboard,
    toMyAccount,
    toCardPage,
    toCreateCardPage,
    toSelectCardTypePage,
    toMyPacks,
    // Query search
    searchParams,
    setSearchParams,
    toCardPageEditorMode,
    toCardPageRecipientMode,
    toRealRecipientPage,
    toPaymentPageForProduct,
    toPaymentPageSuccess,
    // Routes

    /**
     TODO: Real recipient view. Can have either read id or not (if copied)
     
     If rid, this is clicked from email
     http://localhost:3000/card/clocesgwn0000kvp41xpp0pxz?mode=a&rid=1234
     
     If there's no rid, this will be the view that you manually have to paste and mark read, but not demo mode
     http://localhost:3000/card/clocesgwn0000kvp41xpp0pxz?mode=a
     
     * When real recipient link is reached, with reading id,
     * - mark as delivered
     * - display "say thanks"
     * - allow them to view all messages
     */
    // realRecipientUrl: "",

    isLoginPage,
    isCreateCardolyPage,
    currentUrlWithoutSearchParams,
    isDemoRecipientMode,
    isRealRecipientMode,
    isRecipientMode: isDemoRecipientMode || isRealRecipientMode,
    // inviteParticipantUrl: `${window.location.origin}/card/${currentCardID}`,
    inviteParticipantUrlWithoutCardId,
    inviteParticipantUrl,
    // Query params
    productId,
    params: {
      subscriptionId,
    },
  };
}
