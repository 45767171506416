import { CardBgType } from "../graphql/generated/schema";
import {
  getCardBackgroundFromCard,
  selectors,
  useAppSelector,
} from "../state-management";

export function useEditedCard() {
  const editedCard = useAppSelector(selectors.cardSelectors.getEditedCard);
  const isEditing = !!editedCard?.id;

  const cardBackground = editedCard
    ? getCardBackgroundFromCard(editedCard)
    : {};
  const selectedCardBackgroundID =
    cardBackground.bgType === CardBgType.Image
      ? cardBackground.bgImageUrl?.replace("/bg-images/", "")
      : cardBackground.bgType === CardBgType.Pattern
      ? cardBackground.pType
      : null;

  return {
    editedCard,
    isEditing,
    cardBackground,
    selectedCardBackgroundID,
  };
}
