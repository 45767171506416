import { Box, Text, Center, VStack, Container } from "@chakra-ui/react";
import { useCard, useRoutes } from "../../../../hooks";
import useEditCardSettingDrawer from "../../../../hooks/useEditCardSettingDrawer";
import { useEditedCard } from "../../../../hooks/useEditedCard";
import { ACCORDION_INDEXES } from "../../../../state-management";

// function getTextShadow(color: string, x: number, y: number, blur: number) {
//   return `${color} ${x}em ${y}em ${blur}em
//           , ${color} -${x}em -${y}em ${blur}em
//           , ${color} -${x}em -${y}em ${blur}em
//           , ${color} -${x}em -${y}em ${blur}em
//           `;
// }

export function CardHeader() {
  const { card: serverCard, isUserCardOwner } = useCard();
  const { isCreateCardolyPage, isRecipientMode } = useRoutes();
  const { editedCard } = useEditedCard();
  const card = editedCard ?? serverCard;
  const receiverName = card?.receiverName;
  const ownerName = card?.ownerName;
  const title = card?.title;
  const titleFontFamily = card?.titleFontFamily;
  const { handleOpenCardSettingsDrawer } = useEditCardSettingDrawer();
  const canEdit = isUserCardOwner && !isCreateCardolyPage && !isRecipientMode;
  const editCardHeaderProps = canEdit
    ? {
        _hover: {
          borderColor: "white",
          cursor: "pointer",
        },
        onClick: handleOpenCardSettingsDrawer({
          index: ACCORDION_INDEXES.EDIT_DETAILS,
        }),
      }
    : {};

  // const textShadow = getTextShadow("black", 0.1, 0.1, 0.2);

  // const { isOpen: isEditing, onOpen, onClose } = useDisclosure();
  // const dispatch = useAppDispatch();

  // function handleOpenEditDetailsSetting() {
  //   dispatch(
  //     cardActions.openCardSettingsDrawer(ACCORDION_INDEXES.EDIT_DETAILS)
  //   );
  // }

  // return (
  //   <Popover
  //     closeOnBlur={false}
  //     // onClose={onClose}
  //   >
  //     <PopoverTrigger>
  //       {/* <Button>Trigger</Button> */}
  //       <Center
  //         h="full"
  //         border="2px solid transparent"
  //         px="md"
  //         py="sm"
  //         rounded="md"
  //         _hover={{
  //           borderColor: "white",
  //           cursor: "pointer",
  //         }}
  //         // onClick={onOpen}
  //         // onClick={onToggle}
  //       >
  //         <VStack spacing="sm" w="full">
  //           <Box w="full" textAlign={"left"}>
  //             <Text
  //               // color="white" fontSize="3xl"
  //               color="white"
  //               fontSize="xl"
  //               textAlign={"left"}
  //               fontWeight="bold"
  //               // textShadow={textShadow}
  //             >
  //               {receiverName ? receiverName : "Recipient Name"},
  //             </Text>
  //           </Box>

  //           <Text
  //             fontSize="3xl"
  //             // textShadow={textShadow}
  //             // textShadow={"1px 1px 2px grey, 0 0 1px grey, 0 0 0.2px grey"} // Alt example
  //             sx={{
  //               // textShadow:
  //               //   "black 0.1em 0.1em 0.2em, black -0.1em -0.1em 0.2em, black 0.1em -0.1em 0.2em, black -0.1em 0.1em 0.2em",
  //               // textShadow: "black 0.1em 0.1em 0.2em",
  //               wordBreak: "break-word",
  //               // position: "fixed", // When textShadow is fixed, it is performant.
  //               color: "#fff",
  //               fontFamily: titleFontFamily,
  //               textAlign: "center",
  //               // // TextShadow is super costly on scroll. Even highlighting the textShadow text with mouse is super slow.
  //               // textShadow: `0 0 7px #fff,
  //               // 0 0 10px #fff,
  //               // 0 0 21px #fff,
  //               // 0 0 42px #0fa,
  //               // 0 0 82px #0fa,
  //               // 0 0 92px #0fa,
  //               // 0 0 102px #0fa,
  //               // 0 0 151px #0fa`,
  //             }}
  //           >
  //             {title ? title : "Title"}
  //           </Text>

  //           <Box w="full">
  //             <Text
  //               // textShadow={textShadow}
  //               color="white"
  //               fontSize="xl"
  //               textAlign={"right"}
  //               fontWeight="bold"
  //             >
  //               From {ownerName}
  //             </Text>
  //           </Box>
  //         </VStack>
  //       </Center>
  //     </PopoverTrigger>
  //     <PopoverContent>
  //       <PopoverArrow />
  //       <PopoverCloseButton />
  //       {/* <PopoverHeader>Confirmation!</PopoverHeader> */}
  //       {/* <PopoverBody>Are you sure you want to have that milkshake?</PopoverBody> */}
  //       <PopoverBody>
  //         <Box mt="md">
  //           <EditCardDetails />
  //         </Box>
  //       </PopoverBody>
  //     </PopoverContent>
  //   </Popover>
  // );

  return (
    <div>
      <Box
        position="relative"
        sx={{
          py: "lg",
          minHeight: "200px",
          bg: "#00000063",
          // bg: "#000",
        }}
      >
        <Container maxW="container.xl">
          {/* {isEditing ? (
            <Box bg="white" p="lg" rounded={"lg"} mb="lg">
              <CloseButton onClick={onClose} />
              <EditCardDetails />
            </Box>
          ) : ( */}
          <Center
            h="full"
            border="2px solid transparent"
            px="md"
            py="sm"
            rounded="md"
            {...editCardHeaderProps}
          >
            <VStack spacing="sm" w="full">
              <Box w="full" textAlign={"left"}>
                <Text
                  // color="white" fontSize="3xl"
                  color="white"
                  fontSize="xl"
                  textAlign={"left"}
                  fontWeight="bold"
                  // textShadow={textShadow}
                >
                  {receiverName ? receiverName : "Recipient Name"},
                </Text>
              </Box>

              <Text
                fontSize="3xl"
                // textShadow={textShadow}
                // textShadow={"1px 1px 2px grey, 0 0 1px grey, 0 0 0.2px grey"} // Alt example
                sx={{
                  // textShadow:
                  //   "black 0.1em 0.1em 0.2em, black -0.1em -0.1em 0.2em, black 0.1em -0.1em 0.2em, black -0.1em 0.1em 0.2em",
                  // textShadow: "black 0.1em 0.1em 0.2em",
                  wordBreak: "break-word",
                  // position: "fixed", // When textShadow is fixed, it is performant.
                  color: "#fff",
                  fontFamily: titleFontFamily,
                  textAlign: "center",
                  // // TextShadow is super costly on scroll. Even highlighting the textShadow text with mouse is super slow.
                  // textShadow: `0 0 7px #fff,
                  // 0 0 10px #fff,
                  // 0 0 21px #fff,
                  // 0 0 42px #0fa,
                  // 0 0 82px #0fa,
                  // 0 0 92px #0fa,
                  // 0 0 102px #0fa,
                  // 0 0 151px #0fa`,
                }}
              >
                {title ? title : "Title"}
              </Text>

              <Box w="full">
                <Text
                  // textShadow={textShadow}
                  color="white"
                  fontSize="xl"
                  textAlign={"right"}
                  fontWeight="bold"
                >
                  From {ownerName}
                </Text>
              </Box>
            </VStack>
          </Center>
          {/* )} */}
        </Container>
      </Box>
    </div>
  );
}
