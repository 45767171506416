import { Auth } from "aws-amplify";
import { IS_LOCAL, CURRENT_ENV } from "../constants";

/**
 * This sends error string to "errors" channel in Slack.
 * @param text error string to be sent to Slack
 */
export async function sendTextToErrorsChannelInSlack(text: string) {
  if (!IS_LOCAL) {
    const authData = await Auth.currentAuthenticatedUser();
    const userEmail = authData?.getSignInUserSession()?.getIdToken()
      ?.payload?.email;
    const userId = authData?.getSignInUserSession()?.getIdToken()?.payload?.sub;
    const errorStringForSlack = `[${CURRENT_ENV}]\n${userEmail} (${userId})\n${text}`;

    fetch(
      "https://hooks.slack.com/services/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",
      {
        method: "POST",
        headers: {
          Accept: "application/json",
        },
        body: JSON.stringify({
          text: errorStringForSlack,
        }),
      }
    );
  }
}
