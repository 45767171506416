import {
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
} from "@chakra-ui/react";
import { UpgradeCardMessage } from "../feature";

export function UpgradeCardModal() {
  return (
    <>
      <ModalCloseButton />
      <ModalHeader textAlign="center">Yikes!</ModalHeader>
      <ModalBody>
        <UpgradeCardMessage />
      </ModalBody>
      <ModalFooter />
    </>
  );
}
