import { ModalProps } from "@chakra-ui/react";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ModalState {
  modalType: ModalTypes | null;
  chakraModalProps?: Pick<ModalProps, "size">;
  modalProps?: any;
}

const initialState: ModalState = {
  modalType: null,
  // modalType: "ShareCardolyModal",
  // chakraModalProps: { size: "4xl" },
  // modalProps: { price: "4.99", productType: "Premium" },
};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    showModal(state, action: PayloadAction<ModalState>) {
      const { chakraModalProps, modalType, modalProps } = action.payload;
      state.modalType = modalType;
      state.modalProps = modalProps;
      state.chakraModalProps = chakraModalProps;
    },
    hideModal() {
      return initialState;
    },
  },
});

export const modalActions = modalSlice.actions;

export default modalSlice.reducer;
