import { modalActions, ModalState, useAppDispatch } from "../state-management";

export function useModal() {
  const dispatch = useAppDispatch();

  function showModal(params: ModalState) {
    dispatch(modalActions.showModal(params));
  }

  function hideModal() {
    dispatch(modalActions.hideModal());
  }

  function openConfirmModal(params: ConfirmModalProps) {
    showModal({ modalProps: params, modalType: "ConfirmModal" });
  }

  function closeConfirmModal() {
    hideModal();
  }

  return {
    showModal,
    hideModal,
    openConfirmModal,
    closeConfirmModal,
  };
}
