import React from "react";
import { VscSymbolColor as ColorPaletteIcon } from "react-icons/vsc"; // TODO: Check treeshaking options
import {
  IconButton,
  Button,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  SimpleGrid,
  PopoverArrow,
  Tooltip,
  Box,
  useDisclosure,
} from "@chakra-ui/react";

export const iconButtonControlStyleProps = {
  variant: "ghost",
  isRound: true,
  fontSize: "x-large",
};

const colors = [
  "#000",
  "#b80000",
  "#db3e00",
  "#fccb00",
  "#008b02",
  "#006b76",
  "#1273de",
  "#004dcf",
  "#5300eb",
  "#eb9694",
  "#c4def6",
  "#9f0500",
  "#4D4D4D",
  "#228B22",
  "#ff0000",
  "#c0ca33",
  "#009CE0",
  "#7B64FF",
  "#FA28FF",
  "#FCDC00",
  "#666666",
  "#0C797D",
  "#0062B1",
  "#653294",
];

export function ColorPicker({
  onColorChange,
}: {
  onColorChange(color: string): void;
}) {
  const handleChange = (color: string) => {
    onColorChange(color);
  };

  const { isOpen, onToggle, onClose } = useDisclosure();

  return (
    <Popover
      placement="bottom-start"
      isOpen={isOpen}
      onClose={onClose}
      returnFocusOnClose={false}
    >
      {/* Trick to make Popover work with Tooltip: https://github.com/chakra-ui/chakra-ui/issues/2843#issuecomment-748641805 */}
      <Tooltip label="Text color" placement="top">
        <Box display="inline-block">
          <PopoverTrigger>
            <IconButton
              aria-label="color picker icon"
              {...iconButtonControlStyleProps}
              icon={<ColorPaletteIcon />}
              onClick={onToggle}
            />
          </PopoverTrigger>
        </Box>
      </Tooltip>

      <PopoverContent width="280px">
        {/* <PopoverArrow /> */}
        {/* <PopoverCloseButton /> */}
        {/* <PopoverHeader>Pick color</PopoverHeader> */}
        <PopoverBody>
          <SimpleGrid columns={8} spacing={1}>
            {colors.map((c) => (
              <Button
                key={c}
                aria-label={c}
                background={c}
                padding={0}
                minWidth="unset"
                borderRadius={3}
                _hover={{ background: c }}
                onClick={() => {
                  handleChange(c);
                  onClose();
                }}
              />
            ))}
          </SimpleGrid>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
