import { useEffect, useState } from "react";
import { stripePromise } from "../../util";

type StripeObjectType = Awaited<typeof stripePromise>;

/**
 * This hook allows us load Stripe.js and yields the "stripe" object without the need
 * for <Elements stripe={stripePromise} /> wrapping the parent of the client who needs the stripe object
 * e.g. inside useStripePaymentIntent.
 *
 * Without this useStripeObject hook you'd get
 * Uncaught Error: Could not find Elements context; You need to wrap the part of your app that calls useStripe() in an <Elements> provider.
 */
export function useStripeObject() {
  const [stripe, setStripe] = useState<StripeObjectType | null>(null);

  useEffect(() => {
    if (!stripe && stripePromise) {
      (async function () {
        const stripeInstance = await stripePromise;
        setStripe(stripeInstance);
      })();
    }
  }, [stripe]);

  return { stripe };
}
