export function invalidateCacheForCardUpdate(cache: any) {
  // Invalidate the cache, so when these requests are attempted the data will be refetched from server
  cache.evict({
    fieldName: "getCard", // To reflect latest card entry count
  });
  cache.evict({
    fieldName: "getCardEntriesByCardId", // To reflect the latest pagination info from BE
  });
  // cache.evict({
  //   fieldName: "getCardsByOwnerCognitoId", // To reflect latest card entry count. This is automatically handled by getCard, and Apollo associating the resulting data with this cache.
  // });
  cache.gc();
}
