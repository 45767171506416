import { extendTheme } from "@chakra-ui/react";
import * as overriden from "../chakra-overrides";

export const theme = extendTheme({
  // styles: {
  //   global: {
  //     "html, body": {
  //       fontFamily: "Poppins",
  //     },
  //   },
  // },
  components: {
    ...overriden,
  },
  fonts: {
    body: "Poppins",
    heading: "Poppins",
    // mono: "Menlo, monospace",
  },
  colors: {
    // Used chakra blue https://github.com/chakra-ui/chakra-ui/blob/main/packages/theme/src/foundations/colors.ts
    brand: {
      50: "#ebf8ff",
      100: "#bee3f8",
      200: "#90cdf4",
      300: "#63b3ed",
      400: "#4299e1",
      500: "#3182ce",
      600: "#2b6cb0",
      700: "#2c5282",
      800: "#2a4365",
      900: "#1A365D",
    },
  },
  space: {
    xs: "4px",
    sm: "8px",
    md: "16px",
    lg: "32px",
    xl: "48px",
    "2xl": "64px",
  },
});
