import { useTheme, useMediaQuery } from "@chakra-ui/react";

export function useScreen() {
  const theme = useTheme();
  const { md } = theme?.breakpoints;
  const [isDesktop] = useMediaQuery(`(min-width: ${md})`);

  return {
    isDesktop,
    isMobile: !isDesktop,
  };
}
