import {
  AccordionIndexesValueType,
  cardActions,
  useAppDispatch,
  useAppSelector,
} from "../state-management";

export default function useEditCardSettingDrawer() {
  const { isOpen, cardSettingIndex } = useAppSelector(
    (state) => state.card.cardSettingDrawer
  );
  const dispatch = useAppDispatch();

  function handleCloseCardSettingDrawer() {
    dispatch(cardActions.closeCardSettingsDrawer());
  }
  function handleOpenCardSettingsDrawer({
    index = 0,
  }: { index?: AccordionIndexesValueType } = {}) {
    return () => {
      dispatch(cardActions.openCardSettingsDrawer(index));
    };
  }

  return {
    handleOpenCardSettingsDrawer,
    handleCloseCardSettingDrawer,
    isOpen,
    cardSettingIndex,
  };
}
