import { CognitoUserAmplify } from "@aws-amplify/ui";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Auth } from "aws-amplify";
import { cloneDeep } from "lodash";
import { useState } from "react";

import { useNavigate } from "react-router-dom";
import { ROUTES, USE_FIXTURES } from "../../constants";
import {
  UpdateUserInput,
  useUpdateUserMutation,
} from "../../graphql/generated/schema";
import { onLogout, signIn } from "./authActions";

// interface AmplifyCognitoUser {
//   username: string;
//   attributes: {
//     email: string;
//     email_verified: boolean;
//   };
// }

interface User {
  userId?: string;
  email?: string;
}

interface UpdateState {
  isLoadingUpdateUser: boolean;
  isSuccessUpdateUser: boolean;
  isErrorUpdateUser: boolean;
}

type UpdateUserAttributesInput = Omit<UpdateUserInput, "id">;
type UseAuthReturnValue = {
  signIn(config: { username: string; password: string }): Promise<void>;
  handleLogout(): void;
  handleLogin(): void;
  toResetPassword(): void;
  isAuthenticated?: boolean;
  isAuthLoading?: boolean;
  user: User;
  userId: User["userId"];
  updateUserAttributes(user: UpdateUserAttributesInput): void;
  userUpdateState: UpdateState;
};

interface User {
  email?: string;
  name?: string;
}

// // https://ui.docs.amplify.aws/components/authenticator#access-auth-state
// type CognitoAuthState =
//   | "idle"
//   | "setup"
//   | "signIn"
//   | "signUp"
//   | "confirmSignIn"
//   | "confirmSignUp"
//   | "setupTOTP"
//   | "forceNewPassword"
//   | "resetPassword"
//   | "confirmResetPassword"
//   | "verifyUser"
//   | "confirmVerifyUser"
//   | "signOut"
//   | "authenticated";

// export function useAuth(): UseAuthReturnValue {
//   return {
//     handleLogin: () => {},
//     handleLogout: () => {},
//     isAuthenticated: false,
//     user: {},
//   };
// }

function transformCognitoUserToUser(user: CognitoUserAmplify): User {
  const attr = user?.attributes;
  return {
    email: attr?.email,
    userId: user?.username || attr?.sub, // || (USE_FIXTURES ? "1651abf3-2284-4faa-b960-2def41440b64" : ""),
    name: attr?.name,
  };
}

const mockUser: User = {
  userId: "1651abf3-2284-4faa-b960-2def41440b64",
  email: "mock@email.com",
};

// Auth + Groups: https://aws.amazon.com/blogs/mobile/aws-amplify-allows-you-to-mix-and-match-authorization-modes-in-datastore/
export function useAuth(): UseAuthReturnValue {
  const navigate = useNavigate();
  const {
    // validationErrors,
    authStatus,
    route,
    user: cognitoUser,
    // isPending,
    toResetPassword,
  } = useAuthenticator((context) => [context.route]);
  const isAuthLoading = authStatus === "configuring";
  // const isLoading = isPending || authStatus === "configuring";

  const user = USE_FIXTURES
    ? mockUser
    : transformCognitoUserToUser(cognitoUser);
  const userId = user.userId;

  const [updateUser] = useUpdateUserMutation();
  const isAuthenticated = route === "authenticated" || USE_FIXTURES;
  const [userUpdateState, setUserUpdateState] = useState<UpdateState>({
    isSuccessUpdateUser: false,
    isErrorUpdateUser: false,
    isLoadingUpdateUser: false,
  });

  async function updateUserAttributes(input: UpdateUserAttributesInput) {
    if (!cognitoUser) return;

    try {
      setUserUpdateState({
        ...userUpdateState,
        isLoadingUpdateUser: true,
      });
      await Auth.updateUserAttributes(cognitoUser, {
        ...input,
      });
      if (userId) {
        await updateUser({
          variables: {
            input: {
              id: userId,
              ...input,
            },
          },
        });
      }

      setUserUpdateState({
        ...userUpdateState,
        isSuccessUpdateUser: true,
        isLoadingUpdateUser: false,
      });
    } catch (error) {
      // TODO: Log to server.
      console.log("Error updating user attributes:", error);
      setUserUpdateState({
        ...userUpdateState,
        isErrorUpdateUser: true,
        isLoadingUpdateUser: false,
      });
    }
  }

  async function handleLogout() {
    // await Promise.resolve(signOut());
    await onLogout();
    // Hard refresh to home page. This invalidates all data (Invalidate all queries, so you don't see things like another user's info when you login to Account Details).
    // hard refresh is slower to render. Resolves unknown bugs with RTQ resetApiState()
    window.location.href = window.location.origin;
  }
  const handleLogin = () => {
    navigate(ROUTES.LOGIN, { state: { from: window.location.pathname } });
  };

  return {
    signIn,
    toResetPassword,
    handleLogin,
    handleLogout,
    isAuthLoading,
    isAuthenticated,
    user,
    userId: user.userId,
    updateUserAttributes,
    userUpdateState: userUpdateState,
  };
}
