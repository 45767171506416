import {
  Tooltip,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from "@chakra-ui/react";
import { useState } from "react";

import { BiFontFamily as FontFamilyIcon } from "react-icons/bi";

const iconButtonControlStyleProps = {
  variant: "ghost",
  isRound: true,
  fontSize: "x-large",
};

export const fonts = [
  "Leckerli One",
  "Fredericka the Great",
  "Delius",
  "Yellowtail",
  "Kalam",
  "Shadows Into Light",
  "Caveat",
  "Gochi Hand",
  "Reenie Beanie",
  "Coming Soon",
  "Just Me Again Down Here",
  "Swanky and Moo Moo",
  "Patrick Hand SC",
  "Permanent Marker",
  "Cedarville Cursive",
  "Cabin Sketch",
  "Poppins",
];

interface FontPickerProps {
  onFontSelect(font: string): void;
}
export function FontPicker({ onFontSelect }: FontPickerProps) {
  // Solve tooltip issue, where it stays after MenuItem click: https://github.com/chakra-ui/chakra-ui/discussions/3193#discussioncomment-5179180
  const [tooltipOpen, setTooltipOpen] = useState(false);

  return (
    <Menu isLazy>
      <Tooltip label="Font" placement="top" isOpen={tooltipOpen}>
        <MenuButton
          as={IconButton}
          type="button"
          aria-label="color picker icon"
          {...iconButtonControlStyleProps}
          icon={<FontFamilyIcon />}
          onMouseEnter={() => setTooltipOpen(true)}
          onMouseLeave={() => setTooltipOpen(false)}
        />
      </Tooltip>

      <MenuList maxH="240px" overflow={"auto"}>
        {fonts.map((item, index) => (
          <MenuItem
            sx={{
              fontFamily: item,
            }}
            key={`${item}${index}`}
            onClick={() => onFontSelect(item)}
          >
            {item}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
}
