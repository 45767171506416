import { useEffect } from "react";
import { useAuth } from "./hooks";
import { usePostHog } from "posthog-js/react";

export function PostHogInitIdentity() {
  const { user } = useAuth();
  const posthog = usePostHog();

  useEffect(() => {
    if (user.userId) {
      posthog.identify(user.userId, {
        email: user.email,
        name: user.name, // Optional: Set additional user properties
        cog_id: user.userId,
      });
    }
  }, [user.userId]);

  return null;
}
