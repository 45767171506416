import { Button, Center, Text, VStack } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

export function PageNotFound() {
  return (
    <div>
      <Center h="50vh">
        <VStack spacing="lg">
          <Text fontSize={"6xl"} fontWeight="bold" color="gray.700">
            Oops!
          </Text>
          <Text>404 - The page can't be found</Text>
          <Button to="/" as={Link}>
            Go to dashboard
          </Button>
        </VStack>
      </Center>
    </div>
  );
}
