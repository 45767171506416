import React, { useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { Box, Button, Text } from "@chakra-ui/react";
import { Loader } from "../../../../components";
import { useStripePaymentIntent } from "../../useStripePaymentIntent";
import {
  SEARCH_PARAM_PAYMENT_FOR,
  SEARCH_PARAM_PAYMENT_FOR_KEY,
  SEARCH_PARAM_SUBSCRIPTION,
} from "../../../../constants";
import { useAppSelector } from "../../../../state-management";
import { createSearchParams } from "react-router-dom";
import { useCurrentSearchParams } from "../../../../hooks/useCurrentSearchParams";
import { usePayment } from "../../../../hooks/usePayment";

interface CheckoutFormProps {
  clientSecret: string;
  subscriptionId?: string;
}

export function CheckoutForm({
  clientSecret,
  subscriptionId,
}: CheckoutFormProps) {
  const selectedProduct = useAppSelector((state) => state.card.selectedProduct);

  // TODO: Remove. Pass as Controlled Prop
  const { paymentIntentState, setPaymentIntentState } = useStripePaymentIntent({
    fetch: true,
    clientSecret,
  });

  const stripe = useStripe();
  const elements = useElements();
  const [isSubmittingPayment, setSubmittingPayment] = useState(false);
  const [hasStripeFormLoaded, setStripeFormLoaded] = useState<boolean>(false);
  const isLoadingStripeJS = !stripe || !elements;
  const showPayButton = hasStripeFormLoaded || paymentIntentState.isError;
  const { currentSearchParams } = useCurrentSearchParams();
  const { cardId } = usePayment();

  function getReturnUrl() {
    const currentPath = `${window.location.origin}${window.location.pathname}`;
    let returnUrl = "";
    if (subscriptionId) {
      returnUrl = `${currentPath}?${createSearchParams({
        ...currentSearchParams,
        [SEARCH_PARAM_SUBSCRIPTION]: subscriptionId,
        [SEARCH_PARAM_PAYMENT_FOR_KEY]: cardId
          ? SEARCH_PARAM_PAYMENT_FOR.SUB_W_CARD
          : SEARCH_PARAM_PAYMENT_FOR.SUB_ONLY,
      }).toString()}`;
    } else if (cardId) {
      returnUrl = `${currentPath}?${createSearchParams({
        ...currentSearchParams,
        [SEARCH_PARAM_PAYMENT_FOR_KEY]: SEARCH_PARAM_PAYMENT_FOR.SINGLE_CARD,
      }).toString()}`;
    } else {
      // Shouldn't get here. Investigate if it does
      returnUrl = window.location.href; // current url
    }

    return returnUrl;
  }

  const handleSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setSubmittingPayment(true);

    const { error } = await stripe.confirmPayment({
      elements,
      // TODO: Add email
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: getReturnUrl(),
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setPaymentIntentState({ message: error.message, isError: true });
    } else {
      setPaymentIntentState({
        message: "An unexpected error occured.",
        isError: true,
      });
    }

    setSubmittingPayment(false);
  };

  return (
    <form id="payment-form" onSubmit={handleSubmit}>
      {isLoadingStripeJS ? (
        <Loader />
      ) : (
        <>
          <PaymentElement
            id="payment-element"
            onReady={() => setStripeFormLoaded(true)}
          />
          {showPayButton ? (
            <Box mt="6">
              <Button
                w="full"
                isLoading={isSubmittingPayment}
                id="submit"
                colorScheme="brand"
                type="submit"
              >
                Pay ${selectedProduct?.price}
              </Button>
            </Box>
          ) : (
            <Loader />
          )}
        </>
      )}

      {/* Show any error or success messages */}
      {(paymentIntentState.message || paymentIntentState.isError) && (
        <Box mt="sm" mb="lg">
          <Text
            color={paymentIntentState.isError ? "red.500" : "green.500"}
            textAlign="center"
          >
            {paymentIntentState.message}
          </Text>
        </Box>
      )}
    </form>
  );
}
