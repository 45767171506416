import { Text, Button, Box } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useCard, useRoutes } from "../hooks";
import { MAX_FREE_CARDOLY_ENTRIES } from "../constants";

export function UpgradeCardMessage() {
  const { toSelectCardTypePage } = useRoutes();
  const [entriesLeft, setEntriesLeft] = useState<number>();
  const { isFree, card, hasReachedMaxEntries, isUserCardOwner } = useCard();
  const entryCount = card?.entryCount;
  const isOneEntryLeft = entriesLeft === 1;

  useEffect(() => {
    if (isFree && entryCount) {
      if (!hasReachedMaxEntries) {
        const newEntriesLeft = MAX_FREE_CARDOLY_ENTRIES - entryCount;
        setEntriesLeft(newEntriesLeft);
      }
    }
  }, [isFree, entryCount, hasReachedMaxEntries]);

  const renderUpgradeButton = isUserCardOwner ? (
    <>
      <Button
        color="blue.900"
        variant="link"
        onClick={() => toSelectCardTypePage()}
      >
        Click here
      </Button>{" "}
      to upgrade to a premium card for{" "}
      <Text display="inline" textDecor="underline">
        unlimited posts.
      </Text>
    </>
  ) : (
    <Text display="inline">
      Tell the owner to upgrade the Cardoly to accept more posts!
    </Text>
  );

  return (
    <>
      {hasReachedMaxEntries ? (
        <Text textAlign="center">
          This Cardoly has reached its{" "}
          <Box as="span" textDecor={"underline"}>
            limit of {MAX_FREE_CARDOLY_ENTRIES}
          </Box>{" "}
          posts. {renderUpgradeButton}
        </Text>
      ) : (
        <Text textAlign="center">
          There {`${isOneEntryLeft ? "is" : "are"} `}
          <Text fontWeight="bold" display="inline">
            {entriesLeft}
          </Text>
          {` ${isOneEntryLeft ? "post" : "posts"} left. `}
          {renderUpgradeButton}
        </Text>
      )}
    </>
  );
}
