import { useSearchParams } from "react-router-dom";

export function useCurrentSearchParams() {
  const [searchParams] = useSearchParams();
  const currentSearchParams: Record<string, string> = {};

  for (const [key, value] of searchParams.entries()) {
    currentSearchParams[key] = value;
  }

  return { currentSearchParams };
}
