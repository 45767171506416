import { Button, Tooltip, useClipboard } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useGetCardReadIdQuery } from "../../../graphql/generated/schema";
import { RecipientMode, useCard, useRoutes } from "../../../hooks";

export function CopyRecipientLink() {
  const [value, setValue] = useState<string>("");
  const { currentUrlWithoutSearchParams } = useRoutes();
  const { hasCopied, onCopy } = useClipboard(value);

  const { cardID } = useCard();
  const { data, loading } = useGetCardReadIdQuery({
    variables: { cardId: cardID ?? "" },
    skip: !cardID,
  });
  const readId = data?.getCardReadId?.readId;
  const realRecipientUrlWithoutReadId = `${currentUrlWithoutSearchParams}?mode=${RecipientMode.REAL}&rid=${readId}`;

  useEffect(() => {
    if (realRecipientUrlWithoutReadId) {
      setValue(realRecipientUrlWithoutReadId);
    }
  }, [realRecipientUrlWithoutReadId]);

  return (
    <Tooltip
      label="Copied link to clipboard!"
      placement="top"
      isOpen={hasCopied}
    >
      <Button
        aria-label="copy"
        isLoading={loading}
        onClick={onCopy}
        variant="link"
        fontSize="sm"
        colorScheme={hasCopied ? "green" : "brand"}
      >
        Cardoly recipient link
      </Button>
    </Tooltip>
  );
}
