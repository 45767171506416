import { Flex, FlexProps } from "@chakra-ui/react";
import { Footer } from "../Footer";

export function PageLayout(props: FlexProps) {
  return (
    <>
      <Flex flexDirection="column" minHeight="100vh">
        {props.children}
        {/* <Box mt="auto">
        <Footer />
      </Box> */}
      </Flex>
      <Footer />
    </>
  );
}
