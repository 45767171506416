import { useCallback, useEffect, useState } from "react";
import { useStripeObject } from "./useStripeObject";

export function useStripePaymentIntent({
  fetch = false,
  clientSecret = null,
}: {
  fetch: boolean;
  clientSecret: string | null;
}) {
  const { stripe } = useStripeObject();
  const [paymentIntentState, setPaymentIntentState] = useState<{
    message?: string;
    isSuccess?: boolean;
    isError?: boolean;
    error?: string;
  }>({ message: "", isError: false, isSuccess: false });
  const [hasLoadedPaymentIntentFromStripe, setLoadedPaymentIntentFromStripe] =
    useState<boolean>(false);
  const [
    isLoadingPaymentIntentFromStripe,
    setIsLoadingPaymentIntentFromStripe,
  ] = useState<boolean>(fetch);

  /**
   * Conditionally show message underneath Stripe form
   */
  const getMessage = useCallback(
    (msg: string) => {
      return hasLoadedPaymentIntentFromStripe ? msg : "";
    },
    [hasLoadedPaymentIntentFromStripe]
  );

  // FETCH PAYMENT INTENT IF SPECIFIED BY CLIENT
  useEffect(() => {
    if (fetch && stripe && clientSecret) {
      setIsLoadingPaymentIntentFromStripe(true);

      stripe
        .retrievePaymentIntent(clientSecret)
        .then(({ paymentIntent }) => {
          setLoadedPaymentIntentFromStripe(true);

          switch (paymentIntent?.status) {
            case "succeeded":
              setPaymentIntentState({
                message: "Payment succeeded!",
                isError: false,
                isSuccess: true,
              });
              break;
            case "processing":
              setPaymentIntentState({
                message: getMessage("Your payment is processing."),
                isError: false,
              });
              break;
            case "requires_payment_method":
              setPaymentIntentState({
                error: getMessage(
                  "Your payment was not successful, please try again."
                ),
                isError: true,
              });
              break;
            default:
              setPaymentIntentState({
                error: getMessage("Something went wrong."),
                isError: true,
              });
              break;
          }
        })
        .finally(() => {
          setIsLoadingPaymentIntentFromStripe(false);
        });
    }
  }, [!!stripe, fetch, clientSecret]);

  return {
    paymentIntentState,
    setPaymentIntentState,
    isLoadingPaymentIntentFromStripe,
  };
}
