import { AiOutlineRotateRight as RotateIcon } from "react-icons/ai";
import { IconButtonWithTooltip } from "../../../../components";
import { useToggleOrientationMutation } from "../../../../graphql/generated/schema";
import { useCard, useScreen, useSingleCardEntry } from "../../../../hooks";

export function ToggleOrientationButton() {
  const [toggleOrientation] = useToggleOrientationMutation();
  const { isDesktop } = useScreen();
  const { card } = useCard();
  const { hasImage } = useSingleCardEntry();

  return hasImage && isDesktop ? (
    <IconButtonWithTooltip
      label="Orientation"
      icon={RotateIcon}
      onClick={() => {
        if (card?.id) {
          toggleOrientation({
            variables: { input: { cardId: card.id } },
          });
        }
      }}
    />
  ) : null;
}
