import { Authenticator } from "@aws-amplify/ui-react";
import { Box, BoxProps } from "@chakra-ui/react";

export function AmplifyAuthenticator() {
  return (
    <StylesOverride>
      <Authenticator
        // initialState="resetPassword"
        formFields={{
          signUp: {
            name: {
              isRequired: true,
              placeholder: "Full name",
              order: 1,
            },
          },
          // // config values: https://ui.docs.amplify.aws/react/connected-components/authenticator/customization#updating-labels-placeholders-required-fields-and-showing-labels
          // signIn: {
          //   username: {
          //     placeholder: "Your email",
          //     //   defaultValue: "hemhandhem@yepa.com",
          //     //   readOnly: true, // TODO: Test that if after login, the user on the page changes if a diff email is entered at this point from original discovered email.
          //   }, //as any, // "defaultValue" is not supported in TS yet.
          // },
        }}
        // hideSignUp  // set to true IF the user comes here via non-existing email
        loginMechanisms={["email"]}
        // variation="modal"
      />
    </StylesOverride>
  );
}

function StylesOverride(props: BoxProps) {
  return (
    <Box
      sx={{
        // Amplify container holding form
        "[data-amplify-authenticator] [data-amplify-router]": {
          borderColor: "brand.100",
        },
        // Amplify primary button
        ".amplify-button[data-variation='primary']": {
          bg: "brand.500",
          _hover: {
            bg: "brand.600",
          },
        },
        // Amplify active tab
        ".amplify-tabs-item[data-state=active]": {
          borderColor: "brand.500",
          color: "brand.500",
          _hover: {
            borderColor: "brand.600",
            color: "brand.600",
          },
        },
        // Header for "Reset your Password"
        ".amplify-heading--3": {
          color: "gray.600",
          fontSize: "xl",
          fontWeight: "bold",
        },
      }}
      {...props}
    />
  );
}
