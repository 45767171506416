import { useCard } from "../../../../hooks";
import PillPity, { Pattern } from "pill-pity";
import { Box, BoxProps } from "@chakra-ui/react";
import { createPortal } from "react-dom";
import { Card, CardBgType } from "../../../../graphql/generated/schema";
import { useEditedCard } from "../../../../hooks/useEditedCard";

function getBaseBgStyles(isCardPreview: boolean) {
  const layoutStyles = isCardPreview
    ? { bgAttachment: "scroll" }
    : {
        bgAttachment: "fixed", // Parallax.
        minH: "100vh",
      };

  return {
    // Img
    ...layoutStyles,
    // Layout
    h: "100%",
    // Trying to prevent scroll bar from the height stretching page
    // TODO: Make automatic, using only 100vh. Similar to OpeningSequence
    // minH: "calc(100vh - 64px)",
    pos: "absolute",
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
  };
}

function ImageBackground({
  bgImageUrl,
  children,
  zIndex = "hide",
  isCardPreview = false,
  ...boxProps
}: {
  bgImageUrl: ImageBackground["bgImageUrl"];
} & CardBackgroundProps) {
  return (
    <Box
      sx={{
        bgSize: "cover",
        bgImage: bgImageUrl,
        bgPosition: "center",
        bgRepeat: "no-repeat",
        zIndex,
        ...getBaseBgStyles(isCardPreview),
      }}
      {...boxProps}
    >
      {children}
    </Box>
  );
}

function PatternBackground({
  pColorBg = "",
  pColorFg,
  pType = "floating-cogs", // Default to prevent app from crashing due to PillParty pattern being null/undefined
  zIndex = "hide",
  children,
  isCardPreview = false,
  ...boxProps
}: {
  pColorBg?: string; //Card["pColorBg"]; //PatternBackground["color"]["bg"];
  pColorFg?: string; //Card["pColorFg"]; //PatternBackground["color"]["fg"];
  pType: Card["pType"];
} & CardBackgroundProps) {
  return (
    <PillPity
      pattern={pType as Pattern}
      bgColor={pColorBg}
      patternFill={pColorFg}
      patternOpacity={1}
      sx={{
        ...getBaseBgStyles(isCardPreview),
        zIndex,
      }}
      {...boxProps}
    >
      {children}
    </PillPity>
  );
}

interface CardBackgroundProps extends BoxProps {
  isCardPreview?: boolean;
}
export function CardBackground(boxProps: CardBackgroundProps) {
  const { card: serverCard } = useCard();
  const { editedCard, isEditing } = useEditedCard();
  const card = isEditing ? editedCard : serverCard;

  // Render server version of card background
  if (card?.bgType === CardBgType.Pattern) {
    const pColorBg = card?.pColorBg;
    const pColorFg = card?.pColorFg;
    const pType = card?.pType;

    return (
      <PatternBackground
        pColorBg={pColorBg}
        pColorFg={pColorFg}
        pType={pType}
        {...boxProps}
      />
    );
  }

  if (card?.bgType === CardBgType.Image && card?.bgImageUrl) {
    return <ImageBackground bgImageUrl={card.bgImageUrl} {...boxProps} />;
  }

  return null;
}

export function CardBackgroundWithBodyPortal(props: CardBackgroundProps) {
  return createPortal(<CardBackground {...props} />, document.body);
}
