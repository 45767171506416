import { Text } from "@chakra-ui/react";
import React from "react";
import { useAuth } from "../../hooks";

export function UserEmail() {
  const { user } = useAuth();

  return (
    <Text px="3" size="sm" fontSize="sm" color="gray.600">
      {user?.email}
    </Text>
  );
}
