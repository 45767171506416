import { IS_LOCAL, CURRENT_ENV } from "../constants";

const CHANNEL_URLS = {
  cardolies:
    "https://hooks.slack.com/services/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",
  entries:
    "https://hooks.slack.com/services/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",
  errors:
    "https://hooks.slack.com/services/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",
  "contact-us":
    "https://hooks.slack.com/services/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",
} as const;

export async function sendToSlack({
  text,
  channel,
}: {
  text: string;
  channel: keyof typeof CHANNEL_URLS;
}) {
  if (IS_LOCAL) {
    return;
  }

  const slackMessage = {
    text: `[${CURRENT_ENV}]\n${text}`,
  };
  const slackUrl = CHANNEL_URLS[channel];
  const slackPromise = fetch(slackUrl, {
    method: "POST",
    body: JSON.stringify(slackMessage),
  });
  return slackPromise;
}
