import {
  FormControl,
  FormLabel,
  HStack,
  Switch,
  Icon,
  Text,
  Flex,
  Popover,
  PopoverTrigger,
  useDisclosure,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  IconButton,
} from "@chakra-ui/react";
import { BsFillQuestionCircleFill } from "react-icons/bs";
import { useToggleLockMutation } from "../../../../../graphql/generated/schema";
import { useCard } from "../../../../../hooks";

export function LockToggle() {
  const { cardId, card } = useCard();
  const [toggleLock] = useToggleLockMutation();

  const id = "lock-toggle";
  return (
    <FormControl display="flex" alignItems="center" justifyContent={"flex-end"}>
      <FormLabel htmlFor={id} mb="0">
        <HStack spacing="xs">
          <Text fontSize="sm">Lock Cardoly</Text>
          <InfoIconWithPopover />
        </HStack>
      </FormLabel>
      <Switch
        isChecked={card?.isLocked}
        id={id}
        onChange={() => {
          if (cardId) {
            toggleLock({ variables: { input: { cardId } } });
          }
        }}
      />
    </FormControl>
  );
}

function InfoIconWithPopover() {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Flex>
      <Popover isOpen={isOpen} onClose={onClose} placement="top">
        <PopoverTrigger>
          <IconButton
            aria-label="confetti-toggle"
            variant="link"
            size="xs"
            icon={
              <Icon
                onClick={onOpen}
                as={BsFillQuestionCircleFill}
                fontSize="md"
                sx={{ color: "blue.500" }}
              />
            }
          />
        </PopoverTrigger>
        <PopoverContent>
          <PopoverArrow />
          <PopoverCloseButton />
          <PopoverBody>Lock the Cardoly to prevent further posts</PopoverBody>
        </PopoverContent>
      </Popover>
    </Flex>
  );
}
