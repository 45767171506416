import { Box, Container, Text } from "@chakra-ui/react";
import { PageContainer } from "../../components";

export function Footer() {
  // // ALT DESIGN
  // return (
  //   <Box
  //     as="footer"
  //     bg={useColorModeValue("gray.50", "gray.900")}
  //     color={useColorModeValue("gray.700", "gray.200")}
  //   >
  //     <PageContainer py={4}>
  //       <Stack
  //         direction={{ base: "column", md: "row" }}
  //         spacing={4}
  //         justify={{
  //           base: "center",
  //           md: "space-between",
  //         }}
  //         align={{ base: "center", md: "center" }}
  //       >
  //         <Text>© 2023 Cardoly. All rights reserved</Text>
  //         <Stack direction={"row"} spacing={6} align="flex-end">
  //           <Link to="/">Home</Link>
  //           <Box as="a" href={"#"}>
  //             Contact
  //           </Box>
  //         </Stack>
  //       </Stack>
  //     </PageContainer>
  //   </Box>
  // );

  return (
    <Box as="footer" borderTop="1px solid" borderTopColor="gray.100" bg="white">
      <Container maxW="container.xl" py="md">
        <Text textAlign={"center"}>
          © {new Date().getFullYear()} Cardoly. All rights reserved.
        </Text>
      </Container>
    </Box>
  );
}
