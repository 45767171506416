import {
  AccordionButton,
  AccordionPanel,
  Text,
  AccordionIcon,
  TextProps,
  AccordionPanelProps,
} from "@chakra-ui/react";

export function AccordionItemHeader(props: TextProps) {
  return (
    <h2>
      <AccordionButton>
        <Text
          flex="1"
          textAlign="left"
          fontWeight={"bold"}
          fontSize="sm"
          letterSpacing={"wide"}
          textTransform={"uppercase"}
          {...props}
        />
        <AccordionIcon />
      </AccordionButton>
    </h2>
  );
}

export function AccordionItemContent(props: AccordionPanelProps) {
  return <AccordionPanel pb={4} {...props} />;
}
