import { useEffect, useState } from "react";
import { PHOTOS_PER_PAGE } from "../constants";
import {
  useAppDispatch,
  useAppSelector,
  photoSearchSlice,
} from "../state-management";

function useDebounce(value: string, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export function usePhotoSearch() {
  const dispatch = useAppDispatch();
  const searchText = useAppSelector((state) => state.photoSearch.searchText);
  const debounceSearchText = useDebounce(searchText, 300);
  const hasSearched = !!debounceSearchText;

  function handleChangeSearchText(e: any) {
    const val = e.target.value;
    dispatch(photoSearchSlice.actions.onSearchTextChange(val));
  }

  // clear photo searchText when unmounting
  useEffect(() => {
    return () => {
      dispatch(photoSearchSlice.actions.onSearchTextChange(""));
    };
  }, [dispatch]);

  return {
    hasSearched,
    perPage: PHOTOS_PER_PAGE,
    searchText,
    debounceSearchText,
    handleChangeSearchText,
  };
}
