import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "./hooks";
import { initialCardBackgrounds } from "./slices";

// APPOINTMENTS / ENCOUNTERS SELECTORS

// ROOT SELECTORS
const getEntry = (state: RootState) => state?.entry;
const getCard = (state: RootState) => state?.card;

// ENTRY
const getEditedCardEntry = createSelector(getEntry, (entry) => entry?.entry);
const getPhoto = createSelector(getEditedCardEntry, (entry) => entry?.photo);

export const entrySelectors = {
  getEditedCardEntry,
  getPhoto,
};

// CARD
export const cardSelectors = {
  getSelectedCardBackground: createSelector(getCard, (card) =>
    card.selectedCardBackgroundID
      ? initialCardBackgrounds.get(card.selectedCardBackgroundID)
      : null
  ),
  getEditedCard: createSelector(getCard, (card) => card.editedCard),
  getSelectedCardBackgroundID: createSelector(
    getCard,
    (card) => card.selectedCardBackgroundID
  ),
};
