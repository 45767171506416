import {
  Box,
  VStack,
  Stack,
  FormControl,
  Input,
  FormErrorMessage,
  FormLabel,
  Button,
  Textarea,
} from "@chakra-ui/react";
import { pick } from "lodash";
import { Controller, useForm } from "react-hook-form";
import { FontPicker } from "../../../../../feature";
import { useCard, useToast } from "../../../../../hooks";
import { useEditedCardFormListener } from "../../../../../hooks/useEditedCardFormListener";
import { cardActions, useAppDispatch } from "../../../../../state-management";

const inputSize = "md";
const TITLE_MAX_LENGTH = 190;
const NAME_MAX_LENGTH = 60;

const nameValidationRules = {
  required: {
    value: true,
    message: "This field is required",
  },
  maxLength: {
    value: NAME_MAX_LENGTH,
    message: `The text may not exceed ${NAME_MAX_LENGTH} characters`,
  },
};

type FormValues = Pick<
  Card,
  "id" | "receiverName" | "title" | "titleFontFamily" | "ownerName"
>;

export function EditCardDetails() {
  const {
    card,
    isCardUpdating,
    updateCard,
    isCardUpdateSuccess,
    isCardUpdateError,
  } = useCard();
  const dispatch = useAppDispatch();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormValues>({
    // Ensure we aren't overriding unintended values, e.g. Card background, saved elsewhere
    defaultValues: pick(card, [
      "id",
      "receiverName",
      "title",
      "titleFontFamily",
      "ownerName",
    ]),
  });

  useEditedCardFormListener({
    control,
  });

  // Setup toasts
  useToast({
    id: "success-edit-title",
    title: "Success",
    description: "Details saved",
    show: isCardUpdateSuccess,
    status: "success",
    duration: 1000,
    position: "bottom",
  });

  useToast({
    id: "error-edit-title",
    title: "Error",
    description: "Failed to save title.",
    show: isCardUpdateError,
    status: "error",
  });

  const onSubmitBound = handleSubmit((values) => {
    updateCard(values);
  });

  function handleSetEditedCardInRedux(update: Partial<FormValues>) {
    dispatch(cardActions.updateEditedCard(update));
  }

  return (
    <Box
    // minH="60vh"
    >
      <form onSubmit={onSubmitBound}>
        <VStack spacing="md" alignItems={"stretch"}>
          <section>
            <Stack direction={["column", "row"]}>
              <Box flex="1">
                <FormControl isInvalid={!!errors?.receiverName}>
                  <FormLabel>Recipient Name</FormLabel>
                  <Controller
                    control={control}
                    name="receiverName"
                    rules={nameValidationRules}
                    render={({ field: { onChange, value } }) => (
                      <Input
                        size={inputSize}
                        type="text"
                        placeholder="e.g. Jane Smith"
                        value={value}
                        onChange={(e) => {
                          const receiverName = e.target.value;

                          handleSetEditedCardInRedux({ receiverName });
                          onChange(receiverName);
                        }}
                      />
                    )}
                  />
                  <FormErrorMessage>This field is required</FormErrorMessage>
                </FormControl>
              </Box>
            </Stack>
          </section>

          <section>
            <VStack spacing={"sm"} alignItems="flex-start">
              <FormControl isInvalid={!!errors?.title}>
                <FormLabel>Cardoly Title</FormLabel>
                <Controller
                  control={control}
                  name="title"
                  rules={{
                    required: {
                      value: true,
                      message: "This field is required",
                    },
                    maxLength: {
                      value: TITLE_MAX_LENGTH,
                      message: `The text may not exceed ${TITLE_MAX_LENGTH} characters`,
                    },
                  }}
                  render={({ field: { onChange, value } }) => (
                    <Textarea
                      rows={3}
                      size={inputSize}
                      // type="text"
                      value={value}
                      placeholder="e.g. Happy Birthday, Get Well Soon, Congratulations"
                      onChange={(e) => {
                        const title = e.target.value;

                        // handleSetEditedCardInRedux({ title });
                        onChange(title);
                      }}
                    />
                  )}
                />
                {errors.title?.message && (
                  <FormErrorMessage>{errors.title.message}</FormErrorMessage>
                )}
              </FormControl>

              <Controller
                control={control}
                name="titleFontFamily"
                render={({ field: { onChange } }) => (
                  <FontPicker
                    onFontSelect={(titleFontFamily: string) => {
                      handleSetEditedCardInRedux({
                        titleFontFamily,
                      });

                      // Update react hook form
                      onChange(titleFontFamily);
                    }}
                  />
                )}
              />
            </VStack>
          </section>

          <section>
            <Box flex="1">
              <FormControl isInvalid={!!errors?.ownerName}>
                <FormLabel>Sender Name or Group Name</FormLabel>
                <Controller
                  control={control}
                  name="ownerName"
                  rules={nameValidationRules}
                  render={({ field: { onChange, value } }) => (
                    <Input
                      size={inputSize}
                      type="text"
                      value={value}
                      placeholder="e.g. James Robert or The Marketing Team"
                      onChange={(e) => {
                        const ownerName = e.target.value;

                        // handleSetEditedCardInRedux({ ownerName });
                        onChange(ownerName);
                      }}
                    />
                  )}
                />
                {errors.ownerName?.message && (
                  <FormErrorMessage>
                    {errors.ownerName.message}
                  </FormErrorMessage>
                )}
              </FormControl>
            </Box>
          </section>
          <Button type="submit" isLoading={isCardUpdating}>
            Save details
          </Button>
        </VStack>
      </form>
    </Box>
  );
}
