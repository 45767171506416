import { Box } from "@chakra-ui/react";
import { ReactNode } from "react";
import Masonry from "react-masonry-css";

const MASONRY_CLASS_NAME = "my-masonry-grid";
const MASONRY_ITEM_CLASS_NAME = "my-masonry-grid_column ";
const GUTTER = 4;

function cssSelector(className: string) {
  return `.${className}`;
}

interface MasonryGridProps {
  children: ReactNode;
  space: number;
  columns: number;
}

export function MasonryGrid({
  children,
  space = GUTTER,
  columns,
}: MasonryGridProps) {
  return (
    <Box
      sx={{
        [cssSelector(MASONRY_CLASS_NAME)]: {
          display: "flex",
          ml: `-${space}px`,
          width: "auto",
        },
        [cssSelector(MASONRY_ITEM_CLASS_NAME)]: {
          pl: `${space}px`,
          backgroundClip: "padding-box",
        },
        [`${cssSelector(MASONRY_ITEM_CLASS_NAME)} > div`]: {
          mb: `${space}px`,
        },
      }}
    >
      <Masonry
        breakpointCols={columns}
        className={MASONRY_CLASS_NAME}
        columnClassName="my-masonry-grid_column"
      >
        {children}
      </Masonry>
    </Box>
  );
}
