import {
  Box,
  Button,
  Text,
  HStack,
  AspectRatio,
  VStack,
  Center,
  Flex,
  TextProps,
  IconButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Badge,
  Icon,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { MdUpgrade as UpgradeIcon } from "react-icons/md";
import { GoKebabVertical as MenuIcon } from "react-icons/go";
import {
  FiTrash2 as DeleteIcon,
  FiShare2 as LinkIcon,
  FiLock as LockIcon,
  FiSend as DeliverIcon,
} from "react-icons/fi";
import { useModal, useRoutes } from "../../hooks";
import { isCardFree } from "../../util";

import PillPity, { Pattern } from "pill-pity";
import { format, formatDistance } from "date-fns";
import { modalActions, useAppDispatch } from "../../state-management";
import { Card, OrderType } from "../../graphql/generated/schema";
import { MobileOnly } from "../../components";
import { AiOutlineUsergroupAdd } from "react-icons/ai";

interface CardSummaryProps {
  card: Card;
}

function SubHeader(props: TextProps) {
  return (
    <Flex flex="1" h="6" alignItems="center">
      <Text variant="caption" {...props} />
    </Flex>
  );
}

export function CardSummary({ card }: CardSummaryProps) {
  const { showModal } = useModal();
  // TODO: useNav to encapsulate imperative routing.
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { toSelectCardTypePage } = useRoutes();
  const isFree = isCardFree(card);
  const cardID = card?.id;
  const createdAt = card?.createdAt
    ? format(new Date(card?.createdAt), "MMM dd, yyyy")
    : "";
  const { entryCount, receiverName, lastEntryCreatedAt, deliveredAt } = card;

  function handleDeleteCard() {
    dispatch(
      modalActions.showModal({
        modalType: "DeleteCardModal",
        modalProps: { cardId: cardID },
      })
    );
  }

  function handleViewCard() {
    navigate(`/card/${cardID}`);
  }

  const lastPostAdded = lastEntryCreatedAt
    ? formatLastPostAddedDate(lastEntryCreatedAt)
    : null;

  const isSingleEntryCard = card.orderType === OrderType.Solo;
  const shareButtonText = isSingleEntryCard ? "Share" : "Invite";
  const shareButtonIcon = isSingleEntryCard ? (
    <LinkIcon />
  ) : (
    <AiOutlineUsergroupAdd />
  );

  function handleCopyCardolyLink() {
    showModal({
      modalType: "ShareCardolyModal",
      modalProps: {
        cardID,
        isSingleEntryCard,
      },
    });
  }

  const renderDeliveredAt = deliveredAt && (
    <Flex gap="xs" align={"center"}>
      <Icon as={DeliverIcon} color={"teal"} />
      <Badge colorScheme={"green"} rounded="full" px="sm">
        Delivered on {formatDate(deliveredAt)}
      </Badge>
    </Flex>
  );
  const renderIsLocked = card.isLocked && (
    <Flex gap="xs" align={"center"} color={"teal"}>
      <Icon
        as={LockIcon}
        // boxSize={4}
      />
      <Badge colorScheme={"orange"} rounded="full" px="sm">
        View Only
      </Badge>
    </Flex>
  );

  const renderCardolyOptionsMenu = (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="menu"
        icon={<MenuIcon />}
        variant="ghost"
        colorScheme="gray"
      />

      <MenuList>
        <MobileOnly>
          <MenuItem icon={shareButtonIcon} onClick={handleCopyCardolyLink}>
            {shareButtonText}
          </MenuItem>
        </MobileOnly>

        <MenuItem
          color="red.600"
          icon={<DeleteIcon />}
          onClick={handleDeleteCard}
        >
          Delete
        </MenuItem>
      </MenuList>
    </Menu>
  );

  return (
    <Center w="full" pb="lg">
      {/* Table and up */}
      <Box display={["none", null, "block"]}>
        <Box w="container.md" shadow="lg" p="8" rounded="2xl">
          <Box>
            <HStack alignItems={"flex-start"} spacing="lg">
              <Box
                onClick={handleViewCard}
                w={["20%"]}
                // _hover={{ cursor: "pointer" }}
              >
                <CardImage card={card} />
              </Box>

              <VStack alignItems="flex-start" w="full">
                <Flex width="full" justifyContent="space-between">
                  <Text
                    fontSize="2xl"
                    fontFamily={card?.titleFontFamily}
                    sx={{
                      wordBreak: "break-word",
                    }}
                  >
                    {card?.title}
                  </Text>

                  <HStack>
                    <Button
                      variant="outline"
                      colorScheme="brand"
                      onClick={handleViewCard}
                    >
                      View Cardoly
                    </Button>
                    {renderCardolyOptionsMenu}
                  </HStack>
                </Flex>
                {/* <SimpleGrid
                  columns={3}
                  spacing={3}
                  // w={["full", "80%"]}
                  w={"full"}
                > */}
                <Flex w="full">
                  <VStack alignItems={"flex-start"} flex={2}>
                    <Box>
                      <SubHeader>For</SubHeader>
                      <Text>{receiverName}</Text>
                    </Box>
                    <Box>
                      <SubHeader>Created</SubHeader>
                      <Text>{createdAt}</Text>
                    </Box>
                  </VStack>

                  <VStack alignItems={"flex-start"} flex={2}>
                    <Box>
                      <HStack>
                        <SubHeader>Posts</SubHeader>
                        {isFree && (
                          <Button
                            variant="ghost"
                            colorScheme="brand"
                            size="xs"
                            rightIcon={<UpgradeIcon />}
                            onClick={() => toSelectCardTypePage({ cardID })}
                          >
                            Get More
                          </Button>
                        )}
                      </HStack>
                      <Text>
                        {isFree ? `${entryCount || 0} of 10` : entryCount || 0}
                      </Text>
                    </Box>
                    <Box>
                      <SubHeader>Last Post Added</SubHeader>
                      <Text>{lastPostAdded ? lastPostAdded : "No posts"}</Text>
                    </Box>
                  </VStack>

                  <Flex mt="auto" justifyContent={"flex-end"}>
                    <Button
                      size={"sm"}
                      colorScheme="gray"
                      variant={"ghost"}
                      leftIcon={shareButtonIcon}
                      onClick={handleCopyCardolyLink}
                    >
                      {shareButtonText}
                    </Button>
                  </Flex>
                </Flex>
                {renderDeliveredAt}
                {renderIsLocked}
              </VStack>
            </HStack>
          </Box>
        </Box>
      </Box>

      {/* Phone to Tablet */}
      <Box display={["block", null, "none"]} w="full" maxW={"420px"}>
        <Box>
          <VStack alignItems="stretch">
            <CardImage card={card} />
            <Text fontSize="2xl" fontFamily={card?.titleFontFamily}>
              {card?.title}
            </Text>
            <HStack>
              <SubHeader>Posts</SubHeader>
              <Text>
                {isFree ? `${entryCount || 0} of 10` : entryCount || 0}
              </Text>
              {isFree && (
                <Button
                  variant="outline"
                  colorScheme="brand"
                  size="xs"
                  rightIcon={<UpgradeIcon />}
                  onClick={() => toSelectCardTypePage({ cardID })}
                >
                  Get More
                </Button>
              )}
            </HStack>
            <HStack>
              <SubHeader>For</SubHeader>
              <Text>{receiverName}</Text>
            </HStack>
            <HStack>
              <SubHeader>Created</SubHeader>
              <Text>{createdAt}</Text>
            </HStack>
            {renderDeliveredAt}
            {renderIsLocked}
            <HStack>
              <Button
                w="full"
                variant="outline"
                colorScheme="brand"
                onClick={handleViewCard}
              >
                View Cardoly
              </Button>
              {renderCardolyOptionsMenu}
            </HStack>
          </VStack>
        </Box>
      </Box>
    </Center>
  );
}

function formatLastPostAddedDate(isoString: string) {
  if (isoString) {
    return formatDistance(new Date(isoString), new Date(), {
      addSuffix: true,
    });
  }
}

function formatDate(date: string) {
  return new Date(date).toDateString();
}

function CardImage({ card }: { card: Partial<Card> }) {
  return (
    <Box
      key={card.id}
      rounded="2xl"
      overflow="hidden"
      shadow="md"
      _hover={{ shadow: "lg", cursor: "pointer" }}
    >
      <AspectRatio ratio={1}>
        {card?.bgType === "pattern" ? (
          <PillPity
            w="full"
            pattern={card.pType as Pattern}
            bgColor={card.pColorBg ?? ""}
            patternFill={card.pColorFg ?? ""}
          />
        ) : (
          <Box
            sx={{
              w: "full",
              bgImage: card.bgImageUrl ?? "",
              bgSize: "cover",
              bgPosition: "center",
              bgRepeat: "no-repeat",
            }}
          />
        )}
      </AspectRatio>
    </Box>
  );
}
