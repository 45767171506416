import { useSearchParams } from "react-router-dom";
import {
  PRODUCTS,
  SEARCH_PARAM_PAYMENT_FOR,
  SEARCH_PARAM_PAYMENT_FOR_KEY,
} from "../constants";
import { cardActions, useAppDispatch } from "../state-management";
import { useCard } from "./useCard";
import { useModal } from "./useModal";

export function usePayment() {
  // const { cardId } = useParams();
  const { cardID } = useCard();
  const { showModal } = useModal();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();

  function openPaymentModal(productId: string) {
    // TODO:  Refactor for different tiers of single card price
    const product = PRODUCTS[productId];

    dispatch(cardActions.setSelectedProduct(product));
    showModal({
      modalType: "PaymentModal",
      chakraModalProps: { size: ["full", "full", "4xl"] },
    });
  }

  const paymentFor = searchParams.get(SEARCH_PARAM_PAYMENT_FOR_KEY);

  return {
    openPaymentModal,
    cardId: cardID,
    paymentFor,
    isPaymentForSingleCard: paymentFor === SEARCH_PARAM_PAYMENT_FOR.SINGLE_CARD,
    isPaymentForSubscriptionWithCard:
      paymentFor === SEARCH_PARAM_PAYMENT_FOR.SUB_W_CARD,
    isPaymentForSubscriptionOnly:
      paymentFor === SEARCH_PARAM_PAYMENT_FOR.SUB_ONLY,
    isPaymentForAddCardToExistingSubscription:
      paymentFor === SEARCH_PARAM_PAYMENT_FOR.ADD_CARD_TO_EXISTING_SUB,
  };
}
