// https://adamhannigan81.medium.com/the-dos-and-don-ts-of-testing-apollo-in-react-9f8211a6ac91
import { setupWorker, rest } from "msw";
import { gql } from "@apollo/client";

import { buildClientSchema, execute } from "graphql";
import { addMocksToSchema } from "@graphql-tools/mock";

import introspection from "../graphql/generated/introspection.json";

// Build a schema using the introspection
const schema = buildClientSchema(introspection);

const mocks = {
  DateTime: () => {
    return new Date(); // Prevent error, "No mock defined for type \"DateTime\""
  },
};

const SIGNER_EMAIL = "sample1@sample.com";
const TEST_OWNER_COGNITO_ID = "1651abf3-2284-4faa-b960-2def41440b64";

const photo = {
  externalId: "IhyGL914g9ShHdHVFy",
  width: null,
  height: null,
  alt_description: null,
  type: "GIPHY",
  urls: {
    sm: "https://media0.giphy.com/media/IhyGL914g9ShHdHVFy/200w.webp?cid=9f0f6425bvjaf9udet5d8x70t04jo6td3sc447nggvyyuiiv&ep=v1_gifs_search&rid=200w.webp&ct=g",
    md: "https://media0.giphy.com/media/IhyGL914g9ShHdHVFy/giphy.gif?cid=9f0f6425bvjaf9udet5d8x70t04jo6td3sc447nggvyyuiiv&ep=v1_gifs_search&rid=giphy.gif&ct=g",
  },
  user: null,
  sizes: {
    sm: {
      height: "205",
      width: "200",
    },
    md: {
      height: "480",
      width: "469",
    },
  },
};

// Stub out our schema with fake data
const mockedSchema = addMocksToSchema({
  schema,
  mocks,
  resolvers: (store) => {
    // store is your `MockStore`
    return {
      Mutation: {
        emailThanksToCardSigners() {
          return [
            {
              email: SIGNER_EMAIL,
              status: "Success",
              error: null,
            },
            // {
            //   email: "sample2",
            //   status: "Success",
            //   error: "It failed",
            // },
          ];
        },
      },
      Query: {
        getCard() {
          return {
            isLocked: false,
            title: "Somto Happy Bday!",
            bgImageUrl: "/bg-images/bday-1.jpeg",
            bgType: "image",
            // pColorBg: "Hello World",
            // pColorFg: "Hello World",
            // pType: "Hello World",
            ownerName: "The Tech Team",
            titleFontFamily: "Kalam",
            orderStatus: "paid",
            orderType: "free",
            // orderType: "solo",
            isLandscape: true,
            price: 35,
            paymentIntentID: "Hello World",
            id: "f5ff9d64-e549-4ade-8093-e377b9da054f",
            receiverName: "Somto Doe",
            receiverEmail: "som@example.com",
            // receiverEmail: null,
            // deliveredAt: "2023-09-05T00:14:06.071Z",
            // readAt: "2023-09-25T00:14:06.071Z",
            deliveredAt: null,
            readAt: null,
            createdAt: "2023-09-04T00:14:06.071Z",
            updatedAt: "2023-09-04T00:14:06.071Z",
            ownerCognitoID: TEST_OWNER_COGNITO_ID,
            entryCount: 60,
            lastEntryCreatedAt: "2023-09-04T00:14:06.071Z",
            lastEntryCreatedId: "Hello World",
            subscriptionId: "Hello World",
            status: "Hello World",
            __typename: "Card",
          };
        },
        getCardEntriesByCardId() {
          return {
            items: [
              {
                photo,
                cardID: "218ad4ac-a015-484a-b6da-efe4dd42255d",
                entryMessage: "Wow. What of em eh, new navy??",
                font: {
                  fontFamily: "Kalam",
                  color: "#00f",
                  __typename: "Font",
                },

                signerName: "Hello World",
                signerCognitoID: TEST_OWNER_COGNITO_ID,
                id: "4606c790-ec3b-4245-accb-d4f435023d3b",
                updatedAt: "2024-03-08T03:16:33.973Z",
                createdAt: "2024-03-08T03:16:33.973Z",
                __typename: "CardEntry",
              },
            ],
            nextToken: "Hello World",
            __typename: "GetCardEntriesByCardIdResponse",
          };
        },
        getCardsByOwnerCognitoId() {
          return {
            items: [
              {
                id: "4",
                ownerCognitoID: "8ee8a223-55f2-4c03-b09a-ac398db76799",
                createdAt: "2022-02-26T05:33:31.257Z",
                title: "4th Card",
                titleFontFamily: "Caveat",
                orderType: "free",
                orderStatus: "unpaid",
                receiverName: "John Doe",
                paymentIntentID:
                  "pi_3LbcZxIUMCWr2xgV1OoW5YCC_secret_o5hsu9hXKUgAFOa0tLbslT8yZ",
                // ...BG_CONFIG,
                pType: "i-like-food",
                bgType: "pattern",
                pColorBg: "#fff",
                pColorFg: "#00f",
              },
            ],
          };
        },
        getCardSigners() {
          return [
            {
              signerEmail: SIGNER_EMAIL,
              signerCognitoId: "1",
              signerName: "John",
              emailedAt: null,
            },
            // {
            //   signerEmail: "Hello World",
            //   signerCognitoId: "Hello World",
            //   signerName: "Hello World",
            //   emailedAt: "2023-10-30T02:55:07.544Z",
            //   __typename: "CardsToSignersObject",
            // },
          ];
        },
      },
    };
  },
});

// Bearer eyJraWQiOiJaUzd6ZVwvRkg5bVA0UVZsd0IxNDBud0R1K0JTK3ZQMVZcL3BvU3Y0YkhCaVU9IiwiYWxnIjoiUlMyNTYifQ.eyJzdWIiOiI4ZWU4YTIyMy01NWYyLTRjMDMtYjA5YS1hYzM5OGRiNzY3OTkiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiaXNzIjoiaHR0cHM6XC9cL2NvZ25pdG8taWRwLnVzLWVhc3QtMS5hbWF6b25hd3MuY29tXC91cy1lYXN0LTFfMDdmd2tlQlV1IiwiY29nbml0bzp1c2VybmFtZSI6IjhlZThhMjIzLTU1ZjItNGMwMy1iMDlhLWFjMzk4ZGI3Njc5OSIsIm9yaWdpbl9qdGkiOiI0NzA0ZWI1Yi03YWIxLTRhNmItOGE4ZC00MDE3OGFmZTZiNTYiLCJhdWQiOiIyN3VtaXNybjJuMWpiNHRsZGJtMGJ1aDR2NyIsImV2ZW50X2lkIjoiYjRjYmEwNjUtNDdmYi00ZjY5LWJjOWYtNGYwNGEzYTU2NWNjIiwidG9rZW5fdXNlIjoiaWQiLCJhdXRoX3RpbWUiOjE2ODk4MDk2MTAsIm5hbWUiOiJBbGV4YW5kZXIgUyBPa29saSIsImV4cCI6MTY4OTgxMzIxMCwiaWF0IjoxNjg5ODA5NjEwLCJqdGkiOiJjNGVjZTkwYy1kMGMzLTQ1NDEtOGI2Yi00ODQ1NjYwMTVlOTYiLCJlbWFpbCI6Im9rb2xpYWxleEB5YWhvby5jb20ifQ.NunzTihl7nppEwytcfBZ-kDHV185MWFK9PoIDHOVTw_P6v658UTAp0qEi6BqTL6agtF8MJ7GPSwYTmGIuD6lUJkj-4USrB9IDHjustn2lHTHKe1kSZxfr6DnJsQc1crmKyK9OVlIEI1TcrK37Az7_ag45_26AnyGOO9ibO8-C9nqyb2rh0Gephr0vWyliiW4FhZcId5uIib7q5cGs7LSSywsVdA0xJPjaw-N009bSExDhKjxRPKJ3f7RfoLIbTmNcq8hmlVb5wUGEMO6mfhuDgBaRyhwWTkzaL4ejqY2IsS6VAIzIBZloImt-VbnqZBKUVq0cOKeF0y8WNqwWsV7TA
// Set up a server that reads a GraphQL document and returns the data for it
export const serverWorker = setupWorker(
  rest.post("http://localhost:4000/*", async (req, res, ctx) => {
    const result = await execute({
      schema: mockedSchema,
      document: gql`
        ${req.body.query}
      `,
      variableValues: req.body.variables,
    });

    return res(ctx.json(result));
  })
);
