// TODO: This info for products SHOULD be coming from the backend. Use monorepo/database to get in order.
export interface Product {
  id: string;
  price: string;
  priceInt?: number;
  name: string;
  isMostPopular?: boolean;
  // If bundle key is present, isBundle === true
  // Each bundle will have Premium cards
  bundle?: {
    quantity: number;
    discount: number; // 15 = 15%
    singleCardPrice: number;
    singleCardDiscountPrice: string;
  };
  stripePriceId?: string;
}

// TODO: This info for products SHOULD be coming from the backend. Use monorepo/database to get in order.
function getPackName(packNum: number) {
  // return `Pack of ${packNum}`;
  return `${packNum} Premium Cardolies`;
  // return `${packNum} Premium Cardoly Pack`;
  // return `${packNum} Pack Premium Cardoly`;
  // return `${packNum} Pack, Premium Cardoly`;
  // return `${packNum} Cardoly Pack`;
}

// PACK IDs
// const pack1 = "price_1MMNJhIUMCWr2xgVBbwRCdKH";
// const pack1 = 'price_1NZPhbIUMCWr2xgVKkkGEJUU';
// const pack10 = "price_1OTF0RIUMCWr2xgVGk4u0SPj";
// const pack25 = "price_1NbbcpIUMCWr2xgV17B8JXy7";
// const pack50 = "price_1Nc6xpIUMCWr2xgVjPwF4qz7";

export const SINGLE_CARD_PRICE = 4.99; // Ensure it remains decimal, as BE is using for computation

function getPackPriceAndName({
  discount, // 4 = 4%
  singleCardPrice, // 4.99
  quantity, // 10
}: {
  singleCardPrice: number;
  discount: number;
  quantity: number;
}) {
  const fullPrice = quantity * singleCardPrice;
  const discountPrice = fullPrice * (discount / 100);
  const priceInt = Math.floor(fullPrice - discountPrice);
  const price = priceInt.toFixed(0);
  const singleCardDiscountPrice = (priceInt / quantity).toFixed(2);

  return {
    name: getPackName(quantity),
    price,
    priceInt,
    bundle: {
      discount,
      quantity,
      singleCardPrice,
      singleCardDiscountPrice,
    },
  };
}

export const PRODUCTS: Record<string, Product> = {
  mini: { id: "mini", price: "0", priceInt: 0, name: "Mini Cardoly" },
  premium: {
    id: "premium",
    price: SINGLE_CARD_PRICE.toString(),
    priceInt: SINGLE_CARD_PRICE,
    name: "Premium Cardoly",
    isMostPopular: true,
  },
  pack10: {
    id: "pack10",
    stripePriceId: "price_1OTF0RIUMCWr2xgVGk4u0SPj",
    ...getPackPriceAndName({
      singleCardPrice: SINGLE_CARD_PRICE,
      quantity: 10,
      discount: 15,
    }),
  },
  pack25: {
    id: "pack25",
    stripePriceId: "price_1NbbcpIUMCWr2xgV17B8JXy7",
    ...getPackPriceAndName({
      singleCardPrice: SINGLE_CARD_PRICE,
      quantity: 25,
      discount: 25,
    }),
    isMostPopular: true,
  },
  pack50: {
    id: "pack50",
    stripePriceId: "price_1Nc6xpIUMCWr2xgVjPwF4qz7",
    ...getPackPriceAndName({
      singleCardPrice: SINGLE_CARD_PRICE,
      quantity: 50,
      discount: 40,
    }),
  },
};
