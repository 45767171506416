import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Input,
  VStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useAuth, useMutation, useToast } from "../../../hooks";
import {
  forgotPassword,
  forgotPasswordSubmit,
} from "../../../hooks/useAuth/authActions";

const MAX_LENGTH_EMAIL = 200;
const SUCCESS_TOAST_DURATION = 3000;
const ERROR_TOAST_DURATION = 9000;
type ForgotPasswordState = "send_confirmation_code" | "submit_new_password";
interface FormInputs {
  email: string;
}
interface FormInputsSubmitPassword {
  code: string;
  oldPassword: string;
  newPassword: string;
}
export function ForgotPassword() {
  const { user } = useAuth();
  const { email } = user;
  const [forgotPasswordStatus, setForgotPasswordStatus] =
    useState<ForgotPasswordState>("send_confirmation_code");

  const {
    // reset,
    setValue,
    getValues,
    register,
    handleSubmit,
    // watch,
    // defaultValues: {},
    formState: { errors }, // errors are subscribed and reactive to state update
  } = useForm<FormInputs>();

  const {
    // reset: resetSubmitPassword,
    // setValue: setValueSubmitPassword,
    getValues: getValuesSubmitPassword,
    register: registerSubmitPassword,
    handleSubmit: handleSubmitPassword,
    // watch,
    // defaultValues: {},
    formState: { errors: errorsSubmitPassword }, // errors are subscribed and reactive to state update
  } = useForm<FormInputsSubmitPassword>();

  const {
    error,
    isLoading: isLoadingSendCode,
    isSuccess: isSuccessSendCode,
    mutate,
    isError: isErrorSendCode,
  } = useMutation({
    mutationFn: forgotPassword,
  });

  const {
    error: errorSubmitPassword,
    isLoading: isLoadingSubmitPassword,
    isSuccess: isSuccessSubmitPassword,
    mutate: mutateSubmitPassword,
    isError: isErrorSubmitPassword,
  } = useMutation({
    mutationFn: forgotPasswordSubmit,
  });

  useToast({
    id: "isErrorSendCode",
    show: isErrorSendCode,
    status: "error",
    title: "Error",
    duration: ERROR_TOAST_DURATION,
    description: error?.message,
  });

  useToast({
    id: "isSuccessSendCode",
    show: isSuccessSendCode,
    status: "success",
    title: "Success",
    duration: SUCCESS_TOAST_DURATION,
    description: "Sent code successfully!",
  });

  useToast({
    id: "isErrorSubmitPassword",
    show: isErrorSubmitPassword,
    status: "error",
    title: "Error",
    duration: ERROR_TOAST_DURATION,
    description: errorSubmitPassword?.message,
  });

  useToast({
    id: "isSuccessSubmitPassword",
    show: isSuccessSubmitPassword,
    status: "success",
    title: "Success",
    duration: SUCCESS_TOAST_DURATION,
    description: "Submitted password successfully!",
  });

  // const email = "okolialex@yahoo.com"; // TODO: Make dynamic
  // const email = ""; // TODO: Make dynamic

  function onSubmit(values: FormInputs) {
    mutate({ username: values.email });
  }
  function onSubmitPassword(values: FormInputsSubmitPassword) {
    mutateSubmitPassword({
      username: getValues("email"),
      confirmationCode: values.code,
      newPassword: values.newPassword,
    });
  }

  useEffect(() => {
    if (isSuccessSendCode) {
      setForgotPasswordStatus("submit_new_password");
    }
  }, [isSuccessSendCode]);

  useEffect(() => {
    if (email) {
      setValue("email", email);
    }
  }, [email, setValue]);

  const boundSubmitSendCode = handleSubmit(onSubmit);

  return (
    <Box maxW="sm" mx="auto">
      {forgotPasswordStatus === "send_confirmation_code" ? (
        <>
          <form onSubmit={boundSubmitSendCode}>
            <VStack spacing="md" alignItems={"stretch"}>
              <FormControl isInvalid={!!errors?.email}>
                <FormLabel>Reset your password</FormLabel>
                <Input
                  disabled={true}
                  placeholder="Enter your email"
                  {...register("email", {
                    maxLength: MAX_LENGTH_EMAIL,
                    required: true,
                  })}
                />

                {errors?.email?.type === "maxLength" && (
                  <FormErrorMessage>
                    You can't use more than {MAX_LENGTH_EMAIL} characters
                  </FormErrorMessage>
                )}
                {errors?.email?.type === "required" && (
                  <FormErrorMessage>This field is required</FormErrorMessage>
                )}
              </FormControl>

              <Button type="submit" isLoading={isLoadingSendCode}>
                Send code to your email
              </Button>
            </VStack>
          </form>
        </>
      ) : (
        <form onSubmit={handleSubmitPassword(onSubmitPassword)}>
          <VStack gap="md" alignItems={"stretch"}>
            <FormControl isInvalid={!!errorsSubmitPassword?.code}>
              <Input
                placeholder="Enter code"
                {...registerSubmitPassword("code", {
                  required: true,
                })}
              />
              {errorsSubmitPassword?.code?.type === "required" && (
                <FormErrorMessage>This field is required</FormErrorMessage>
              )}
            </FormControl>

            <FormControl isInvalid={!!errorsSubmitPassword?.oldPassword}>
              <Input
                placeholder="New password"
                type="password"
                {...registerSubmitPassword("oldPassword", {
                  required: true,
                })}
              />
              {errorsSubmitPassword?.oldPassword?.type === "required" && (
                <FormErrorMessage>This field is required</FormErrorMessage>
              )}
            </FormControl>

            <FormControl isInvalid={!!errorsSubmitPassword?.newPassword}>
              <Input
                placeholder="Confirm password"
                type="password"
                {...registerSubmitPassword("newPassword", {
                  required: true,
                  validate: (val: string) => {
                    if (getValuesSubmitPassword("oldPassword") !== val) {
                      return "Your passwords do no match";
                    }
                  },
                })}
              />
              {errorsSubmitPassword?.newPassword?.type === "required" && (
                <FormErrorMessage>This field is required</FormErrorMessage>
              )}
              {errorsSubmitPassword?.newPassword?.type === "validate" && (
                <FormErrorMessage>
                  {errorsSubmitPassword?.newPassword.message}
                </FormErrorMessage>
              )}
            </FormControl>
            <Button
              type="submit"
              disabled={isLoadingSubmitPassword || isLoadingSendCode}
            >
              Submit
            </Button>

            <Button
              disabled={isLoadingSendCode}
              isLoading={isLoadingSendCode}
              variant={"ghost"}
              onClick={() => {
                boundSubmitSendCode();
              }}
            >
              Resend Code
            </Button>
          </VStack>
        </form>
      )}
    </Box>
  );
}
