import { createApi } from "@reduxjs/toolkit/query/react";
import { API, Auth } from "aws-amplify";

// Queries
import * as graphqlQueries from "./graphql-queries";
import { USE_FIXTURES } from "../constants";
import { SAMPLE_API_RESPONSES } from "./fixtures";
import { isEmpty } from "lodash";

async function getCognitoAuthHeader() {
  try {
    return {
      Authorization: `Bearer ${(await Auth.currentSession())
        .getIdToken()
        .getJwtToken()}`,
    };
  } catch (e) {
    return {};
  }
}

function graphqlBaseQuery() {
  return async ({
    queryString,
    variables,
  }: {
    queryString: string;
    variables?: any;
  }) => {
    try {
      if (USE_FIXTURES) {
        return SAMPLE_API_RESPONSES;
      }

      const cognitoAuthHeaders = await getCognitoAuthHeader();
      const isPublic = isEmpty(cognitoAuthHeaders);
      let apiName = "cardoly-graphql";

      if (isPublic) {
        apiName = "cardoly-graphql-public";
      }

      const result: any = await API.post(apiName, "", {
        body: {
          query: queryString,
          variables,
        },
        headers: {
          "content-type": "application/json",
          ...cognitoAuthHeaders,
        },
      });

      if (result?.errors) {
        throw result;
      }

      return { data: result };
    } catch (e) {
      const err: any = e;
      let error = { message: "", code: "", rawApiError: e };
      let nestErr = err?.errors?.[0];

      // Regular Error
      if (err?.message) {
        error.message = err.message;
      }

      // GraphQL / Nest error
      if (nestErr) {
        error.message = nestErr?.message;
        error.code = nestErr.extensions?.code;
      }

      return {
        error,
      };
    }
  };
}

export const cardolyApi = createApi({
  reducerPath: "cardolyApi",
  baseQuery: graphqlBaseQuery(),
  tagTypes: ["Entries", "Card", "Cards", "User", "Subscriptions"],
  endpoints: (builder) => ({
    getUnsplashPhotos: builder.query<
      { photos: CardolyPhoto[]; total: number },
      { queryText: string; page: number; perPage: number }
    >({
      query: (variables) => ({
        queryString: graphqlQueries.getUnsplashPhotos,
        variables,
      }),
      transformResponse: (response) => {
        const photosData = response?.data?.getPhotos;
        return {
          photos: photosData?.photos,
          total: photosData?.total,
        };
      },
    }),
  }),
});
