import { PRODUCT_NAME_SINGULAR, SINGLE_CARD_PRICE } from "../../../constants";
import { useCard } from "../../../hooks";
import { PaymentButton } from "../PaymentSection";
import { CreateCardolyButton } from "./CreateCardolyButton";
import { Pricing } from "./Pricing";

export function PremiumPricingCard() {
  const { cardID } = useCard();

  return (
    <Pricing.Card>
      <Pricing.ProductType>Premium</Pricing.ProductType>
      <Pricing.Price>${SINGLE_CARD_PRICE}</Pricing.Price>
      <Pricing.CardNumber>1 {PRODUCT_NAME_SINGULAR}</Pricing.CardNumber>
      <Pricing.FeatureList
        features={[
          "Unlimited posts",
          "Use multiple fonts",
          "Custom font colors",
          "Add messages and GIFs",
          "Many backgrounds",
          "Desktop & mobile friendly",
          "Accessible forever",
          "No advertisements",
          "Deliver online",
        ]}
      />
      <Pricing.Footer>
        {cardID ? (
          <PaymentButton productId="premium" />
        ) : (
          <CreateCardolyButton productId="premium" />
        )}
      </Pricing.Footer>
    </Pricing.Card>
  );
}
