import { Appearance } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import { CheckoutForm } from "./CheckoutForm";
import { Box } from "@chakra-ui/react";
import { useStripeObject } from "../../useStripeObject";
import { useStripeClientSecretForSubscription } from "./useStripeClientSecretForSubscription";
import { useAppSelector } from "../../../../state-management";
import { Loader } from "../../../../components";

const appearance = {
  theme: "stripe" as Appearance["theme"],
};

export function PaymentFormForSubscription() {
  // TODO productId. Make server-served
  const selectedProduct = useAppSelector((state) => state.card.selectedProduct);
  const productId = selectedProduct?.id;

  const { stripe } = useStripeObject();
  const { clientSecret, subscriptionId, loading, error } =
    useStripeClientSecretForSubscription({
      productId,
    });

  const options = {
    clientSecret,
    appearance,
  };

  if (error?.message) {
    return <Box>{error?.message}</Box>;
  }

  if (loading) {
    return <Loader />;
  }

  return (
    <Box w="full">
      {clientSecret && (
        <Elements options={options} stripe={stripe}>
          <CheckoutForm
            clientSecret={clientSecret}
            subscriptionId={subscriptionId}
          />
        </Elements>
      )}
    </Box>
  );
}
