import {
  UpgradeCardAlert,
  CardEntryList,
  CardHeader,
  CardBackgroundWithBodyPortal,
  OpeningSequence,
  CardSettings,
} from "./components";
import { useCard, useRoutes } from "../../hooks";
import {
  Spinner,
  Icon,
  Box,
  Center,
  Text,
  HStack,
  VStack,
} from "@chakra-ui/react";
import { RiWifiOffLine as NetworkDownIcon } from "react-icons/ri";
import { PageContainer } from "../../components";
import { RedirectTo404 } from "../../feature";
import { AddEntryOrUpgradeCardButton } from "./components/CardHeader/AddEntryOrUpgradeCardButton";
import { useOpeningAnimation } from "../../hooks/useOpeningAnimation";
import { useEffect } from "react";
import { useMatch, useSearchParams } from "react-router-dom";
import { useMarkCardAsReadMutation } from "../../graphql/generated/schema";
import { SingleEntryBody } from "./components/SingleEntryBody";
import { useDocumentTitle } from "usehooks-ts";

export function CardolyCardPage() {
  const { isLoading, error, cardId, card, isSolo } = useCard();
  const receiverName = card?.receiverName;
  const demoMatch = useMatch("/card/demo/");
  const isDemo = !!demoMatch;
  useDocumentTitle(
    `${isDemo ? "Demo Cardoly" : `${receiverName}'s Cardoly`} | Cardoly`
  );
  const isAlreadyRead = !!card?.readAt;

  const { handleStopOpeningSequence } = useOpeningAnimation();
  let [searchParams] = useSearchParams();
  const readId = searchParams.get("rid");

  const [markCardAsRead] = useMarkCardAsReadMutation();

  useEffect(() => {
    if (readId && cardId && !isAlreadyRead && !isLoading) {
      markCardAsRead({
        variables: {
          input: {
            cardId,
            readId,
          },
        },
      });
    }
  }, [cardId, isAlreadyRead, markCardAsRead, readId, isLoading]);

  if (isLoading) {
    return (
      <Center mt="8">
        <Spinner color="brand.500" size="lg" />
      </Center>
    );
  }

  if (error) {
    let errorMessage = error?.message;
    let is404 = error?.graphQLErrors?.[0]?.extensions.code === "404"; // TODO: Figure out not found for GQL error?.code === "404";

    if (is404) {
      return <RedirectTo404 />;
    }

    const isOffline = errorMessage === "Network Error";
    if (isOffline) {
      errorMessage = "Something went wrong.";
    }

    return (
      <Center mt="10">
        <HStack>
          <Text align="center" color="red.600">
            {errorMessage}
          </Text>
          {isOffline && <Icon as={NetworkDownIcon} color="red.600" />}
        </HStack>
      </Center>
    );
  }

  return (
    <Box pos="relative">
      <OpeningSequence onCompleteOpenAnimation={handleStopOpeningSequence} />
      <CardBackgroundWithBodyPortal />
      {isSolo ? <SingleEntryBody /> : <MultiEntryBody />}
    </Box>
  );
}

function MultiEntryBody() {
  const { isRecipientMode } = useRoutes();

  return (
    <Box mb="6">
      <UpgradeCardAlert />
      <Center bg="white">
        <VStack p="sm">
          <CardSettings />
          {!isRecipientMode && <AddEntryOrUpgradeCardButton />}
        </VStack>
      </Center>

      <CardHeader />
      <PageContainer>
        <CardEntryList />
      </PageContainer>
    </Box>
  );
}
