import {
  Button,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalHeader,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { SEARCH_PARAM_PAYMENT_FOR } from "../constants";
import { useAddCardToSubscriptionMutation } from "../graphql/generated/schema";
import { useModal, useRoutes, useToast } from "../hooks";

interface PayWithSubscriptionModalProps {
  cardId: string;
  subscriptionId: string;
}

export function PayWithSubscriptionModal({
  cardId,
  subscriptionId,
}: PayWithSubscriptionModalProps) {
  const { hideModal } = useModal();
  const nav = useRoutes();
  const [addCardToSubscription, { loading: isLoading, error }] =
    useAddCardToSubscriptionMutation();

  useToast({
    id: "useAddCardToSubscriptionMutation",
    show: !!error,
    title: "Something went wrong",
    description: error?.message,
    status: "error",
  });

  function handlePayWithSubscription() {
    if (cardId && subscriptionId) {
      addCardToSubscription({
        variables: {
          input: {
            subscriptionId,
            cardId,
          },
        },
        onCompleted() {
          hideModal();
          // Show payment success page
          nav.toPaymentPageSuccess(
            SEARCH_PARAM_PAYMENT_FOR.ADD_CARD_TO_EXISTING_SUB
          );
        },
      });
    }
  }

  return (
    <>
      <ModalHeader>Get Cardoly with Subscription</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        <Text display="inline">
          You are about to get a Cardoly with your Subscription. Proceed?
        </Text>
      </ModalBody>

      <ModalFooter>
        <Button variant="ghost" colorScheme={"gray"} onClick={hideModal} mr={2}>
          Cancel
        </Button>
        <Button
          colorScheme="brand"
          isLoading={isLoading}
          // isLoading={isLoadingPayWithSubscription}
          onClick={handlePayWithSubscription}
        >
          Yes
        </Button>
      </ModalFooter>
    </>
  );
}
