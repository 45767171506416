import {
  Badge,
  BadgeProps,
  Box,
  BoxProps,
  Flex,
  Heading as ChakraHeading,
  HeadingProps,
  List,
  ListIcon,
  ListItem,
  Text,
  TextProps,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";
import { HiCheckCircle as CheckIcon } from "react-icons/hi";

export interface PricingCardData {
  features: string[];
  name: string;
  price: string;
}

function ProductType(props: HeadingProps) {
  return (
    <ChakraHeading
      size="md"
      fontWeight="extrabold"
      textAlign={"center"}
      {...props}
    />
  );
}

function Price(props: HeadingProps) {
  return (
    <ChakraHeading
      size="3xl"
      textAlign="center"
      color="blue.500"
      mt="8"
      // my="8"
      {...props}
    />
  );
}

function FeatureList({ features }: { features: string[] }) {
  const accentColor = useColorModeValue("blue.600", "blue.200");

  return (
    <List spacing="4" maxW="28ch" mx="auto" mb="auto">
      {features.map((feature, index) => (
        <ListItem fontWeight="medium" key={index} textAlign="left">
          <ListIcon
            fontSize="xl"
            as={CheckIcon}
            marginEnd={2}
            color={accentColor}
          />
          {feature}
        </ListItem>
      ))}
    </List>
  );
}

function Card({ children }: { children: React.ReactNode }) {
  return (
    <Flex
      flexDirection="column"
      rounded={{ sm: "xl" }}
      p="8"
      sx={{
        border: "solid",
        borderColor: "transparent",
        borderWidth: "2px",
        shadow: "md",
      }}
      // w={["100%", "100%", "300px"]}
      w={"300px"}
    >
      {children}
    </Flex>
  );
}

function Footer(props: BoxProps) {
  return <Box mt="8" {...props} />;
}

function CardNumber(props: TextProps) {
  return (
    <Text
      mt="8"
      mb="md"
      textAlign="center"
      fontWeight={"bold"}
      textColor="gray.700"
      {...props}
    />
  );
}

function SavingsBadge(props: BadgeProps) {
  return (
    <Badge
      fontSize="sm"
      colorScheme="green"
      variant="subtle"
      px="sm"
      rounded="md"
      // rounded="full"
      {...props}
    />
  );
}

export const Pricing = {
  Card,
  CardNumber,
  Price,
  ProductType,
  FeatureList,
  Footer,
  // Subscription
  SavingsBadge,
};
